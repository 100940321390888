import React, { useEffect, useState } from 'react'
import Select from 'react-select'

function DataSelect({ data, selectedItem, updateItem, placeholder }) {
  const [initItem, setInitItem] = useState(selectedItem)
  const handleChange = selectedItem => {
    updateItem(selectedItem)
    setInitItem(selectedItem)
  }
  data.map(el => {
    el.value = el.id
    el.label = el.name
  })

  useEffect(() => {
    if (selectedItem) {
      let item = null
      if (typeof selectedItem == 'string') item = JSON.parse(selectedItem)
      if (typeof selectedItem == 'object') item = selectedItem
      if (item) {
        //console.log('ItemSelect->useEffect->', item)
        setInitItem(item)
      }
    }
  }, [selectedItem])

  return <Select className="general-select" name="general-select" value={initItem} options={data} placeholder={placeholder} onChange={handleChange} />
}

export default DataSelect
