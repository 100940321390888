import React, { useState, useEffect, useRef } from 'react'

import { getData, postData } from 'utils/server'
import { notificationOptions, getAuthorizationHeader } from 'utils/helpers'
import NotificationAlert from 'react-notification-alert'
import CustomSelect from 'components/Inputs/CustomSelect'
import DataSelect from 'components/Inputs/generalDataSelect'
import MonthSelect from 'components/Inputs/MonthsSelect'
import { Chart } from 'chart.js/auto'
import { initPieChart } from './initializers/initPieChart'

import csvDownload from 'json-to-csv-export'
import _ from 'lodash'
import moment from 'moment/moment'

// react-bootstrap components
import { Badge, Button, Card, Navbar, Nav, Table, Container, Row, Col, Form, OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap'

let myChart = null
let currentYear = moment().year()

export default function FixtureStats({ autoload, collapsed }) {
  const controller = new AbortController()

  const notificationAlertRef = useRef(null)
  const [stats, setStats] = useState([])
  const [visible, setVisible] = useState(collapsed)
  const [selectedProvider, setSelectedProvider] = useState({ value: 0, label: 'All providers' })
  const [providers, setProviders] = useState([])
  const [selectedStatus, setSelectedStatus] = useState({ value: '1', label: 'In progress', id: '1', name: 'In progress' })
  const [startDate, setStartDate] = useState(moment(`01-01-${currentYear}`, 'DD-MM-YYYY').unix() * 1000)
  const [endDate, setEndDate] = useState(moment().endOf('month').unix() * 1000)
  const [disabled, setDisabled] = useState(false)
  const [icon, setIcon] = useState(<i className="fas fa-file-export"></i>)
  const [message, setMessage] = useState('')

  const provider = async () => {
    return selectedProvider
  }

  const status = async () => {
    return selectedStatus
  }

  const date1 = async () => {
    return startDate
  }

  const date2 = async () => {
    return endDate
  }

  const minimizeChart = () => {
    if (visible) setVisible(false)
    if (!visible) setVisible(true)

    if (!myChart) {
      fetchStats(controller)
    }
  }

  const createDataset = response => {
    return [
      {
        label: 'Total',
        data: response.data.map(o => o.Total)
      }
    ]
  }

  const statsHandler = async controller => {
    if (visible) return
    setDisabled(true)
    setIcon(<i className="fas fa-file-export text-primary"></i>)
    setMessage(
      <div className="text-center">
        <i className="fas fa-spinner fa-spin text-center loadingSpinnerColor"></i>
        <span className="loadingSpinnerColor"> Loading Data ...</span>
      </div>
    )
    if (stats == []) {
      setDisabled(true)
      setIcon(<i className="fas fa-file-export text-primary"></i>)
    }
    let currentProvider = await provider()
    let currentStatus = await status()
    let startDate = await date1()
    let endDate = await date2()
    console.log(currentProvider)
    console.log(currentStatus)
    console.log(moment(startDate)._d)
    console.log(moment(endDate)._d)

    let request = {
      from_date: moment(startDate).format('MMMM') + ' ' + moment(startDate).format('YYYY'),
      to_date: moment(endDate).format('MMMM') + ' ' + moment(endDate).format('YYYY'),
      provider_id: currentProvider.value,
      status: currentStatus.label
    }

    let response = await postData('/api/fixture/stats', request, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (response != null) {
      response.data.sort((a, b) => {
        return a.sport < b.sport ? -1 : 1
      })
      setStats(response.data)
      let sports = response.data.map(o => o.sport)
      myChart.data.datasets = createDataset(response)
      myChart.data.labels = sports
      myChart.update()
      setIcon(<i className="fas fa-file-export"></i>)
      setDisabled(false)
    } else notificationAlertRef.current.notificationAlert(notificationOptions('There are no data available for these options', 'danger'))
    setMessage('')
  }

  const fetchStats = async controller => {
    setDisabled(true)
    setMessage(
      <div className="text-center">
        <i className="fas fa-spinner fa-spin text-center loadingSpinnerColor"></i>
        <span className="loadingSpinnerColor"> Loading Data ...</span>
      </div>
    )
    setIcon(<i className="fas fa-file-export text-primary"></i>)
    let providers_response = await getData('/api/providers', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (providers_response != null) {
      let data = providers_response.data
      data.unshift({ id: 0, name: 'All providers' })
      setProviders(providers_response.data)
      setSelectedProvider({ value: 0, label: 'All providers' })
    }
    console.log(moment(startDate)._d)
    console.log(moment(endDate)._d)
    let request = {
      from_date: moment(startDate).format('MMMM') + ' ' + moment(startDate).format('YYYY'),
      to_date: moment(endDate).format('MMMM') + ' ' + moment(endDate).format('YYYY'),
      provider_id: selectedProvider.value,
      status: selectedStatus.label
    }

    let response = await postData('/api/fixture/stats', request, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (response != null) {
      response.data.sort((a, b) => {
        return a.sport < b.sport ? -1 : 1
      })
      setStats(response.data)
      let sports = response.data.map(o => o.sport)
      let dataSet = createDataset(response)
      console.log(dataSet)
      let statsChart = document.getElementById('fixtureStats')

      if (myChart) {
        await myChart.destroy()
      }

      myChart = initPieChart(statsChart, sports, dataSet, true, 'bottom', false)
      
      setDisabled(false)
      setIcon(<i className="fas fa-file-export"></i>)
      myChart.update()
    }

    setMessage('')
  }

  const exportToCSV = () => {
    const obj = stats[0]
    let months = Object.getOwnPropertyNames(obj)
    months.shift()
    let totals = {
      sport: 'Total'
    }
    for (let i = 0; i < months.length; i++) {
      if (!_.has(totals, months[i])) totals[months[i]] = 0
      totals[months[i]] = stats.map(o => o[months[i]]).reduce((a, b) => a + b, 0)
    }
    stats.push(totals)
    const dataToCSV = {
      data: stats,
      filename: `from_${moment(startDate).format('DD/MM/YYYY')}_to_${moment(endDate).format('DD/MM/YYYY')}_${selectedProvider.label}_${selectedStatus.label}_coverage`,
      delimiter: ';'
    }
    csvDownload(dataToCSV)
    notificationAlertRef.current.notificationAlert(notificationOptions('Your file created successfully', 'success'))
  }

  const updateStatsSelectedProvider = selProv => {
    setSelectedProvider(selProv)
  }

  const updateStatus = status => {
    setSelectedStatus(status)
  }

  const updateStartDate = startDate => {
    setStartDate(moment(startDate).unix() * 1000)
  }

  const updateEndDate = endDate => {
    let date = moment(endDate)
    setEndDate(date.clone().endOf('month').unix() * 1000)
  }

  useEffect(() => {
    if (autoload) fetchStats(controller)

    return () => {
      controller.abort()
      if (myChart) {
        myChart.destroy()
        myChart = null
      }
    }
  }, [])

  useEffect(() => {
    if (myChart && selectedProvider && selectedStatus && endDate) statsHandler(controller)
  }, [selectedProvider, selectedStatus, startDate, endDate])

  return (
    <Card>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Card.Header>
        <Card.Title as="h4">
          Fixture Stats
          <div style={{ position: 'absolute', top: '5px', right: '10px' }} onClick={minimizeChart}>
            <i style={{ display: visible ? '' : 'none' }} className="fas fa-angle-down custom-button-like"></i>
            <i style={{ display: visible ? 'none' : '' }} className="fas fa-angle-up custom-button-like"></i>
          </div>
        </Card.Title>
        <Row style={{ display: visible ? 'none' : '' }}>
          <Col md="12">
            <Row>
              <Col md="3">
                <label>From:</label>
                <MonthSelect selectedDate={startDate} handleDateChange={updateStartDate} />
              </Col>
              <Col md="3">
                <label>To:</label>
                <MonthSelect selectedDate={endDate} handleDateChange={updateEndDate} />
              </Col>
              <Col md="3">
                <Form.Group>
                  <label>Provider:</label>
                  <DataSelect data={providers} updateItem={updateStatsSelectedProvider} selectedItem={selectedProvider} placeholder={`Select Provider...`}/>
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group>
                  <label>Status:</label>
                  <CustomSelect
                    options={[
                      { id: '1', name: 'In progress', value: '1', label: 'In progress' },
                      { id: '2', name: 'Not started yet', value: '2', label: 'Not started yet' }
                    ]}
                    selectedItem={'{ "id": "1", "name": "In progress", "value": "1", "label": "In progress" }'}
                    setName="status"
                    updateSelection={updateStatus}
                  ></CustomSelect>
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body style={{ display: visible ? 'none' : '' }}>
        <div className="stats-chart" id="fixtureStatsChartHolder" style={{ display: message === '' ? '' : 'none' }}>
          <div style={{ height: '30rem' }}>
            <canvas id="fixtureStats" height="500px auto" style={{ height: '500px auto' }} />
          </div>
        </div>
        <div>{message}</div>
        <div style={{ textAlign: 'right' }}>
          <hr style={{ display: message === '' ? '' : 'none' }}></hr>
          <Button variant="primary" onClick={exportToCSV} title="Export data to CSV" disabled={disabled} style={{ display: message === '' ? '' : 'none' }}>
            export Data {icon}
          </Button>
        </div>
      </Card.Body>
      <Card.Footer>
        <hr></hr>
        <div className="stats">
          <i className="fas fa-history"></i>
          Updated 3 minutes ago
        </div>
      </Card.Footer>
    </Card>
  )
}
