import React, { useState, useEffect } from 'react'
import { Button, Modal, Form, Row, Col } from 'react-bootstrap'
import NotificationAlert from 'react-notification-alert'

import { getData } from 'utils/server'
import { notificationOptions, getAuthorizationHeader } from 'utils/helpers'

const _ = require('lodash')

function SendCoverageLost({ showModal, signalCloseModal, eventId }) {
  const notificationAlertRef = React.useRef(null)
  const [roleId, setRoleId] = useState(null)
  const [show, setShow] = useState(false)
  const [receivedResponse, setReceivedResponse] = useState(false)
  const [data, setData] = useState([])

  const handleClose = () => {
    setShow(false)
    signalCloseModal(false)
    setReceivedResponse(false)
    setData([])
  }

  const handleSubmit = async () => {
    let response = await getData(`/api/operation/coverageLost/${eventId}`, { Authorization: getAuthorizationHeader() })
    setData(response.data)
    if (data == {}) {
      notificationAlertRef.current.notificationAlert(notificationOptions('Error! Something went wrong.', 'danger'))
    } else {
      setReceivedResponse(true)
    }
  }

  const showContent = () => {
    if (!receivedResponse) {
      return (
        <div>
          <label className='h4'>Are you sure you want to send Coverage Lost for Events: </label>
          <div>{eventId} ?</div>
        </div>
      )
    } else {
      return (
        <div className='w-100'>
          <Row>
            <Col>Given ID:</Col>
            <Col>Event ID:</Col>
            <Col>Uni ID:</Col>
            <Col>Sport:</Col>
            <Col>Success:</Col>
          </Row>
          <hr></hr>
          {data.map(item => {
            return (
              <Row key={item.id}>
                <Col>{item.given_id}</Col>
                <Col>{item.event_id}</Col>
                <Col>{item.uni_id}</Col>
                <Col>{item.sport}</Col>
                <Col style={{ color: item.available == false ? 'red' : 'green' }}>{item.available.toString()}</Col>
              </Row>
            )
          })}
        </div>
      )
    }
  }

  useEffect(() => {
    setShow(showModal)
  }, [showModal])

  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Body>{showContent()}</Modal.Body>
        <hr></hr>
        <Modal.Footer className='justify-content-center'>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
          <div>
            {!receivedResponse ? (
              <Button variant="success" onClick={handleSubmit}>
                Proceed
              </Button>
            ) : null}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default SendCoverageLost
