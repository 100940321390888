import React, { useEffect, useState } from 'react'
import Select from 'react-select'

function SportSelect({ sports, selectedItem, updateSport, isMulti = false }) {
  const [initItem, setInitItem] = useState(selectedItem)
  const handleChange = selectedItem => {
    updateSport(selectedItem)
    setInitItem(selectedItem)
  }
  sports.map(el => {
    el.value = el.id
    el.label = el.name
  })

  useEffect(() => {
    if (selectedItem) {
      let item = null
      if (typeof selectedItem == 'string') item = JSON.parse(selectedItem)
      if (typeof selectedItem == 'object') item = selectedItem
      if (item) {
        //console.log('SportSelect->useEffect->', item)
        setInitItem(item)
      }
    }
  }, [selectedItem])

  return <Select isMulti={isMulti} className="sport-select" name="sport-select" value={initItem} options={sports} placeholder="Select Sport.." onChange={handleChange} />
}

export default SportSelect
