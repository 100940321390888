import React, { useState, useEffect } from 'react'
import { Button, Modal, Form, Row, Col } from 'react-bootstrap'
import NotificationAlert from 'react-notification-alert'

import { putData, getData } from 'utils/server'
import { notificationOptions, getAuthorizationHeader } from 'utils/helpers'

const _ = require('lodash')

function UniTranslation({ showModal, entityId, entityName, entityType, entityKey, signalCloseModal }) {
  const controller = new AbortController()

  const notificationAlertRef = React.useRef(null)
  const [show, setShow] = useState(false)
  const [showhideUpdate, setShowHideUpdate] = useState(true)
  const [entityTranslations, setEntityTranslations] = useState([])

  const handleClose = () => {
    setShow(false)
    signalCloseModal(false)
  }

  const handleSubmit = async () => {
    const retTranslation = entityTranslations.map(el => {
      if (el.translation == '') el.translation = null
      el.lang_name = undefined
      el[entityKey] = entityId
      return el
    })

    let response = await putData(`/api/unis/${entityType}/translations`, retTranslation, { Authorization: getAuthorizationHeader() })
    if (response == null) {
      notificationAlertRef.current.notificationAlert(notificationOptions('Error! Something went wrong.', 'danger'))
    } else {
      setShow(false)
      signalCloseModal(true)
      notificationAlertRef.current.notificationAlert(notificationOptions('Success! Translations Updated.', 'success'))
    }
  }

  const handleInputChange = e => {
    let lang_id = e.target.getAttribute('id')
    lang_id = lang_id.replace(/\D/g, '')
    let langEntityIndex = entityTranslations.findIndex(e => e.lang_id == lang_id)
    entityTranslations[langEntityIndex].translation = e.target.value
    setEntityTranslations(_.cloneDeep(entityTranslations))
  }

  const fetchData = async controller => {
    let response = await getData(`/api/unis/${entityType}/${entityId}/translations`, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (!response) {
      setShowHideUpdate(false)
      return
    }
    if (response.data) setEntityTranslations(response.data)
  }

  useEffect(() => {
    if (showModal) fetchData(controller)
    setShow(showModal)
  }, [showModal])

  useEffect(() => {
    return () => {
      setShow(false)
      controller.abort()
    }
  }, [])

  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <h4>Translation For: {entityName}</h4>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              {entityTranslations.map(obj => {
                return (
                  <Col md="6" key={obj.lang_id} id={'col-lang-' + obj.lang_id}>
                    <Form.Group id={'form-group-lang-' + obj.lang_id}>
                      <label id={'label-lang-' + obj.lang_id}>{obj.lang_name}</label>
                      <Form.Control id={'ctrl-lang-' + obj.lang_id} name={'lang-' + obj.lang_id} value={obj.translation == null ? '' : obj.translation} type="text" placeholder={obj.lang_name + ' Translation'} onChange={handleInputChange}></Form.Control>
                    </Form.Group>
                  </Col>
                )
              })}
            </Row>

            <br />
          </Form>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="warning" style={{ disabled: showhideUpdate ? true : false }} onClick={handleSubmit}>
            Update {entityType} Translation
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default UniTranslation
