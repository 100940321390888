const Joi = require('joi')

export function userValidation(data) {
  const User = Joi.object({
    name: Joi.string().min(3).max(255).required(),
    username: Joi.string().min(3).max(255).required(),
    role_id: Joi.number().required(),
    password: Joi.string().min(8).required(),
    repeat_password: Joi.ref('password')
  })
  const validation = User.validate(data)
  if (validation.error) return validation.error
  return null
}

export function languageValidation(data) {
  const Language = Joi.object({
    name: Joi.string().min(3).max(255).required(),
    code: Joi.string().min(2).max(5).required()
  })
  const validation = Language.validate(data)
  if (validation.error) return validation.error
  return null
}

export function marketGroupValidation(data) {
  const marketGroup = Joi.object({
    name: Joi.string().min(3).max(255).required(),
    sort: Joi.number().required(),
    sport_id: Joi.number().required()
  })
  const validation = marketGroup.validate(data)
  if (validation.error) return validation.error
  return null
}

export function settledValidation(data) {
  const settled = Joi.object({
    provider_id: Joi.number().optional(),
    sport_id: Joi.number().optional(),
    from_date: Joi.number().required(),
    to_date: Joi.number().required(),
    originId: Joi.number().optional()
  })
  const validation = settled.validate(data)
  if (validation.error) return validation.error
  return null
}

export function unmatchedFixturesValidation(data) {
  const unmatched = Joi.object({
    provider_id: Joi.number().required(),
    sport_id: Joi.number().required(),
    from_date: Joi.number().required(),
    to_date: Joi.number().required(),
    percentage: Joi.number().min(0).max(1).required()
  })
  const validation = unmatched.validate(data)
  if (validation.error) return validation.error
  return null
}

export function unmatchedCompetitionValidation(data) {
  const unmatched = Joi.object({
    provider_id: Joi.number().required(),
    sport_id: Joi.number().required(),
    percentage: Joi.number().min(0).max(1).required()
  })
  const validation = unmatched.validate(data)
  if (validation.error) return validation.error
  return null
}

export function fixtureConflictsValidation(data) {
  const conflict = Joi.object({
    sport_id: Joi.number().required(),
    from_date: Joi.number().required(),
    to_date: Joi.number().required(),
    sorted: Joi.bool().optional(),
    uc_sort_to: Joi.string().allow('').optional(),
    uc_sort_from: Joi.string().allow('').optional()
  })
  const validation = conflict.validate(data)
  if (validation.error) return validation.error
  return null
}

export function highlightedFixturesValidation(data) {
  const conflict = Joi.object({
    sport_id: Joi.number().required(),
    from_date: Joi.number().required(),
    to_date: Joi.number().required(),
    sorted: Joi.bool().optional(),
    uc_sort_to: Joi.string().allow('').optional(),
    uc_sort_from: Joi.string().allow('').optional()
  })
  const validation = conflict.validate(data)
  if (validation.error) return validation.error
  return null
}
