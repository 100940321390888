const _ = require('lodash')
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter'
import cellEditFactory from 'react-bootstrap-table2-editor'
import Swal from 'sweetalert2'
import NotificationAlert from 'react-notification-alert'
import { getData, delData, putData, postData } from 'utils/server'
import { getAuthorizationHeader, notificationOptions, copyToClipboard } from 'utils/helpers'
import TableCounters from 'components/UniObject/TableCounters'

function UniPlayers({ forceUpdate, sports, informParent, downloadcsv, informer }) {
  const controller = new AbortController()

  const notificationAlertRef = React.useRef(null)
  const [tableData, setData] = useState([])
  const [allcounter, setAllCounter] = useState(0)
  const [filteredcounter, setFilteredcounter] = useState(0)
  const [sportFilters, setSportFilters] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [pid, setpid] = useState([])
  const [providerid, setProviderid] = useState([])
  const [sportId, setSportId] = useState([])
  const [ProviderName, setProviderName] = useState([])

  const paginationOptions = {
    disablePageTitle: true,
    showTotal: false,
    sizePerPageList: [
      {
        text: '25',
        value: 20
      },
      {
        text: '50',
        value: 50
      }
    ]
  }

  window.handleInfoClick = function (event, pid, provider_id, sport_id, provider_name) {
    event.preventDefault()
    setpid(parseInt(pid))
    console.log(provider_name)
    setProviderName(provider_name)
    setProviderid(parseInt(provider_id))
    setSportId(parseInt(sport_id))
  }
  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleMatchingPlayers = async (players, controller) => {
    let result = await getData(`/api/unis/players/${players.id}/relations`, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })

    console.log(result.data)

    let htmlText = '<div style="min-width:150px;text-align: left;"><ul>'

    let name_width = 100
    let provider_width = 115

    for (const element of result.data) {
      name_width = Math.max(name_width, element.name.length * 10)
      provider_width = Math.max(provider_width, element.provider.length * 10)
    }

    // Construct the HTML text for each element
    for (const element of result.data) {
      htmlText += 
      `<li style="border-bottom: 1px solid;width: max-content;">
        <div style="display: flex;gap: 5px;">
          <div class="rowCell" style="text-align: left;padding: 8px;min-width: max-content;">${element.pid}</div>
          <div class="rowCell" style="text-align: left;padding: 8px;min-width: 100px;">${element.sport}</div>
          <div class="rowCell ${element.provider}Provider" style="text-align: center; padding: 8px;width: ${provider_width}px;">${element.provider}</div>
          <div class="rowCell" style="text-align: center; padding: 8px; width: ${name_width}px; white-space: nowrap;">${element.name}</div>
        </div>          
      </li>`
    }

    htmlText += '</ul></div>'

    Swal.fire({
      title: `Matched Players:`,
      customClass: 'swal-wide',
      html: htmlText,
      showDenyButton: false,
      showConfirmButton: true,
      confirmButtonText: 'OK',
      denyButtonText: 'Cancel',
      icon: 'info',
      confirmButtonColor: '#87cb16',
      denyButtonColor: '#ff4a55'
    }).then(async result => {
      if (result.isConfirmed) {
        // Handle confirmation
      }
    })
  }

  const handleDeleteClick = row => {
    Swal.fire({ title: `Delete Player ${row.name}?`, showDenyButton: true, showConfirmButton: true, confirmButtonText: 'Yes, delete it!', denyButtonText: 'Cancel', icon: 'warning', confirmButtonColor: '#87cb16', denyButtonColor: '#ff4a55' }).then(async result => {
      if (result.isConfirmed) {
        let result = await delData(`/api/unis/players/${row.id}`, { Authorization: getAuthorizationHeader() })
        if (result != null) {
          Swal.fire('Deleted!', `Player ${row.name} has been deleted.`, 'success')
          fetchData(controller)
          informer({ refresh: true })
        } else {
          Swal.fire('Error!', `Something went wrong.`, 'error')
        }
      }
    })
  }

  const fetchData = async controller => {
    if (sports.length == 0) return
    let response = await getData('/api/unis/players', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    let tableData = []
    if (response != null) tableData = response.data
    setAllCounter(response.data.length)
    setData(tableData)
    informParent({ downloadcsv: true })
  }

  const downloadCsv = async () => {
    let csvData = _.sortBy(tableData, ['sport , name'])
    let csv = 'data:text/csv;charset=utf-8,'
    csv += 'Sport;Name\n'
    csvData.forEach(row => {
      csv += `${row.sport};${row.name}\n`
    })
    var encodedUri = encodeURI(csv)
    var link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', 'players.csv')
    document.body.appendChild(link)
    link.click()
    informParent({ downloadcsv: true })
  }

  const changeCellValue = (oldValue, newValue, row, column, done) => {
    if (newValue == '') done(false)
    row.name = newValue
    putData(`/api/unis/players/${row.id}`, row, { Authorization: getAuthorizationHeader() }).then(response => {
      if (response == null) {
        notificationAlertRef.current.notificationAlert(notificationOptions('Error! Cannot edit record.', 'danger'))
        done(false)
      } else {
        notificationAlertRef.current.notificationAlert(notificationOptions(`Participant ${row.id} has been Updated.`, 'success'))
        done(true)
      }
    })
    return { async: true }
  }

  const createFilters = () => {
    if (sports.length == 0) return
    let mySportFilters = {}
    for (let sport of sports) mySportFilters[sport.id] = sport.name
    setSportFilters(mySportFilters)
    fetchData(controller)
  }

  function afterFilter(newResult, newFilters) {
    setFilteredcounter(newResult.length)
  }

  useEffect(() => {
    if (downloadcsv) downloadCsv(controller)
  }, [downloadcsv])

  useEffect(() => {
    createFilters()
  }, [sports])

  useEffect(() => {
    if (Object.keys(sportFilters).length == 0) return
    fetchData(controller)
  }, [forceUpdate])

  useEffect(() => {
    return () => {
      controller.abort()
    }
  }, [])

  const columns = [
    {
      dataField: 'id',
      headerStyle: { width: '8vw' },
      text: 'UNI ID',
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <div onClick={e => copyToClipboard(e, cellContent, notificationAlertRef)} style={{ cursor: 'copy' }}>
            {cellContent}
          </div>
        </>
      ),
      filter: textFilter()
    },
    {
      dataField: 'sport_id',
      headerStyle: { width: '8vw' },
      text: 'Sport',
      formatter: cell => sportFilters[cell],
      filter: selectFilter({
        options: sportFilters
      }),
      editable: false
    },
    {
      dataField: 'name',
      text: 'Name',
      editable: true,
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'dm1',
      headerStyle: { width: '3.9vw' },
      style: { padding: '0px', textAlign: 'center'},
      isDummyField: true,
      text: 'Actions',
      editable: false,
      formatter: (cellContent, row) => (
        <>
          <Button
            title="Show matched players"
            className="btn-simple btn-link p-1"
            type="button"
            onClick={() => {
              handleMatchingPlayers(row, controller)
            }}
          >
            <i className="fas fa fa-info"></i>
          </Button>
          <span>|</span>
          <Button
            title="Delete this player"
            className="btn-simple btn-link p-1"
            type="button"
            variant="danger"
            onClick={() => {
              handleDeleteClick(row)
            }}
          >
            <i className="fas fa-times"></i>
          </Button>
        </>
      )
    }
  ]

  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <TableCounters all={allcounter} filtered={filteredcounter}></TableCounters>
      <BootstrapTable keyField="id" data={tableData} columns={columns} pagination={paginationFactory({ sizePerPage: 10 })} filter={filterFactory({ afterFilter })} cellEdit={cellEditFactory({ mode: 'click', beforeSaveCell: changeCellValue })} />
    </>
  )
}
export default UniPlayers