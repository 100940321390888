import React, { useState, useEffect, useRef } from 'react'

import { Chart } from 'chart.js/auto'
import { initBarChart } from './initializers/initBarChart'

import { getData, postData } from 'utils/server'
import { notificationOptions, getAuthorizationHeader } from 'utils/helpers'

import SportSelect from 'components/Inputs/SportSelect'
import DateSelect from 'components/Inputs/generalDateTimeSelect'

import NotificationAlert from 'react-notification-alert'

// react-bootstrap components
import { Badge, Button, Card, Navbar, Nav, Table, Container, Row, Col, Form, OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap'

let myChart = null

export default function FixtureMatchingStats({ autoload, collapsed }) {
  const controller = new AbortController()

  let today = new Date()
  let yesterday = new Date()
  yesterday.setDate(today.getDate() - 1)

  const notificationAlertRef = useRef(null)
  const [stats, setStats] = useState([])
  const [uniSports, setUniSports] = useState([])
  const [selectedSport, setSelectedSport] = useState({ value: 1, label: 'Soccer' })
  const [visible, setVisible] = useState(collapsed)

  const [startDate, setStartDate] = useState(yesterday)
  const [endDate, setEndDate] = useState(today)

  const [message, setMessage] = useState('')

  const sport = async () => {
    return selectedSport
  }

  const date1 = async () => {
    return startDate
  }

  const date2 = async () => {
    return endDate
  }

  const statsHandler = async controller => {
    setMessage(
      <div className="text-center">
        <i className="fas fa-spinner fa-spin text-center loadingSpinnerColor"></i>
        <span className="loadingSpinnerColor"> Loading Data ...</span>
      </div>
    )

    let currentSport = await sport()
    let startDate = await date1()
    let endDate = await date2()

    // console.log(currentSport)
    // console.log(startDate)
    // console.log(endDate)

    let request = {
      sport_id: currentSport.value,
      from_date: Date.parse(startDate),
      to_date: Date.parse(endDate)
    }

    let response = await postData('/api/matching/matchedFixtures', request, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (response != null) {
      setStats(response.data)

      let providers = response.data.map(o => o.provider)
      let percentage = response.data.map(o => o.percentage)
      let unmatchedPercentage = []
      for (let i = 0; i < percentage.length; i++) {
        unmatchedPercentage.push(100 - percentage[i])
      }
      myChart.data.datasets = [
        {
          label: 'Matched',
          data: percentage
        },
        {
          label: 'Unmatched',
          data: unmatchedPercentage
        }
      ]
      myChart.data.labels = providers
      myChart.update()
    } else notificationAlertRef.current.notificationAlert(notificationOptions('There are no data available for these options', 'danger'))

    setMessage('')
  }

  const fetchStats = async controller => {
    setMessage(
      <div className="text-center">
        <i className="fas fa-spinner fa-spin text-center loadingSpinnerColor"></i>
        <span className="loadingSpinnerColor"> Loading Data ...</span>
      </div>
    )

    let sports_response = await getData('/api/sports', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (sports_response != null) {
      setUniSports(sports_response.data)
      setSelectedSport({ value: 1, label: 'Soccer' })
    }
    let request = {
      sport_id: selectedSport.value,
      from_date: Date.parse(startDate),
      to_date: Date.parse(endDate)
    }
    let response = await postData('/api/matching/matchedFixtures', request, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })

    if (response != null) {
      setStats(response.data)
      let providers = response.data.map(o => o.provider)
      let percentage = response.data.map(o => o.percentage)
      let unmatchedPercentage = []
      for (let i = 0; i < percentage.length; i++) {
        unmatchedPercentage.push(100 - percentage[i])
      }
      let dataSet = [
        {
          label: 'Matched',
          data: percentage
        },
        {
          label: 'Unmatched',
          data: unmatchedPercentage
        }
      ]

      // console.log(dataSet)

      let statsChart = document.getElementById('fixtureMatchingStatsChart')

      if (myChart) {
        myChart.destroy()
      }

      myChart = initBarChart(statsChart, providers, dataSet, 'y', true, 'top', true)
    }
    setMessage('')
  }

  const updateStatsSelectedSport = selSport => {
    setSelectedSport(selSport)
  }

  const updateStartDate = newDate => {
    setStartDate(newDate)
  }

  const updateEndDate = newDate => {
    setEndDate(newDate)
  }

  const minimizeChart = () => {
    if (visible) setVisible(false)
    if (!visible) setVisible(true)

    if (!myChart) fetchStats(controller)
  }

  useEffect(() => {
    if (autoload) fetchStats(controller)

    return () => {
      controller.abort()
      if (myChart) {
        myChart.destroy()
        myChart = null
      }
    }
  }, [])

  useEffect(() => {
    if (myChart && selectedSport && startDate && endDate) statsHandler(controller)
  }, [selectedSport, startDate, endDate])

  const makeLegend = data => {
    if (!data) return
    let domElements = []
    for (let object of data) {
      domElements.push(
        <Col key={object.provider} md={18 / data.length}>
          <span style={{ fontSize: '0.8rem', margin: '2px' }}>{object.provider}</span>
          <Row>
            <Col md="12" sm="12" style={{ textAlign: 'right', marginRight: '1px', paddingRight: '1px', display: 'flex' }}>
              <div style={{ textAlign: 'center', backgroundColor: '#9ad0f5', display: 'block', height: '15px', width: '15px', marginRight: '7px', marginLeft: '5px' }}></div>
              <label style={{ textAlign: 'left', display: 'block', width: '50px' }}>{object.matched}</label>
            </Col>
          </Row>
          <Row>
            <Col md="12" sm="12" style={{ textAlign: 'right', marginRight: '1px', paddingRight: '1px', display: 'flex' }}>
              <div style={{ textAlign: 'center', backgroundColor: '#ffb1c1', display: 'block', height: '15px', width: '15px', marginRight: '7px', marginLeft: '5px' }}></div>
              <label style={{ textAlign: 'left', display: 'block', width: '50px' }}>{object.unmatched}</label>
            </Col>
          </Row>
        </Col>
      )
    }
    return domElements
  }

  return (
    <Card>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Card.Header>
        <Card.Title as="h4">
          Fixture Matching Stats
          <div style={{ position: 'absolute', top: '5px', right: '10px' }} onClick={minimizeChart}>
            <i style={{ display: visible ? '' : 'none' }} className="fas fa-angle-down custom-button-like"></i>
            <i style={{ display: visible ? 'none' : '' }} className="fas fa-angle-up custom-button-like"></i>
          </div>
        </Card.Title>
        <Row style={{ display: visible ? 'none' : '' }}>
          <Col md="12">
            <Row>
              <Col md="4">
                <Form.Group>
                  <label>Sport:</label>
                  <SportSelect sports={uniSports} updateSport={updateStatsSelectedSport} selectedItem={JSON.stringify(selectedSport)} />
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group>
                  <label>From:</label>
                  <DateSelect selectedDate={startDate} handleDateChange={updateStartDate} />
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group>
                  <label>To:</label>
                  <DateSelect selectedDate={endDate} handleDateChange={updateEndDate} />
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body style={{ display: visible ? 'none' : '' }}>
        <div className="stats-chart" id="fixtureMatchingStatsChartHolder" style={{ height: '30rem', marginBottom: '80px', display: message === '' ? '' : 'none' }}>
          <canvas id="fixtureMatchingStatsChart" />
          <Row style={{ margin: '10px 0px 20px 35px' }}>{makeLegend(stats)}</Row>
        </div>
        <div>{message}</div>
      </Card.Body>
      <Card.Footer>
        <hr></hr>
        <div className="stats">
          <i className="fas fa-history"></i>
          Updated 3 minutes ago
        </div>
      </Card.Footer>
    </Card>
  )
}
