const _ = require('lodash')
import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Badge } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory, { textFilter, selectFilter, customFilter, multiSelectFilter } from 'react-bootstrap-table2-filter'
import NotificationAlert from 'react-notification-alert'

import { getData, delData, putData } from 'utils/server'
import { getAuthorizationHeader } from 'utils/helpers'
import TableCounters from 'components/UniObject/TableCounters'

// import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'

// const { SearchBar } = Search

let filters = []

function MarketSettlements({ sports, loadingHandler }) {
  const controller = new AbortController()

  const notificationAlertRef = React.useRef(null)
  const [tableData, setData] = useState([])
  const [allcounter, setAllCounter] = useState(0)
  const [filteredcounter, setFilteredcounter] = useState(0)
  const [sportFilters, setSportFilters] = useState({})
  const [settlementFilters] = useState({ true: 'true', false: 'false' })
  const [distinct, setDistinct] = useState(false)
  const [fetching, setFetching] = useState(true)
  const [filterVal, setFilterVal] = useState([])
  let filteredData = []

  const switchButton = cellContent => {
    return (
      <Button className="btn-simple btn-link p-1" type="button" variant={cellContent == true ? 'success' : cellContent == false ? 'danger' : 'danger'}>
        <i className={cellContent == true ? 'fas fa-check' : cellContent == false ? 'fas fa-times' : ''}></i>
      </Button>
    )
  }

  const filterSport = (filterVal, data) => {
    setFilterVal(filterVal)
    if (filterVal && filterVal.length > 0) {
      filteredData = []

      filterVal.forEach(filter => {
        let filteredMarkets = data.filter(market => market.sport == filter)
        filteredData = filteredData.concat(filteredMarkets)
      })

      return filteredData
    }
    return data
  }

  const columns = [
    {
      dataField: 'sport',
      text: 'Sport',
      align: 'center',
      editable: false,
      headerClasses: 'top-align',
      filter: multiSelectFilter({
        options: sportFilters,
        onFilter: filterSport
      }),
      editable: false
    },
    {
      dataField: 'match_name',
      text: 'UniName',
      align: 'center',
      editable: false,
      headerClasses: 'top-align',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'online_settlement',
      text: 'Online Set/ment',
      align: 'center',
      editable: false,
      headerClasses: 'top-align',
      formatter: (cellContent, row) => switchButton(cellContent),
      filter: selectFilter({
        options: settlementFilters
      })
    },
    {
      dataField: 'offline_settlement',
      text: 'Offline Set/ment',
      align: 'center',
      editable: false,
      headerClasses: 'top-align',
      formatter: (cellContent, row) => switchButton(cellContent),
      filter: selectFilter({
        options: settlementFilters
      })
    },
    {
      dataField: 'operator',
      text: 'Operator',
      align: 'center',
      editable: false,
      headerClasses: 'top-align',
      formatter: (cellContent, row) => switchButton(cellContent),
      filter: selectFilter({
        options: settlementFilters
      })
    }
  ]

  const sleep = ms =>
    new Promise(resolve => {
      setTimeout(resolve, ms)
    })

  const initTable = () => {
    loadingHandler(false)
    setFetching(true)
    setData([])
    setAllCounter(0)
    setFilteredcounter(0)
    afterFilter([], null)

    return true
  }

  const fetchData = async controller => {
    initTable()
    if (sports.length == 0) return
    let response = await getData('/api/markets/getSettlementStatus', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    let tableData = []
    if (response && response != null) {
      tableData = response.data
    }
    setAllCounter(tableData.length)
    setData([...tableData])
    setFetching(false)
    loadingHandler(true)
  }

  const createFilters = () => {
    if (sports && sports.length > 0) {
      let mySportFilters = {}
      for (let sport of sports) mySportFilters[sport.name] = sport.name
      setSportFilters(mySportFilters)
      fetchData(controller)
    }
  }

  const afterFilter = async (newResult, newFilters) => {
    let filterstoPush = []
    if (newFilters) {
      for (const key in newFilters) {
        if (Object.hasOwnProperty.call(newFilters, key)) {
          filterstoPush.push(key)
        }
      }
    }
    filters = filterstoPush
    setFilteredcounter(newResult.length)
  }

  const getFilters = () => {
    let filtersString = filters.join(',')
    return filtersString
  }

  const exportCSV = () => {
    let header = []
    let dataToExport = [...filteredData]
    let filename = ''
    if (dataToExport.length == 0) dataToExport = [...tableData]
    if (filterVal.length == 0) {
      filename = `All_Sports_Markets_Settlements`
      header = ['Sport', 'Markets', 'During the Game', 'After the Game Finish', 'Operation']
    } else {
      filename = `${filterVal.join('_')}_Markets_Settlements`
      if (filterVal.length == 1) {
        header = ['Markets', 'During the Game', 'After the Game Finish', 'Operation']
      } else {
        header = ['Sport', 'Markets', 'During the Game', 'After the Game Finish', 'Operation']
      }
    }

    let result = [header]

    dataToExport.forEach(rowObject => {
      let rowArray = []
      for (var i in rowObject) {
        if (i === 'id') continue
        if (i === 'enabled') continue
        if (i === 'sport' && filterVal.length == 1) continue

        rowArray.push(rowObject[i] == true ? 'X' : rowObject[i] == false ? '' : rowObject[i])
      }
      result.push(rowArray)
    })

    const rows = result

    let csvContent = 'data:text/csv;charset=utf-8,'

    rows.forEach(function (rowArray) {
      let row = rowArray.join(',')
      csvContent += row + ';\r\n'
    })
    downloadWithName(csvContent, filename)
    // var encodedUri = encodeURI(csvContent)
    // window.open(encodedUri, 'marinos.csv')
  }

  function downloadWithName(uri, name) {
    var link = document.createElement('a')
    link.download = name
    link.href = encodeURI(uri)
    link.click()
  }

  useEffect(() => {
    createFilters()
  }, [sports])

  useEffect(() => {
    return () => {
      controller.abort()
    }
  }, [])

  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div style={{ display: fetching ? 'none' : '' }}>
        <div style={{ display: 'flex' }}>
          <Button size="sm" onClick={exportCSV}>
            exportCSV
          </Button>
          <div style={{ width: '100%', marginRight: '10px' }}>
            <TableCounters all={allcounter} filtered={filteredcounter}></TableCounters>
          </div>
        </div>
        <div style={{ display: getFilters().length > 0 ? 'flex' : 'none', fontSize: '0.75rem', color: '#ff00837d' }}>You have apply filters: ({getFilters()})</div>
        <BootstrapTable bootstrap4 keyField="id" data={tableData} columns={columns} pagination={paginationFactory({ sizePerPage: 10 })} filter={filterFactory({ afterFilter })} />
      </div>
    </>
  )
}
export default MarketSettlements
