function ProviderFixture({ fix }) {
  return (
    <div id="fixture-row-renderer" style={{ display: 'flex' }}>
      <div className="fix-date-div">{fix ? fix.dateString : ''}</div>
      <div className="fix-hours-div">{fix ? fix.hourString : ''}</div>
      <div className="part-div-h">{fix ? fix.home : ''}</div>
      <div className="part-seperator-div">Vs</div>
      <div className="part-div-a">{fix ? fix.away : ''}</div>
    </div>
  )
}
export default ProviderFixture
