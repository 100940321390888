import React, { useEffect, useState, useRef } from 'react'
import { Container, Row, Col, Card, Button, Modal } from 'react-bootstrap'

import { getData, postData } from 'utils/server'
import ParticipantFixture from 'views/ParticipantFixture'
import { getAuthorizationHeader } from 'utils/helpers'
import { set } from 'lodash'
let pos1 = 0,
  pos2 = 0,
  pos3 = 0,
  pos4 = 0

const ParticipantFixtures = ({ providerName, showModal, pid, providerId, signalCloseModal, sportId, fromProviderParticipants }) => {
  const controller = new AbortController()
  const [fixturesData, setFixturesData] = useState([])
  const [fixturesModal, setFixturesModal] = useState(false)
  const lastPosition = useRef({ top: 0, left: 0 })
  const className = fromProviderParticipants ? 'from-provider-participant-fixtures-holder' : 'participant-fixtures-holder'

  const setFixturesModalData = async controller => {
    let Pid = parseInt(pid)

    if (isNaN(Pid) || isNaN(providerId)) {
      //console.error('Invalid pid or providerId')
      return
    }
    let apiUrl = `/api/providers/participantfixtures/200`
    let payload = {
      sportId: sportId,
      providerId: providerId,
      Pid: Pid
    }

    let fixtures = await postData(apiUrl, payload, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })

    setFixturesData(fixtures.data)

    //setFixturesModal(true)
  }

  const handleClose = () => {
    let elementId = `participant-fixtures-holder-${pid}-${providerId}`
    let element = document.getElementById(elementId)
    if (element) {
      let top = element.style.top ? parseFloat(element.style.top, 10) : element.offsetTop
      let left = element.style.left ? parseFloat(element.style.left, 10) : element.offsetLeft
      lastPosition.current = { top, left } // Store the last position
    }
    setFixturesModal(false)

    signalCloseModal(false)
  }

  const dragMouseDown = e => {
    e = e || window.event
    e.preventDefault()
    pos3 = e.clientX
    pos4 = e.clientY
    document.onmouseup = closeDragElement
    document.onmousemove = elementDrag
  }

  const elementDrag = e => {
    e = e || window.event
    e.preventDefault()
    pos1 = pos3 - e.clientX
    pos2 = pos4 - e.clientY
    pos3 = e.clientX
    pos4 = e.clientY
    let elementId = `participant-fixtures-holder-${pid}-${providerId}`
    let element = document.getElementById(elementId)
    if (element) {
      let position = window.getComputedStyle(element).position
      if (position === 'fixed') {
        element.style.top = element.offsetTop - pos2 + 'px'
        element.style.left = element.offsetLeft - pos1 + 'px'
      } else if (position === 'relative') {
        let top = element.style.top ? parseFloat(element.style.top) : 0
        let left = element.style.left ? parseFloat(element.style.left) : 0
        element.style.top = top - pos2 + 'px'
        element.style.left = left - pos1 + 'px'
      }
    } else return
  }

  const closeDragElement = () => {
    document.onmouseup = null
    document.onmousemove = null
  }
  useEffect(() => {
    // This code will run whenever showModal, sportId, pid, or providerId changes
    if (showModal) setFixturesModalData(controller)
  }, [sportId, pid, providerId, showModal])
  useEffect(() => {
    if (showModal) setFixturesModalData(controller)
    setFixturesModal(showModal)
  }, [showModal])

  useEffect(() => {
    return () => {
      setFixturesModal(false)
      controller.abort()
    }
  }, [])
  useEffect(() => {
    if (fixturesData.length === 0) {
      setFixturesModalData(controller)
    }
  }, [fixturesData])

  return showModal ? (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card id={`participant-fixtures-holder-${pid}-${providerId}`} className={className} style={{ display: fixturesModal ? '' : 'none' }} onBlur={handleClose}>
            <Card.Header>
              <Row>
                <Col md="11">
                  <Card.Title as="h6" name="provider-fixtures-holder" style={{ cursor: 'move' }} onMouseDown={dragMouseDown}>
                    <span className={`${providerName}Provider`}>{providerName}</span> Particpant Fixtures
                  </Card.Title>
                </Col>
                <Col md="1" style={{ marginTop: '-14px', textAlign: 'right' }}>
                  <Button className="btn-simple btn-link p-1" type="button" variant="danger" onClick={handleClose}>
                    <i className="fas fa-times"></i>
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <Col md="12">
                <div style={{ fontSize: '12px', overflowX: 'auto' }}>
                  <div id="main-info-holder"></div>
                  <div style={{ overflowX: 'auto', height: '205px' }}>
                    {fixturesData.map((fixture, index) => {
                      let parsedDate = new Date(fixture.startdate * 1)
                      fixture.date = parsedDate
                      fixture.dateString = fixture.date.getDate() + '-' + (fixture.date.getMonth() + 1) + '-' + fixture.date.getFullYear()
                      let [hours, minutes] = [parsedDate.getHours(), parsedDate.getMinutes()] //fixture.startdate.split(' ')[3].split(':')
                      fixture.hourString = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`
                      fixture.home = fixture.home_name
                      fixture.away = fixture.away_name
                      fixture.competition = fixture.competition
                      fixture.region = fixture.region
                      fixture.event_id = fixture.pid
                      return <ParticipantFixture id={'matchedFixture-' + fixture.pid} key={'matchedFixture-' + fixture.pid} fix={fixture}></ParticipantFixture>
                    })}
                  </div>
                </div>
              </Col>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  ) : null
}

export default ParticipantFixtures
