import React, { useState, useEffect } from 'react'
import { Button, Modal, Form } from 'react-bootstrap'
import NotificationAlert from 'react-notification-alert'

import { postData } from 'utils/server'
import { getAuthorizationHeader, notificationOptions } from 'utils/helpers'

function CreateUniRegions({ showModal, signalCloseModal }) {
  const notificationAlertRef = React.useRef(null)

  const [show, setShow] = useState(false)

  const handleClose = () => {
    setShow(false)
    signalCloseModal(false)
  }

  const handleSubmit = async () => {
    let region = document.getElementById('region-name')
    let response = await postData(`/api/unis/regions`, { name: region.value }, { Authorization: getAuthorizationHeader() })
    if (response == null) {
      notificationAlertRef.current.notificationAlert(notificationOptions('Error! Cannot add region.', 'danger'))
    } else {
      setShow(false)
      signalCloseModal(true)
      notificationAlertRef.current.notificationAlert(notificationOptions('Success! Region Added.', 'success'))
    }
  }

  useEffect(() => {
    setShow(showModal)
  }, [showModal])
  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <label>Region Name</label>
              <Form.Control id="region-name" name="region-name" type="text" placeholder="Type regions name"></Form.Control>
            </Form.Group>
            <div className="clearfix"></div>
          </Form>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Create Region
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CreateUniRegions
