import { Chart } from 'chart.js/auto'

export const initPieChart = (chart, labels, dataSet, legend_display, legend_position, maintainAspectRatio) => {
  return new Chart(chart, {
    type: 'pie',
    data: {
      labels: labels,
      datasets: dataSet
    },
    options: {
      plugins: {
        title: {
          display: false,
          text: 'Chart.js Bar Chart - Stacked'
        },
        legend: {
          display: legend_display,
          position: legend_position
        }
      },
      hoverOffset: 15,
      responsive: true,
      maintainAspectRatio: maintainAspectRatio,
      height: '500px'
    }
  })
}
