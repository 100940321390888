import React from 'react'
import { version } from 'react'
import { render } from 'react-dom'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/animate.min.css'
import './assets/css/light-bootstrap-dashboard-react.css'
import './assets/css/demo.css'
import './assets/css/custom.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'

import LoginLayout from 'layouts/Login.js'
import AdminLayout from 'layouts/Admin.js'

const root = document.getElementById('root')
console.log(`React Version: ${version}`)
render(
  <BrowserRouter>
    <Switch>
      <Route path="/admin" render={props => <AdminLayout {...props} />} />
      <Route path="/" render={props => <LoginLayout {...props} />} />
    </Switch>
  </BrowserRouter>,
  root
)
