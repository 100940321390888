import React, { useEffect, useState } from 'react'

import Select from 'react-select'

function GroupSelect({ groups, groupSelected, updateGroup }) {
  const [selected, setSelected] = useState(groupSelected)

  const handleChange = selections => {
    updateGroup(selections)
    setSelected(selections)
  }

  groups.map(el => {
    el.value = el.id
    el.label = el.name
  })
  return <Select isMulti className="sport-select" name="group-select" options={groups} placeholder="Select group.." onChange={handleChange} value={selected} />
}

export default GroupSelect
