import React, { useEffect, useState } from 'react'
import Select from 'react-select'

function RequestSelect({ requests, selectedRequest, updateRequest }) {
  const [initRequest, setInitRequest] = useState(selectedRequest)
  const handleChange = selectedRequest => {
    updateRequest(selectedRequest)
    setInitRequest(selectedRequest)
  }
  requests.map((el) => {
    el.value = el.index
    el.label = el.name
  })

  useEffect(() => {
    if (selectedRequest) {
      let request = null
      if (typeof selectedRequest == 'string') request = JSON.parse(selectedRequest)
      if (typeof selectedRequest == 'object') request = selectedRequest
      if (request) {
        //console.log('UserSelect->useEffect->', user)
        setInitRequest(request)
      }
    }
  }, [selectedRequest])

  return <Select className="general-select" name="general-select" value={initRequest} options={requests} placeholder={`Select Request...`} onChange={handleChange} />
}

export default RequestSelect
