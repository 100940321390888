import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import UserActivity from 'components/Charts/UserActivity'
import RequestStats from 'components/Charts/RequestStats'

export default function UserDashboard() {
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <UserActivity autoload={false} collapsed={true} />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <RequestStats autoload={false} collapsed={true} />
          </Col>
        </Row>
      </Container>
    </>
  )
}
