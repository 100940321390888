const _ = require('lodash')
import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { Button, Modal, Form, Col, Row } from 'react-bootstrap'
import NotificationAlert from 'react-notification-alert'

import { postData } from 'utils/server'
import { getAuthorizationHeader, notificationOptions } from 'utils/helpers'
import SportSelect from 'components/Inputs/SportSelect'
import GroupSelect from 'components/Inputs/GroupSelect'

function CreateUniMarkets({ showModal, signalCloseModal, sports, groups }) {
  const notificationAlertRef = React.useRef(null)

  const [show, setShow] = useState(false)
  const [selectedSport, setSelectedSport] = useState({})
  const [selectedGroup, setSelectedGroup] = useState([])
  const [typeOptions] = useState([
    { value: 'inPlay', label: 'inPlay' },
    { value: 'preGame', label: 'preGame' }
  ])
  const [selectedType, setSelectedType] = useState({})
  const [selectedEnabled, setSelectedEnabled] = useState(false)
  const [expertChecked, setExpertChecked] = useState(false)

  const handleClose = () => {
    setShow(false)
    signalCloseModal(false)
  }

  const handleTypeChange = selectedItem => {
    setSelectedType(selectedItem)
  }

  const handleEnabledChange = selectedItem => {
    setSelectedEnabled(selectedItem.target.checked)
  }

  const handleExpertCheckedChange = selectedItem => {
    setExpertChecked(selectedItem.target.checked)
  }

  const handleSubmit = async () => {
    let marketName = document.getElementById('market-name').value
    let nameTemplate = document.getElementById('name-template').value
    let marketColumns = document.getElementById('market-columns').value
    let marketSort = document.getElementById('market-sort').value
    let marketType = selectedType.value
    let marketEnabled = document.getElementById('market-enabled').checked ? 1 : 0
    setSelectedEnabled(document.getElementById('market-enabled').checked)
    let expertChecked = document.getElementById('expert-checked').checked
    setExpertChecked(document.getElementById('expert-checked').checked)

    if (_.isEmpty(selectedSport) || _.isEmpty(marketName) || _.isEmpty(marketType)) {
      notificationAlertRef.current.notificationAlert(notificationOptions('Error! Cannot add Market. Missing Value!', 'danger'))
      return
    }
    const market = {
      match_name: marketName,
      name_template: nameTemplate ? nameTemplate : null,
      columns: marketColumns ? marketColumns : null,
      market_sort: marketSort ? marketSort : null,
      type: marketType,
      enabled: marketEnabled,
      sport: selectedSport.name,
      groups: selectedGroup.map(marketGroup => {
        return marketGroup.id
      }),
      sport_id: selectedSport.id,
      expert_checked: expertChecked
    }
    let response = await postData(`/api/unis/markets`, market, { Authorization: getAuthorizationHeader() })
    if (response == null) {
      notificationAlertRef.current.notificationAlert(notificationOptions('Error! Cannot add Market. Error on Request!', 'danger'))
      return
    }
    notificationAlertRef.current.notificationAlert(notificationOptions('Success! Market Added.', 'success'))
    setSelectedGroup([])
    setSelectedSport({})
    setShow(false)
    signalCloseModal(true)
  }

  const updateSelectedSport = selSport => {
    setSelectedSport(selSport)
  }

  const updateSelectedGroup = selGroup => {
    setSelectedGroup(selGroup)
  }

  useEffect(() => {
    setShow(showModal)
  }, [showModal])
  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Col md="12">
            <div>Create NEW Market UNI</div>
          </Col>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column md="3">
                UniName:
              </Form.Label>
              <Col md="9">
                <Form.Control id="market-name" name="market_name" type="text" placeholder="Type Market name"></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column md="3">
                Name Template:
              </Form.Label>
              <Col md="9">
                <Form.Control id="name-template" name="name_template" type="text" placeholder="Type Name Template"></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column md="3">
                Columns:
              </Form.Label>
              <Col md="9">
                <Form.Control id="market-columns" name="market-columns" type="text" placeholder="Type Market columns"></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column md="3">
                Sorting:
              </Form.Label>
              <Col md="9">
                <Form.Control id="market-sort" name="market-sort" type="text" placeholder="Type Market Sorting" defaultValue={100000}></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column md="3">
                Type:
              </Form.Label>
              <Col md="9">
                <Select id="market-type" name="market_type" placeholder="Select Market Type" options={typeOptions} onChange={handleTypeChange} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column md="3">
                Sport:
              </Form.Label>
              <Col md="9">
                <SportSelect sports={sports} updateSport={updateSelectedSport} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column md="3">
                Group:
              </Form.Label>
              <Col md="9">
                <GroupSelect groups={groups.filter(group => group.sport_name === selectedSport.name)} updateGroup={updateSelectedGroup} disabled={!selectedSport.name} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column md="3">
                Enabled:
              </Form.Label>
              <Col md="9">
                <Form.Check id="market-enabled" defaultChecked={true} name="market_enabled" className="mb-1 pl-4" onChange={handleEnabledChange} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column md="3">
                Expert Checked:
              </Form.Label>
              <Col md="9">
                <Form.Check id="expert-checked" defaultChecked={false} name="expert_chacked" className="mb-1 pl-4" onChange={handleExpertCheckedChange} />
              </Col>
            </Form.Group>
            <div className="clearfix"></div>
          </Form>

          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Create Market
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CreateUniMarkets
