const _ = require('lodash')
import React, { useState, useEffect } from 'react'
import { Button, Modal, Form, Col } from 'react-bootstrap'
import NotificationAlert from 'react-notification-alert'

import { postData } from 'utils/server'
import { getAuthorizationHeader, notificationOptions } from 'utils/helpers'
import SportSelect from 'components/Inputs/SportSelect'
import RegionSelect from 'components/Inputs/RegionSelect'

function CreateUniComps({ showModal, signalCloseModal, sports, regions }) {
  const notificationAlertRef = React.useRef(null)

  const [show, setShow] = useState(false)
  const [selectedSport, setSelectedSport] = useState({})
  const [selectedRegion, setSelectedRegion] = useState({})

  const handleClose = () => {
    setShow(false)
    signalCloseModal(false)
  }

  const handleSubmit = async () => {
    let compName = document.getElementById('competition-name').value
    let compSort = document.getElementById('sort').value
    compSort = compSort === '' ? null : compSort
    console.log(compSort)
    if (_.isEmpty(selectedSport) || _.isEmpty(selectedRegion) || _.isEmpty(compName) || (compSort !== null && /[^0-9]/.test(compSort))) {
      notificationAlertRef.current.notificationAlert(notificationOptions('Error! Cannot add competition.', 'danger'))
      return
    }

    const competition = {
      name: compName,
      region: selectedRegion.name,
      sport: selectedSport.name,
      sport_id: selectedSport.id,
      region_id: selectedRegion.id,
      sort: parseInt(compSort)
    }
    let response = await postData(`/api/unis/competitions`, competition, { Authorization: getAuthorizationHeader() })
    if (response == null) {
      notificationAlertRef.current.notificationAlert(notificationOptions('Error! Cannot add competition.', 'danger'))
      return
    }
    notificationAlertRef.current.notificationAlert(notificationOptions('Success! Competitions Added.', 'success'))
    setShow(false)
    signalCloseModal(true)
  }

  const updateSelectedSport = selSport => {
    setSelectedSport(selSport)
  }
  const updateSelectedRegions = selRegion => {
    setSelectedRegion(selRegion)
  }
  useEffect(() => {
    setShow(showModal)
  }, [showModal])
  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Col md="12">
              <Form.Group>
                <label>Select Sport</label>
                <SportSelect sports={sports} updateSport={updateSelectedSport} />
              </Form.Group>
            </Col>
            <br />
            <Col md="12">
              <Form.Group>
                <label>Select Region</label>
                <RegionSelect regions={regions} updateRegion={updateSelectedRegions} />
              </Form.Group>
            </Col>
            <br />
            <Col md="12">
              <Form.Group>
                <label>Competition name</label>
                <Form.Control id="competition-name" name="competition-name" type="text" placeholder="Type competition name"></Form.Control>
              </Form.Group>
            </Col>

            <Col md="12">
              <Form.Group>
                <label>Sort</label>
                <Form.Control id="sort" name="sort" placeholder="Type sort"></Form.Control>
              </Form.Group>
            </Col>
            <div className="clearfix"></div>
          </Form>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Create Competition
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CreateUniComps
