const _ = require('lodash')
import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Badge } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter'
import cellEditFactory from 'react-bootstrap-table2-editor'
import Swal from 'sweetalert2'
import NotificationAlert from 'react-notification-alert'

import { getData, delData, putData } from 'utils/server'
import { notificationOptions, getAuthorizationHeader, copyToClipboard } from 'utils/helpers'
import UniTranslation from 'components/Modals/UniTranslation'
import UniMarketUpdate from 'components/Modals/UpdateUniMarket'
import MarketOdds from 'components/Modals/MarketOdds'
import TableCounters from 'components/UniObject/TableCounters'
import DataSelect from 'components/Inputs/generalDataSelect'
import ProviderMarkets from './ProviderMarkets'

let filters = []

function UniMarkets({ informParent, downloadcsv, forceUpdate, sports, groups }) {
  const controller = new AbortController()

  const notificationAlertRef = React.useRef(null)
  const [allTableData, setAllData] = useState([])
  const [tableData, setData] = useState([])
  const [allcounter, setAllCounter] = useState(0)
  const [filteredcounter, setFilteredcounter] = useState(0)
  const [sportFilters, setSportFilters] = useState({})
  const [groupsFilters, setGroupsFilters] = useState({})
  const [typeFilters] = useState({ inPlay: 'inPlay', preGame: 'preGame' })
  const [enabledFilters] = useState({ 1: 'On', 0: 'Off' })
  const [showModal, setShowModal] = useState(false)
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const [showOddsModal, setShowOddsModal] = useState(false)
  const [selectedMarketId, setSelectedMarketId] = useState(null)
  const [selectedMarket, setSelectedMarket] = useState({})
  const [selectedMarketName, setSelectedMarketName] = useState(null)
  const [providers, setProviders] = useState([])
  const [selectedProvider, setSelectedProvider] = useState({ value: 0, label: 'All providers' })
  const [matched, setMatched] = useState(false)
  const [filterAll, setFilterAll] = useState(true)

  let initializing = false

  const paginationOptions = {
    disablePageTitle: true,
    showTotal: false,
    sizePerPageList: [
      {
        text: '25',
        value: 20
      },
      {
        text: '50',
        value: 50
      }
    ]
  }

  const fetchProviderFilter = async controller => {
    let providers_response = await getData('/api/providers', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (providers_response != null) {
      let data = providers_response.data
      data.unshift({ id: 0, name: 'All providers' })
      setProviders(providers_response.data)
      setSelectedProvider({ value: 0, label: 'All providers' })
    }
  }

  const fetchData = async controller => {
    if (initializing) return
    initializing = true
    initTable()

    if (sports.length == 0) return
    let response = await getData('/api/unis/markets', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    let tableData = []
    if (response != null) tableData = response.data
    setAllCounter(tableData.length)
    setAllData(tableData)
    setData(tableData)

    initializing = false
    informParent({ downloadcsv: true })
  }

  const getCounts = async controller => {
    let providerUniCounts = []
    for (const provider of providers) {
      let count = { provider: provider.name, InPlay: 0, PreGame: 0, sports: {} }
      let dataToFilter = allTableData
      if (provider.value != 0) {
        dataToFilter = dataToFilter.filter(item => item.matched.indexOf(provider.value) >= 0)
      }

      for (const key in dataToFilter) {
        if (Object.hasOwnProperty.call(dataToFilter, key)) {
          const line = dataToFilter[key]
          if (line.matched && line.matched != 0 && line.matched != ' ' && line.enabled == 1) {
            if (line.type == 'inPlay') count['InPlay'] += 1
            else if (line.type === 'preGame') count['PreGame'] += 1

            // Increment the appropriate count for the sport
            if (!count.sports[line.sport]) {
              count.sports[line.sport] = { InPlay: 0, PreGame: 0 }
            }
            if (line.type == 'inPlay') count.sports[line.sport]['InPlay'] += 1
            else if (line.type === 'preGame') count.sports[line.sport]['PreGame'] += 1
          }
        }
      }
      providerUniCounts.push(count)
    }

    let response = await getData('/api/providers/all/provider-markets', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    let table = []
    if (response != null) table = response.data
    let providerCounts = []
    // Create a Map to store counts for each provider
    const providerMap = new Map()

    // Iterate over the table data
    for (const item of table) {
      if (item.enabled == 1 && item.uni_id != null) {
        // Get the count object for the provider
        let count = providerMap.get(item.provider)

        // If count is undefined, create a new count object for the provider
        if (!count) {
          count = { provider: item.provider, InPlay: 0, PreGame: 0, sports: {} }
          providerMap.set(item.provider, count)
        }

        // Increment the appropriate count
        if (item.type == 'inPlay') count['InPlay'] += 1
        else if (item.type === 'preGame') count['PreGame'] += 1

        // Increment the appropriate count for the sport
        if (!count.sports[item.sport]) {
          count.sports[item.sport] = { InPlay: 0, PreGame: 0 }
        }
        if (item.type == 'inPlay') count.sports[item.sport]['InPlay'] += 1
        else if (item.type === 'preGame') count.sports[item.sport]['PreGame'] += 1

        // Update the count object in the Map
        providerMap.set(item.provider, count)
      }
    }

    // Convert the Map values to an array and add to providerCounts
    providerCounts = [...providerCounts, ...Array.from(providerMap.values())]

    providerCounts.shift()
    providerUniCounts.shift()

    let csvContent = ','

    // Add provider columns to the header
    providerUniCounts.forEach(count => {
      csvContent += `${count.provider},,,,`
    })

    csvContent += '\n'

    // Add sub-columns for each provider
    csvContent += ',' + Array.from({ length: providerUniCounts.length }, () => 'PreGame (Uni),InPlay (Uni),PreGame (Provider),InPlay (Provider)').join(',') + '\n'
    csvContent += 'Sports'
    // Prepare CSV rows
    const allSports = new Set([...providerUniCounts, ...providerCounts].flatMap(count => Object.keys(count.sports)))

    // Object to store the sums for each column
    let columnSums = {}

    allSports.forEach(sport => {
      let row = [sport]

      providerUniCounts.forEach((count, index) => {
        let inPlayUni = 0
        let preGameUni = 0

        // Check if sport exists for the current provider in providerUniCounts
        const uniCount = count.sports[sport]
        if (uniCount) {
          inPlayUni = uniCount.InPlay || 0
          preGameUni = uniCount.PreGame || 0
        }

        let inPlayCounts = 0
        let preGameCounts = 0

        // Check if sport exists for the current provider in providerCounts
        const providerCount = providerCounts.find(p => p.provider === count.provider)?.sports[sport]
        if (providerCount) {
          inPlayCounts = providerCount.InPlay || 0
          preGameCounts = providerCount.PreGame || 0
        }

        // Update the sums for each column
        columnSums[`PreGameUni${index}`] = (columnSums[`PreGameUni${index}`] || 0) + preGameUni
        columnSums[`InPlayUni${index}`] = (columnSums[`InPlayUni${index}`] || 0) + inPlayUni
        columnSums[`PreGameCounts${index}`] = (columnSums[`PreGameCounts${index}`] || 0) + preGameCounts
        columnSums[`InPlayCounts${index}`] = (columnSums[`InPlayCounts${index}`] || 0) + inPlayCounts

        row.push(preGameUni, inPlayUni, preGameCounts, inPlayCounts)
      })

      csvContent += '\n' + row.join(',')
    })

    // Add a row with the sums for each column
    csvContent += '\nTotal'
    providerUniCounts.forEach((_, index) => {
      csvContent += `,${columnSums[`PreGameUni${index}`]},${columnSums[`InPlayUni${index}`]},${columnSums[`PreGameCounts${index}`]},${columnSums[`InPlayCounts${index}`]}`
    })

    // Download CSV
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodedUri)
    link.setAttribute('download', 'provider_counts.csv')
    document.body.appendChild(link)
    link.click()

    informParent({ downloadcsv: true })
  }

  const handleMatchingInfoUniMarket = async (market, controller) => {
    let result = await getData(`/api/unis/markets/${market.id}/relations`, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })

    let htmlText = '<div style="min-width:150px;text-align: left;"><ul>'

    let uniname_width = 100
    let pure_name_width = 100

    for (let index = 0; index < result.data.length; index++) {
      const element = result.data[index]
      uniname_width = uniname_width < element.uni_name.length * 10 ? element.uni_name.length * 10 : uniname_width
      pure_name_width = pure_name_width < element.pure_name.length * 10 ? element.pure_name.length * 10 : pure_name_width
    }

    for (let index = 0; index < result.data.length; index++) {
      const element = result.data[index]
      htmlText =
        htmlText +
        `<li style="border-bottom: 1px solid;width: max-content;">
          <div style="display: flex;gap: 5px;">
            <div class="rowCell" style="text-align: left;padding: 8px;min-width: 100px;margin-bottom: auto;margin-top: auto;">${element.id}</div>
            <div class="${element.type}Type" style="text-align: center;margin-bottom: auto;margin-top: auto;">${element.type}</div>
            <div class="${element.provider}Provider" style="text-align: center;margin-bottom: auto;margin-top: auto;">${element.provider}</div>
            <div class="rowCell" style="text-align: center;padding: 8px;width: 220px !important;margin-bottom: auto;margin-top: auto;">${element.sport}</div>
            <div class="rowCell" style="text-align: left;padding: 8px;min-width: max-content; width:${uniname_width}px;margin-bottom: auto;margin-top: auto;">${element.uni_name}</div>
            <div class="rowCell" style="text-align: left;padding: 8px;min-width: max-content; width:${pure_name_width}px;margin-bottom: auto;margin-top: auto;">${element.pure_name}</div>
          </div>
        </li>`
    }

    htmlText = htmlText + '</ul></div>'

    Swal.fire({ title: `Matched Markets:`, customClass: 'swal-wide', html: htmlText, showDenyButton: false, showConfirmButton: true, confirmButtonText: 'OK', denyButtonText: 'Cancel', icon: 'info', confirmButtonColor: '#87cb16', denyButtonColor: '#ff4a55' }).then(async result => {
      if (result.isConfirmed) {
      }
    })
  }

  const columns = [
    {
      dataField: 'id',
      text: 'UNI ID',
      align: 'center',
      editable: false,
      formatter: (cellContent, row) => (
        <>
          <div onClick={e => copyToClipboard(e, cellContent, notificationAlertRef)} style={{ cursor: 'copy' }}>
            <a href="">{cellContent}</a>
            <Badge bg="info" className="market-matches" style={{ display: Object.keys(row.matched).length != 0 ? '' : 'none' }}>
              {row.matched.length}
            </Badge>
          </div>
        </>
      ),
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'type',
      text: 'Type',
      align: 'center',
      editable: false,
      filter: selectFilter({
        options: typeFilters
      }),
      sort: true
    },
    {
      dataField: 'groups',
      text: 'Groups',
      editable: false,
      sort: true,
      formatter: cell => {
        let groupNamesArray = []
        for (const key in cell) {
          if (Object.hasOwnProperty.call(cell, key)) {
            const groupId = cell[key]
            groupNamesArray.push(groupsFilters[groupId])
          }
        }
        return groupNamesArray.toString()
      },
      filter: textFilter(),
      filterValue: (cell, row) => {
        let groupNamesArray = []
        for (const key in cell) {
          if (Object.hasOwnProperty.call(cell, key)) {
            const groupId = cell[key]
            groupNamesArray.push(groupsFilters[groupId])
          }
        }
        return groupNamesArray.toString()
      }
    },
    {
      dataField: 'sport_id',
      text: 'Sport',
      align: 'center',
      editable: false,
      formatter: cell => sportFilters[cell],
      filter: selectFilter({
        options: sportFilters
      }),
      editable: false
    },
    {
      dataField: 'match_name',
      text: 'UniName',
      align: 'center',
      editable: false,
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'enabled',
      text: 'Status',
      align: 'center',
      formatter: cell => {
        return <span style={{ color: cell == 1 ? 'green' : 'red' }}>{enabledFilters[cell]}</span>
      },
      filter: selectFilter({
        options: enabledFilters
      }),
      classes: row => {
        row.enabled == 1 ? 'bg-success' : 'bg-danger'
      }
    },
    {
      dataField: 'dm1',
      isDummyField: true,
      editable: false,
      text: 'Actions',
      formatter: (cellContent, row) => (
        <>
          {/* <Button
            className="btn-simple btn-link p-1"
            type="button"
            variant="warning"
            onClick={() => {
              handleTranslationClick(row)
            }}
          >
            <i className="fas fa-comments"></i>
          </Button> */}
          <Button
            title="Show available odds"
            className="btn-simple btn-link p-1"
            type="button"
            variant="warning"
            onClick={() => {
              handleShowOddsClick(row)
            }}
          >
            <i className="fas fa-list"></i>
          </Button>
          <span>&nbsp;&nbsp;&nbsp;</span>
          <Button
            title="Edit market"
            className="btn-simple btn-link p-1"
            type="button"
            variant="info"
            onClick={() => {
              handleEditClick(row)
            }}
          >
            <i className="fas fa-edit"></i>
          </Button>
          <Button
            className="btn-simple btn-link p-1"
            type="button"
            variant={row.matched.length > 0 ? 'dark' : 'secondary'}
            disabled={row.matched.length > 0 ? false : true}
            onClick={() => {
              handleMatchingInfoUniMarket(row, controller)
            }}
            title={'Matched Markets: ' + row.matched.length}
          >
            <i className="fas fa fa-info"></i>
          </Button>
          <span>|</span>
          <Button
            title="Delete market"
            className="btn-simple btn-link p-1"
            type="button"
            variant="danger"
            onClick={() => {
              handleDeleteClick(row)
            }}
          >
            <i className="fas fa-times"></i>
          </Button>
        </>
      )
    }
  ]

  const sleep = ms =>
    new Promise(resolve => {
      setTimeout(resolve, ms)
    })

  const initTable = () => {
    setMatched(false)
    setFilterAll(true)
    setSelectedProvider({ value: 0, label: 'All providers' })
    setAllData([])
    setData([])
    setAllCounter(0)
    setFilteredcounter(0)
    afterFilter([], null)

    preFilterData(false, true, [])
    return true
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleUpdatedMarketCloseModal = (update, data) => {
    setShowUpdateModal(false)
    if (update) {
      fetchData(controller)
    }
  }

  const handleOddsCloseModal = () => {
    setShowOddsModal(false)
  }

  const handleShowOddsClick = row => {
    setSelectedMarket(row)
    setShowOddsModal(true)
  }

  const handleTranslationClick = row => {
    setSelectedMarketId(row.id)
    setSelectedMarketName(row.match_name)
    setShowModal(true)
  }

  const handleEditClick = row => {
    setSelectedMarket(row)
    setShowUpdateModal(true)
  }

  const updateSelectedProvider = provider => {
    setSelectedProvider(provider)
    preFilterData(filterAll, matched)
  }

  const handleDeleteClick = row => {
    Swal.fire({ title: `Delete Market "${row.match_name}" with id: ${row.id} ?`, showDenyButton: true, showConfirmButton: true, confirmButtonText: 'Yes, delete it!', denyButtonText: 'Cancel', icon: 'warning', confirmButtonColor: '#87cb16', denyButtonColor: '#ff4a55' }).then(async result => {
      if (result.isConfirmed) {
        let result = await delData(`/api/unis/markets/${row.id}`, { Authorization: getAuthorizationHeader() })
        if (result != null) {
          Swal.fire('Deleted!', `Market ${row.match_name} has been deleted.`, 'success')
          fetchData(controller)
        } else {
          Swal.fire('Error!', `Something went wrong.`, 'error')
        }
      }
    })
  }

  const createFilters = async () => {
    if (sports && sports.length > 0) {
      let mySportFilters = {}
      for (let sport of sports) mySportFilters[sport.id] = sport.name
      setSportFilters(mySportFilters)
      fetchData(controller)
    }
    if (groups && groups.length > 0) {
      let myGroupsFilters = {}
      for (let group of groups) myGroupsFilters[group.id] = group.name
      setGroupsFilters(myGroupsFilters)
    }
  }

  const afterFilter = async (newResult, newFilters) => {
    let filterstoPush = []
    if (newFilters) {
      for (const key in newFilters) {
        if (Object.hasOwnProperty.call(newFilters, key)) {
          filterstoPush.push(key)
        }
      }
    }
    filters = filterstoPush
    setFilteredcounter(newResult.length)
  }

  const preFilterData = async (allfilter, matchedfilter, data) => {
    sleep(2000)
    if (data) {
      setAllData([])
    }

    let dataToFilter = data ? data : allTableData

    let filteredData = []

    if (selectedProvider.value == 0) dataToFilter = dataToFilter
    else dataToFilter = dataToFilter.filter(item => item.matched.indexOf(selectedProvider.value) >= 0)
    for (const key in dataToFilter) {
      if (Object.hasOwnProperty.call(dataToFilter, key)) {
        const line = dataToFilter[key]
        if (allfilter == false) {
          if (matchedfilter == true) {
            if (line.matched && line.matched != 0 && line.matched != ' ') filteredData.push(line)
          } else {
            if (!line.matched || line.matched == 0 || line.matched == ' ') filteredData.push(line)
          }
        } else {
          filteredData.push(line)
        }
      }
    }
    setData(filteredData)
    return true
  }

  const clearToggleButtons = async () => {
    setMatched(false)
    setFilterAll(false)
  }

  const handleMatchedFilter = async () => {
    clearToggleButtons()
    setMatched(true)
    await preFilterData(false, true)
  }

  const handleUnmatchedFilter = async () => {
    clearToggleButtons()
    setMatched(false)
    await preFilterData(false, false)
  }

  const handleFilterAll = async () => {
    clearToggleButtons()
    setFilterAll(true)
    await preFilterData(true, false)
  }

  const getFilters = () => {
    let filtersString = filters.join(',')
    return filtersString
  }

  useEffect(() => {
    if (downloadcsv) getCounts(controller)
  }, [downloadcsv])

  useEffect(() => {
    if (sports.length == 0 || groups.length == 0) return
    createFilters()
  }, [sports])

  useEffect(() => {
    if (groups.length == 0 || sports.length == 0) return
    createFilters()
  }, [groups])

  useEffect(() => {
    fetchProviderFilter(controller)
    return () => {
      setShowModal(false)
      setShowUpdateModal(false)
      setShowOddsModal(false)
      Swal.clickConfirm()
      controller.abort()
    }
  }, [])

  useEffect(() => {
    preFilterData(filterAll, matched)
  }, [selectedProvider])

  useEffect(() => {
    if (Object.keys(sportFilters).length == 0) return
    fetchData(controller)
  }, [forceUpdate])

  return (
    <>
      <UniTranslation showModal={showModal} entityId={selectedMarketId} entityName={selectedMarketName} entityType={'markets'} entityKey={'market_id'} signalCloseModal={handleCloseModal} />
      <UniMarketUpdate showModal={showUpdateModal} market={selectedMarket} groups={groups} signalCloseModal={handleUpdatedMarketCloseModal} />
      <MarketOdds showModal={showOddsModal} market={selectedMarket} signalCloseModal={handleOddsCloseModal} />
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '100%', paddingBottom: '0.7vh' }}>
          <DataSelect data={providers} updateItem={updateSelectedProvider} selectedItem={selectedProvider} />
        </div>
        <div style={{ width: '100%' }}>
          <TableCounters all={allcounter} filtered={filteredcounter}></TableCounters>
        </div>
        <div style={{ width: '400px', textAlign: 'right' }}>
          <ButtonGroup size="sm" aria-label="Basic example">
            <Button variant={filterAll ? 'success' : 'danger'} onClick={handleFilterAll}>
              All
            </Button>
            <Button variant={matched ? 'success' : 'danger'} onClick={handleMatchedFilter}>
              Matched
            </Button>
            <Button variant={filterAll ? 'danger' : !matched ? 'success' : 'danger'} onClick={handleUnmatchedFilter}>
              Unmatched
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <div style={{ display: getFilters().length > 0 ? 'flex' : 'none', fontSize: '0.75rem', color: '#ff00837d' }}>You have apply filters: ({getFilters()})</div>
      <BootstrapTable bootstrap4 keyField="id" data={tableData} columns={columns} pagination={paginationFactory({ sizePerPage: 10 })} filter={filterFactory({ afterFilter })} />
    </>
  )
}
export default UniMarkets
