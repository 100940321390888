import React, { useState, useEffect } from 'react'
import { Card, Container, Row, Col, Button } from 'react-bootstrap'
import NotificationAlert from 'react-notification-alert'

import MarketSettlementsTable from 'components/Tables/MarketSettlements'
import UpdateMarketSettlement from 'components/Modals/UpdateMarketSettlement'
import InfoMarketSettlement from 'components/Modals/InfoMarketSettlement'

import { getData, delData, putData, postData } from 'utils/server'
import { notificationOptions, getAuthorizationHeader } from 'utils/helpers'

import Swal from 'sweetalert2'
import { validateViewCredentials } from 'utils/auth'

function MarketSettlements(props) {
  validateViewCredentials(props.routeInfo)

  const controller = new AbortController()

  const notificationAlertRef = React.useRef(null)
  const [showUpdateMarketSettlementModal, setUpdateMarketSettlementsModal] = useState(false)
  const [showInfoMarketSettlementModal, setInfoMarketSettlementsModal] = useState(false)
  const [updateMarketSettlements, setUpdateMarketSettlements] = useState(false)
  const [uniSports, setUniSports] = useState([])
  const [dataLoaded, setDataLoaded] = useState(false)

  const setUpdateMarketSettlement = () => {
    setUpdateMarketSettlementsModal(true)
  }

  const handleUpdateAllMarketsSettlements = async (event, controller) => {
    let response = await getData(`/api/markets/fetchSettlementJobs`, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    let pendingProcess = null
    let pendingMessage = ''

    if (response != null) {
      response.data.forEach(process => {
        if (process.project == 'All Projects') {
          if (process.completed == false) pendingProcess = process
          if (process.completed) pendingProcess = null
        }
      })

      if (pendingProcess) {
        pendingMessage = `There is a pending Job from ${pendingProcess.timestamp}!<br><br>`
      }
    }

    Swal.fire({
      title: `${pendingMessage} You are about to update the Settlement Status (Online, Offline) <br> for all Markets and Sports (about 30min)!<br><br> Are you sure you want to proceed?`,
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Yes, go for it!',
      denyButtonText: 'Cancel',
      icon: 'warning',
      confirmButtonColor: '#87cb16',
      denyButtonColor: '#ff4a55',
      width: '60%'
    }).then(async result => {
      if (result.isConfirmed) {
        let response = await getData(`/api/markets/updateSettlementStatus`, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
        if (response == null) {
          notificationAlertRef.current.notificationAlert(notificationOptions('Error! Something went wrong.', 'danger'))
        } else {
          setShow(false)
          signalCloseModal(true)
          notificationAlertRef.current.notificationAlert(notificationOptions('Success! Markets Settlements Status Updater is running.', 'success'))
        }
      }
    })
  }

  const handleCloseModal = uMarketSettlement => {
    setUpdateMarketSettlementsModal(false)
    if (uMarketSettlement) {
      updateMarketSettlements == true ? setUpdateMarketSettlements(false) : setUpdateMarketSettlements(true)
    }
  }

  const openInfoModal = () => {
    setInfoMarketSettlementsModal(true)
  }

  const handleCloseInfoModal = () => {
    setInfoMarketSettlementsModal(false)
  }

  const fetchSports = async controller => {
    let response = await getData('/api/sports', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (response != null) {
      response.data = response.data.sort((a, b) => a.id - b.id)
      setUniSports(response.data)
    }
  }

  const handlerLoadingData = status => {
    setDataLoaded(status)
  }

  useEffect(() => {
    fetchSports(controller)

    return () => {
      controller.abort()
    }
  }, [])

  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Container fluid>
        <UpdateMarketSettlement showModal={showUpdateMarketSettlementModal} signalCloseModal={handleCloseModal} sports={uniSports} />
        <InfoMarketSettlement showModal={showInfoMarketSettlementModal} signalCloseModal={handleCloseInfoModal} />
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col md="8">
                    <Card.Title as="h4">Markets Settlement</Card.Title>
                  </Col>
                  <Col md="4">
                    <Button style={{ float: 'right', marginLeft: '10px' }} onClick={openInfoModal}>
                      info
                    </Button>
                    <Button style={{ display: dataLoaded ? 'block' : 'none', float: 'right' }} className="update-settlement-btn" variant="warning" onClick={e => handleUpdateAllMarketsSettlements(e, controller)}>
                      Update Everything
                    </Button>
                    <Button style={{ display: dataLoaded ? 'block' : 'none', float: 'right' }} className="update-settlement-btn" variant="success" onClick={setUpdateMarketSettlement}>
                      Update Markets Status
                    </Button>
                  </Col>
                </Row>
                <Row style={{ display: dataLoaded ? 'none' : '' }}>
                  <Col md="12">
                    <label>loading data ...</label>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Col md="12" style={{ display: dataLoaded ? 'block' : 'none' }}>
                  <MarketSettlementsTable sports={uniSports} loadingHandler={handlerLoadingData} />
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default MarketSettlements
