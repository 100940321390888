import React, { useState, useEffect } from 'react'

import { Chart } from 'chart.js/auto'

import { getData } from 'utils/server'
import { getAuthorizationHeader } from 'utils/helpers'

import SportSelect from 'components/Inputs/SportSelect'
import DataSelect from 'components/Inputs/generalDataSelect'

// react-bootstrap components
import { Badge, Button, Card, Navbar, Nav, Table, Container, Row, Col, Form, OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap'

let myChart = null

function MatchingStats({ autoload, collapsed }) {
  const controller = new AbortController()

  const [stats, setStats] = useState([])
  const [selectedStat, setSelectedStat] = useState(0)
  const [uniSports, setUniSports] = useState([])
  const [selectedSport, setSelectedSport] = useState({ value: 1, label: 'Soccer' })
  const [selectedProvider, setSelectedProvider] = useState({ value: 2, label: 'vbet' })
  const [providers, setProviders] = useState([])
  const [visible, setVisible] = useState(collapsed)
  const [message, setMessage] = useState('')

  const statsHandler = () => {
    let index = stats.findIndex(stat => stat.provider_id == selectedProvider.value)

    if (index == -1) return
    setSelectedStat(index)
    myChart.data.datasets.forEach(dataset => {
      dataset.data = [stats[index].sport_stats[selectedSport.label][dataset.label.toLowerCase()].regions, stats[index].sport_stats[selectedSport.label][dataset.label.toLowerCase()].competitions, stats[index].sport_stats[selectedSport.label][dataset.label.toLowerCase()].participants]
    })
    myChart.update()
  }

  const fetchStats = async controller => {
    setMessage(
      <div className="text-center">
        <i className="fas fa-spinner fa-spin text-center loadingSpinnerColor"></i>
        <span className="loadingSpinnerColor"> Loading Data ...</span>
      </div>
    )
    let sports_response = await getData('/api/sports', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (sports_response != null) {
      setUniSports(sports_response.data)
      setSelectedSport({ value: 1, label: 'Soccer' })
    }

    let providers_response = await getData('/api/providers', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (providers_response != null) {
      setProviders(providers_response.data)
    }

    let response = await getData('/api/reports/entities/matched', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (response != null) {
      setStats(response.data)

      let dataSet = [
        {
          label: 'Matched',
          data: [response.data[0].sport_stats['Soccer'].matched.regions, response.data[0].sport_stats['Soccer'].matched.competitions, response.data[0].sport_stats['Soccer'].matched.participants]
        },
        {
          label: 'Unmatched',
          data: [response.data[0].sport_stats['Soccer'].unmatched.regions, response.data[0].sport_stats['Soccer'].unmatched.competitions, response.data[0].sport_stats['Soccer'].unmatched.participants]
        }
      ]

      let statsChart = document.getElementById('matchingStatsChart')

      myChart = new Chart(statsChart, {
        type: 'bar',
        data: {
          labels: ['Regions', 'Competitions', 'Participants'],
          datasets: dataSet
        },
        options: {
          plugins: {
            title: {
              display: false,
              text: 'Chart.js Bar Chart - Stacked'
            },
            legend: {
              display: true,
              position: 'top'
            }
          },
          responsive: true,
          scales: {
            x: {
              stacked: true
            },
            y: {
              stacked: true
            }
          },
          maintainAspectRatio: false
        }
      })
    }
    setMessage('')
  }

  const updateStatsSelectedSport = selSport => {
    setSelectedSport(selSport)
  }

  const updateStatsSelectedProvider = selProv => {
    setSelectedProvider(selProv)
  }

  const minimizeChart = () => {
    if (visible) setVisible(false)
    if (!visible) setVisible(true)

    if (!myChart) fetchStats(controller)
  }

  useEffect(() => {
    if (autoload) fetchStats(controller)

    return () => {
      controller.abort()
      if (myChart) {
        myChart.destroy()
        myChart = null
      }
    }
  }, [])

  useEffect(() => {
    if (myChart && selectedProvider.value && selectedSport) statsHandler()
  }, [selectedProvider.value, selectedSport])

  return (
    <Card>
      <Card.Header>
        <Card.Title as="h4">
          Entities Matching Stats
          <div style={{ position: 'absolute', top: '5px', right: '10px' }} onClick={minimizeChart}>
            <i style={{ display: visible ? '' : 'none' }} className="fas fa-angle-down custom-button-like"></i>
            <i style={{ display: visible ? 'none' : '' }} className="fas fa-angle-up custom-button-like"></i>
          </div>
        </Card.Title>
        <Row style={{ display: visible ? 'none' : '' }}>
          <Col md="12">
            <Row>
              <Col md="6">
                <Form.Group>
                  <label>Providers:</label>
                  <DataSelect data={providers} updateItem={updateStatsSelectedProvider} selectedItem={selectedProvider} placeholder={`Select Provider...`}/>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group>
                  <label>Sport:</label>
                  <SportSelect sports={uniSports} updateSport={updateStatsSelectedSport} selectedItem={JSON.stringify(selectedSport)} />
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body style={{ display: visible ? 'none' : '' }}>
        <div className="stats-chart" id="matchingStatsChartHolder" style={{ height: '30rem', marginBottom: '80px', display: message === '' ? '' : 'none' }}>
          <canvas id="matchingStatsChart" style={{ height: '300px' }} height="300px" />
          <Row style={{ margin: '10px 0px 20px 35px', display: stats.length == 0 ? 'none' : '' }}>
            <Col md="12">
              <Row>
                <Col md="4">
                  <Row>
                    <Col md="6" style={{ textAlign: 'right', marginRight: '0px', paddingRight: '0px', display: 'flex', paddingLeft: '20%' }}>
                      <div style={{ textAlign: 'center', backgroundColor: '#9ad0f5', display: 'block', height: '15px', width: '15px', marginRight: '5px' }}> </div>
                      <label style={{ textAlign: 'left', display: 'block', width: '50px' }}>{stats[selectedStat] ? stats[selectedStat].sport_stats[selectedSport.label].matched.regions_abs : ''}</label>
                    </Col>
                    <Col md="6" style={{ textAlign: 'left', marginLeft: '0px', paddingLeft: '0px', display: 'flex' }}>
                      <div style={{ textAlign: 'center', backgroundColor: '#ffb1c1', display: 'block', height: '15px', width: '15px', marginRight: '5px' }}> </div>
                      <label style={{ textAlign: 'left', display: 'block', width: '50px' }}>{stats[selectedStat] ? stats[selectedStat].sport_stats[selectedSport.label].unmatched.regions_abs : ''}</label>
                    </Col>
                  </Row>
                </Col>
                <Col md="4">
                  <Row>
                    <Col md="6" style={{ textAlign: 'right', marginRight: '0px', paddingRight: '0px', display: 'flex', paddingLeft: '20%' }}>
                      <div style={{ textAlign: 'center', backgroundColor: '#9ad0f5', display: 'block', height: '15px', width: '15px', marginRight: '5px' }}> </div>
                      <label style={{ textAlign: 'left', display: 'block', width: '50px' }}>{stats[selectedStat] ? stats[selectedStat].sport_stats[selectedSport.label].matched.competitions_abs : ''}</label>
                    </Col>
                    <Col md="6" style={{ textAlign: 'left', marginLeft: '0px', paddingLeft: '0px', display: 'flex' }}>
                      <div style={{ textAlign: 'center', backgroundColor: '#ffb1c1', display: 'block', height: '15px', width: '15px', marginRight: '5px' }}> </div>
                      <label style={{ textAlign: 'left', display: 'block', width: '50px' }}>{stats[selectedStat] ? stats[selectedStat].sport_stats[selectedSport.label].unmatched.competitions_abs : ''}</label>
                    </Col>
                  </Row>
                </Col>
                <Col md="4">
                  <Row>
                    <Col md="6" style={{ textAlign: 'right', marginRight: '0px', paddingRight: '0px', display: 'flex', paddingLeft: '20%' }}>
                      <div style={{ textAlign: 'center', backgroundColor: '#9ad0f5', display: 'block', height: '15px', width: '15px', marginRight: '5px' }}> </div>
                      <label style={{ textAlign: 'left', display: 'block', width: '50px' }}>{stats[selectedStat] ? stats[selectedStat].sport_stats[selectedSport.label].matched.participants_abs : ''}</label>
                    </Col>
                    <Col md="6" style={{ textAlign: 'left', marginLeft: '0px', paddingLeft: '0px', display: 'flex' }}>
                      <div style={{ textAlign: 'center', backgroundColor: '#ffb1c1', display: 'block', height: '15px', width: '15px', marginRight: '5px' }}> </div>
                      <label style={{ textAlign: 'left', display: 'block', width: '50px' }}>{stats[selectedStat] ? stats[selectedStat].sport_stats[selectedSport.label].unmatched.participants_abs : ''}</label>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div>{message}</div>
      </Card.Body>
      <Card.Footer>
        <hr></hr>
        <div className="stats">
          <i className="fas fa-history"></i>
          Updated 3 minutes ago
        </div>
      </Card.Footer>
    </Card>
  )
}

export default MatchingStats
