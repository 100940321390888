const _ = require('lodash')
import React, { useState, useEffect } from 'react'
import { Button, Modal, Form, Col, Row } from 'react-bootstrap'
import NotificationAlert from 'react-notification-alert'

import { putData } from 'utils/server'
import { getAuthorizationHeader, notificationOptions } from 'utils/helpers'
import SportSelect from 'components/Inputs/SportSelect'
import GroupSelect from 'components/Inputs/GroupSelect'

function UpdateUniMarket({ showModal, signalCloseModal, market, groups }) {
  const notificationAlertRef = React.useRef(null)

  const [show, setShow] = useState(false)
  const [selectedSport, setSelectedSport] = useState({})
  const [selectedGroup, setSelectedGroup] = useState({})
  const [selectedEnabled, setSelectedEnabled] = useState(false)
  const [marketNameChanged, setMarketNameChanged] = useState(false)
  const [nameTemplateChanged, setNameTemplateChanged] = useState(false)
  const [marketColumnsChanged, setMarketColumnsChanged] = useState(false)
  const [marketSortChanged, setMarketSortChanged] = useState(false)
  const [expertChecked, setExpertChecked] = useState(false)

  const handleEnabledChange = selectedItem => {
    setSelectedEnabled(selectedItem.target.checked)
  }

  const handleExpertCheckedChange = selectedItem => {
    setExpertChecked(selectedItem.target.checked)
  }

  const handleNameChange = selectedItem => {
    setMarketNameChanged(selectedItem.target.value)
  }

  const handleColumnsChange = selectedItem => {
    setMarketColumnsChanged(selectedItem.target.value)
  }

  const handleSortingChange = selectedItem => {
    setMarketSortChanged(selectedItem.target.value)
  }

  const handleNameTemplateChange = selectedItem => {
    setNameTemplateChanged(selectedItem.target.value)
  }

  const updateSelectedGroup = selGroup => {
    setSelectedGroup(selGroup)
  }

  const handleClose = () => {
    setShow(false)
    signalCloseModal(false, null)
  }

  const handleSubmit = async () => {
    let marketId = document.getElementById('market-id').value
    let marketName = document.getElementById('market-name').value
    let marketType = document.getElementById('market-type').value
    let marketEnabled = document.getElementById('market-enabled').checked ? 1 : 0
    setSelectedEnabled(document.getElementById('market-enabled').checked)
    let expertChecked = document.getElementById('expert-checked').checked
    setExpertChecked(document.getElementById('expert-checked').checked)
    let nameTemplate = document.getElementById('name-template').value
    let marketColumns = document.getElementById('market-columns').value
    let marketSort = document.getElementById('market-sort').value

    if (_.isEmpty(marketName) || _.isEmpty(marketType)) {
      notificationAlertRef.current.notificationAlert(notificationOptions('Error! Cannot add Market. Missing Value!', 'danger'))
      return
    }
    const market = {
      match_name: marketName,
      name_template: nameTemplate ? nameTemplate : null,
      type: marketType,
      enabled: marketEnabled,
      sport: selectedSport.name,
      groups: selectedGroup.map(marketGroup => {
        return marketGroup ? marketGroup.value : null
      }),
      columns: marketColumns ? marketColumns : null,
      market_sort: marketSort ? marketSort : 100000,
      sport_id: selectedSport.id,
      expert_checked: expertChecked
    }
    let response = await putData(`/api/unis/markets/${marketId}`, market, { Authorization: getAuthorizationHeader() })
    if (response == null) {
      notificationAlertRef.current.notificationAlert(notificationOptions('Error! Cannot add Market. Error on Request!', 'danger'))
      return
    }
    notificationAlertRef.current.notificationAlert(notificationOptions('Success! Market Added.', 'success'))
    setShow(false)
    signalCloseModal(true, market)
  }

  useEffect(() => {
    setShow(showModal)
    setSelectedSport({ name: market.sport, id: market.sport_id })
    if (market && market.groups)
      setSelectedGroup(
        market.groups.map(group => {
          return group
            ? {
                value: group,
                label: groups
                  ? groups.find(gr => {
                      if (gr.id === group) {
                        return gr.name
                      }
                    }).name
                  : ''
              }
            : null
        })
      )
    setSelectedEnabled(market.enabled == 1 ? true : false)
    setMarketNameChanged(market.match_name)
  }, [showModal])
  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Col md="12">
            <div>Update Selected Market</div>
          </Col>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column md="3">
                UniId:
              </Form.Label>
              <Col md="9">
                <Form.Control id="market-id" name="market_uni" value={market.id} plaintext="true" readOnly></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column md="3">
                Type:
              </Form.Label>
              <Col md="9">
                <Form.Control id="market-type" name="market_type" value={market.type} plaintext="true" readOnly></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column md="3">
                Sport:
              </Form.Label>
              <Col md="9">
                <Form.Control id="market-sport" name="market_sport" value={market.sport} plaintext="true" readOnly></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column md="3">
                Columns:
              </Form.Label>
              <Col md="9">
                <Form.Control id="market-columns" name="market_columns" defaultValue={market.columns} type="text" placeholder="Type Market columns" onChange={handleColumnsChange}></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column md="3">
                Sorting:
              </Form.Label>
              <Col md="9">
                <Form.Control id="market-sort" name="market_sort" defaultValue={market.sort} type="text" placeholder="Type Market sort" onChange={handleSortingChange}></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column md="3">
                Group:
              </Form.Label>
              <Col md="9">
                <GroupSelect groups={groups.filter(group => group.sport_name === market.sport)} groupSelected={selectedGroup} updateGroup={updateSelectedGroup} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column md="3">
                UniName:
              </Form.Label>
              <Col md="9">
                <Form.Control id="market-name" name="market_name" defaultValue={market.match_name} type="text" placeholder="Type Market name" onChange={handleNameChange}></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column md="3">
                Name Template:
              </Form.Label>
              <Col md="9">
                <Form.Control id="name-template" name="name_template" defaultValue={market.name_template} type="text" placeholder="Type Name Template" onChange={handleNameTemplateChange}></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column md="3">
                Enabled:
              </Form.Label>
              <Col md="9">
                <Form.Check id="market-enabled" name="market_enabled" defaultChecked={market.enabled == 1 ? true : false} className="mb-1 pl-4" onChange={handleEnabledChange} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column md="3">
                Expert Checked:
              </Form.Label>
              <Col md="9">
                <Form.Check id="expert-checked" defaultChecked={market.expert_checked} name="expert_chacked" className="mb-1 pl-4" onChange={handleExpertCheckedChange} />
              </Col>
            </Form.Group>
            <div className="clearfix"></div>
          </Form>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Update Market
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default UpdateUniMarket
