import React, { useState, useEffect } from 'react'
import { Card, Container, Row, Col, Button } from 'react-bootstrap'
import ProviderFixtures from 'components/Tables/ProviderFixturesExtra'
import UniFixturesExtra from 'components/Tables/UniFixturesExtra'
import ProviderSelect from 'components/Inputs/ProviderSelect'
import SportSelect from 'components/Inputs/SportSelect'
import DateSelect from 'components/Inputs/DateSelect'
import NotificationAlert from 'react-notification-alert'
import Select from 'react-select'
import Swal from 'sweetalert2'
import CreateUniFixture from 'components/Modals/CreateUniFixture'

import { getData, postData } from 'utils/server'
import { notificationOptions, getAuthorizationHeader, getUserData } from 'utils/helpers'

import useMousetrap from 'react-hook-mousetrap'

let lastUniSelected = null
let lastProviderSelected = null

function Fixtures() {
  const notificationAlertRef = React.useRef(null)
  const controller = new AbortController()

  const [disableMatchFixtures, setDisableMatchFixtures] = useState(true)
  const [refreshRightTable, setRefreshRightTable] = useState(false)
  const [refreshLeftTable, setRefreshLeftTable] = useState(false)
  const [downloadcsv, setDownloadcsv] = useState(false)
  const [leftFixture, setLeftFixture] = useState(null)
  const [rightFixture, setRightFixture] = useState(null)
  const [sportId, setSportId] = useState(null)
  const [matched, setMatched] = useState(false)
  const [sports, setSports] = useState([])
  const [rightProviderId, setRightProviderId] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [showCreateUniFixtureModal, setShowCreateUniFixtureModal] = useState(false)
  const [validMatch, setValidMatch] = useState(0.8)
  const [competitionSector, setCompetitionSector] = useState(false)
  const [regionSector, setRegionSector] = useState(false)
  const [participantSector, setParticipantSector] = useState(true)
  const [matchingPattern, setMatchingPattern] = useState('')
  const [searchUniSport, setSearchUniSport] = useState(null)
  const [searchUniRegion, setSearchUniRegion] = useState(null)
  const [searchUniCompetition, setSearchUniCompetition] = useState(null)
  const [searchUniHome, setSearchUniHome] = useState(null)
  const [searchUniAway, setSearchUniAway] = useState(null)
  const [enableMatch, setEnableMatch] = useState(null)
  const [clickedTime, setClickedTime] = useState(null)
  const [autoSimilaritySuggest, setAutoSimilaritySuggest] = useState(false)
  const [matchingOptions, setMatchingOptions] = useState(false)
  const [dateOptionSelected, setDateOptionSelected] = useState('startDate')
  const dateOptions = [
    { value: 'startDate', label: 'Fixtures Start Date' },
    { value: 'createDate', label: 'Fixtures Creation Date' }
  ]
  const matchedOptions = [
    { value: true, label: 'matched' },
    { value: false, label: 'unmatched' }
  ]
  useMousetrap('alt+n', () => {
    if (!disableMatchFixtures) clickMatchFixtures()
  })

  const sleep = ms =>
    new Promise(resolve => {
      setTimeout(resolve, ms)
    })

  const updateRightSelectedProvider = pId => {
    setRightProviderId(pId)
  }

  const updateStartDate = startDate => {
    setStartDate(startDate)
  }

  const updateEndDate = endDate => {
    setEndDate(endDate)
  }

  const updateSportId = sport => {
    if (sport) console.log(sport)
    setSportId(sport.id)
  }

  const updateRightSportId = sport => {
    setRigthSportId(sport.id)
  }

  const leftHandler = event => {
    setLeftFixture(event)
    if (!event) setEnableMatch(null)
    if (event) console.log('UNISelected:', `${event.id}`)
    lastUniSelected = event
  }

  const rightHandler = event => {
    clearUniFilters('-1')
    setRightFixture(event)
    if (!event) setEnableMatch(null)
    if (event) console.log('ProviderSelected:', `${event.providerId}::${event.eventId}`)
    lastProviderSelected = event
  }

  const cloneHandler = event => {
    console.log(event)
    rightHandler(event)
    sleep(500)
    setShowCreateUniFixtureModal(true)
  }

  const clearUniFilters = col => {
    console.log('clearUniFilters')
    switch (col) {
      case null:
        setSearchUniSport(null)
        setSearchUniRegion(null)
        setSearchUniCompetition(null)
        setSearchUniHome(null)
        setSearchUniAway(null)
        break
      case 'sport':
        setSearchUniSport(null)
        break
      case 'region':
        setSearchUniRegion(null)
        break
      case 'comp':
        setSearchUniCompetition(null)
        break
      case 'home':
        setSearchUniHome(null)
        break
      case 'away':
        setSearchUniAway(null)
        break
      case '-1':
        if (sportId == 1000) setSearchUniSport('-1')
        setSearchUniRegion('-1')
        setSearchUniCompetition('-1')
        setSearchUniHome('-1')
        setSearchUniAway('-1')
        break
      default:
        break
    }
  }

  const setFilters = (f, val) => {
    console.log('setFilters: ', f, ' -> ', val)
    switch (f) {
      case 'sport':
        setSearchUniSport(val)
        break
      case 'region':
        setSearchUniRegion(val)
        break
      case 'competition':
        setSearchUniCompetition(val)
        break
      case 'home':
        setSearchUniHome(val)
        break
      case 'away':
        setSearchUniAway(val)
        break
      default:
        break
    }
  }

  const clearFilters = (f, val) => {
    console.log('setFilters: ', f, ' -> ', val)
    switch (f) {
      case 'sport':
        setSearchUniSport(val)
        break
      case 'region':
        setSearchUniRegion(val)
        break
      case 'competition':
        setSearchUniCompetition(val)
        break
      case 'home':
        setSearchUniHome(val)
        break
      case 'away':
        setSearchUniAway(val)
        break
      default:
        break
    }
  }

  const searchUniHandler = data => {
    setClickedTime(new Date())
    console.log('searchUniHandler...')
    switch (data.col) {
      case 'sport':
        setSearchUniSport(null)
        sleep(500)
        setSearchUniSport(data.value)
        break
      case 'region':
        setSearchUniRegion(null)
        sleep(500)
        setSearchUniRegion(data.value)
        break
      case 'competition':
        setSearchUniCompetition(null)
        sleep(500)
        setSearchUniCompetition(data.value)
        break
      case 'home':
        setSearchUniHome(data.value)
        break
      case 'away':
        setSearchUniAway(data.value)
        break

      default:
        break
    }
    console.log(data)
  }

  const leftSelector = {
    clicked: clickedTime,
    color: '#00BFFF30',
    handler: leftHandler,
    clearFilter: clearUniFilters,
    setFilters: setFilters,
    autoSimilaritySuggest: autoSimilaritySuggest,
    sportSelected: searchUniSport,
    regionSelected: searchUniRegion,
    competitionSelected: searchUniCompetition,
    homeSelected: searchUniHome,
    awaySelected: searchUniAway
  }

  const matchHandler = () => {
    let html = `
    <p>${lastUniSelected.eventId} - <b>${lastUniSelected.home}</b> Vs <b>${lastUniSelected.away}</b></p>
    <p>${lastProviderSelected.eventId} - <b>${lastProviderSelected.home}</b> Vs <b>${lastProviderSelected.away}</b></p>
    `

    const matchingMessage = {
      uni_id: lastUniSelected.eventId,
      provider_id: lastProviderSelected.providerId,
      event_id: lastProviderSelected.eventId
    }

    Swal.fire({ title: `Send Matching Request?`, html: html, showDenyButton: true, showConfirmButton: true, confirmButtonText: 'Yes', denyButtonText: 'Cancel', icon: 'info', confirmButtonColor: '#87cb16', denyButtonColor: '#ff4a55' }).then(async result => {
      if (result.isConfirmed) {
        let informAboutMatching = await postData(`/api/fixture/match`, matchingMessage, { Authorization: getAuthorizationHeader() })

        if (informAboutMatching != null) {
          Swal.fire('Process Completed!', `Matched Fixtures Sent.`, 'success')
        } else {
          Swal.fire('Error!', `Something goes wrong while saving fixture relation.`, 'error')
        }
        refreshUnis(new Event('refresh'))
        refreshProviderData(new Event('refresh'))
      }
    })
  }

  const rightSelectColor = {
    color: '#DC143C30',
    handler: rightHandler,
    cloneHandler: cloneHandler,
    searchUniHandler: searchUniHandler,
    enableMatch: enableMatch,
    matchHandler: matchHandler
  }

  const clickMatchFixtures = () => {
    let html = `
    <p><b>${leftFixture.home}</b> = ${rightFixture.home}</p>
    <p><b>${leftFixture.away}</b> = ${rightFixture.away}</p>
    `

    const matchParticipants = []

    if (leftFixture.home_id) {
      matchParticipants.push({
        master_id: leftFixture.home_id,
        master_provider_id: 0,
        child_id: rightFixture.participants[0].id,
        child_provider_id: rightFixture.providerId
      })
    }

    if (leftFixture.away_id) {
      matchParticipants.push({
        master_id: leftFixture.away_id,
        master_provider_id: 0,
        child_id: rightFixture.participants[1].id,
        child_provider_id: rightFixture.providerId
      })
    }

    if (matchParticipants.length == 0) {
      Swal.fire('Error!', `Fixtures cannot be matched! Data missing!`, 'error')
      return
    }

    const matchingMessage = {
      uni_id: leftFixture.eventId,
      provider_id: rightFixture.providerId,
      event_id: rightFixture.eventId,
      user_id: getUserData().id
    }

    Swal.fire({ title: `Match Entities?`, html: html, showDenyButton: true, showConfirmButton: true, confirmButtonText: 'Yes, match them!', denyButtonText: 'Cancel', icon: 'info', confirmButtonColor: '#87cb16', denyButtonColor: '#ff4a55' }).then(async result => {
      if (result.isConfirmed) {
        let participantsResults = await postData(`/api/providers/participants/match`, matchParticipants, { Authorization: getAuthorizationHeader() })

        if (participantsResults != null) {
          Swal.fire({ title: `Entities Matched. Send Matching Message!`, html: html, showDenyButton: false, showConfirmButton: true, confirmButtonText: 'Ok', denyButtonText: 'Cancel', icon: 'info', confirmButtonColor: '#87cb16', denyButtonColor: '#ff4a55' }).then(async result => {
            refreshRightTable == true ? setRefreshRightTable(false) : setRefreshRightTable(true)

            let informAboutMatching = await postData(`/api/fixture/matched`, matchingMessage, { Authorization: getAuthorizationHeader() })

            if (informAboutMatching != null) {
              Swal.fire('Process Completed!', `Matched Fixtures Sent.`, 'success')
            } else {
              Swal.fire('Error!', `Something goes wrong while saving fixture relation.`, 'error')
            }
          })

          // Swal.fire('Matched!', `Entities Matched.`, 'success')
          // refreshRightTable == true ? setRefreshRightTable(false) : setRefreshRightTable(true)

          // let informAboutMatching = await postData(`/api/fixture/matched`, matchingMessage, { Authorization: getAuthorizationHeader() })

          // if (informAboutMatching != null) {
          //   console.log('Matching sent to RMQ.')
          // }
        } else {
          Swal.fire('Error!', `- Partitipants Entities ${participantsResults ? 'matched' : 'cannot be matched!'} <br> -Message for Matching didnt send!`, 'error')
        }
      }
    })
  }

  const handleUniFixtures = data => {
    if (data.downloadcsv && data.downloadcsv == true) {
      setDisabled(false)
      setDownloadcsv(false)
    }
  }

  const handleCloseUniFixtureModal = (update, event) => {
    setShowCreateUniFixtureModal(false)
    if (update) {
      refreshLeftTable ? setRefreshLeftTable(false) : setRefreshLeftTable(true)
      if (event) {
        event.eventId = event.id
        leftHandler(event)
        matchHandler()
      }
    } else {
      setRefreshLeftTable(false)
    }
  }

  const fetchSports = async controller => {
    let response = await getData('/api/sports', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (response != null) {
      response.data.unshift({ id: 1000, name: 'All', is_esport: false })
      setSports(response.data)
    }
  }

  const changeValidMatchRate = ev => {
    ev.preventDefault()
    setValidMatch(ev.target.value)
  }

  const changeCompetitionSector = ev => {
    setCompetitionSector(ev.target.checked)
  }

  const changeRegionSector = ev => {
    setRegionSector(ev.target.checked)
  }

  const changeParticipantSector = ev => {
    setParticipantSector(ev.target.checked)
  }

  const constructMatchingStringPattern = () => {
    let stringToCheck = ''

    if (regionSector) stringToCheck = 'Region'
    if (competitionSector) stringToCheck = stringToCheck == '' ? 'Competition' : `${stringToCheck}::Competition`
    if (participantSector) stringToCheck = stringToCheck == '' ? `Home::Away` : `${stringToCheck}::Home::Away`

    setMatchingPattern(stringToCheck)
  }

  const refreshUnis = e => {
    e.preventDefault()
    refreshLeftTable ? setRefreshLeftTable(false) : setRefreshLeftTable(true)
  }

  const refreshProviderData = e => {
    e.preventDefault()
    refreshRightTable ? setRefreshRightTable(false) : setRefreshRightTable(true)
  }

  const handleDateOptionsChange = selectedItem => {
    setDateOptionSelected(selectedItem.value)
    // setSelectedProject(selectedItem)
    // if (selectedItem.value == 'settlement') {
    //   setProviderShow(false)
    // } else {
    //   setProviderShow(true)
    // }
  }

  const handleMachedOptionsChange = selectedItem => {
    setMatched(selectedItem.value)
  }

  useEffect(() => {
    fetchSports(controller)
    constructMatchingStringPattern()

    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    if (leftFixture == null || rightFixture == null) {
      console.log('matching aborded')
      return
    }

    if (rightFixture.sport_id != leftFixture.sport_id) {
      setDisableMatchFixtures(true)
      setEnableMatch(null)
      notificationAlertRef.current.notificationAlert(notificationOptions(`Events from different sports are selected`, 'danger'))
      return
    }
    setDisableMatchFixtures(false)
    setEnableMatch(rightFixture.eventId)
    console.log('Ready to match!! ', 'PROVIDER: ', rightFixture.providerId, ':', rightFixture.eventId, ' <-> UNI: ', leftFixture.eventId)
  }, [leftFixture, rightFixture])

  useEffect(() => {
    constructMatchingStringPattern()
  }, [regionSector, competitionSector, participantSector])

  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Container fluid>
        <CreateUniFixture showModal={showCreateUniFixtureModal} signalCloseModal={handleCloseUniFixtureModal} copiedFixture={rightFixture} />
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Body>
                <Row>
                  <Col md="4"></Col>
                  <Col md="2">
                    <label>Date Search: </label>
                    <Select options={dateOptions} defaultValue={dateOptions[0]} onChange={handleDateOptionsChange} />
                  </Col>
                  <Col md="2">
                    <label>FROM: </label>
                    <DateSelect setParentData={updateStartDate} />
                  </Col>
                  <Col md="2">
                    <label>To: </label>
                    <DateSelect setParentData={updateEndDate} />
                  </Col>

                  <Col md="2">
                    <label>Sport: </label>
                    <SportSelect sports={sports} updateSport={updateSportId} isMulti={false} />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col md="6">{/* <SportSelect sports={sports} updateSport={updateSportId} /> */}</Col>
                  <Col md="6" style={{ textAlign: 'right' }}>
                    <Button className="btn-simple btn-link p-1 btn btn-info" type="button" title="Refresh Uni Fixtures" disabled={startDate == null || sportId == null}>
                      <i className="fas fa-refresh" aria-hidden="true" onClick={e => refreshUnis(e)} />
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Col md="12">
                  <UniFixturesExtra
                    dateOptions={dateOptionSelected}
                    startDate={startDate}
                    endDate={endDate}
                    sportId={sportId}
                    matched={matched}
                    defaultSimilarity={validMatch}
                    defaultMatchingSectors={{ rSector: regionSector, cSector: competitionSector, pSector: participantSector }}
                    selectedEvent={rightFixture}
                    selectOptions={leftSelector}
                    forceUpdate={refreshLeftTable}
                  />
                </Col>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col md="4">{/* <SportSelect sports={sports} updateSport={updateRightSportId} /> */}</Col>
                  <Col md="8">
                    <Row>
                      <Col md="6">
                        <ProviderSelect setParentProviderId={updateRightSelectedProvider} defaultValue={null} />
                      </Col>
                      <Col md="4">
                        <Select options={matchedOptions} defaultValue={matchedOptions[1]} onChange={handleMachedOptionsChange}></Select>
                      </Col>
                      <Col md="2">
                        <Button className="btn-simple btn-link p-1 btn btn-info" type="button" title="Refresh Provider Fixtures" disabled={rightProviderId == null || startDate == null || sportId == null}>
                          <i className="fas fa-refresh" aria-hidden="true" onClick={e => refreshProviderData(e)} />
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row style={{ padding: '10px 0px 10px 0px', margin: '10px 0px 20px 0px', border: '1px solid #cecece60', background: 'honeydew' }}>
                  <Row style={{ textAlign: 'right', fontSize: '11px', width: '100%', margin: 'auto' }}>
                    <Col md="6" style={{ textAlign: 'left', padding: '0px 10px 0px 5px' }}>
                      <span style={{ color: 'rgb(132, 132, 132)' }}>string matching pattern:</span>{' '}
                      <span style={{ color: '#cecece' }}>
                        ({matchingPattern}) {validMatch * 100}%
                      </span>
                    </Col>
                    <Col md="6" style={{ textAlign: 'right', fontSize: '11px', padding: '0px' }}>
                      <span style={{ color: 'rgb(132, 132, 132)' }}>Auto Similarity Suggestion: </span>{' '}
                      <span style={{ display: autoSimilaritySuggest ? 'none' : '' }} className={'off-switch'} onClick={e => setAutoSimilaritySuggest(!autoSimilaritySuggest)}>
                        <i className="fa fa-circle"></i>
                        {autoSimilaritySuggest ? 'ON' : 'OFF'}
                      </span>
                      <span style={{ display: autoSimilaritySuggest ? '' : 'none' }} className={'on-switch'} onClick={e => setAutoSimilaritySuggest(!autoSimilaritySuggest)}>
                        {autoSimilaritySuggest ? 'ON' : 'OFF'}
                        <i className="fa fa-circle"></i>
                      </span>
                      <span style={{ margin: '10px 10px 10px 10px', display: matchingOptions ? 'none' : '', fontSize: '14px', cursor: 'pointer' }} onClick={e => setMatchingOptions(true)}>
                        <i className="fa fa-sort-down"></i>
                      </span>
                      <span style={{ margin: '10px 10px 10px 10px', display: matchingOptions ? '' : 'none', fontSize: '14px', cursor: 'pointer' }} onClick={e => setMatchingOptions(false)}>
                        <i className="fa fa-sort-up"></i>
                      </span>
                    </Col>
                  </Row>
                  <Row style={{ display: matchingOptions ? '' : 'none', textAlign: 'right', width: '100%', fontSize: '11px', margin: 'auto', marginTop: '10px' }}>
                    <Col md="2" style={{ textAlign: 'left', fontSize: '11px', padding: '0px 0px 0px 5px' }}>
                      <div style={{ textAlign: 'left', fontSize: '14px', lineHeight: '22px' }}>Matching sectors:</div>
                    </Col>
                    <Col md="2" style={{ padding: '0px 0px 0px 5px' }}>
                      <div style={{ cursor: 'pointer ' }}>
                        <input type="checkbox" id="region" checked={regionSector} value="" onChange={changeRegionSector} />
                        <label style={{ paddingLeft: '3px', fontSize: '11px' }} htmlFor="region">
                          Region
                        </label>
                      </div>
                    </Col>
                    <Col md="2" style={{ padding: '0px 0px 0px 5px' }}>
                      <div style={{ cursor: 'pointer' }}>
                        <input type="checkbox" id="competition" checked={competitionSector} value="" onChange={changeCompetitionSector} />
                        <label style={{ paddingLeft: '3px', fontSize: '11px' }} htmlFor="competition">
                          Competition
                        </label>
                      </div>
                    </Col>
                    <Col md="2" style={{ padding: '0px 0px 0px 5px' }}>
                      <div style={{ cursor: 'pointer' }}>
                        <input type="checkbox" id="participant" checked={participantSector} value="" onChange={changeParticipantSector} />
                        <label style={{ paddingLeft: '2px', fontSize: '11px' }} htmlFor="participant">
                          Participants
                        </label>
                      </div>
                    </Col>
                    <Col md="3" style={{ textAlign: 'right', padding: '0px 10px 0px 5px' }}>
                      <input id="similarity" style={{ width: '60px', fontSize: '11px' }} value={validMatch} onChange={changeValidMatchRate}></input>
                      <label style={{ paddingLeft: '2px', fontSize: '11px' }} htmlFor="similarity">
                        Similarity (%)
                      </label>
                    </Col>
                  </Row>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0 provider-fixtures-table-holder">
                <Col md="12">
                  <ProviderFixtures providerId={rightProviderId} dateOptions={dateOptionSelected} startDate={startDate} endDate={endDate} sportId={sportId} allreadyMatched={matched} selectOptions={rightSelectColor} forceUpdate={refreshRightTable} />
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Fixtures
