const _ = require('lodash')
import React, { useState, useEffect } from 'react'
import { Button, Modal, Form, Col, Row } from 'react-bootstrap'
import Badge from 'react-bootstrap/Badge'
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter'

import { postData } from 'utils/server'
import { getAuthorizationHeader, notificationOptions } from 'utils/helpers'

function ProviderMarketOdds({ showModal, signalCloseModal, market }) {
  const controller = new AbortController()

  const [show, setShow] = useState(false)
  const [tableData, setData] = useState([])

  const variantBgs = ['success', 'primary', 'secondary', 'danger', 'info', 'warning', 'dark']

  const columns = [
    {
      dataField: 'properties',
      text: 'Odd properties',
      align: 'center'
    },
    {
      dataField: 'values',
      text: 'Odd values',
      align: 'center',
      formatter: (cellContent, row) =>
        cellContent.length > 0
          ? cellContent.map((obj, index) => {
              let bgIndex = 1 //Math.floor(Math.random() * variantBgs.length)
              return obj ? (
                <Badge key={index} pill bg={variantBgs[bgIndex]} style={{ minWidth: '40px', marginRight: '3px', color: '#fff' }}>
                  {obj}
                </Badge>
              ) : (
                '-'
              )
            })
          : '-',
      filter: textFilter(),
      sort: true
    }
  ]

  const handleClose = () => {
    market = null
    setShow(false)
    signalCloseModal(false, null)
  }

  const fetchData = async controller => {
    if (!market) return

    let request = {
      provider_id: market.provider_id,
      sport_id: market.sport_id,
      type: market.type,
      uni_name: market.uni_name
    }
    let response = await postData(`/api/markets/providerOdds`, request, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    let tableData = []

    if (response != null) {
      for (const key in response.data) {
        if (Object.hasOwnProperty.call(response.data, key)) {
          tableData.push({ properties: key, values: response.data[key] })
        }
      }
    }

    setData(tableData)
  }

  useEffect(() => {
    setShow(showModal)
    if (showModal) fetchData(controller)
  }, [showModal])

  useEffect(() => {
    return () => {
      setShow(false)
      controller.abort()
    }
  }, [])

  return (
    <>
      <Modal show={show} onHide={handleClose} className="odds-modal">
        <Modal.Header>
          <Col md="12">
            <div>
              Available Odds for: <span style={{ fontWeight: 'bold' }}>{market.pure_name}</span>
            </div>
          </Col>
        </Modal.Header>
        <Modal.Body>
          <span style={{ display: tableData.length > 0 ? 'none' : 'block' }}>No Odds have been recorded yet! Communicate with Fifi!</span>
          <div style={{ display: tableData.length > 0 ? 'block' : 'none' }}>
            <BootstrapTable bootstrap4 keyField="properties" data={tableData} columns={columns} filter={filterFactory()} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button style={{ margin: 'auto' }} variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ProviderMarketOdds
