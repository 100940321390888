function ParticipantFixture({ fix }) {
  return (
    <div id="fixture-row-renderer" style={{ display: 'flex', flexWrap: 'nowrap', width: '100%' }}>
      <div className="fix-eventId-div">{fix ? fix.pid : ''}</div>
      <div className="fix-date-div">{fix ? fix.dateString : ''}</div>
      <div className="fix-hours-div">{fix ? fix.hourString : ''}</div>
      <div className="region-div">{fix ? fix.region : ''}</div>
      <div className="comp-div"> {fix ? fix.competition : ''}</div>
      <div className="part-div-h" style={{ maxWidth: '150px', flex: 1, minWidth: '100px' }}>
        {fix ? fix.home : ''}
      </div>
      <div className="part-seperator-div">Vs</div>
      <div className="part-div-a" style={{ maxWidth: '150px', flex: 1, minWidth: '100px' }}>
        {fix ? fix.away : ''}
      </div>
    </div>
  )
}
export default ParticipantFixture
