import React, { useState, useEffect } from 'react'
import { Button, Modal, Form, Row, Col } from 'react-bootstrap'
import NotificationAlert from 'react-notification-alert'

import { languageValidation } from 'utils/validations'
import { postData } from 'utils/server'
import { notificationOptions, getAuthorizationHeader } from 'utils/helpers'

const _ = require('lodash')

function CreateLanguage({ showModal, signalCloseModal }) {
  const notificationAlertRef = React.useRef(null)
  const [show, setShow] = useState(false)

  const handleClose = () => {
    setShow(false)
    signalCloseModal(false)
  }

  const handleSubmit = async () => {
    let language = {
      name: document.getElementById('name').value,
      code: document.getElementById('code').value
    }
    let validation = languageValidation(language)
    if (validation != null) {
      notificationAlertRef.current.notificationAlert(notificationOptions(validation.message, 'danger'))
      return
    }
    let response = await postData(`/api/languages`, language, { Authorization: getAuthorizationHeader() })
    if (response == null) {
      notificationAlertRef.current.notificationAlert(notificationOptions('Error! Something went wrong.', 'danger'))
    } else {
      setShow(false)
      signalCloseModal(true)
      notificationAlertRef.current.notificationAlert(notificationOptions('Success! Language Added.', 'success'))
    }
  }

  useEffect(() => {
    setShow(showModal)
  }, [showModal])
  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md="12">
                <Form.Group>
                  <label>Name</label>
                  <Form.Control id="name" name="name" type="text" placeholder="Type Name"></Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <br />
            <Row>
              <Col md="12">
                <Form.Group>
                  <label>ISO Code</label>
                  <Form.Control id="code" name="code" type="text" placeholder="Type ISO Code"></Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Create Language
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CreateLanguage
