import { Chart } from 'chart.js/auto'

export const initBarChart = (chart, labels, dataSet, axis, legend_display, legend_position, maintainAspectRatio) => {
  return new Chart(chart, {
    type: 'bar',
    data: {
      labels: labels,
      datasets: dataSet
    },
    options: {
      indexAxis: axis,
      plugins: {
        title: {
          display: false,
          text: 'Chart.js Bar Chart - Stacked'
        },
        legend: {
          display: legend_display,
          position: legend_position
        }
      },
      responsive: true,
      scales: {
        x: {
          stacked: true
        },
        y: {
          stacked: true
        }
      },
      maintainAspectRatio: maintainAspectRatio
    }
  });
}
