import React, { useState, useEffect } from 'react'
import { Button, Card, Container, Row, Col } from 'react-bootstrap'
import UniComps from 'components/Tables/UniComps'
import ProviderComps from 'components/Tables/ProviderComps'
import ProviderSelect from 'components/Inputs/ProviderSelect'
import CreateUniComps from 'components/Modals/CreateUniComps'
import NotificationAlert from 'react-notification-alert'
import CompetitionParticipants from 'components/Modals/CompetitionParticipants'
import _, { set } from 'lodash'

import { getData } from 'utils/server'
import { getAuthorizationHeader, notificationOptions } from 'utils/helpers'
import ProviderFixture from './ProviderFixture'
import MatchedFixture from './MatchedFixture'

function Competitions() {
  const controller = new AbortController()

  var pos1 = 0,
    pos2 = 0,
    pos3 = 0,
    pos4 = 0
  const notificationAlertRef = React.useRef(null)
  const [providerId, setProviderId] = useState(0)
  const [updateUniComps, setUpdateUniComps] = useState(false)
  const [updateProviderComps, setUpdateProviderComps] = useState(false)
  const [showCreateCompModal, setCreateCompModal] = useState(false)
  const [uniSports, setUniSports] = useState([])
  const [uniRegions, setUniRegions] = useState([])
  const [downloadcsv, setDownloadcsv] = useState(false)
  const [providerFixturesModal, setProviderFixturesModal] = useState(false)
  const [matchedFixturesModal, setMatchedFixturesModal] = useState(false)
  const [providerFixtures, setProviderFixtures] = useState([])
  const [matchedFixtures, setMatchedFixtures] = useState([])
  const [selectedProviderCompetition, setSelectedProviderCompetition] = useState(null)
  const [selectedUniCompetition, setSelectedUniCompetition] = useState(null)
  const [selectedUniCompParticipants, setSelectedUniCompParticipants] = useState(null)
  const [showParticipantsModal, setShowParticipantsModal] = useState(false)
  const [formProvidersCompetition, setFormProvidersCompetition] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const updateSelectedProvider = pId => {
    setProviderId(pId)
  }

  const handleCreateCompClick = () => {
    setCreateCompModal(true)
  }

  const clickDownloadComps = () => {
    setDownloadcsv(true)
    setDisabled(true)
  }

  const handleUniComps = data => {
    if (data.downloadcsv && data.downloadcsv == true) {
      setDisabled(false)
      setDownloadcsv(false)
    }
  }
  const handleCloseModal = updateUComps => {
    setCreateCompModal(false)
    if (updateUComps) {
      updateUniComps == true ? setUpdateUniComps(false) : setUpdateUniComps(true)
    }

    if (updateProviderComps) {
      updateProviderComps == true ? setUpdateProviderComps(false) : setUpdateProviderComps(true)
    }
  }

  const handleProvidersCompCommunication = data => {
    if (data.refresh && data.refresh === true) {
      setUpdateUniComps(false)
      setUpdateUniComps(true)
    }
    if (data.fixtures) {
      setSelectedProviderCompetition(data.fixtures)
      setProviderFixturesModal(true)
      fetchFixtures(data.fixtures, 'provider', controller)
    }

    if (data.participants) {
      setShowParticipantsModal(true)
      setFormProvidersCompetition(true)
      setSelectedUniCompParticipants(data.participants)
    }
  }

  const handleUniCompCommunication = data => {
    if (data.refresh && data.refresh === true) {
      setUpdateProviderComps(false)
      setUpdateProviderComps(true)
    }
    if (data.fixtures) {
      setSelectedUniCompetition(data.fixtures)
      setMatchedFixturesModal(true)
      fetchFixtures(data.fixtures, 'matched', controller)
    }

    if (data.participants) {
      setShowParticipantsModal(true)
      setFormProvidersCompetition(false)
      setSelectedUniCompParticipants(data.participants)
    }
  }

  const fetchSports = async controller => {
    let response = await getData('/api/sports', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (response != null) {
      setUniSports(response.data)
    }
  }

  const fetchRegions = async controller => {
    let response = await getData('/api/unis/regions', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (response != null) {
      setUniRegions(response.data)
    }
    setUpdateUniComps(true)
  }

  const fetchFixtures = async (row, type, controller) => {
    console.log(`load ${type} Fixtures with post:  `, row)
    let apiUrl = ''

    switch (type) {
      case 'uni':
        setUniFixtures([])
        apiUrl = `/api/unis/competitions/${row.id}/fixtures/200`
        break
      case 'provider':
        setProviderFixtures([])
        apiUrl = `/api/providers/${row.provider_id}/competitions/${row.pid}/fixtures/200`
        break
      case 'matched':
        setMatchedFixtures([])
        apiUrl = `/api/fixture/competitions/${row.id}/matchedFixtures/500`
        break
      default:
        apiUrl = ''
        break
    }

    let response = await getData(apiUrl, { Authorization: getAuthorizationHeader() }, errorHandler, { signal: controller.signal })
    if (response != null) {
      switch (type) {
        case 'uni':
          setUniFixtures(response.data)
          break
        case 'provider':
          setProviderFixtures(response.data)
          break
        case 'matched':
          setMatchedFixtures(response.data)
          break
        default:
          apiUrl = ''
          break
      }
    }
  }

  const errorHandler = err => {
    let errorToShow = err.name + ': ' + err.message
    if (err.response && err.response.data) errorToShow = errorToShow + ' (' + err.response.data + ')'
    notificationAlertRef.current.notificationAlert(notificationOptions('Error!\n ' + errorToShow, 'danger'))
  }

  const handleProviderFixturesModal = event => {
    event.preventDefault()
    setProviderFixturesModal(false)
    // document.getElementById('provider-fixtures-holder').style.top = 'unset'
    // document.getElementById('provider-fixtures-holder').style.left = 'unset'
    // document.getElementById('provider-fixtures-holder').style.bottom = 10
  }

  const handleUniFixturesModal = event => {
    event.preventDefault()
    setUniFixturesModal(false)
    // document.getElementById('uni-fixtures-holder').style.top = 'unset'
    // document.getElementById('uni-fixtures-holder').style.left = 'unset'
    // document.getElementById('uni-fixtures-holder').style.bottom = 10
  }

  const handleMatchedFixturesModal = event => {
    event.preventDefault()
    setMatchedFixturesModal(false)
    // document.getElementById('matched-fixtures-holder').style.top = 'unset'
    // document.getElementById('matched-fixtures-holder').style.left = 'unset'
    // document.getElementById('matched-fixtures-holder').style.bottom = 10
  }

  function dragMouseDown(e) {
    e = e || window.event
    e.preventDefault()
    // get the mouse cursor position at startup:
    pos3 = e.clientX
    pos4 = e.clientY
    document.onmouseup = closeDragElement
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag
  }

  function elementDrag(e) {
    e = e || window.event
    e.preventDefault()
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX
    pos2 = pos4 - e.clientY
    pos3 = e.clientX
    pos4 = e.clientY
    //set element to move

    let element = null
    switch (e.target.textContent) {
      case 'Uni Fixtures':
        element = document.getElementById('uni-fixtures-holder')
        break
      case 'Provider Fixtures':
        element = document.getElementById('provider-fixtures-holder')
        break
      case 'Matched Fixtures':
        element = document.getElementById('matched-fixtures-holder')
        break
      default:
        break
    }
    if (element) {
      // set the element's new position:
      element.style.top = element.offsetTop - pos2 + 'px'
      element.style.left = element.offsetLeft - pos1 + 'px'
    } else return
  }

  function closeDragElement() {
    // stop moving when mouse button is released:
    document.onmouseup = null
    document.onmousemove = null
  }

  const handleCloseParticipantsModal = () => {
    setShowParticipantsModal(false)
  }

  useEffect(() => {
    fetchSports(controller)
    fetchRegions(controller)
    return () => {
      controller.abort()
    }
  }, [])
  return (
    <>
      <CompetitionParticipants fromProviderComps={formProvidersCompetition} showModal={showParticipantsModal} signalCloseModal={handleCloseParticipantsModal} competition={selectedUniCompParticipants} />
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Container fluid>
        <CreateUniComps showModal={showCreateCompModal} signalCloseModal={handleCloseModal} sports={uniSports} regions={uniRegions} />
        <Row>
          <Col md="6">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col md="8">
                    <Card.Title as="h4">Uni Competitions</Card.Title>
                  </Col>

                  <Col md="4">
                    <Row>
                      <Col md="8" sm="12">
                        <Button style={{ backgroundColor: 'rgb(44, 47, 45)' }} className="create-region-btn" variant="success" onClick={clickDownloadComps}>
                          Download
                        </Button>
                      </Col>
                      <Col md="4" sm="12">
                        <Button className="create-region-btn" variant="success" onClick={handleCreateCompClick}>
                          Create
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Col md="12">
                  <UniComps forceUpdate={updateUniComps} sports={uniSports} regions={uniRegions} informParent={handleUniCompCommunication} informer={handleUniComps} downloadcsv={downloadcsv} />
                </Col>
              </Card.Body>
            </Card>
            <Card id="matched-fixtures-holder" style={{ display: matchedFixturesModal ? '' : 'none' }}>
              <Card.Header>
                <Row>
                  <Col md="11">
                    <Card.Title as="h6" onMouseDown={dragMouseDown} name="provider-fixtures-holder" style={{ cursor: 'move' }}>
                      Matched Fixtures
                    </Card.Title>
                  </Col>
                  <Col md="1" style={{ marginTop: '-14px', textAlign: 'right' }}>
                    <Button
                      className="btn-simple btn-link p-1"
                      type="button"
                      variant="danger"
                      onClick={event => {
                        handleMatchedFixturesModal(event)
                      }}
                    >
                      <i className="fas fa-times"></i>
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Col md="12">
                  <div style={{ fontSize: '12px' }}>
                    <div id="main-info-holder">
                      <div style={{ width: '100%' }}>{selectedUniCompetition === null ? '' : 'No fixtures found.'}</div>
                      <div className={`sport-div ${selectedUniCompetition !== null ? selectedUniCompetition.sport.toLowerCase() : ''}`}>{_.has(selectedUniCompetition, 'sport') ? selectedUniCompetition.sport : ''}</div>
                      <div className={`region-div ${selectedUniCompetition !== null ? selectedUniCompetition.sport.toLowerCase() + '-region' : ''}`}>{_.has(selectedUniCompetition, 'region') ? selectedUniCompetition.region : ''}</div>
                      <div className={`comp-div ${selectedUniCompetition !== null ? selectedUniCompetition.sport.toLowerCase() + '-comp' : ''}`}>{_.has(selectedUniCompetition, 'name') ? selectedUniCompetition.name : ''}</div>
                    </div>
                    <div style={{ overflowX: 'auto', height: '205px' }}>
                      {matchedFixtures.map(item => {
                        item.date = new Date(item.start_date * 1)
                        item.dateString = item.date.getDate() + '-' + (item.date.getMonth() + 1) + '-' + item.date.getFullYear()
                        let hours = item.date.getHours() < 10 ? '0' + item.date.getHours() : item.date.getHours()
                        let minutes = item.date.getMinutes() < 10 ? '0' + item.date.getMinutes() : item.date.getMinutes()
                        item.hourString = hours + ':' + minutes
                        item.home = item.home
                        item.away = item.away
                        return <MatchedFixture id={'matchedFixture-' + item.event_id} key={'matchedFixture-' + item.event_id} fix={item}></MatchedFixture>
                      })}
                    </div>
                  </div>
                </Col>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col md="6">
                    <Card.Title as="h4">Provider Competitions</Card.Title>
                  </Col>
                  <Col md="6">
                    <ProviderSelect setParentProviderId={updateSelectedProvider} />
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Col md="12">
                  <ProviderComps providerId={providerId} sports={uniSports} informParent={handleProvidersCompCommunication} forceUpdate={updateProviderComps} />
                </Col>
              </Card.Body>
            </Card>
            <Card id="provider-fixtures-holder" style={{ display: providerFixturesModal ? '' : 'none' }}>
              <Card.Header>
                <Row>
                  <Col md="11">
                    <Card.Title as="h6" onMouseDown={dragMouseDown} name="provider-fixtures-holder" style={{ cursor: 'move' }}>
                      Provider Fixtures
                    </Card.Title>
                  </Col>
                  <Col md="1" style={{ marginTop: '-14px', textAlign: 'right' }}>
                    <Button
                      className="btn-simple btn-link p-1"
                      type="button"
                      variant="danger"
                      onClick={event => {
                        handleProviderFixturesModal(event)
                      }}
                    >
                      <i className="fas fa-times"></i>
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Col md="12">
                  <div style={{ fontSize: '12px' }}>
                    <div id="main-info-holder">
                      <div style={{ width: '100%' }}>{selectedProviderCompetition == null ? '' : 'No fixtures found.'}</div>
                      <div className={`sport-div ${selectedProviderCompetition !== null ? selectedProviderCompetition.sport.toLowerCase() : ''}`}>{_.has(selectedProviderCompetition, 'sport') ? selectedProviderCompetition.sport : ''}</div>
                      <div className={`region-div ${selectedProviderCompetition !== null ? selectedProviderCompetition.sport.toLowerCase() + '-region' : ''}`}>{_.has(selectedProviderCompetition, 'region') ? selectedProviderCompetition.region : ''}</div>
                      <div className={`comp-div ${selectedProviderCompetition !== null ? selectedProviderCompetition.sport.toLowerCase() + '-comp' : ''}`}>{_.has(selectedProviderCompetition, 'name') ? selectedProviderCompetition.name : ''}</div>
                    </div>
                    <div style={{ overflowX: 'auto', height: '205px' }}>
                      {providerFixtures.map(item => {
                        item.date = new Date(item.start_date * 1)
                        item.dateString = item.date.getDate() + '-' + (item.date.getMonth() + 1) + '-' + item.date.getFullYear()
                        let hours = item.date.getHours() < 10 ? '0' + item.date.getHours() : item.date.getHours()
                        let minutes = item.date.getMinutes() < 10 ? '0' + item.date.getMinutes() : item.date.getMinutes()
                        item.hourString = hours + ':' + minutes
                        return <ProviderFixture id={'providerFixture-' + item.pid} key={'providerFixture-' + item.event_id} fix={item}></ProviderFixture>
                      })}
                    </div>
                  </div>
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Competitions
