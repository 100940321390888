import React from 'react'
import { Container } from 'react-bootstrap'
import UnmatchedCompetitions from 'components/Tables/UnmatchedCompetitions'

export default function MatchCompetitions() {
  return (
    <>
      <Container fluid>
        <UnmatchedCompetitions />
      </Container>
    </>
  )
}