import React, { useState, useEffect } from 'react'
import { Button, Modal, Form, Row, Col } from 'react-bootstrap'
import NotificationAlert from 'react-notification-alert'
import UserRoleSelect from 'components/Inputs/UserRoleSelect'

import { marketGroupValidation } from 'utils/validations'
import { putData } from 'utils/server'
import { notificationOptions, getAuthorizationHeader } from 'utils/helpers'

import SportSelect from 'components/Inputs/SportSelect'

const _ = require('lodash')

function UpdateMarketGroup({ showModal, parentName, parentSort, parentId, sport, sports, signalCloseModal }) {
  const notificationAlertRef = React.useRef(null)
  const [show, setShow] = useState(false)
  const [marketGroupName, setMarkerGroupName] = useState(parentName)
  const [marketGroupSort, setMarkerGroupSort] = useState(parentSort)
  const [selectedSport, setSelectedSport] = useState(sport)

  const handleClose = () => {
    setShow(false)
    signalCloseModal(false)
  }

  const handleMarketGroupNameChange = () => {
    setMarkerGroupName(document.getElementById('group-name').value)
  }

  const handleMarketGroupSortChange = () => {
    setMarkerGroupSort(document.getElementById('group-sort').value)
  }

  const handleSportChange = selectedItem => {
    setSelectedSport(selectedItem)
  }

  const handleSubmit = async () => {
    let marketGroup = {
      name: marketGroupName,
      sort: marketGroupSort,
      sport_id: selectedSport.id
    }
    let validation = marketGroupValidation(marketGroup)
    if (validation != null) {
      notificationAlertRef.current.notificationAlert(notificationOptions(validation.message, 'danger'))
      return
    }
    let response = await putData(`/api/unis/groups/${parentId}`, marketGroup, { Authorization: getAuthorizationHeader() })
    if (response == null) {
      notificationAlertRef.current.notificationAlert(notificationOptions('Error! Something went wrong.', 'danger'))
    } else {
      setShow(false)
      signalCloseModal(true)
      notificationAlertRef.current.notificationAlert(notificationOptions('Success! User Updated.', 'success'))
    }
  }

  useEffect(() => {
    if (sport) setSelectedSport({ id: sport.value, name: sport.label })
    setShow(showModal)
    setMarkerGroupName(parentName)
    setMarkerGroupSort(parentSort)
  }, [showModal])
  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md="12">
                <Form.Group>
                  <label>Market Group Name:</label>
                  <Form.Control id="group-name" name="group-name" type="text" placeholder="Type Market Group Name" defaultValue={marketGroupName} onChange={handleMarketGroupNameChange}></Form.Control>
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group>
                  <label>Sport:</label>
                  <SportSelect sports={sports} selectedItem={sport} updateSport={handleSportChange} />
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group>
                  <label>Sort:</label>
                  <Form.Control id="group-sort" name="group-sort" type="number" placeholder="Type Market Group Sort" defaultValue={marketGroupSort} onChange={handleMarketGroupSortChange}></Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="warning" onClick={handleSubmit}>
            Update Market Group
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default UpdateMarketGroup
