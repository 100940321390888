const _ = require('lodash')
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import NotificationAlert from 'react-notification-alert'

import UpdateMarketGroup from 'components/Modals/UpdateMarketGroup'
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter'
import cellEditFactory from 'react-bootstrap-table2-editor'

import Swal from 'sweetalert2'

import { getData, delData, putData } from 'utils/server'
import { getAuthorizationHeader, notificationOptions, getUserRole } from 'utils/helpers'
import UniTranslation from 'components/Modals/UniTranslation'

let regionFilter = ''

function MarketGroupsTable({ forceUpdate, sports }) {
  const controller = new AbortController()

  const notificationAlertRef = React.useRef(null)
  const [tableData, setData] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [showTranslationModal, setShowTranslationModal] = useState(false)
  const [name, setMarketGroupName] = useState('')
  const [sort, setMarketGroupSort] = useState(0)
  const [id, setMarketGroupId] = useState(null)
  const [sport, setMarketGroupSport] = useState(null)
  const [sportFilters, setSportFilters] = useState({})
  const [selectedMarketGroupId, setSelectedMarketGroupId] = useState(null)
  const [selectedMarketGroupName, setSelectedMarketGroupName] = useState(null)

  const paginationOptions = {
    disablePageTitle: true,
    showTotal: false,
    sizePerPageList: [
      {
        text: '10',
        value: 10
      }
    ]
  }

  function clearAllFilter() {
    regionFilter('')
  }

  const createFilters = () => {
    let mySportFilters = {}
    for (let sport of sports) mySportFilters[sport.id] = sport.name
    setSportFilters(mySportFilters)
  }

  const handleDeleteClick = row => {
    Swal.fire({ title: `Delete Market Group ${row.name}?`, showDenyButton: true, showConfirmButton: true, confirmButtonText: 'Yes, delete it!', denyButtonText: 'Cancel', icon: 'warning', confirmButtonColor: '#87cb16', denyButtonColor: '#ff4a55' }).then(async result => {
      if (result.isConfirmed) {
        let result = await delData(`/api/unis/groups/${row.id}`, { Authorization: getAuthorizationHeader() })
        if (result != null) {
          Swal.fire('Deleted!', `Market Group ${row.name} has been deleted.`, 'success')
          fetchData(controller)
        } else {
          Swal.fire('Error!', `Something went wrong.`, 'error')
        }
      }
    })
  }

  const handleEditClick = row => {
    setMarketGroupName(row.name)
    setMarketGroupSort(row.sort)
    setMarketGroupId(row.id)
    setMarketGroupSport({ value: row.sport_id, label: row.sport_name })
    showModal ? setShowModal(false) : setShowModal(true)
  }

  const handleTranslationClick = row => {
    setSelectedMarketGroupId(row.id)
    setSelectedMarketGroupName(row.name)
    showTranslationModal ? setShowTranslationModal(false) : setShowTranslationModal(true)
  }

  const DeleteButton = props => {
    return (
      <Button
        title="Delete group"
        className="btn-simple btn-link p-1"
        type="button"
        variant="danger"
        onClick={() => {
          handleDeleteClick(props.item)
        }}
      >
        <i className="fas fa-times"></i>
      </Button>
    )
  }

  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'name',
      text: 'Name',
      editable: false,
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'sport_id',
      text: 'Sport',
      align: 'center',
      editable: false,
      formatter: cell => sportFilters[cell],
      filter: selectFilter({
        options: sportFilters,
        getFilter: filter => {
          regionFilter = filter
        }
      })
    },
    {
      dataField: 'sort',
      text: 'Sort',
      align: 'center',
      sort: true,
      editable: false
    },
    {
      dataField: 'dm1',
      isDummyField: true,
      text: 'Actions',
      editable: false,
      formatter: (cellContent, row) => (
        <>
          <Button
            title="Edit group"
            className="btn-simple btn-link p-1"
            type="button"
            variant="warning"
            onClick={() => {
              handleEditClick(row)
            }}
          >
            <i className="fas fa-pen"></i>
          </Button>
          <Button
            title="Add translations"
            className="btn-simple btn-link p-1"
            type="button"
            variant="warning"
            onClick={() => {
              handleTranslationClick(row)
            }}
          >
            <i className="fas fa-comments"></i>
          </Button>
          <DeleteButton item={row} />
        </>
      )
    }
  ]

  const fetchData = async controller => {
    //clearAllFilter()
    let response = await getData(`/api/unis/groups`, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    let tableData = []
    if (response != null) tableData = response.data
    setData(tableData)
  }

  const handleCloseModal = updt => {
    setShowModal(false)
    setShowTranslationModal(false)
    if (updt) fetchData(controller)
  }

  useEffect(() => {
    if (forceUpdate) fetchData(controller)
  }, [forceUpdate])

  useEffect(() => {
    if (sports) createFilters()
  }, [sports])

  useEffect(() => {
    if (Object.keys(sportFilters).length > 0) fetchData(controller)
  }, [sportFilters])

  useEffect(() => {
    return () => {
      controller.abort()
    }
  }, [])

  return (
    <>
      <UpdateMarketGroup showModal={showModal} parentName={name} parentSort={sort} parentId={id} sports={sports} sport={sport} signalCloseModal={handleCloseModal} />
      <UniTranslation showModal={showTranslationModal} entityId={selectedMarketGroupId} entityName={selectedMarketGroupName} entityType={'marketGroups'} entityKey={'group_name'} signalCloseModal={handleCloseModal} />
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <BootstrapTable bootstrap4 keyField="id" data={tableData} columns={columns} pagination={paginationFactory({ sizePerPage: 10 })} filter={filterFactory()} />
    </>
  )
}
export default MarketGroupsTable
