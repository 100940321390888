import React, { useState, useEffect, useRef } from 'react'

import { getData } from 'utils/server'
import NotificationAlert from 'react-notification-alert'
import { notificationOptions, getAuthorizationHeader } from 'utils/helpers'

import SportSelect from 'components/Inputs/SportSelect'
import DataSelect from 'components/Inputs/generalDataSelect'
import DateSelect from 'components/Inputs/DateSelect'
import SettlementOriginSelect from 'components/Inputs/settlementOriginSelect'

import { settledValidation } from 'utils/validations'

// react-bootstrap components
import { Badge, Button, Card, Navbar, Nav, Table, Container, Row, Col, Form, OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap'
import { postData } from 'utils/server'

import csvDownload from 'json-to-csv-export'

export default function UnisSettlementStats({ autoload, collapsed }) {
  const controller = new AbortController()

  const notificationAlertRef = useRef(null)
  const [providers, setProviders] = useState([])
  const [uniSports, setUniSports] = useState([])
  const [settlementOrigins, setSettlementOrigins] = useState([])
  const [selectedProvider, setSelectedProvider] = useState({ value: 0, label: 'All providers' })
  const [selectedSport, setSelectedSport] = useState({ value: 0, label: 'All sports' })
  const [selectedSettlementOrigin, setSelectedSettlementOrigin] = useState({ value: 0, label: 'All' })
  const [visible, setVisible] = useState(collapsed)
  const [disabled, setDisabled] = useState(false)
  const [firstIcon, setFirstIcon] = useState(<i className="fas fa-file-csv"></i>)
  const [secondIcon, setSecondIcon] = useState(<i className="fas fa-file-csv"></i>)
  const [firstText, setFirstText] = useState(<span>Settlement stats</span>)
  const [secondText, setSecondText] = useState(<span>Different settlement stats</span>)

  const dateConverter = date => {
    return (
      date.getTime()
    )
  }

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const provider = async () => {
    return selectedProvider
  }

  const sport = async () => {
    return selectedSport
  }

  const settlementOrigin = async () => {
    return selectedSettlementOrigin
  }

  const date1 = async () => {
    return startDate
  }

  const date2 = async () => {
    return endDate
  }

  const fetchFilters = async controller => {
    let providers_response = await getData('/api/providers', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (providers_response != null) {
      let data = providers_response.data
      data.unshift({ id: 0, name: 'All providers' })
      setProviders(providers_response.data)
      setSelectedProvider({ value: 0, label: 'All providers' })
    }

    let sports_response = await getData('/api/sports', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (sports_response != null) {
      let data = sports_response.data
      data.unshift({ id: 0, name: 'All sports' })
      setUniSports(sports_response.data)
      setSelectedSport({ value: 0, label: 'All sports' })
    }

    let settlement_origins = await getData('/api/markets/settlementOrigins', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (settlement_origins != null) {
      let data = settlement_origins.data
      data.unshift({ id: 0, description: 'All' })
      setSettlementOrigins(data)
      setSelectedSettlementOrigin({ value: 0, label: 'All' })
    }
  }

  const fetchDiffs = async (event, controller) => {
    setDisabled(true)
    setSecondIcon(<i className="fas fa-spinner fa-spin text-danger"></i>)
    setSecondText(<span className="text-danger">Please wait...</span>)
    setFirstIcon(<i className="fas fa-file-csv text-primary"></i>)
    setFirstText(<span className="text-primary">Settlement stats</span>)
    let request = {}
    let startDate = await date1()
    let endDate = await date2()
    let currentProvider = await provider()
    let currentSport = await sport()

    // console.log(currentProvider)
    // console.log(currentSport)
    // console.log(startDate)
    // console.log(endDate)

    if (currentProvider.value == 0 && currentSport.value == 0) {
      request = {
        from_date: startDate,
        to_date: endDate
      }
    } else if (currentProvider.value == 0 && currentSport.value != 0) {
      request = {
        sport_id: currentSport.value,
        from_date: startDate,
        to_date: endDate
      }
    } else if (currentProvider.value != 0 && currentSport.value == 0) {
      request = {
        provider_id: currentProvider.value,
        from_date: startDate,
        to_date: endDate
      }
    } else if (currentProvider.value != 0 && currentSport.value != 0) {
      request = {
        provider_id: currentProvider.value,
        sport_id: currentSport.value,
        from_date: startDate,
        to_date: endDate
      }
    }

    let response = await postData(`/api/reports/markets/settlement/percentages`, request, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (response != null) {
      if (response.data.length != 0) {
        response.data.sort((a, b) => {
          if (a.provider > b.provider) return 1
          if (a.provider < b.provider) return -1
          return a.sport.localeCompare(b.sport)
        })

        const dataToCSV = {
          data: response.data,
          filename: `${currentProvider.label}_${currentSport.label}_diff_percentages_from_${new Date(startDate).toLocaleString()}_to_${new Date(endDate).toLocaleString()}`,
          delimiter: ';'
        }
        csvDownload(dataToCSV)
        notificationAlertRef.current.notificationAlert(notificationOptions('Your file created successfully', 'success'))
      } else {
        notificationAlertRef.current.notificationAlert(notificationOptions('There are no data available for these options', 'danger'))
        setSecondIcon(<i className="fas fa-file-csv"></i>)
        setSecondText(<span>Different settlement stats</span>)
        setDisabled(false)
        return false
      }
      setSecondIcon(<i className="fas fa-file-csv"></i>)
      setSecondText(<span>Different settlement stats</span>)
      setDisabled(false)
    } else {
      notificationAlertRef.current.notificationAlert(notificationOptions('Error! Something went wrong', 'danger'))
      setSecondIcon(<i className="fas fa-file-csv"></i>)
      setSecondText(<span>Different settlement stats</span>)
      setDisabled(false)
    }
  }

  const fetchData = async (event, controller) => {
    setDisabled(true)
    setFirstIcon(<i className="fas fa-spinner fa-spin text-primary"></i>)
    setFirstText(<span className="text-primary">Please wait...</span>)
    setSecondIcon(<i className="fas fa-file-csv text-danger"></i>)
    setSecondText(<span className="text-danger">Different settlement stats</span>)
    let request = {}

    let currentProvider = await provider()
    let currentSport = await sport()
    let currentSettlementOrigin = await settlementOrigin()
    let startDate = await date1()
    let endDate = await date2()

    console.log(currentProvider)
    console.log(currentSport)
    console.log(currentSettlementOrigin)
    console.log(startDate)
    console.log(endDate)

    if (currentProvider.value != 0 && currentSport.value == 0) {
      if (currentSettlementOrigin.value == 0) {
        request = {
          provider_id: currentProvider.value,
          from_date: startDate,
          to_date: endDate
        }
      } else {
        request = {
          provider_id: currentProvider.value,
          from_date: startDate,
          to_date: endDate,
          originId: currentSettlementOrigin.value
        }
      }
    } else if (currentProvider.value != 0 && currentSport.value != 0) {
      if (currentSettlementOrigin.value == 0) {
        request = {
          provider_id: currentProvider.value,
          sport_id: currentSport.value,
          from_date: startDate,
          to_date: endDate
        }
      } else {
        request = {
          provider_id: currentProvider.value,
          sport_id: currentSport.value,
          from_date: startDate,
          to_date: endDate,
          originId: currentSettlementOrigin.value
        }
      }
    } else if (currentProvider.value == 0 && currentSport.value == 0) {
      if (currentSettlementOrigin.value == 0) {
        request = {
          from_date: startDate,
          to_date: endDate
        }
      } else {
        request = {
          from_date: startDate,
          to_date: endDate,
          originId: currentSettlementOrigin.value
        }
      }
    } else if (currentProvider.value == 0 && currentSport.value != 0) {
      if (currentSettlementOrigin.value == 0) {
        request = {
          sport_id: currentSport.value,
          from_date: startDate,
          to_date: endDate
        }
      } else {
        request = {
          sport_id: currentSport.value,
          from_date: startDate,
          to_date: endDate,
          originId: currentSettlementOrigin.value
        }
      }
    }

    let validation = settledValidation(request)
    if (validation != null) {
      notificationAlertRef.current.notificationAlert(notificationOptions(validation.message, 'danger'))
      setFirstIcon(<i className="fas fa-file-csv"></i>)
      setFirstText(<span>Settlement stats</span>)
      setDisabled(false)
      return
    }

    let response = await postData('/api/reports/markets/settlement/provider/sport/stats', request, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (response != null) {
      if (response.data.length != 0) {
        response.data.sort((a, b) => (a.sport > b.sport ? 1 : b.sport > a.sport ? -1 : 0))

        const dataToCSV = {
          data: response.data,
          filename: `${currentProvider.label}_${currentSport.label}_percentages_from_${new Date(startDate).toLocaleString()}_to_${new Date(endDate).toLocaleString()}`,
          delimiter: ';'
        }
        csvDownload(dataToCSV)
        notificationAlertRef.current.notificationAlert(notificationOptions('Your file created successfully', 'success'))
      } else {
        notificationAlertRef.current.notificationAlert(notificationOptions('There are no data available for these options', 'danger'))
        setFirstIcon(<i className="fas fa-file-csv"></i>)
        setFirstText(<span>Settlement stats</span>)
        setDisabled(false)
        return false
      }
      setFirstIcon(<i className="fas fa-file-csv"></i>)
      setFirstText(<span>Settlement stats</span>)
      setDisabled(false)
    } else {
      notificationAlertRef.current.notificationAlert(notificationOptions('Error! Something went wrong', 'danger'))
      setFirstIcon(<i className="fas fa-file-csv"></i>)
      setFirstText(<span>Settlement stats</span>)
      setDisabled(false)
      return
    }
  }

  const updateStatsSelectedProvider = selProv => {
    setSelectedProvider(selProv)
  }

  const updateStatsSelectedSport = selSport => {
    setSelectedSport(selSport)
  }

  const updateSettlementOrigin = sSO => {
    setSelectedSettlementOrigin(sSO)
  }

  const updateStartDate = newDate => {
    let tmpDate = new Date(newDate)
    setStartDate(dateConverter(tmpDate))
  }

  const updateEndDate = newDate => {
    let tmpDate = new Date(newDate)
    setEndDate(dateConverter(tmpDate))
  }

  const minimizeChart = () => {
    if (visible) {
      fetchFilters(controller)
      setVisible(false)
    }
    if (!visible) {
      setVisible(true)
    }
  }

  return (
    <Card>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Card.Header>
        <Card.Title as="h4">
          Export unis Settlements Stats
          <div style={{ position: 'absolute', top: '5px', right: '10px' }} onClick={minimizeChart}>
            <i style={{ display: visible ? '' : 'none' }} className="fas fa-angle-down custom-button-like"></i>
            <i style={{ display: visible ? 'none' : '' }} className="fas fa-angle-up custom-button-like"></i>
          </div>
        </Card.Title>
        <Row style={{ display: visible ? 'none' : '' }}>
          <Col md="12">
            <span style={{ color: '#cecece' }}>Insert filters and then press the button to create your file</span>
          </Col>
          <Col md="12">&nbsp;</Col>
          <Col md="12">
            <Row>
              <Col md="4">
                <Form.Group>
                  <label>Provider:</label>
                  <DataSelect data={providers} updateItem={updateStatsSelectedProvider} selectedItem={selectedProvider} placeholder={`Select Provider...`}/>
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group>
                  <label>Sport:</label>
                  <SportSelect sports={uniSports} updateSport={updateStatsSelectedSport} selectedItem={JSON.stringify(selectedSport)} />
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group>
                  <label>Settlement Origin:</label>
                  <SettlementOriginSelect settlementOrigins={settlementOrigins} updateSettlementOrigin={updateSettlementOrigin} selectedItem={JSON.stringify(selectedSettlementOrigin)} />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md="6">
                <Form.Group>
                  <label>From:</label>
                  <DateSelect setParentData={updateStartDate} showTime={true} />
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group>
                  <label>To:</label>
                  <DateSelect setParentData={updateEndDate} showTime={true} />
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col md="6">
            <div className="mt-2">
              <Button variant="primary" onClick={e => fetchData(e, controller)} disabled={disabled}>
                {firstIcon} {firstText}
              </Button>
            </div>
          </Col>
          <Col md="6">
            <div className="mt-2">
              <Button variant="danger" onClick={e => fetchDiffs(e, controller)} disabled={disabled}>
                {secondIcon} {secondText}
              </Button>
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body style={{ display: visible ? 'none' : '' }}></Card.Body>
      <Card.Footer>
        <hr></hr>
        <div className="stats">
          <i className="fas fa-history"></i>
          Updated 3 minutes ago
        </div>
      </Card.Footer>
    </Card>
  )
}
