import DatePicker from 'react-datepicker'
import moment from 'moment/moment'

import 'react-datepicker/dist/react-datepicker.css'

function MonthSelect({ selectedDate, handleDateChange }) {
  return <DatePicker className="monthSelect" selected={selectedDate} onChange={handleDateChange} showMonthYearPicker dateFormat="MMMM yyyy" placeholderText="Select month" />
}

export default MonthSelect
