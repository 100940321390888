import React, { useEffect, useState } from 'react'

import Select from 'react-select'

import { getData } from 'utils/server'
import { getAuthorizationHeader } from 'utils/helpers'

function UserRoleSelect({ setParentRole, defaultValue }) {
  const controller = new AbortController()

  const [userRoles, setUserRoles] = useState([])
  const [selected, setSelected] = useState(null)

  const fetchData = async controller => {
    let response = await getData('/api/roles', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    let userRoles = []
    if (response != null) {
      response.data.map(el => {
        el.value = el.id
        el.label = el.name
      })
    }
    userRoles = response.data
    setUserRoles(userRoles)
    if (defaultValue != null) {
      setSelected(userRoles.find(el => el.id == defaultValue))
    }
  }

  const handleChange = selectedItem => {
    setSelected(selectedItem)
    setParentRole(selectedItem.id)
  }
  useEffect(() => {
    fetchData(controller)

    return () => {
      controller.abort()
    }
  }, [])
  return <Select className="userRolesSelect" name="userRolesSelect" options={userRoles} placeholder="Select user role.." onChange={handleChange} value={selected} />
}

export default UserRoleSelect
