import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import cellEditFactory from 'react-bootstrap-table2-editor'
import Swal from 'sweetalert2'
import NotificationAlert from 'react-notification-alert'

import { getData, delData, putData } from 'utils/server'
import { getAuthorizationHeader, notificationOptions, copyToClipboard } from 'utils/helpers'
import UniTranslation from 'components/Modals/UniTranslation'
import TableCounters from 'components/UniObject/TableCounters'

function UniRegions({ forceUpdate, informParent, downloadcsv }) {
  const controller = new AbortController()

  const notificationAlertRef = React.useRef(null)
  const [tableData, setData] = useState([])
  const [allcounter, setAllCounter] = useState(0)
  const [filteredcounter, setFilteredcounter] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [selectedRegionId, setSelectedRegionId] = useState(null)
  const [selectedRegionName, setSelectedRegionName] = useState('')

  const paginationOptions = {
    disablePageTitle: true,
    showTotal: false,
    sizePerPageList: [
      {
        text: '25',
        value: 20
      },
      {
        text: '50',
        value: 50
      }
    ]
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }
  const handleMatchingInfoUniRegions = async (regions, controller) => {
    let result = await getData(`/api/unis/regions/${regions.id}/relations`, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })

    console.log(result.data)

    let htmlText = '<div style="min-width:50px;text-align: left;"><ul>'

    let name_width = 100
    let provider_width = 100

    for (const element of result.data) {
      name_width = Math.max(name_width, element.name.length * 10)
      provider_width = Math.max(provider_width, element.provider.length * 10)
    }

    // Construct the HTML text for each element
    for (const element of result.data) {
      const minWidth = Math.max(element.pid.toString().length * 8, provider_width)
      htmlText += `<li style="border-bottom: 1px solid;width: max-content; display: flex; align-items: stretch;">
        <div class="rowCell" style="text-align: left;padding: 8px;min-width: ${minWidth}px;">${element.pid}</div>
        <div class="${element.provider}Provider" style="text-align: center; padding: 8px;width: ${minWidth}px;">${element.provider}</div>
        <div class="rowCell" style="text-align: center;padding: 8px;width: ${name_width}px;">${element.name}</div>
      </li>`
    }

    htmlText += '</ul></div>'

    Swal.fire({
      title: `Matched Regions:`,
      customClass: 'swal-wide',
      html: htmlText,
      showDenyButton: false,
      showConfirmButton: true,
      confirmButtonText: 'OK',
      denyButtonText: 'Cancel',
      icon: 'info',
      confirmButtonColor: '#87cb16',
      denyButtonColor: '#ff4a55'
    }).then(async result => {
      if (result.isConfirmed) {
        // Handle confirmation
      }
    })
  }

  const handleDeleteClick = row => {
    Swal.fire({ title: `Delete Region ${row.name}?`, showDenyButton: true, showConfirmButton: true, confirmButtonText: 'Yes, delete it!', denyButtonText: 'Cancel', icon: 'warning', confirmButtonColor: '#87cb16', denyButtonColor: '#ff4a55' }).then(async result => {
      if (result.isConfirmed) {
        let result = await delData(`/api/unis/regions/${row.id}`, { Authorization: getAuthorizationHeader() })
        if (result != null) {
          Swal.fire('Deleted!', `Region ${row.name} has been deleted.`, 'success')
          fetchData(controller)
        } else {
          Swal.fire('Error!', `Something went wrong.`, 'error')
        }
      }
    })
  }

  const handleTranslationClick = row => {
    setSelectedRegionId(row.id)
    setSelectedRegionName(row.name)
    setShowModal(true)
  }

  const columns = [
    {
      dataField: 'id',
      text: 'UNI ID',
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <div onClick={e => copyToClipboard(e, cellContent, notificationAlertRef)} style={{ cursor: 'copy' }}>
            <p>{cellContent}</p>
          </div>
        </>
      ),
      filter: textFilter()
    },
    {
      dataField: 'name',

      text: 'Name',
      filter: textFilter(),
      editable: true,
      sort: true
    },
    {
      dataField: 'dm1',
      isDummyField: true,
      text: 'Actions',
      editable: false,
      formatter: (cellContent, row) => (
        <>
          <Button
            className="btn-simple btn-link p-1"
            type="button"
            //variant={row.matched.length > 0 ? 'dark' : 'secondary'}
            //disabled={row.matched.length > 0 ? false : true}
            onClick={() => {
              handleMatchingInfoUniRegions(row, controller)
            }}
            //title={'Matched Markets: ' + row.matched.length}
          >
            <i className="fas fa fa-info"></i>
          </Button>
          <Button
            title="Edit Translations"
            className="btn-simple btn-link p-1"
            type="button"
            variant="warning"
            onClick={() => {
              handleTranslationClick(row)
            }}
          >
            <i className="fas fa-comments"></i>
          </Button>
          <span>|</span>
          <Button
            title="Delete Region"
            className="btn-simple btn-link p-1"
            type="button"
            variant="danger"
            onClick={() => {
              handleDeleteClick(row)
            }}
          >
            <i className="fas fa-times"></i>
          </Button>
        </>
      )
    }
  ]

  const downloadCsv = async controller => {
    let csvData = _.sortBy(tableData, ['name'])
    let csv = 'data:text/csv;charset=utf-8,'
    csv += 'ID;Name\n'
    csvData.forEach(row => {
      csv += `${row.id};${row.name};\n`
    })
    let encodedUri = encodeURI(csv)
    let link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', 'regions.csv')
    document.body.appendChild(link)
    link.click()
    informParent({ downloadcsv: true })
  }

  const changeCellValue = (oldValue, newValue, row, column, done) => {
    if (newValue == '') done(false)
    putData(`/api/unis/regions/${row.id}`, { name: newValue }, { Authorization: getAuthorizationHeader() }).then(response => {
      console.log(response)
      if (response == null) {
        notificationAlertRef.current.notificationAlert(notificationOptions('Error! Cannot edit record.', 'danger'))
        done(false)
      } else {
        notificationAlertRef.current.notificationAlert(notificationOptions(`Region ${row.id} has been Updated.`, 'success'))
        done(true)
      }
    })
    return { async: true }
  }

  const fetchData = async controller => {
    let response = await getData('/api/unis/regions', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    let tableData = []
    if (response != null) tableData = response.data
    setAllCounter(response.data.length)
    setData(tableData)
    informParent({ downloadcsv: true })
  }

  function afterFilter(newResult, newFilters) {
    setFilteredcounter(newResult.length)
  }

  useEffect(() => {
    if (downloadcsv) downloadCsv(controller)
  }, [downloadcsv])

  useEffect(() => {
    fetchData(controller)
  }, [forceUpdate])

  useEffect(() => {
    return () => {
      controller.abort()
    }
  }, [])

  return (
    <>
      <UniTranslation showModal={showModal} entityId={selectedRegionId} entityName={selectedRegionName} entityType={'regions'} entityKey={'region_id'} signalCloseModal={handleCloseModal} />
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <TableCounters all={allcounter} filtered={filteredcounter}></TableCounters>
      <BootstrapTable bootstrap4 keyField="id" data={tableData} columns={columns} pagination={paginationFactory({ sizePerPage: 10 })} filter={filterFactory({ afterFilter })} cellEdit={cellEditFactory({ mode: 'click', beforeSaveCell: changeCellValue })} />
    </>
  )
}
export default UniRegions
