const _ = require('lodash')
import React from 'react'
import NotificationAlert from 'react-notification-alert'
import { useHistory } from 'react-router-dom'
import { notificationOptions } from 'utils/helpers'

// react-bootstrap components

function Logout() {
  localStorage.removeItem(`s90Cookie`)
  const history = useHistory()
  history.push(`/`)

  return <></>
}

export default Logout
