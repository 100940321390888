import _, { set } from 'lodash'
import { Row, Col } from 'react-bootstrap'
import React, { useEffect, useState, useRef } from 'react'
import { getAuthorizationHeader, notificationOptions } from 'utils/helpers'
import { putData } from 'utils/server'
import NotificationAlert from 'react-notification-alert'

function ParticipantListElement({ participant, provider, provider_id, sportId, onInfoClick, sport, competition }) {
  const isProviderParticipant = _.has(participant, 'id')
  const notificationAlertRef = React.useRef(null)
  const inputRef = useRef(null)
  const [inputValue, setInputValue] = useState('')
  const [isEditing, setIsEditing] = useState(false)
  const [uniId, setUniId] = useState(participant.uni_id)
  const handleInfoClick = event => {
    event.preventDefault()

    onInfoClick(participant)
  }

  useEffect(() => {
    if (isEditing) {
      setInputValue(uniId || '')
      inputRef.current.focus()
    }
  }, [isEditing])

  useEffect(() => {
    setUniId(participant.uni_id)
  }, [participant])
  const handleInputChange = async (e, participant) => {
    let newUniId = parseInt(e.target.value)
    let rowData = {
      uni_id: parseInt(e.target.value),
      id: participant.p1_id,
      name: participant.name,
      pid: parseInt(participant.id),
      provider: provider,
      provider_id: provider_id,
      sport_id: sportId,
      sport: sport,
      competition: competition.name
    }

    const response = await putData(`/api/providers/participants/${participant.p1_id}`, rowData, { Authorization: getAuthorizationHeader() })
    if (response == null) {
      notificationAlertRef.current.notificationAlert(notificationOptions('Error! Cannot edit record.', 'danger'))
    } else {
      notificationAlertRef.current.notificationAlert(notificationOptions('Record updated successfully.', 'success'))
      setUniId(newUniId)
      setIsEditing(false)
    }
  }

  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      {isProviderParticipant ? (
        <Row style={{ color: participant.uni_id == null ? 'red' : 'green' }}>
          <Col md="3">
            {participant.id}
          </Col>
          <Col md="4">
            {participant.name}
          </Col>

          <Col md="3">
            {isEditing ? (
              <input
                ref={inputRef}
                type="text"
                value={inputValue}
                onChange={e => setInputValue(e.target.value)}
                onBlur={() => setIsEditing(false)}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    e.target.blur()
                    handleInputChange(e, participant)
                  }
                }}
                style={{ width: '180%' }}
              />
            ) : (
              <div onClick={() => setIsEditing(true)} style={{ paddingRight: '60px' }}>
                <span>{uniId || '\u00A0'}</span>
              </div>
            )}
          </Col>

          <Col md="1">
            <a href="#" onClick={handleInfoClick} style={{ backgroundColor: '#4CAF50', border: 'none', color: 'white', padding: '2px 5px', textAlign: 'center', textDecoration: 'none', display: 'inline-block', fontSize: '12px', margin: '4px 2px', cursor: 'pointer' }}>
              <i className="fas fa-info"></i>
            </a>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col md="3" style={{ paddingLeft: '2px' }}>
            {participant.uni_id}
          </Col>
          <Col md="6">{participant.name}</Col>
        </Row>
      )}
    </>
  )
}

export default ParticipantListElement
