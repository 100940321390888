import React, { useState, useEffect } from 'react'
import { Card, Container, Row, Col, Button } from 'react-bootstrap'
import LanguagesTable from 'components/Tables/Languages'
import CreateLanguage from 'components/Modals/CreateLanguage'

function Languages() {
  const [showCreateLangModal, setCreateLangModal] = useState(false)
  const [updateLangs, setUpdateLangs] = useState(false)

  const handleCreateLang = () => {
    setCreateLangModal(true)
  }

  const handleCloseModal = uLang => {
    setCreateLangModal(false)
    if (uLang) {
      updateLangs == true ? setUpdateLangs(false) : setUpdateLangs(true)
    }
  }

  return (
    <>
      <Container fluid>
        <CreateLanguage showModal={showCreateLangModal} signalCloseModal={handleCloseModal} />
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col md="8">
                    <Card.Title as="h4">Languages</Card.Title>
                  </Col>
                  <Col md="4">
                    <Button className="create-user-btn" variant="success" onClick={handleCreateLang}>
                      Add Language
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Col md="12">
                  <LanguagesTable forceUpdate={updateLangs} />
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Languages
