import React, { useState, useEffect, useRef } from 'react'
import { getData, postData } from 'utils/server'
import { notificationOptions, getAuthorizationHeader } from 'utils/helpers'
import DataSelect from 'components/Inputs/generalDataSelect'
import DateSelect from 'components/Inputs/generalDateTimeSelect'
import NotificationAlert from 'react-notification-alert'
import { initBarChart } from './initializers/initBarChart'
import { generateRandomColors } from 'utils/helpers'

import { Card, Row, Col, Form, Tooltip as BootstrapTooltip } from 'react-bootstrap'

let myChart = null

export default function UserActivity({ autoload, collapsed }) {
  const controller = new AbortController()

  let today = new Date()
  let yesterday = new Date()
  yesterday.setDate(today.getDate() - 1)

  const notificationAlertRef = useRef(null)
  const [stats, setStats] = useState([])
  const [users, setUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState({ value: 9, label: 'Skill90' })
  const [visible, setVisible] = useState(collapsed)
  const [startDate, setStartDate] = useState(yesterday)
  const [endDate, setEndDate] = useState(today)
  const [message, setMessage] = useState('')

  const user = async () => {
    return selectedUser
  }

  const date1 = async () => {
    return startDate
  }

  const date2 = async () => {
    return endDate
  }

  const statsHandler = async controller => {
    setMessage(
      <div className="text-center">
        <i className="fas fa-spinner fa-spin text-center loadingSpinnerColor"></i>
        <span className="loadingSpinnerColor"> Loading Data ...</span>
      </div>
    )

    let currentUser = await user()
    let startDate = await date1()
    let endDate = await date2()

    let request = {
      user_id: currentUser.value,
      from_date: Date.parse(startDate),
      to_date: Date.parse(endDate)
    }

    let response = await postData('/api/users/stats', request, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (response != null) {
      setStats(response.data)
      // console.log(stats)

      let activities = response.data.map(o => o.name)
      let amount = response.data.map(o => o.amount)

      let dataset = []

      for (const activity of response.data) {
        dataset.push({
          label: activity.name,
          data: activity.amount
        })
      }

      let colors = generateRandomColors(response.data.length)

      let dataSet = [
        {
          label: 'Number of Requests',
          data: amount,
          backgroundColor: colors
        }
      ]

      myChart.data.datasets = dataSet
      myChart.data.labels = activities
      myChart.update()
    } else notificationAlertRef.current.notificationAlert(notificationOptions('There are no data available for these options', 'danger'))

    setMessage('')
  }

  const fetchStats = async controller => {
    setMessage(
      <div className="text-center">
        <i className="fas fa-spinner fa-spin text-center loadingSpinnerColor"></i>
        <span className="loadingSpinnerColor"> Loading Data ...</span>
      </div>
    )

    let users_response = await getData('/api/users', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (users_response != null) {
      setUsers(users_response.data)
      setSelectedUser({ value: 9, label: 'Skill90' })
    }
    let request = {
      user_id: selectedUser.value,
      from_date: Date.parse(startDate),
      to_date: Date.parse(endDate)
    }
    let response = await postData('/api/users/stats', request, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })

    if (response != null) {
      setStats(response.data)
      // console.log(stats)

      let activities = response.data.map(o => o.name)
      let amount = response.data.map(o => o.amount)

      let colors = generateRandomColors(response.data.length)

      let dataSet = [
        {
          label: 'Number of Requests',
          data: amount,
          backgroundColor: colors
        }
      ]

      let statsChart = document.getElementById('userActivityStatsChart')

      if (myChart) {
        myChart.destroy()
      }

      myChart = initBarChart(statsChart, activities, dataSet, 'y', false, 'bottom', false)
    }
    setMessage('')
  }

  const updateStatsSelectedUser = selUser => {
    setSelectedUser(selUser)
  }

  const updateStartDate = newDate => {
    setStartDate(newDate)
  }

  const updateEndDate = newDate => {
    setEndDate(newDate)
  }

  const minimizeChart = () => {
    if (visible) setVisible(false)
    if (!visible) setVisible(true)

    if (!myChart) fetchStats(controller)
  }

  useEffect(() => {
    if (autoload) fetchStats(controller)

    return () => {
      controller.abort()
      if (myChart) {
        myChart.destroy()
        myChart = null
      }
    }
  }, [])

  useEffect(() => {
    if (myChart && selectedUser && startDate && endDate) statsHandler(controller)
  }, [selectedUser, startDate, endDate])

  return (
    <Card className="shadow border-0">
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Card.Header>
        <Card.Title as="h4">
          User Activity Stats
          <div style={{ position: 'absolute', top: '5px', right: '10px' }} onClick={minimizeChart}>
            <i style={{ display: visible ? '' : 'none' }} className="fas fa-angle-down custom-button-like"></i>
            <i style={{ display: visible ? 'none' : '' }} className="fas fa-angle-up custom-button-like"></i>
          </div>
        </Card.Title>
        <Row style={{ display: visible ? 'none' : '' }}>
          <Col md="9">
            <Row>
              <Col md="3">
                <Form.Group>
                  <label>User:</label>
                  <DataSelect data={users} updateItem={updateStatsSelectedUser} selectedItem={JSON.stringify(selectedUser)} placeholder={`Select User...`}/>
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group>
                  <label>From:</label>
                  <DateSelect selectedDate={startDate} handleDateChange={updateStartDate} />
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group>
                  <label>To:</label>
                  <DateSelect selectedDate={endDate} handleDateChange={updateEndDate} />
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body style={{ display: visible ? 'none' : '' }}>
        <div className="stats-chart" id="userActivityStatsHolder" style={{ height: '70vh', marginBottom: '0.4vh', display: message === '' ? '' : 'none' }}>
          <canvas id="userActivityStatsChart" />
        </div>
        <div>{message}</div>
      </Card.Body>
      <Card.Footer>
        <hr></hr>
        <div className="stats">
          <i className="fas fa-history"></i>
          Updated 3 minutes ago
        </div>
      </Card.Footer>
    </Card>
  )
}
