import React, { useState, useEffect } from 'react'
import { Button, Modal, Form, Row, Col } from 'react-bootstrap'

import Select from 'react-select'

import NotificationAlert from 'react-notification-alert'
import UserRoleSelect from 'components/Inputs/UserRoleSelect'

import { marketGroupValidation } from 'utils/validations'
import { postData } from 'utils/server'
import { notificationOptions, getAuthorizationHeader } from 'utils/helpers'

import SportSelect from 'components/Inputs/SportSelect'

const _ = require('lodash')

function CreateMarketGroup({ sports, showModal, signalCloseModal }) {
  const notificationAlertRef = React.useRef(null)
  const [roleId, setRoleId] = useState(null)
  const [show, setShow] = useState(false)
  const [selectedSport, setSelectedSport] = useState({})

  const handleClose = () => {
    setShow(false)
    signalCloseModal(false)
  }

  const updateUserRole = selectedRoleId => {
    setRoleId(selectedRoleId)
  }

  const handleSubmit = async () => {
    let marketGroup = {
      name: document.getElementById('name').value,
      sort: document.getElementById('sort').value,
      sport_id: selectedSport.id
    }
    let validation = marketGroupValidation(marketGroup)
    if (validation != null) {
      notificationAlertRef.current.notificationAlert(notificationOptions(validation.message, 'danger'))
      return
    }
    let response = await postData(`/api/unis/groups`, marketGroup, { Authorization: getAuthorizationHeader() })
    if (response == null) {
      notificationAlertRef.current.notificationAlert(notificationOptions('Error! Something went wrong.', 'danger'))
    } else {
      setShow(false)
      signalCloseModal(true)
      notificationAlertRef.current.notificationAlert(notificationOptions('Success! Market Group Added.', 'success'))
    }
  }

  const handleSportChange = selectedItem => {
    setSelectedSport(selectedItem)
  }

  useEffect(() => {
    setShow(showModal)
  }, [showModal])
  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md="12">
                <Form.Group>
                  <label>Name:</label>
                  <Form.Control id="name" name="name" type="text" placeholder="Type Group Name"></Form.Control>
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group>
                  <label>Sport:</label>
                  <SportSelect sports={sports} updateSport={handleSportChange} />
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group>
                  <label>Sort:</label>
                  <Form.Control id="sort" name="sort" type="number" placeholder="Type Group Sort"></Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Create Market Group
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CreateMarketGroup
