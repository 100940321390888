function TableCounters({ filtered, all }) {
  return (
    <>
      <div style={{ marginBottom: '10px', textAlign: 'right' }}>
        <span>
          Count: {filtered}
          <span style={{ fontSize: '12px' }}>(Filtered)</span> / {all}
          <span style={{ fontSize: '12px' }}>(Total)</span>
        </span>
      </div>
    </>
  )
}

export default TableCounters
