import React, { useState, useEffect } from 'react'
import { Card, Container, Form, Row, Col, Button, InputGroup } from 'react-bootstrap'

import SendCoverageLost from 'components/Modals/SendCoverageLost'

function Operation() {
  const [showCoverageLostModal, setCoverageLostModal] = useState(false)
  const [text, setText] = useState('')
  const [getEventId, setEventId] = useState(null)
  const [CoverageLost, setCoverageLost] = useState(false)

  const handleSendCoverageLost = () => {
    setEventId(document.getElementById('eventId').value)
    setCoverageLostModal(true)
  }

  const handleCloseModal = cl => {
    setCoverageLostModal(false)
    if (cl) {
      CoverageLost == true ? setCoverageLost(false) : setCoverageLost(true)
    }
    setText('')
    setEventId(null)
  }

  const autoComplete = e => {
    e.preventDefault()
    setText(e.target.value)
  }

  return (
    <>
      <Container fluid>
        <SendCoverageLost showModal={showCoverageLostModal} eventId={getEventId} signalCloseModal={handleCloseModal} />
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col md="12">
                    <Card.Title as="h4">Send Coverage Lost</Card.Title>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md="1">
                    <InputGroup.Text>Event Ids:</InputGroup.Text>
                  </Col>
                  <Col md="8">
                    <Form.Control id="eventId" name="eventId" type="text" onChange={autoComplete} value={text} placeholder="Comma seperated for more than one (ex. 123456,342342)"></Form.Control>
                  </Col>
                  <Col md="3">
                    <Button className="coverage-lost-btn" variant="success" disabled={!text} onClick={handleSendCoverageLost}>
                      Send
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Operation
