import React, { useState, useEffect } from 'react'
import { Button, Modal, Form, Row, Col } from 'react-bootstrap'

import Select from 'react-select'

import NotificationAlert from 'react-notification-alert'
import UserRoleSelect from 'components/Inputs/UserRoleSelect'

import { marketGroupValidation } from 'utils/validations'
import { postData } from 'utils/server'
import { notificationOptions, getAuthorizationHeader } from 'utils/helpers'

import SportSelect from 'components/Inputs/SportSelect'
import ProviderSelect from 'components/Inputs/ProviderSelect'

const _ = require('lodash')

function UpdateMarketSettlement({ sports, showModal, signalCloseModal }) {
  const [providerId, setProviderId] = useState(0)
  const notificationAlertRef = React.useRef(null)
  const [show, setShow] = useState(false)
  const [selectedSport, setSelectedSport] = useState({})
  const [selectedProject, setSelectedProject] = useState({})
  const [selectedProvider, setSelectedProvider] = useState({})
  const [providerShow, setProviderShow] = useState(true)

  const projectOptions = [
    { value: 'settlement', label: 'Online Settlement' },
    { value: 'settlementFormatter', label: 'Offline Settlement' }
  ]
  const providerOptions = [
    { value: '1', label: 'Generic' },
    { value: '2', label: 'Vbet Offline' }
  ]

  const handleClose = () => {
    setShow(false)
    signalCloseModal(false)
    setProviderShow(true)
  }

  const handleSubmit = async () => {
    let settlementProperties = {
      project: selectedProject.value,
      provider: selectedProvider.value == 'settlement' ? undefined : selectedProvider.value,
      sportName: selectedSport.name
    }
    // let validation = marketGroupValidation(settlementProperties)
    if (!settlementProperties.project || !settlementProperties.sportName || (settlementProperties.project != 'settlement' && !settlementProperties.provider)) {
      notificationAlertRef.current.notificationAlert(notificationOptions('Missing Values', 'danger'))
      return
    }
    let response = await postData(`/api/markets/updateSportSettlementStatus`, settlementProperties, { Authorization: getAuthorizationHeader() })
    if (response == null) {
      notificationAlertRef.current.notificationAlert(notificationOptions('Error! Something went wrong.', 'danger'))
    } else {
      setShow(false)
      signalCloseModal(true)
      notificationAlertRef.current.notificationAlert(notificationOptions('Success! Markets Settlements Status Updated.', 'success'))
    }
  }

  const handleSportChange = selectedItem => {
    setSelectedSport(selectedItem)
  }

  const handleProjectChange = selectedItem => {
    setSelectedProject(selectedItem)
    if (selectedItem.value == 'settlement') {
      setProviderShow(false)
    } else {
      setProviderShow(true)
    }
  }

  const handleProviderChange = selectedItem => {
    setSelectedProvider(selectedItem)
  }

  useEffect(() => {
    setShow(showModal)
  }, [showModal])
  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Col md="12">
            <div>Auto-Update Markets Settlement Status</div>
          </Col>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md="12">
                <Form.Group>
                  <label>Project:</label>
                  <Select options={projectOptions} onChange={handleProjectChange} />
                </Form.Group>
              </Col>
              <Col md="12" style={{ display: providerShow ? '' : 'none' }}>
                <Form.Group>
                  <label>Providers:</label>
                  {/* <ProviderSelect setParentProviderId={updateSelectedProvider} /> */}
                  <Select options={providerOptions} onChange={handleProviderChange} />
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group>
                  <label>Sport:</label>
                  <SportSelect sports={sports} updateSport={handleSportChange} />
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Update Markets Status
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default UpdateMarketSettlement
