import React, { useState, useEffect } from 'react'
import { Button, Card, Container, Row, Col } from 'react-bootstrap'
import UniPlayers from 'components/Tables/UniPlayers'
import ProviderPlayers from 'components/Tables/ProviderPlayers'
import ProviderSelect from 'components/Inputs/ProviderSelect'
import CreateUniPlayers from 'components/Modals/CreateUniPlayers'

import { getData } from 'utils/server'
import { getAuthorizationHeader } from 'utils/helpers'
import { set } from 'lodash'

function Players() {
  const [providerId, setProviderId] = useState(0)
  const [updateUniPlayers, setUpdateUniPlayers] = useState(false)
  const [showCreatePlayerModal, setCreatePlayerModal] = useState(false)
  const [uniSports, setUniSports] = useState([])
  const [downloadcsv, setDownloadcsv] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [updateProviderPlayers, setUpdateProviderPlayers] = useState(false)

  const updateSelectedProvider = pId => {
    setProviderId(pId)
  }

  const clickDownloadUniPlayers = () => {
    setDownloadcsv(true)
    setDisabled(true)
  }

  const handleUniPlayers = data => {
    if (data.downloadcsv && data.downloadcsv == true) {
      setDisabled(false)
      setDownloadcsv(false)
    }
  }

  const handleCreatePlayerClick = () => {
    setCreatePlayerModal(true)
  }

  const handleProvidersPlayerCommunication = data => {
    if (data.refresh && data.refresh == true) {
      setUpdateUniPlayers(false)
      setUpdateUniPlayers(true)
    }
  }

  const handleUniPlayersCommunication = data => {
    if (data.refresh && data.refresh == true) {
      setUpdateProviderPlayers(false)
      setUpdateProviderPlayers(true)
    }
  }

  const handleCloseModal = updateUPlayers => {
    setCreatePlayerModal(false)
    if (updateUPlayers) {
      updateUniPlayers == true ? setUpdateUniPlayers(false) : setUpdateUniPlayers(true)
    }
  }

  const fetchSports = async () => {
    let response = await getData('/api/sports', { Authorization: getAuthorizationHeader() })
    if (response != null) {
      setUniSports(response.data)
    }
  }

  useEffect(() => {
    fetchSports()
  }, [])
  return (
    <>
      <Container fluid>
        <CreateUniPlayers showModal={showCreatePlayerModal} signalCloseModal={handleCloseModal} sports={uniSports} />
        <Row>
          <Col md="6">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col md="8">
                    <Card.Title as="h4">Uni Players</Card.Title>
                  </Col>
                  <Col md="4">
                    <Row>
                      <Col md="8" sm="12">
                        <Button className="create-region-btn" variant="danger" onClick={clickDownloadUniPlayers}>
                          Download
                        </Button>
                      </Col>
                      <Col md="4" sm="12">
                        <Button className="create-region-btn" variant="success" onClick={handleCreatePlayerClick}>
                          Create
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Col md="12">
                  <UniPlayers forceUpdate={updateUniPlayers} sports={uniSports} informParent={handleUniPlayers} informer={handleUniPlayersCommunication} downloadcsv={downloadcsv} />
                </Col>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col md="6">
                    <Card.Title as="h4">Provider Players</Card.Title>
                  </Col>
                  <Col md="6">
                    <ProviderSelect setParentProviderId={updateSelectedProvider} />
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Col md="12">
                  <ProviderPlayers informParent={handleProvidersPlayerCommunication} forceUpdate={updateProviderPlayers} providerId={providerId} sports={uniSports} />
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Players