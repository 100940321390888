const _ = require('lodash')
import React, { useState, useEffect } from 'react'
import { Button, Modal, Form, Col, Row } from 'react-bootstrap'
import Badge from 'react-bootstrap/Badge'
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter'
import paginationFactory from 'react-bootstrap-table2-paginator'

import { getData } from 'utils/server'
import { getAuthorizationHeader, notificationOptions } from 'utils/helpers'

function InfoMarketsSettlement({ showModal, signalCloseModal }) {
  const controller = new AbortController()

  const [show, setShow] = useState(false)
  const [tableData, setData] = useState([])
  const [settlementStatuses] = useState({ completed: 'Completed', pending: 'Pending', fail: 'Fail' })
  const [projectOptions] = useState({ settlement: 'Online Settlement', settlementFormatter: 'Offline Settlement' })

  const variantBgs = ['success', 'primary', 'secondary', 'danger', 'info', 'warning', 'dark']

  const columns = [
    {
      dataField: 'id',
      text: 'id',
      align: 'center',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'provider',
      text: 'Provider',
      align: 'center',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'project',
      text: 'Project',
      align: 'center',
      formatter: (cellContent, row) => <>{cellContent == 'settlement' ? 'Online Settlement' : cellContent == 'settlementFormatter' ? 'Offline Settlement' : cellContent}</>,
      filter: selectFilter({
        options: projectOptions
      }),
      sort: true
    },
    {
      dataField: 'sport',
      text: 'Sport',
      align: 'center',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'completed',
      text: 'Status',
      align: 'center',
      filter: selectFilter({
        options: settlementStatuses
      }),
      sort: true
    },
    {
      dataField: 'timestamp',
      text: 'Time',
      align: 'center',
      filter: textFilter(),
      sort: true
    }
  ]

  const handleClose = () => {
    setShow(false)
    signalCloseModal(false, null)
  }

  const fetchData = async controller => {
    let response = await getData(`/api/markets/fetchSettlementJobs`, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    let tableData = []

    if (response != null) {
      response.data.forEach(job => {
        if (job.completed == null) job.completed = 'fail'
        if (job.completed == true) job.completed = 'completed'
        if (job.completed == false) job.completed = 'pending'
      })

      response.data = response.data.sort((a, b) => b.id - a.id)

      tableData = response.data
    }

    setData(tableData)
  }

  useEffect(() => {
    setShow(showModal)
    if (showModal) fetchData(controller)
  }, [showModal])

  useEffect(() => {
    return () => {
      setShow(false)
      controller.abort()
    }
  }, [])
  return (
    <>
      <Modal show={show} onHide={handleClose} className="markets-settlement-modal">
        <Modal.Header>
          <Col md="12">
            <div>Available Markets-Settlements statuses AutoJobs :</div>
          </Col>
        </Modal.Header>
        <Modal.Body>
          <span style={{ display: tableData.length > 0 ? 'none' : 'block' }}>loading ...</span>
          <div style={{ display: tableData.length > 0 ? 'block' : 'none' }}>
            <BootstrapTable bootstrap4 keyField="id" data={tableData} columns={columns} pagination={paginationFactory({ sizePerPage: 10 })} filter={filterFactory()} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button style={{ margin: 'auto' }} variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default InfoMarketsSettlement
