const _ = require('lodash')
const moment = require('moment')

import React, { useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory, { textFilter, selectFilter, Comparator, numberFilter } from 'react-bootstrap-table2-filter'
import NotificationAlert from 'react-notification-alert'
import { Card, Container, Row, Col, Button, ButtonGroup } from 'react-bootstrap'
import DataSelect from 'components/Inputs/generalDataSelect'
import SportSelect from 'components/Inputs/SportSelect'
import DateSelect from 'components/Inputs/DateSelect'
import Swal from 'sweetalert2'

import { postData, getData, delData } from 'utils/server'
import { notificationOptions, getAuthorizationHeader } from 'utils/helpers'
import TableCounters from 'components/UniObject/TableCounters'
import SelectedCounter from 'components/UniObject/SelectedCounter'
import useMousetrap from 'react-hook-mousetrap'

import { unmatchedCompetitionValidation } from 'utils/validations'
import CustomSelect from 'components/Inputs/CustomSelect'
import { date } from 'joi'

let customFilterFunc

function UnmatchedCompetitions() {
  const controller = new AbortController()

  const notificationAlertRef = React.useRef(null)
  const [disableSearch, setDisableSearch] = useState(true)
  const [disableMatch, setDisableMatch] = useState(true)
  const [icon, setIcon] = useState(<i className="fas fa-search text-primary"></i>)
  const [text, setText] = useState(<span className="text-primary">Search competitions</span>)
  const [matchIcon, setMatchIcon] = useState(<i className="fas fa-equals text-danger"></i>)
  const [matchText, setMatchText] = useState(<span className="text-danger">Match selected</span>)
  const [tableData, setData] = useState([])
  const [allcounter, setAllCounter] = useState(0)
  const [filteredcounter, setFilteredcounter] = useState(0)
  const [selectedcounter, setSelectedCounter] = useState(0)
  const [allTableData, setAllData] = useState([])
  const [providers, setProviders] = useState([])
  const [sports, setSports] = useState([])
  const [selectedSport, setSelectedSport] = useState(null)
  const [selectedProvider, setSelectedProvider] = useState(null)
  const [percentage, setPercentage] = useState('')
  const [competition, setCompetition] = useState(null)
  const [checked, setChecked] = useState([])
  const [refreshTable, setRefreshTable] = useState(false)
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedOperator, setSelectedOperator] = useState('>')

  const customFilter = numberFilter({
    placeholder: 'My Custom PlaceHolder', // custom the input placeholder
    className: 'my-custom-text-filter', // custom classname on input
    defaultValue: { number: 1, comparator: selectedOperator }, // default filtering value
    // comparator: operators[selectedOperator], // default is Comparator.LIKE
    comparators: [Comparator.EQ, Comparator.GT, Comparator.LT],
    caseSensitive: false, // default is false, and true will only work when comparator is LIKE
    style: { display: 'inline-grid' }, // custom the style on number filter
    className: 'custom-numberfilter-class', // custom the class on number filter
    comparatorStyle: { backgroundColor: 'antiquewhite', visibility: 'hidden', display: 'none' }, // custom the style on comparator select
    comparatorClassName: 'custom-comparator-class', // custom the class on comparator select
    numberStyle: { backgroundColor: 'cadetblue', margin: '0px', visibility: 'hidden', display: 'none' }, // custom the style on number input/select
    numberClassName: 'custom-number-class', // custom the class on ber input/select
    delay: 500, // how long will trigger filtering after user typing, default is 500 ms
    getFilter: filter => (customFilterFunc = filter), // accept callback function and you can call it for filter programmtically
    id: 'uni_id' // assign a unique value for htmlFor attribute, it's useful when you have same dataField across multiple table in one page
  })

  function customExternalFilter(func, data) {
    if (func) func(data)
  }

  const fetchSelected = (row, isSelect) => {
    if (isSelect == true) {
      checked.push(allTableData.find(o => o.id == row.id))
      console.log(checked)
      setSelectedCounter(checked.length)
    } else if (isSelect == false) {
      let idToRemove = row.id
      let index = checked
        .map(o => {
          return o.id
        })
        .indexOf(idToRemove)
      checked.splice(index, 1)
      setSelectedCounter(checked.length)
      console.log(checked)
    }
  }

  const selectRow = {
    clickToSelect: true,
    selectColumnPosition: 'right',
    style: { backgroundColor: '#24e1cf1a' },
    mode: 'checkbox',
    onSelect: (ev, selected) => {
      if (ev) fetchSelected(ev, selected)
    },
    hideSelectAll: false,
    onSelectAll: (selected, events) => {
      handleMultiSelectedEvents(events, selected)
    }
  }

  const paginationOptions = {
    disablePageTitle: true,
    showTotal: false,
    sizePerPageList: [
      {
        text: '25',
        value: 20
      },
      {
        text: '50',
        value: 50
      }
    ]
  }

  const provider = async () => {
    return selectedProvider
  }

  const sport = async () => {
    return selectedSport
  }

  const rating = async () => {
    return percentage
  }

  const checkedList = async () => {
    return checked
  }

  const fetchFilters = async controller => {
    let providers_response = await getData('/api/providers', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (providers_response != null) {
      setProviders(providers_response.data)
    }

    let sports_response = await getData('/api/sports', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (sports_response != null) {
      setSports(sports_response.data)
    }
  }

  const columns = [
    {
      dataField: 'u_id',
      text: 'UNI ID',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'u_region',
      text: 'Region',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'u_name',
      text: 'Name',
      filter: textFilter(),
      sort: true,
      classes: 'custom-cell-name',
      formatter: (cellContent, row) => <div className="text-primary">{cellContent}</div>
    },
    {
      dataField: 'separator',
      text: '',
      headerStyle: { width: '3vw', backgroundColor: 'rgba(143, 211, 254, 0.00)', border: '1px solid #fff !important' },
      style: { backgroundColor: 'rgba(143, 211, 254, 0.00)', borderStyle: 'hidden' },
      headerClasses: 'custom-header-seperator',
      classes: 'custom-cell-seperator',
      formatter: (cellContent, row) => (
        <div className="text-center" style={{ margin: '24px 0px 24px' }}>
          <span className="nc-icon nc-stre-left"></span>
          <span className="nc-icon nc-stre-right"></span>
        </div>
      )
    },
    {
      dataField: 'id',
      text: 'P-ID',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'region',
      text: 'Region',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'name',
      text: 'Name',
      filter: textFilter(),
      sort: true,
      classes: 'custom-cell-name',
      formatter: (cellContent, row) => <div className="text-primary">{cellContent}</div>
    },
    {
      dataField: 'created_tmstmp',
      text: 'Created',
      filter: customFilter,
      editable: false,
      sort: true,
      headerStyle: { width: '5vw' },
      formatter: (cell, row) => {
        return (
          <div className="text-center" style={{ margin: '24px 0px 24px', fontSize: '0.85em' }}>
            {row.created.split('.')[0].split('T')[0] + ', ' + row.created.split('.')[0].split('T')[1]}
          </div>
        )
      }
    },
    {
      dataField: 'created',
      text: 'Created',
      filter: textFilter(),
      editable: false,
      sort: true,
      headerStyle: { width: '5vw' },
      hidden: true,
      formatter: (cell, row) => (
        <div className="text-center" style={{ margin: '24px 0px 24px', fontSize: '0.85em' }}>
          {cell.split('.')[0].split('T')[0] + ', ' + cell.split('.')[0].split('T')[1]}
        </div>
      )
    }
  ]

  const fetchData = async (event, controller) => {
    setChecked([])
    setDisableSearch(true)
    setIcon(<i className="fas fa-spinner fa-spin text-primary"></i>)
    setText(<span className="text-primary">Please wait...</span>)
    let currentProvider = await provider()
    let currentSport = await sport()
    let percentage = await rating()

    console.log(currentProvider)
    console.log(currentSport)
    console.log(percentage)

    let filters = {
      provider_id: currentProvider.value,
      sport_id: currentSport.value,
      percentage: parseFloat(percentage)
    }

    let validation = unmatchedCompetitionValidation(filters)
    if (validation != null) {
      notificationAlertRef.current.notificationAlert(notificationOptions(validation.message, 'danger'))
      setDisableSearch(true)
      setIcon(<i className="fas fa-search text-primary"></i>)
      setText(<span className="text-primary">Search fixtures</span>)
      return
    }

    let uri = '/api/fixture/unmatchedCompetitions'
    let response = await postData(uri, filters, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (response == null) {
      notificationAlertRef.current.notificationAlert(notificationOptions('Error! Something went wrong', 'danger'))
      setDisableSearch(false)
      setIcon(<i className="fas fa-search text-primary"></i>)
      setText(<span className="text-primary">Search competitions</span>)
      return
    }
    if (_.isEmpty(response.data)) {
      notificationAlertRef.current.notificationAlert(notificationOptions('There are no data available for these options', 'danger'))
      setDisableSearch(false)
      setIcon(<i className="fas fa-search text-primary"></i>)
      setText(<span className="text-primary">Search competitions</span>)
    }

    let tableData = []
    for (let competition in response.data) {
      let c_key = new Date() + '-' + currentProvider.label + '-' + currentSport.label + '-' + competition
      response.data[competition].c_key = c_key
      tableData.push(response.data[competition])
    }

    tableData.map(item => {
      item.created_tmstmp = new Date(item.created).getTime()
    })

    tableData.sort((a, b) => (a.created < b.created ? 1 : -1))

    setAllCounter(tableData.length)
    setAllData(tableData)
    setData(tableData)
    setDisableSearch(false)
    setIcon(<i className="fas fa-search text-primary"></i>)
    setText(<span className="text-primary">Search competitions</span>)
  }

  const updateDate = endDate => {
    setSelectedDate(endDate ? new Date(endDate).getTime() : 0)
    if ((endDate, customFilterFunc)) customExternalFilter(customFilterFunc, { number: endDate ? new Date(endDate).getTime() : 0, comparator: selectedOperator })
  }

  const updateOperator = operator => {
    let operatorSymbol = ''
    if (operator.label == 'After') operatorSymbol = '>'
    if (operator.label == 'Before') operatorSymbol = '<'
    setSelectedOperator(operatorSymbol)
    if ((selectedDate, customFilterFunc)) customExternalFilter(customFilterFunc, { number: selectedDate ? new Date(selectedDate).getTime() : 0, comparator: operatorSymbol })
  }

  const matchCompetitions = async () => {
    let currentProvider = await provider()
    let currentSport = await sport()
    let currentChecked = await checkedList()
    let htmlData = `${Object.keys(currentChecked).length * 2} Competitions ready to match!`

    if (currentChecked.length == 0) {
      notificationAlertRef.current.notificationAlert(notificationOptions('Error. Select some competitions first', 'danger'))
      return
    }

    setMatchIcon(<i className="fas fa-spinner fa-spin text-danger"></i>)
    setMatchText(<span className="text-danger">Prease wait...</span>)

    Swal.fire({ title: `Match Competitions?`, html: htmlData, showDenyButton: true, showConfirmButton: true, confirmButtonText: 'Yes, match them!', denyButtonText: 'Cancel', icon: 'warning', confirmButtonColor: '#87cb16', denyButtonColor: '#ff4a55' }).then(async result => {
      if (result.isConfirmed) {
        let request = []
        for (let i = 0; i < checked.length; i++) {
          request.push({
            provider_id: currentProvider.value,
            sport_id: currentSport.value,
            u_id: currentChecked[i].u_id,
            p_id: currentChecked[i].id
          })
        }
        let result = await postData('/api/matching/competitions', request, { Authorization: getAuthorizationHeader() })
        if (result == null) {
          setMatchIcon(<i className="fas fa-equals text-danger"></i>)
          setMatchText(<span className="text-danger">Match selected</span>)
          return
        }
        setChecked([])
        await fetchData(new Event('faceEvent'), controller)
        console.log(result)
        setMatchIcon(<i className="fas fa-equals text-danger"></i>)
        setMatchText(<span className="text-danger">Match selected</span>)
        if (result != null) {
          Swal.fire({ title: `Competitions matched successfully!`, html: '', showDenyButton: false, showConfirmButton: true, confirmButtonText: 'OK', denyButtonText: 'Cancel', icon: 'success', confirmButtonColor: '#87cb16', denyButtonColor: '#ff4a55' }).then(async result => {
            refreshTable == true ? setRefreshTable(false) : setRefreshTable(true)
          })
        } else {
          Swal.fire('Error!', `- Competition Entities ${result ? 'matched' : 'cannot be matched!'} <br> -Message for Matching didnt send!`, 'error')
        }
      }
      setMatchIcon(<i className="fas fa-equals text-danger"></i>)
      setMatchText(<span className="text-danger">Match selected</span>)
    })
  }

  const updateSelectedProvider = selProv => {
    setSelectedProvider(selProv)
  }

  const updateSelectedSport = selSport => {
    setSelectedSport(selSport)
  }

  const changePercentage = e => {
    e.preventDefault()
    setPercentage(e.target.value)
  }

  function afterFilter(newResult, newFilters) {
    setFilteredcounter(newResult.length)
  }

  useEffect(() => {
    if (selectedSport == null || selectedProvider == null) return
    if (percentage.length == 0 || isNaN(parseFloat(percentage))) {
      setDisableSearch(true)
      return
    }
    setDisableSearch(false)
  }, [selectedProvider, selectedSport, percentage])

  const handleMultiSelectedEvents = (events, isSelected) => {
    for (let index = 0; index < events.length; index++) {
      const event = events[index]
      fetchSelected(event, isSelected)
    }
  }

  useEffect(() => {
    setDisableMatch(true)
    if (checked.length > 0) setDisableMatch(false)
    setSelectedCounter(checked.length)
  }, [checked.length])

  useEffect(() => {
    fetchFilters(controller)
    return () => {
      controller.abort()
    }
  }, [])

  return (
    <>
      <Card className="strpied-tabled-with-hover">
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <Card.Body>
          <Row>
            <Col md="2">
              <label>Provider:</label>
              <DataSelect data={providers} updateItem={updateSelectedProvider} selectedItem={selectedProvider} />
            </Col>
            <Col md="2">
              <label>Sport:</label>
              <SportSelect sports={sports} updateSport={updateSelectedSport} selectedItem={JSON.stringify(selectedSport)} />
            </Col>
            <Col md="2">
              <label>Created:</label>
              <CustomSelect
                options={[
                  { id: '1', name: 'After' },
                  { id: '2', name: 'Before' }
                ]}
                selectedItem={'{ "id": "1", "name": "After", "value": "1", "label": "After" }'}
                setName="operators"
                updateSelection={updateOperator}
              ></CustomSelect>
              <DateSelect setParentData={updateDate} showTime={true} />
            </Col>
            <Col md="2" style={{ textAlign: 'right' }}>
              <label>Match Rating:</label>
              <div>
                <input style={{ width: '90px' }} defaultValue={percentage} onChange={changePercentage} placeholder="0.0"></input>
              </div>
            </Col>
            <Col md="4" style={{ marginTop: '2vh' }}>
              <Row>
                <Col md="6">
                  <Button variant="primary" onClick={e => fetchData(e, controller)} disabled={disableSearch}>
                    {icon} {text}
                  </Button>
                </Col>
                <Col md="5">
                  <Button variant="danger" onClick={matchCompetitions} disabled={disableMatch}>
                    {matchIcon} {matchText}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="strpied-tabled-with-hover">
        <Card.Body>
          <Row>
            <Col md="6">
              <h4>Uni Competitions:</h4>
            </Col>
            <Col md="6">
              <h4>Provider Competitions:</h4>
            </Col>
          </Row>
          <Row className="justify-content-md-end">
            <Col md="auto">
              <SelectedCounter all={allcounter} selected={selectedcounter}></SelectedCounter>
            </Col>
            <Col md="auto">
              <TableCounters all={allcounter} filtered={filteredcounter}></TableCounters>
            </Col>
          </Row>
          <BootstrapTable bootstrap4 keyField="c_key" data={tableData} columns={columns} pagination={paginationFactory({ sizePerPage: 10 })} filter={filterFactory({ afterFilter })} selectRow={selectRow} hover />
        </Card.Body>
      </Card>
    </>
  )
}

export default UnmatchedCompetitions
