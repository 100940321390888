import React, { useEffect, useState } from 'react'

import Select from 'react-select'

import { getData } from 'utils/server'
import { getAuthorizationHeader } from 'utils/helpers'

function ProviderSelect({ setParentProviderId, defaultValue, mustBeDisabled }) {
  const controller = new AbortController()

  const [providers, setData] = useState([])
  const [selected, setSelected] = useState(null)
  const [disabled, setDisabled] = useState(false)

  const fetchData = async controller => {
    let response = await getData('/api/providers', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    let providers = []
    if (response != null) {
      response.data.map(el => {
        el.value = el.id
        el.label = el.name
      })
    }
    providers = response.data
    setData(providers)
    if (defaultValue != null) {
      if (typeof mustBeDisabled == 'undefined') setDisabled(true)
      setSelected(providers.find(el => el.id == defaultValue))
    }
  }

  const handleChange = selectedItem => {
    setSelected(selectedItem)
    setParentProviderId(selectedItem.id)
  }
  useEffect(() => {
    fetchData(controller)

    return () => {
      controller.abort()
    }
  }, [])
  return <Select disabled={providers.length > 0 ? false : true} className="providersSelect" name="providersSelect" options={providers} placeholder="Select Provider.." onChange={handleChange} isDisabled={disabled} value={selected} />
}

export default ProviderSelect
