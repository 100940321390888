import React, { useState, useEffect } from 'react'
import { Button, Card, Container, Row, Col } from 'react-bootstrap'
import OrderingMarkets from 'components/Tables/OrderingMarkets'

import { getData } from 'utils/server'
import { getAuthorizationHeader } from 'utils/helpers'
import { validateViewCredentials } from 'utils/auth'

function MarketsOrdering(props) {
  validateViewCredentials(props.routeInfo)

  const controller = new AbortController()

  const [providerId, setProviderId] = useState(0)
  const [updateUniMarkets, setUpdateUniMarkets] = useState(false)
  const [showCreateMarketModal, setCreateMarketsModal] = useState(false)
  const [uniSports, setUniSports] = useState([])
  const [uniGroups, setUniGroups] = useState([])

  const updateSelectedProvider = pId => {
    setProviderId(pId)
  }

  const handleCreteMarketClick = () => {
    setCreateMarketsModal(true)
  }

  const handleCloseModal = updateUMarkets => {
    setCreateMarketsModal(false)
    if (updateUMarkets) {
      updateUniMarkets == true ? setUpdateUniMarkets(false) : setUpdateUniMarkets(true)
    }
  }

  const fetchSports = async controller => {
    let response = await getData('/api/sports', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (response != null) {
      setUniSports(response.data)
    }
  }

  const fetchGroups = async controller => {
    let response = await getData('/api/unis/groups', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (response != null) {
      setUniGroups(response.data)
    }
  }

  useEffect(() => {
    fetchSports(controller)
    fetchGroups(controller)

    return () => {
      controller.abort()
    }
  }, [])
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col md="6">
                    <Card.Title as="h4">Ordering Uni-Markets</Card.Title>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Col md="12">
                  <OrderingMarkets forceUpdate={updateUniMarkets} sports={uniSports} groups={uniGroups} />
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default MarketsOrdering
