import React, { useState, useEffect } from 'react'
import { Button, Modal, Form, Row, Col } from 'react-bootstrap'
import NotificationAlert from 'react-notification-alert'
import UserRoleSelect from 'components/Inputs/UserRoleSelect'

import { userValidation } from 'utils/validations'
import { postData } from 'utils/server'
import { notificationOptions, getAuthorizationHeader } from 'utils/helpers'

const _ = require('lodash')

function CreateUser({ showModal, signalCloseModal }) {
  const notificationAlertRef = React.useRef(null)
  const [roleId, setRoleId] = useState(null)
  const [show, setShow] = useState(false)

  const handleClose = () => {
    setShow(false)
    signalCloseModal(false)
  }

  const updateUserRole = selectedRoleId => {
    setRoleId(selectedRoleId)
  }

  const handleSubmit = async () => {
    let user = {
      name: document.getElementById('fullname').value,
      username: document.getElementById('username').value,
      role_id: roleId,
      password: document.getElementById('password').value,
      repeat_password: document.getElementById('repeat-password').value
    }
    let validation = userValidation(user)
    if (validation != null) {
      notificationAlertRef.current.notificationAlert(notificationOptions(validation.message, 'danger'))
      return
    }
    let response = await postData(`/api/users`, _.omit(user, ['repeat_password']), { Authorization: getAuthorizationHeader() })
    if (response == null) {
      notificationAlertRef.current.notificationAlert(notificationOptions('Error! Something went wrong.', 'danger'))
    } else {
      setShow(false)
      signalCloseModal(true)
      notificationAlertRef.current.notificationAlert(notificationOptions('Success! User Added.', 'success'))
    }
  }

  useEffect(() => {
    setShow(showModal)
  }, [showModal])
  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md="6">
                <Form.Group>
                  <label>Full Name</label>
                  <Form.Control id="fullname" name="fullname" type="text" placeholder="Type fullname"></Form.Control>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group>
                  <label>Username</label>
                  <Form.Control id="username" name="username" type="text" placeholder="Type username"></Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <br />
            <Row>
              <Col md="12">
                <Form.Group>
                  <label>Role</label>
                  <UserRoleSelect setParentRole={updateUserRole} />
                </Form.Group>
              </Col>
            </Row>
            <br />
            <Row>
              <Col md="6">
                <Form.Group>
                  <label>Password</label>
                  <Form.Control id="password" name="username" type="password" placeholder="Type password"></Form.Control>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group>
                  <label>Repeat Password</label>
                  <Form.Control id="repeat-password" name="repeat-password" type="password" placeholder="Repeat password"></Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Create User
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CreateUser
