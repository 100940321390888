import React, { useState, useEffect } from 'react'
import { Button, Modal, Form, Row, Col } from 'react-bootstrap'
import NotificationAlert from 'react-notification-alert'

import { getData, postData } from 'utils/server'
import { notificationOptions, getAuthorizationHeader } from 'utils/helpers'

const _ = require('lodash')

function UpdateProviderLanguagesPriority({ showModal, langId, signalCloseModal, lang }) {
  const notificationAlertRef = React.useRef(null)
  const [providerLangs, setProviderLangs] = React.useState({})
  const [langData, setLangData] = React.useState([])
  const [show, setShow] = useState(false)

  const handleClose = () => {
    setShow(false)
    signalCloseModal(false)
  }

  const handleInputChange = e => {
    providerLangs[e.target.getAttribute('id')] = e.target.value;
    setProviderLangs(_.cloneDeep(providerLangs))
  }

  const handleSubmit = async () => {
    let languageProviderPriorities = [];

    for (const plangKey in providerLangs) {
      let splittedKey = plangKey.split('-')
      if (providerLangs[plangKey] != '') {
        languageProviderPriorities.push({
          lang_id: langId,
          provider_id: parseInt(splittedKey[1]),
          priority: providerLangs[plangKey]
        })
      }
    }
    
    let languageProviderPriorityData = {
      lang_id: langId,
      lang_provider_data: languageProviderPriorities
    };

    let response = await postData(`/api/providers/languages/priorities`, languageProviderPriorityData, { Authorization: getAuthorizationHeader() })
    if (response == null) {
      notificationAlertRef.current.notificationAlert(notificationOptions('Error! Something went wrong.', 'danger'))
    } else {
      setShow(false)
      signalCloseModal(true)
      notificationAlertRef.current.notificationAlert(notificationOptions('Success! Provider Languages Updated.', 'success'))
    }
  }

  const fetchLangPriorityInfo = async () => {
    let response = await getData(`/api/providers/languages/${langId}/priorities`, { Authorization: getAuthorizationHeader() })
    if (response.data) {
      setLangData(response.data)
      let providerLangPriorityData = {}
      for (let ldp of response.data) providerLangPriorityData['provider-' + ldp.id + '-lang-' + langId] = ldp.priority == null ? '' : ldp.priority
      setProviderLangs(providerLangPriorityData)
    }
  }

  useEffect(() => {
    if (showModal) fetchLangPriorityInfo()
    setShow(showModal)
  }, [showModal])
  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            {langData.map((obj, index) => {
              return (
                <Col md="6" key={index}>
                  <Form.Group>
                    <label className="provider-lang-label" style={{ whiteSpace: 'nowrap', marginTop: '0.2rem' }} >
                      {obj.name.toUpperCase()} {lang}
                    </label>
                    <Form.Control
                      id={'provider-' + obj.id + '-lang-' + langId}
                      className="provider-lang-input"
                      type="number"
                      min="1"
                      value={providerLangs['provider-' + obj.id + '-lang-' + langId]}
                      placeholder={obj.name.toUpperCase() + ' Priority'}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              )
            })}
          </Row>
        </Form>

          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="warning" onClick={handleSubmit}>
            Update Provider Language Priority
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default UpdateProviderLanguagesPriority
