const _ = require('lodash')
const moment = require('moment')

import React, { useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import NotificationAlert from 'react-notification-alert'
import { Card, Row, Col, Button } from 'react-bootstrap'
import SportSelect from 'components/Inputs/SportSelect'
import DateSelect from 'components/Inputs/DateSelect'
import Swal from 'sweetalert2'
import { postData, getData } from 'utils/server'
import { notificationOptions, getAuthorizationHeader } from 'utils/helpers'
import TableCounters from 'components/UniObject/TableCounters'
import SelectedCounter from 'components/UniObject/SelectedCounter'

import { fixtureConflictsValidation } from 'utils/validations'

export default function RecreatedUniFixtures() {
  const controller = new AbortController()

  const notificationAlertRef = React.useRef(null)
  const [disableSearch, setDisableSearch] = useState(true)
  const [disableMatch, setDisableMatch] = useState(true)
  const [icon, setIcon] = useState(<i className="fas fa-search text-primary"></i>)
  const [text, setText] = useState(<span className="text-primary">Search fixtures</span>)
  const [matchIcon, setMatchIcon] = useState(<i className="fas fa-hammer text-success"></i>)
  const [matchText, setMatchText] = useState(<span className="text-success">Recreate Fixtures</span>)
  const [tableData, setData] = useState([])
  const [allcounter, setAllCounter] = useState(0)
  const [filteredcounter, setFilteredcounter] = useState(0)
  const [selectedcounter, setSelectedCounter] = useState(0)
  const [allTableData, setAllData] = useState([])
  const [sports, setSports] = useState([])
  const [selectedSport, setSelectedSport] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [fixture, setFixture] = useState(null)
  const [checked, setChecked] = useState([])
  const [refreshTable, setRefreshTable] = useState(false)

  const handleMultiSelectedEvents = (events, isSelected) => {
    for (let index = 0; index < events.length; index++) {
      const event = events[index]
      fetchSelected(event, isSelected)
    }
  }

  const fetchSelected = (row, isSelect) => {
    if (isSelect == true) {
      checked.push(allTableData.find(o => o.id == row.id))
      setSelectedCounter(checked.length)
    } else if (isSelect == false) {
      let idToRemove = row.id
      let index = checked
        .map(o => {
          return o.id
        })
        .indexOf(idToRemove)
      checked.splice(index, 1)
      setSelectedCounter(checked.length)
    }
  }

  const selectRow = {
    clickToSelect: false,
    selectColumnPosition: 'right',
    style: { backgroundColor: '#24e1cf1a' },
    mode: 'checkbox',
    onSelect: (ev, selected) => {
      if (ev) fetchSelected(ev, selected)
    },
    hideSelectAll: false,
    onSelectAll: (selected, events) => {
      handleMultiSelectedEvents(events, selected)
    }
  }

  const paginationOptions = {
    disablePageTitle: true,
    showTotal: false,
    sizePerPageList: [
      {
        text: '25',
        value: 20
      },
      {
        text: '50',
        value: 50
      }
    ]
  }

  const sport = async () => {
    return selectedSport
  }

  const date1 = async () => {
    return startDate
  }

  const date2 = async () => {
    return endDate
  }

  const checkedList = async () => {
    return checked
  }

  const fetchFilters = async controller => {
    let sports_response = await getData('/api/sports', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (sports_response != null) {
      setSports(sports_response.data)
    }
  }

  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'region',
      text: 'Region',
      filter: textFilter(),
      sort: true,
      formatter: (cellContent, row) => (
        <div>
          <p>{`${row.region_id}`}</p>
          <p>{`${row.region}`}</p>
        </div>
      )
    },
    {
      dataField: 'provider',
      text: 'Created From',
      filter: textFilter(),
      sort: true,
      classes: (cellContent, row) => {
        return `${cellContent}Cell`
      }
    },
    {
      dataField: 'competition',
      text: 'Competition',
      filter: textFilter(),
      sort: true,
      formatter: (cellContent, row) => (
        <div className="text-primary">
          <p>{`${row.competition_id}`}</p>
          <p>{`${row.competition}`}</p>
        </div>
      )
    },
    {
      dataField: 'home_id',
      text: 'Home ID',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'home',
      text: 'Home',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'away_id',
      text: 'Away ID',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'away',
      text: 'Away',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'start_date',
      text: 'Start Date',
      sort: true
    },
    {
      dataField: 'references',
      filter: textFilter(),
      headerClasses: 'custom-header-class',
      classes: 'custom-row-class',
      sort: true,
      headerFormatter: () => {
        return (
          <div>
            <h5 className="m-2">References</h5>
            <table className="table table-bordered m-0">
              <thead>
                <tr>
                  <th>EVENT ID</th>
                  <th>REGION</th>
                  <th>PROVIDER</th>
                  <th>COMPETITION</th>
                  <th>HOME ID</th>
                  <th>HOME</th>
                  <th>AWAY ID</th>
                  <th>AWAY</th>
                  <th>START DATE</th>
                </tr>
              </thead>
            </table>
          </div>
        )
      },
      formatter: (cellContent, row) => {
        return (
          <table className="table m-0">
            <tbody>
              {cellContent.map(reference => {
                return (
                  <tr key={reference.pf_id}>
                    <td
                      title={`id: ${reference.id} | Region Id: ${reference.region_id} | Region: ${reference.region} | Competition id: ${reference.competition_id} | Competition: ${reference.competition} | Home Id: ${reference.home_id} | Home: ${reference.home} | Away Id: ${reference.away_id} | Away: ${reference.away}`}
                    >
                      <span className="fas fa-info fa-xs text-primary"></span>
                      {reference.pf_id}
                    </td>
                    <td>
                      <p>{`${reference.p_region_id}`}</p>
                      <p>{`${reference.p_region}`}</p>
                    </td>
                    <td className={`${reference.p_provider}Cell`}>{reference.p_provider}</td>
                    <td className="text-primary">
                      <p>{`${reference.p_competition_id}`}</p>
                      <p>{`${reference.p_competition}`}</p>
                    </td>
                    <td>{reference.p_home_id}</td>
                    <td>{reference.p_home}</td>
                    <td>{reference.p_away_id}</td>
                    <td>{reference.p_away}</td>
                    <td>{moment(new Date(parseInt(reference.p_start_date))).format('DD/MM/YYYY, HH:mm')}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )
      }
    }
  ]

  const fetchData = async (event, controller) => {
    setChecked([])
    setDisableSearch(true)
    setIcon(<i className="fas fa-spinner fa-spin text-primary"></i>)
    setText(<span className="text-primary">Please wait...</span>)
    let currentSport = await sport()

    let uri = `/api/fixture/fixturesRecreated/${currentSport.value}`
    let response = await getData(uri, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (response == null) {
      notificationAlertRef.current.notificationAlert(notificationOptions('Error! Something went wrong', 'danger'))
      setDisableSearch(false)
      setIcon(<i className="fas fa-search text-primary"></i>)
      setText(<span className="text-primary">Search fixtures</span>)
      return
    }
    if (_.isEmpty(response.data)) {
      notificationAlertRef.current.notificationAlert(notificationOptions('There are no data available for these options', 'danger'))
      setDisableSearch(false)
      setIcon(<i className="fas fa-search text-primary"></i>)
      setText(<span className="text-primary">Search fixtures</span>)
    }

    let tableData = []
    for (let fixture in response.data) {
      let jsDate1 = new Date(parseInt(response.data[fixture].start_date))
      let jsDate2 = new Date(parseInt(response.data[fixture].u_start_date))
      let f_key = new Date() + '-' + currentSport.label + '-' + fixture
      response.data[fixture].f_key = f_key
      response.data[fixture].start_date = moment(jsDate1).format('DD/MM/YYYY, HH:mm')
      response.data[fixture].u_start_date = moment(jsDate2).format('DD/MM/YYYY, HH:mm')
      tableData.push(response.data[fixture])
    }

    tableData.sort((a, b) => (a.u_start_date > b.u_start_date ? 1 : -1))

    setAllCounter(tableData.length)
    setAllData(tableData)
    setData(tableData)
    setDisableSearch(false)
    setIcon(<i className="fas fa-search text-primary"></i>)
    setText(<span className="text-primary">Search fixtures</span>)
  }

  const recreateFixture = async () => {
    let startDate = await date1()
    let endDate = await date2()
    let htmlData = `Fixtures ready to recreate`

    if (!startDate || !endDate) {
      notificationAlertRef.current.notificationAlert(notificationOptions('Error. The date range is required', 'danger'))
      return
    }

    Swal.fire({ title: `Recreate Fixtures?`, html: htmlData, showDenyButton: true, showConfirmButton: true, confirmButtonText: 'Recreate', denyButtonText: 'Cancel', icon: 'warning', confirmButtonColor: '#87cb16', denyButtonColor: '#ff4a55' }).then(async result => {
      if (result.isConfirmed) {
        let payload = {
          sport_id: selectedSport.value,
          from_date: new Date(startDate).getTime() / 1000,
          to_date: new Date(endDate).getTime() / 1000
        }

        let validation = fixtureConflictsValidation(payload)

        if (validation != null) {
          notificationAlertRef.current.notificationAlert(notificationOptions(validation.message, 'danger'))
          setDisableMatch(true)
          setIcon(<i className="fas fa-hammer text-success"></i>)
          setText(<span className="text-success">Search fixtures</span>)
          return
        }

        let uri = '/api/fixture/uniFixturesRecreate'

        let result = await postData(uri, payload, { Authorization: getAuthorizationHeader() })
        if (result == null) {
          setMatchIcon(<i className="fas fa-hammer text-success"></i>)
          setMatchText(<span className="text-success">Recreate Fixtures</span>)
          return
        }

        setChecked([])
        await fetchData(new Event('FaceEvent'), controller)
        setMatchIcon(<i className="fas fa-hammer text-success"></i>)
        setMatchText(<span className="text-success">Recreate Fixtures</span>)
        if (result != null) {
          let html = `
            <table>
              <style>
                td, th {
                  text-align: center;
                  padding: 8px;
                }
              </style>
              <tr>
                <th>Fixtures</th>
                <th>Dropped Fixtures</th>
                <th>Inserted</th>
                <th>Problematic</th>
                <th>Same Rows</th>
              </tr>
              <tr>
                <td>${result.data.providersFixtures}</td>
                <td>${result.data.dropped}</td>
                <td>${result.data.inserted}</td>
                <td>${result.data.problematic}</td>
                <td>${result.data.sameRows}</td>
              </tr>
            </table>
          `
          Swal.fire({ title: `Fixtures recreated`, html: html, showDenyButton: false, showConfirmButton: true, customClass: 'swal-wide', confirmButtonText: 'OK', denyButtonText: 'Cancel', icon: 'success', confirmButtonColor: '#87cb16', denyButtonColor: '#ff4a55' }).then(async result => {
            refreshTable == true ? setRefreshTable(false) : setRefreshTable(true)
          })
        } else {
          Swal.fire('Error!', `- Fixtures ${result ? 'recreated' : 'cannot be recreated!'} <br> -Message for Recreation didn't send!`, 'error')
        }
      }
      setMatchIcon(<i className="fas fa-hammer text-success"></i>)
      setMatchText(<span className="text-success">Recreate Fixtures</span>)
    })

    setMatchIcon(<i className="fas fa-spinner fa-spin text-success"></i>)
    setMatchText(<span className="text-success">Please wait...</span>)
  }

  const updateStartDate = startDate => {
    setStartDate(startDate)
  }

  const updateEndDate = endDate => {
    setEndDate(endDate)
  }

  const updateSelectedSport = selSport => {
    setSelectedSport(selSport)
  }

  function afterFilter(newResult, newFilters) {
    setFilteredcounter(newResult.length)
  }

  useEffect(() => {
    if (fixture == null) return
    setDisableMatchFixtures(false)
  }, [fixture])

  useEffect(() => {
    if (selectedSport == null) {
      setDisableSearch(true)
      return
    }

    setDisableSearch(false)
  }, [selectedSport])

  useEffect(() => {
    fetchFilters(controller)
    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    if (selectedSport == null || startDate == null || endDate == null) return
    if (startDate == 'Invalid date' || endDate == 'Invalid date') {
      setDisableMatch(true)
      return
    }
    setDisableMatch(false)
  }, [selectedSport, startDate, endDate])

  return (
    <>
      <Card className="strpied-tabled-with-hover">
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <Card.Body>
          <Row>
            <Col md="6" className="row">
              <Col md="4">
                <label>Sport:</label>
                <SportSelect sports={sports} updateSport={updateSelectedSport} selectedItem={JSON.stringify(selectedSport)} />
              </Col>
              <Col md="6" className="mt-4">
                <Button variant="primary" onClick={e => fetchData(e, controller)} disabled={disableSearch}>
                  {icon} {text}
                </Button>
              </Col>
            </Col>
            <Col md="6" className="row">
              <Col md="4">
                <label>From:</label>
                <DateSelect setParentData={updateStartDate} showTime={true} />
              </Col>
              <Col md="4">
                <label>To:</label>
                <DateSelect setParentData={updateEndDate} showTime={true} minDate={startDate ? startDate : null} />
              </Col>
              <Col md="4" className="mt-4 text-right">
                <Button variant="success" onClick={recreateFixture} disabled={disableMatch}>
                  {matchIcon} {matchText}
                </Button>
              </Col>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="strpied-tabled-with-hover">
        <Card.Body>
          <Row>
            <Col md="6">
              <h4>Uni Fixtures:</h4>
            </Col>
            <Col md="6">
              <h4>Provider Fixtures:</h4>
            </Col>
          </Row>
          <Row className="justify-content-md-end">
            <Col md="auto">
              <SelectedCounter all={allcounter} selected={selectedcounter}></SelectedCounter>
            </Col>
            <Col md="auto">
              <TableCounters all={allcounter} filtered={filteredcounter}></TableCounters>
            </Col>
          </Row>
          <BootstrapTable parentClassName="bootstrap-table" bootstrap4 keyField="f_key" data={tableData} columns={columns} pagination={paginationFactory({ sizePerPage: 10 })} filter={filterFactory({ afterFilter })} selectRow={selectRow} classes="custom-table" hover />
        </Card.Body>
      </Card>
    </>
  )
}
