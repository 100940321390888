import _ from 'lodash'
import React, { useState, useEffect, useRef } from 'react'
import { Button, Modal, Form, Col, Row } from 'react-bootstrap'

import { getData } from 'utils/server'
import { getAuthorizationHeader } from 'utils/helpers'
import ProviderParticipants from 'components/Lists/ProviderParticipants'
import Competitions from 'views/Competitions'
import { postData } from 'utils/server'

function CompetitionParticipants({ showModal, signalCloseModal, competition, fromProviderComps }) {
  const [competitionFullName, setCompetitonFullName] = useState('')
  const [participantsData, setParticipantsData] = useState({})
  const handleClose = () => {
    signalCloseModal(false)
  }
  const modalHolderRef = useRef(null)
  const [width, setWidth] = useState(window.innerWidth)
  const fetchData = async () => {
    if (competition == null) return
    setCompetitonFullName(`${competition.region} | ${competition.name}`)
    let response = []
    if (fromProviderComps) {
      const controller = new AbortController()

      let payload = {
        pid: competition.pid,
        provider_id: competition.provider_id
      }
      let apiUrl = `/api/providers/competitions/participants`

      response = await postData(apiUrl, payload, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    } else {
      response = await getData(`/api/unis/competitions/${competition.id}/participants`, { Authorization: getAuthorizationHeader() }, null)
    }
    if (!_.has(response, 'data')) {
      console.log(`Unable to receive participants!`)
      return
    }
    setParticipantsData(response.data)
  }

  useEffect(() => {
    fetchData()
  }, [competition, fromProviderComps])

  const toggleProviderParticipantsList = (event, providerId) => {
    if (participantsData[providerId].visible == true) participantsData[providerId].visible = false
    else participantsData[providerId].visible = true
    setParticipantsData(_.cloneDeep(participantsData))
  }

  return (
    <>
      <div className="modals-holder" ref={modalHolderRef} style={{ position: 'absolute', width: 'calc(100vw - 180px)', display: 'flex', maxWidth: 'calc(100vw - 180px)', top: '25%', flexWrap: 'wrap', flexDirection: 'row' }}></div>

      <Modal show={showModal} onHide={handleClose} className={fromProviderComps ? 'participant-modal' : 'participants-modal'}>
        <Modal.Header>
          <Col md={fromProviderComps ? '12' : '6'} style={{ textAlign: 'center' }}>
            <h4 style={{ margin: '0%', fontWeight: 'bold' }}>{competitionFullName}</h4>
          </Col>
          <Col md="6">
            <Row>
              {Object.keys(participantsData).map(key => {
                if (key === '0') return

                const providerTextClass = `col-provider-${key}`
                const providerClass = `${participantsData[key].name}Provider`

                return (
                  !fromProviderComps && (
                    <Col md="2" id={providerTextClass} key={key}>
                      <h5 key={key} style={{ textAlign: 'center', width: '100%', cursor: 'pointer' }} className={providerClass} onClick={e => toggleProviderParticipantsList(e, key)}>
                        {participantsData[key].name}
                      </h5>
                    </Col>
                  )
                )
              })}
            </Row>
          </Col>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="2" key={0}>
              {_.has(participantsData, '0') ? <ProviderParticipants modalHolderRef={modalHolderRef} competition={competition} key={0} sportId={competition.sport_id} sport={Competitions.port} provider_id={participantsData['0'].id} provider={participantsData['0'].name} participants={participantsData['0'].participants} /> : ''}
            </Col>
            <Col md="10" style={!fromProviderComps ? {} : { right: '5%' }}>
              <Row>
                {Object.keys(participantsData).map(key => {
                  if (key === '0') return
                  if (participantsData[key].visible == false) return
                  return (
                    <Col md={fromProviderComps ? '13' : '3'} key={key}>
                      <ProviderParticipants modalHolderRef={modalHolderRef} competition={competition} provider_id={participantsData[key].id} sportId={competition.sport_id} sport={competition.sport} provider={participantsData[key].name} participants={participantsData[key].participants} />
                    </Col>
                  )
                })}
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button style={{ margin: 'auto' }} variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CompetitionParticipants
