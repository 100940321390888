import axios from 'axios'
import { getUserRole } from 'utils/helpers'

function signout() {
  redirect('/login')
}

function redirect(path) {
  window.location.replace(path)
}

export async function getConstantData(uri, headers = {}, errorHandler, abordController) {
  try {
    headers['Content-Type'] = 'application/json'
    headers['Access-Control-Allow-Origin'] = '*'
    let userRole = getUserRole()
    let apiUrl = process.env.REACT_APP_BASE_URL

    let axiosConfig = {}
    axiosConfig.headers = headers ? headers : {}
    axiosConfig.signal = abordController ? abordController.signal : null

    return await axios.get(apiUrl + uri, axiosConfig)
  } catch (err) {
    if (errorHandler) errorHandler(err)
    console.error(`Request Error:`, err)
    if (err.response && err.response.status === 401) {
      signout()
    }
    return null
  }
}

export async function authenticate(uri, payload = {}, headers = {}, errorHandler, abordController) {
  try {
    headers['Content-Type'] = 'application/json'
    headers['Access-Control-Allow-Origin'] = '*'
    let apiUrl = process.env.REACT_APP_AUTH_URL

    let axiosConfig = {}
    axiosConfig.headers = headers ? headers : {}
    axiosConfig.signal = abordController ? abordController.signal : null

    return await axios.post(apiUrl + uri, payload, axiosConfig)
  } catch (err) {
    if (errorHandler) errorHandler(err)
    console.error(`Request Error:`, err)
    if (err.response && err.response.status === 401) {
      signout()
    }
    return null
  }
}

export async function getData(uri, headers = {}, errorHandler, abordController) {
  try {
    headers['Content-Type'] = 'application/json'
    headers['Access-Control-Allow-Origin'] = '*'
    let apiUrl = process.env.REACT_APP_BASE_URL

    let axiosConfig = {}
    axiosConfig.headers = headers ? headers : {}
    axiosConfig.signal = abordController ? abordController.signal : null

    return await axios.get(apiUrl + uri, axiosConfig)
  } catch (err) {
    if (axios.isCancel(err)) {
      console.error(`Operation ${uri} canceled`)
    } else {
      if (errorHandler) errorHandler(err)
      console.error(`Request Error:`, err)
      if (err.response && err.response.status === 401) {
        signout()
      }
      return null
    }
  }
}

export async function postData(uri, payload = {}, headers = {}, errorHandler, abordController) {
  try {
    headers['Content-Type'] = 'application/json'
    headers['Access-Control-Allow-Origin'] = '*'
    let apiUrl = process.env.REACT_APP_BASE_URL

    let axiosConfig = {}
    axiosConfig.headers = headers ? headers : {}
    axiosConfig.signal = abordController ? abordController.signal : null

    return await axios.post(apiUrl + uri, payload, axiosConfig)
  } catch (err) {
    if (errorHandler) errorHandler(err)
    console.error(`Request Error:`, err)
    if (err.response && err.response.status === 401) {
      signout()
    }
    return null
  }
}

export async function putData(uri, payload = {}, headers = {}, errorHandler, abordController) {
  try {
    headers['Content-Type'] = 'application/json'
    headers['Access-Control-Allow-Origin'] = '*'
    let apiUrl = process.env.REACT_APP_BASE_URL

    let axiosConfig = {}
    axiosConfig.headers = headers ? headers : {}
    axiosConfig.signal = abordController ? abordController.signal : null

    return await axios.put(apiUrl + uri, payload, axiosConfig)
  } catch (err) {
    if (errorHandler) errorHandler(err)
    console.error(`Request Error:`, err)
    if (err.response && err.response.status === 401) {
      signout()
    }
    return null
  }
}

export async function delData(uri, headers = {}, errorHandler, abordController) {
  try {
    headers['Content-Type'] = 'application/json'
    headers['Access-Control-Allow-Origin'] = '*'
    let apiUrl = process.env.REACT_APP_BASE_URL

    let axiosConfig = {}
    axiosConfig.headers = headers ? headers : {}
    axiosConfig.signal = abordController ? abordController.signal : null

    return await axios.delete(apiUrl + uri, axiosConfig)
  } catch (err) {
    if (errorHandler) errorHandler(err)
    console.error(`Request Error:`, err)
    if (err.response && err.response.status === 401) {
      signout()
    }
    return null
  }
}
