import React from 'react'
import { Container } from 'react-bootstrap'
import UnmatchedFixtures from 'components/Tables/UnmatchedFixtures'

export default function Matching() {
  return (
    <>
      <Container fluid>
        <UnmatchedFixtures />
      </Container>
    </>
  )
}
