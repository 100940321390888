import React, { useState } from 'react'
import { Badge, Button, Card, Navbar, Nav, Table, Container, Row, Col, Modal } from 'react-bootstrap'
import UniRegions from 'components/Tables/UniRegions'
import ProviderRegions from 'components/Tables/ProviderRegions'
import ProviderSelect from 'components/Inputs/ProviderSelect'
import CreateUniRegions from 'components/Modals/CreateUniRegion'
import { set } from 'lodash'

function Regions() {
  const [downloadcsv, setDownloadcsv] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [providerId, setProviderId] = useState(0)
  const [updateUniRegions, setUpdateUniRegions] = useState(false)
  const [showCreateRegionModal, setCreateRegionModal] = useState(false)
  const updateSelectedProvider = pId => {
    setProviderId(pId)
  }

  const handleUniRegions = data => {
    if (data.downloadcsv && data.downloadcsv == true) {
      setDisabled(false)
      setDownloadcsv(false)
    }
  }

  const clickDownloadRegions = () => {
    setDownloadcsv(true)
    setDisabled(true)
  }
  const handleCreteRegionClick = () => {
    setCreateRegionModal(true)
  }

  const handleCloseModal = updateURegions => {
    setCreateRegionModal(false)
    if (updateURegions) {
      updateUniRegions == true ? setUpdateUniRegions(false) : setUpdateUniRegions(true)
    }
  }

  return (
    <>
      <Container fluid>
        <CreateUniRegions showModal={showCreateRegionModal} signalCloseModal={handleCloseModal} />
        <Row>
          <Col md="6">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col md="8">
                    <Card.Title as="h4">Uni Regions</Card.Title>
                  </Col>
                  <Col md="4">
                    <Row>
                      <Col md="8" sm="12">
                        <Button style={{ backgroundColor: 'rgb(44, 47, 45)' }} className="create-region-btn" variant="success" onClick={clickDownloadRegions}>
                          Download
                        </Button>
                      </Col>
                      <Col md="4" sm="12">
                        <Button className="create-region-btn" variant="success" onClick={handleCreteRegionClick}>
                          Create
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Col md="12">
                  <UniRegions forceUpdate={updateUniRegions} downloadcsv={downloadcsv} informParent={handleUniRegions} />
                </Col>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col md="6">
                    <Card.Title as="h4">Provider Regions</Card.Title>
                  </Col>
                  <Col md="6">
                    <ProviderSelect setParentProviderId={updateSelectedProvider} />
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Col md="12">
                  <ProviderRegions providerId={providerId} />
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Regions
