import React, { useState, useEffect } from 'react'
import MatchingStats from 'components/Charts/MatchingStats'
import TranslationStats from 'components/Charts/TranslationStats'
import MarketsSettlementStats from 'components/Charts/MarketsSettlementStats'
import UnisSettlementStats from 'components/Charts/UnisSettlementStats'
import FixtureMatchingStats from 'components/Charts/FixtureMatchingStats'
import FixtureStats from 'components/Charts/FixtureStats'
import MarketsCategories from 'components/Charts/MarketsCategories'

import { validateViewCredentials } from 'utils/auth'

// react-bootstrap components
import { Badge, Button, Card, Navbar, Nav, Table, Container, Row, Col, Form, OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap'

function Dashboard(props) {
  validateViewCredentials(props.routeInfo)

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="6">
            <FixtureStats autoload={false} collapsed={true}></FixtureStats>
          </Col>
          <Col md="6">
            <MatchingStats autoload={false} collapsed={true}></MatchingStats>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <FixtureMatchingStats autoload={false} collapsed={true}></FixtureMatchingStats>
          </Col>
          <Col md="6">
            <TranslationStats autoload={false} collapsed={true}></TranslationStats>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <MarketsSettlementStats autoload={false} collapsed={true}></MarketsSettlementStats>
          </Col>
          <Col md="6">
            <UnisSettlementStats autoload={true} collapsed={true}></UnisSettlementStats>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <MarketsCategories autoload={false} collapsed={true}></MarketsCategories>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Dashboard
