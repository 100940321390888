import { Col } from 'react-bootstrap'
import ParticipantListElement from './ParticipantListElement'
import React, { useEffect, useState, useRef } from 'react'
import ParticipantFixtures from 'components/Modals/ParticipantFixtures'
import ReactDOM from 'react-dom'

function ProviderParticipants({ provider, participants, provider_id, sportId, sport, competition, modalHolderRef }) {
  const providerClassName = `${provider}Provider`
  const providerText = `${provider} (${participants.length})`
  const [activeParticipant, setActiveParticipant] = useState(null)
  const handleInfoClick = participant => {
    setActiveParticipant(participant)
  }

  const handleCloseFixtureModal = () => {
    setActiveParticipant(null)
  }

  return (
    <Col md="12" style={{ textAlign: 'center', border: '2px solid', margin: '0.1%', borderColor: `var(--${provider}-color)` }}>
      <h5 style={{ textAlign: 'center', width: '100%' }} className={providerClassName}>
        {providerText}
      </h5>
      {participants.map(element => {
        return <ParticipantListElement competition={competition} provider={provider} participant={element} provider_id={provider_id} sportId={sportId} sport={sport} onInfoClick={handleInfoClick} />
      })}
      {activeParticipant &&
        ReactDOM.createPortal(
          <ParticipantFixtures fromProviderParticipants={true} showModal={true} sportId={sportId} pid={activeParticipant.id} providerId={provider_id} providerName={provider} signalCloseModal={handleCloseFixtureModal}></ParticipantFixtures>,
          modalHolderRef.current // render the modal directly under <body>
        )}{' '}
    </Col>
  )
}

export default ProviderParticipants
