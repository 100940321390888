import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import cellEditFactory from 'react-bootstrap-table2-editor'
import NotificationAlert from 'react-notification-alert'

import { getAuthorizationHeader, notificationOptions, getUserRole } from 'utils/helpers'
import { putData } from 'utils/server'

function RegionTranslations({ data, langId, refreshTable }) {
  const notificationAlertRef = React.useRef(null)

  const paginationOptions = {
    disablePageTitle: true,
    showTotal: false,
    sizePerPageList: [
      {
        text: '25',
        value: 20
      },
      {
        text: '50',
        value: 50
      }
    ]
  }

  const columns = [
    {
      dataField: 'region_id',
      text: 'ID',
      editable: false,
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'name',
      text: 'Name',
      editable: false,
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'translation',
      text: 'Translation',
      editable: true,
      sort: true
    }
  ]

  const changeCellValue = (oldValue, newValue, row, column, done) => {
    let translation = {
      translation: newValue,
      lang_id: langId
    }

    let userRole = getUserRole()
    let endPointUrl = `/api/providers/translations/regions/${row.region_id}`

    switch (userRole) {
      case 1:
        endPointUrl = `/api/providers/translations/regions/${row.region_id}`
        break
      case 2:
        endPointUrl = `/api/providers/translations/regions/${row.region_id}`
        break
      default:
        break
    }

    putData(endPointUrl, translation, { Authorization: getAuthorizationHeader() }).then(response => {
      if (response == null) {
        notificationAlertRef.current.notificationAlert(notificationOptions('Error! Cannot edit record.', 'danger'))
        done(false)
      } else {
        done(true)
        refreshTable()
      }
    })
    return { async: true }
  }

  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <BootstrapTable bootstrap4 keyField="region_id" data={data} columns={columns} pagination={paginationFactory({ sizePerPage: 10 })} filter={filterFactory()} cellEdit={cellEditFactory({ mode: 'click', beforeSaveCell: changeCellValue })} />
    </>
  )
}
export default RegionTranslations
