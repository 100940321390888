import React, { useState, useEffect, useRef } from 'react'
import { postData } from 'utils/server'
import { notificationOptions, getAuthorizationHeader } from 'utils/helpers'
import RequestSelect from 'components/Inputs/RequestSelect'
import DateSelect from 'components/Inputs/generalDateTimeSelect'
import NotificationAlert from 'react-notification-alert'
import { initBarChart } from './initializers/initBarChart'
import { generateRandomColors } from 'utils/helpers'

import { Card, Row, Col, Form, Tooltip as BootstrapTooltip } from 'react-bootstrap'

let myChart = null

export default function RequestStats({ autoload, collapsed }) {
  const controller = new AbortController()

  let today = new Date()
  let yesterday = new Date()
  yesterday.setDate(today.getDate() - 1)

  const notificationAlertRef = useRef(null)
  const [stats, setStats] = useState([])
  const [requests, setRequests] = useState([])
  const [selectedRequest, setSelectedRequest] = useState({ value: 0, label: 'Uni Regions Created' })
  const [visible, setVisible] = useState(collapsed)
  const [startDate, setStartDate] = useState(yesterday)
  const [endDate, setEndDate] = useState(today)
  const [message, setMessage] = useState('')

  const request = async () => {
    return selectedRequest
  }

  const date1 = async () => {
    return startDate
  }

  const date2 = async () => {
    return endDate
  }

  const statsHandler = async controller => {
    setMessage(
      <div className="text-center">
        <i className="fas fa-spinner fa-spin text-center loadingSpinnerColor"></i>
        <span className="loadingSpinnerColor"> Loading Data ...</span>
      </div>
    )

    let currentRequest = await request()
    let startDate = await date1()
    let endDate = await date2()

    let payload = {
      request_name: currentRequest.label,
      from_date: Date.parse(startDate),
      to_date: Date.parse(endDate)
    }

    let response = await postData('/api/users/requeststats', payload, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (response != null) {
      setStats(response.data.requestStats)
      // console.log(stats)

      let users = response.data.requestStats.map(o => o.name)
      let amount = response.data.requestStats.map(o => o.amount)

      let dataset = []

      for (const request of response.data.requestStats) {
        dataset.push({
          label: request.name,
          data: request.amount
        })
      }

      let colors = generateRandomColors(response.data.requests.length)

      let dataSet = [
        {
          label: 'Number of Requests',
          data: amount,
          backgroundColor: colors
        }
      ]

      myChart.data.datasets = dataSet
      myChart.data.labels = users
      myChart.update()
    } else notificationAlertRef.current.notificationAlert(notificationOptions('There are no data available for these options', 'danger'))

    setMessage('')
  }

  const fetchStats = async controller => {
    setMessage(
      <div className="text-center">
        <i className="fas fa-spinner fa-spin text-center loadingSpinnerColor"></i>
        <span className="loadingSpinnerColor"> Loading Data ...</span>
      </div>
    )

    let payload = {
      request_name: selectedRequest.label,
      from_date: Date.parse(startDate),
      to_date: Date.parse(endDate)
    }
    let response = await postData('/api/users/requeststats', payload, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })

    if (response != null) {
      setStats(response.data.requestStats)
      setRequests(response.data.requests)
      setSelectedRequest({ value: 0, label: 'Uni Regions Created' })
      // console.log(stats)

      let users = response.data.requestStats.map(o => o.name)
      let amount = response.data.requestStats.map(o => o.amount)

      let colors = generateRandomColors(requests.length)

      let dataSet = [
        {
          label: 'Number of Requests',
          data: amount,
          backgroundColor: colors
        }
      ]

      let statsChart = document.getElementById('requestStatsChart')

      if (myChart) {
        myChart.destroy()
      }

      myChart = initBarChart(statsChart, users, dataSet, 'x', false, 'bottom', false)
    }
    setMessage('')
  }

  const updateStatsSelectedRequest = selRequest => {
    setSelectedRequest(selRequest)
  }

  const updateStartDate = newDate => {
    setStartDate(newDate)
  }

  const updateEndDate = newDate => {
    setEndDate(newDate)
  }

  const minimizeChart = () => {
    if (visible) setVisible(false)
    if (!visible) setVisible(true)

    if (!myChart) fetchStats(controller)
  }

  useEffect(() => {
    if (autoload) fetchStats(controller)

    return () => {
      controller.abort()
      if (myChart) {
        myChart.destroy()
        myChart = null
      }
    }
  }, [])

  useEffect(() => {
    if (myChart && selectedRequest && startDate && endDate) statsHandler(controller)
  }, [selectedRequest, startDate, endDate])

  return (
    <Card className="shadow border-0">
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Card.Header>
        <Card.Title as="h4">
          Matching Actions Stats
          <div style={{ position: 'absolute', top: '5px', right: '10px' }} onClick={minimizeChart}>
            <i style={{ display: visible ? '' : 'none' }} className="fas fa-angle-down custom-button-like"></i>
            <i style={{ display: visible ? 'none' : '' }} className="fas fa-angle-up custom-button-like"></i>
          </div>
        </Card.Title>
        <Row style={{ display: visible ? 'none' : '' }}>
          <Col md="9">
            <Row>
              <Col md="3">
                <Form.Group>
                  <label>Action Name:</label>
                  <RequestSelect requests={requests} updateRequest={updateStatsSelectedRequest} selectedRequest={JSON.stringify(selectedRequest)} />
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group>
                  <label>From:</label>
                  <DateSelect selectedDate={startDate} handleDateChange={updateStartDate} />
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group>
                  <label>To:</label>
                  <DateSelect selectedDate={endDate} handleDateChange={updateEndDate} />
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body style={{ display: visible ? 'none' : '' }}>
        <div className="stats-chart" id="requestStatsChartHolder" style={{ height: '65vh', marginBottom: '0.4vh', display: message === '' ? '' : 'none' }}>
          <canvas id="requestStatsChart" />
        </div>
        <div>{message}</div>
      </Card.Body>
      <Card.Footer>
        <hr></hr>
        <div className="stats">
          <i className="fas fa-history"></i>
          Updated 3 minutes ago
        </div>
      </Card.Footer>
    </Card>
  )
}
