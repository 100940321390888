import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

function DateSelect({ selectedDate, handleDateChange }) {
  return (
    <div>
      <DatePicker selected={selectedDate} onChange={handleDateChange} dateFormat="Pp" timeFormat='p' showYearDropdown showTimeSelect/>
    </div>
  )
}

export default DateSelect