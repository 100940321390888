const _ = require('lodash')
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import NotificationAlert from 'react-notification-alert'

import UpdateUser from 'components/Modals/UpdateUser'

import Swal from 'sweetalert2'

import { getData, delData } from 'utils/server'
import { getAuthorizationHeader, getUserData } from 'utils/helpers'

function UsersTable({ forceUpdate }) {
  const controller = new AbortController()

  const notificationAlertRef = React.useRef(null)
  const [tableData, setData] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [roleId, setRoleId] = useState('')
  const [username, setUsername] = useState('')
  const [fullname, setFullname] = useState('')
  const [id, setUserId] = useState(null)

  const paginationOptions = {
    disablePageTitle: true,
    showTotal: false,
    sizePerPageList: [
      {
        text: '10',
        value: 10
      }
    ]
  }

  const handleDeleteClick = row => {
    Swal.fire({ title: `Delete user ${row.user.username}?`, showDenyButton: true, showConfirmButton: true, confirmButtonText: 'Yes, delete it!', denyButtonText: 'Cancel', icon: 'warning', confirmButtonColor: '#87cb16', denyButtonColor: '#ff4a55' }).then(async result => {
      if (result.isConfirmed) {
        let result = await delData(`/api/users/${row.user.id}`, { Authorization: getAuthorizationHeader() })
        if (result != null) {
          Swal.fire('Deleted!', `User ${row.user.username} has been deleted.`, 'success')
          fetchData()
        } else {
          Swal.fire('Error!', `Something went wrong.`, 'error')
        }
      }
    })
  }

  const handleEditClick = row => {
    setRoleId(row.role_id)
    setUsername(row.username)
    setUserId(row.id)
    row.name == null ? setFullname('') : setFullname(row.name)
    showModal ? setShowModal(false) : setShowModal(true)
  }

  const DeleteButton = row => {
    if (row.user.id != getUserData().id) {
      return (
        <Button
          className="btn-simple btn-link p-1"
          type="button"
          variant="danger"
          onClick={() => {
            handleDeleteClick(row)
          }}
        >
          <i className="fas fa-times"></i>
        </Button>
      )
    }
    return null
  }

  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'name',
      text: 'Name',
      editable: false,
      sort: true
    },
    {
      dataField: 'username',
      text: 'Username',
      editable: false,
      sort: true
    },
    {
      dataField: 'user_role',
      text: 'Role',
      editable: false,
      sort: true
    },
    {
      dataField: 'dm1',
      isDummyField: true,
      text: 'Actions',
      editable: false,
      formatter: (cellContent, row) => (
        <>
          <Button
            className="btn-simple btn-link p-1"
            type="button"
            variant="warning"
            onClick={() => {
              handleEditClick(row)
            }}
          >
            <i className="fas fa-pen"></i>
          </Button>
          <DeleteButton user={row} />
        </>
      )
    }
  ]

  const fetchData = async controller => {
    let response = await getData(`/api/users`, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    let tableData = []
    if (response != null) tableData = response.data
    setData(tableData)
  }
  const handleCloseModal = updt => {
    setShowModal(false)
    if (updt) fetchData(controller)
  }
  useEffect(() => {
    fetchData(controller)
  }, [forceUpdate])

  useEffect(() => {
    return () => {
      controller.abort()
    }
  }, [])

  return (
    <>
      <UpdateUser showModal={showModal} parentFullname={fullname} parentUsername={username} parentRoleId={roleId} parentId={id} signalCloseModal={handleCloseModal} />
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <BootstrapTable bootstrap4 keyField="id" data={tableData} columns={columns} pagination={paginationFactory({ sizePerPage: 10 })} filter={filterFactory()} />
    </>
  )
}
export default UsersTable
