const moment = require('moment')

import React, { useState, useEffect } from 'react'

import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

function DateSelect({ setParentData, showTime, minDate }) {
  const [startDate, setStartDate] = useState(null)
  const handleChange = date => {
    let selectedDate = date ? moment(date) : null
    if (showTime) {
      setParentData(selectedDate)
    } else {
      setParentData(selectedDate ? selectedDate.format('YYYY-MM-DD') : null)
    }

    setStartDate(date)
  }

  useEffect(() => {
    if (minDate && startDate) {
      let start = new Date(minDate)
      let end = startDate
      if (end.getTime() <= start.getTime()) setStartDate(null)
    }
  }, [minDate])

  if (showTime) {
    if (minDate) return <DatePicker showIcon isClearable selected={startDate} onChange={handleChange} dateFormat="Pp" timeFormat="p" excludeDateIntervals={[{ start: 284031561000, end: new Date(minDate).getTime() }]} showTimeSelect placeholderText="Select Start Date" />
    return <DatePicker showIcon isClearable selected={startDate} onChange={handleChange} dateFormat="Pp" timeFormat="p" showTimeSelect placeholderText="Select Start Date" />
  } else {
    return <DatePicker showIcon isClearable selected={startDate} onChange={handleChange} dateFormat="P" placeholderText="Select Start Date" />
  }
}

export default DateSelect
