import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import HLFixtures from 'components/Tables/HLFixtures'
import { getData } from 'utils/server'
import { getAuthorizationHeader } from 'utils/helpers'
import { controllers } from 'chart.js'

export default function Matching() {
  const [providers, setProviders] = useState([])
  const controller = new AbortController()
  const fetchProviders = async controller => {
    let response = await getData('/api/providers', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (response != null) {
      response.data = response.data.sort((a, b) => a.id - b.id)
      setProviders(response.data)
    }
  }

  useEffect(() => {
    fetchProviders(controller)

    return () => {
      controller.abort()
    }
  }, [])
  return (
    <>
      <Container fluid>
        <HLFixtures providers={providers} />
      </Container>
    </>
  )
}
