const moment = require('moment')

import React, { useState, useEffect } from 'react'

import DateTimePicker from 'react-datetime-picker'

import 'react-datetime-picker/dist/DateTimePicker.css'
import 'react-calendar/dist/Calendar.css'
import 'react-clock/dist/Clock.css'

function StartDateSelect({ setParentUnformattedDate, disabled, initDate }) {
  const [value, onChange] = useState(new Date())
  // const onChange = date => {
  //   let selectedDate = moment(date)
  //   setParentStartDate(selectedDate.format('YYYY-MM-DD'))
  //   setStartDate(date)
  // }

  useEffect(() => {
    setParentUnformattedDate(value)
  }, [value])

  useEffect(() => {
    if (initDate) {
      onChange(new Date(initDate))
    }
  }, [initDate])

  return (
    <div>
      <DateTimePicker onChange={onChange} value={value} disabled={disabled} />
    </div>
  )
}

export default StartDateSelect
