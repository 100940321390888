const _ = require('lodash')
import React, { useEffect, useState } from 'react'
import { Card, Container, Row, Col, Button } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import NotificationAlert from 'react-notification-alert'
import stringSimilarity from 'string-similarity'
import Swal from 'sweetalert2'
import { postData, getData, delData } from 'utils/server'
import { notificationOptions, getAuthorizationHeader } from 'utils/helpers'
import TableCounters from 'components/UniObject/TableCounters'
import moment from 'moment'

let idFilter
let sportFilter
let regionFilter
let competitionFilter
let homeFilter
let awayFilter

function UniFixturesExtra({ dateOptions, startDate, endDate, sportId, selectedEvent, defaultSimilarity, defaultMatchingSectors, selectOptions, forceUpdate }) {
  const controller = new AbortController()

  const notificationAlertRef = React.useRef(null)
  const [tableData, setData] = useState([])
  const [searchId, setSearchId] = useState(null)
  const [searchSport, setSearchSport] = useState(null)
  const [searchRegion, setSearchRegion] = useState(null)
  const [searchCompetition, setSearchCompetition] = useState(null)
  const [searchHome, setSearchHome] = useState(null)
  const [searchAway, setSearchAway] = useState(null)

  const [allcounter, setAllCounter] = useState(0)
  const [filteredcounter, setFilteredcounter] = useState(0)
  const [bestMatch, setBestMatch] = useState([])
  const [eventSimilarity, setEventSimilarity] = useState(0)
  const [checkSimilarity, setDefaultSimilarity] = useState(0.5)
  const [regionSector, setRegionSector] = useState(false)
  const [competitionSector, setCompetitionSector] = useState(false)
  const [participantSector, setParticipantSector] = useState(true)
  const [backdrop, setBackdrop] = useState(false)
  const [selectedEventForMatching, setSelectedEventForMatching] = useState(null)
  const [selectedID, setSelectedID] = useState(null)

  const [rowSelected, setRowSelected] = useState([])

  const DeleteButton = props => {
    return props.item.created_from_provider_id === 0 ? (
      <Button
        className="btn-simple btn-link p-1"
        type="button"
        variant={props.item.created_from_provider_id == 0 ? 'danger' : 'secondary'}
        disabled={props.item.created_from_provider_id == 0 ? false : true}
        onClick={() => {
          handleDeleteUniFixture(props.item)
        }}
      >
        <i className="fas fa-times"></i>
      </Button>
    ) : (
      ''
    )
  }

  const InfoButton = props => {
    return (
      <Button
        className="btn-simple btn-link p-1"
        type="button"
        variant="dark"
        onClick={() => {
          handleMatchingInfoUniFixture(props.item)
        }}
      >
        <i className="fab fa-hubspot"></i>
      </Button>
    )
  }

  const ShowLinkedButton = props => {
    return props.item.linked_id !== null ? (
      <Button className="btn-simple btn-link p-1" type="button" variant="primary" title={'Linked to fixture: ' + props.item.linked_id}>
        <i className="fas fa-link"></i>
      </Button>
    ) : (
      ''
    )
  }

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setRowSelected([row.eventId])
      setBestMatch([row.eventId])
      selectOptions.handler(row)
      setSelectedEventForMatching(row)
    } else {
      setRowSelected([])
      setBestMatch([])
      selectOptions.handler(null)
      setSelectedEventForMatching(null)
    }
  }

  const selectRow = {
    mode: 'radio',
    clickToSelect: true,
    hideSelectColumn: true,
    bgColor: selectOptions.color,
    onSelect: handleOnSelect,
    selected: rowSelected
  }

  const columns = [
    {
      dataField: 'eventId',
      text: 'Event ID',
      filter: textFilter({
        delay: 600, // default is 500ms
        style: {
          // backgroundColor: 'yellow'
        },
        className: 'test-classname',
        // placeholder: 'Custom PlaceHolder',
        getFilter: filter => {
          idFilter = filter
        },
        defaultValue: selectedID ? selectedID : '',
        onFilter: value => setSearchId(value)
      }),
      sort: true
    },
    {
      dataField: 'region',
      text: 'Region',
      filter: textFilter({
        delay: 600, // default is 500ms
        style: {
          // backgroundColor: 'yellow'
        },
        className: 'test-classname',
        // placeholder: 'Custom PlaceHolder',
        getFilter: filter => {
          regionFilter = filter
        },
        defaultValue: searchRegion ? searchRegion : '',
        onFilter: value => setSearchRegion(value)
      }),
      sort: true
    },
    {
      dataField: 'competition',
      text: 'Competition',
      filter: textFilter({
        delay: 600, // default is 500ms
        style: {
          // backgroundColor: 'yellow'
        },
        className: 'test-classname',
        // placeholder: 'Custom PlaceHolder',
        getFilter: filter => {
          competitionFilter = filter
        },
        defaultValue: searchCompetition ? searchCompetition : '',
        onFilter: value => setSearchCompetition(value)
      }),
      sort: true
    },
    {
      dataField: 'home',
      text: 'Home',
      filter: textFilter({
        delay: 600, // default is 500ms
        style: {
          // backgroundColor: 'yellow'
        },
        className: 'test-classname',
        // placeholder: 'Custom PlaceHolder',
        getFilter: filter => {
          homeFilter = filter
        },
        defaultValue: searchHome ? searchHome : '',
        onFilter: value => setSearchHome(value)
      }),
      sort: true
    },
    {
      dataField: 'away',
      text: 'Away',
      filter: textFilter({
        delay: 600, // default is 500ms
        style: {
          // backgroundColor: 'yellow'
        },
        className: 'test-classname',
        // placeholder: 'Custom PlaceHolder',
        getFilter: filter => {
          awayFilter = filter
        },
        defaultValue: searchAway ? searchAway : '',
        onFilter: value => setSearchAway(value)
      }),
      sort: true
    },
    {
      dataField: 'timestamp',
      text: 'Start Date',
      sort: true
    },
    {
      dataField: 'action',
      isDummyField: true,
      text: '',
      editable: false,
      headerClasses: 'delete-column',
      formatter: (cellContent, row) => (
        <>
          <InfoButton item={row} />
          <ShowLinkedButton item={row} />
          <DeleteButton item={row} />
        </>
      )
    }
  ]

  const columnsAll = [
    {
      dataField: 'sport',
      text: 'Sport',
      filter: textFilter({
        delay: 600, // default is 500ms
        style: {
          // backgroundColor: 'yellow'
        },
        className: 'test-classname',
        // placeholder: 'Custom PlaceHolder',
        getFilter: filter => {
          sportFilter = filter
        },
        defaultValue: searchSport ? searchSport : '',
        onFilter: value => setSearchSport(value)
      }),
      sort: true
    },
    {
      dataField: 'eventId',
      text: 'Event ID',
      filter: textFilter({
        delay: 600, // default is 500ms
        style: {
          // backgroundColor: 'yellow'
        },
        className: 'test-classname',
        // placeholder: 'Custom PlaceHolder',
        getFilter: filter => {
          idFilter = filter
        },
        defaultValue: selectedID ? selectedID : ''
      }),
      sort: true
    },

    {
      dataField: 'region',
      text: 'Region',
      filter: textFilter({
        delay: 600, // default is 500ms
        style: {
          // backgroundColor: 'yellow'
        },
        className: 'test-classname',
        // placeholder: 'Custom PlaceHolder',
        getFilter: filter => {
          regionFilter = filter
        },
        defaultValue: searchRegion ? searchRegion : '',
        onFilter: value => setSearchRegion(value)
      }),
      sort: true
    },
    {
      dataField: 'competition',
      text: 'Competition',
      filter: textFilter({
        delay: 600, // default is 500ms
        style: {
          // backgroundColor: 'yellow'
        },
        className: 'test-classname',
        // placeholder: 'Custom PlaceHolder',
        getFilter: filter => {
          competitionFilter = filter
        },
        defaultValue: searchCompetition ? searchCompetition : '',
        onFilter: value => setSearchCompetition(value)
      }),
      sort: true
    },
    {
      dataField: 'home',
      text: 'Home',
      filter: textFilter({
        delay: 600, // default is 500ms
        style: {
          // backgroundColor: 'yellow'
        },
        className: 'test-classname',
        // placeholder: 'Custom PlaceHolder',
        getFilter: filter => {
          homeFilter = filter
        },
        defaultValue: searchHome ? searchHome : '',
        onFilter: value => setSearchHome(value)
      }),
      sort: true
    },
    {
      dataField: 'away',
      text: 'Away',
      filter: textFilter({
        delay: 600, // default is 500ms
        style: {
          // backgroundColor: 'yellow'
        },
        className: 'test-classname',
        // placeholder: 'Custom PlaceHolder',
        getFilter: filter => {
          awayFilter = filter
        },
        defaultValue: searchAway ? searchAway : '',
        onFilter: value => setSearchAway(value)
      }),
      sort: true
    },
    {
      dataField: 'timestamp',
      text: 'Start Date',
      sort: true
    },
    {
      dataField: 'action',
      isDummyField: true,
      text: '',
      editable: false,
      headerClasses: 'delete-column',
      formatter: (cellContent, row) => (
        <>
          <InfoButton item={row} />
          <ShowLinkedButton item={row} />
          <DeleteButton item={row} />
        </>
      )
    }
  ]

  const fetchData = async controller => {
    if (startDate == null || sportId == null || endDate == null) return
    setBackdrop(true)
    clearMatching(new Event('Clear Matching'))
    let filters = {
      sport_id: sportId,
      date_option: dateOptions,
      start_date: startDate,
      end_date: endDate
    }
    let uri = '/api/unis/multifetchFixtures'
    let response = await postData(uri, filters, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (response == null) return
    let tableData = []
    // setData(tableData)
    // sleep(1000)
    for (let fixture of response.data) {
      let jsDate = new Date(parseInt(fixture.start_date))
      fixture.eventId = fixture.id
      fixture.timestamp = moment(jsDate).format('DD/MM/YYYY HH:MM')
      tableData.push(fixture)
    }

    setAllCounter(response.data.length)
    setData(tableData)
    setBackdrop(false)
  }

  const handleMatchingInfoUniFixture = async fixture => {
    let result = await getData(`/api/unis/fixtures/${fixture.id}/relations`, { Authorization: getAuthorizationHeader() })

    console.log(result.data)

    let htmlText = '<div style="min-width:150px;text-align: left;"><ul>'

    let eventId_width = 150
    let region_width = 100
    let competition_width = 100
    let participants_width = 100

    for (let index = 0; index < result.data.length; index++) {
      const element = result.data[index]
      eventId_width = eventId_width < element.fixture.eventId.toString().length * 10 ? element.fixture.eventId.toString().length * 10 : eventId_width
      region_width = region_width < element.fixture.region.length * 10 ? element.fixture.region.length * 10 : region_width
      competition_width = competition_width < element.fixture.competition.length * 10 ? element.fixture.competition.length * 10 : competition_width
      participants_width = participants_width < (element.fixture.participants[0].name + ' Vs ' + element.fixture.participants[1].name).length * 10 ? (element.fixture.participants[0].name + ' Vs ' + element.fixture.participants[1].name).length * 10 : participants_width
    }

    for (let index = 0; index < result.data.length; index++) {
      const element = result.data[index]
      htmlText =
        htmlText +
        `<li style="border-bottom: 1px solid;width: max-content;">
          <div style="display: flex;gap: 5px;">
            <div class="${element.provider}Provider" style="text-align: center;margin-bottom: auto;margin-top: auto;">${element.provider}</div>
            <div class="event_id" style="width: ${eventId_width}px">${element.fixture.eventId}</div>
            <div class="event_region" style="width: ${region_width}px">${element.fixture.region}</div>
            <div class="event_competition" style="width: ${competition_width}px">${element.fixture.competition}</div>
            <div class="event_participants" style="width: ${participants_width}px">${element.fixture.participants[0].name} Vs ${element.fixture.participants[1].name}</div>
          </div>
        </li>`
    }

    htmlText = htmlText + '</ul></div>'

    Swal.fire({
      title: `Related Fixtures:`,
      customClass: 'swal-wide',
      html: htmlText,
      showDenyButton: false,
      showConfirmButton: true,
      confirmButtonText: 'OK',
      denyButtonText: 'Cancel',
      icon: 'info',
      confirmButtonColor: '#87cb16',
      denyButtonColor: '#ff4a55'
    }).then(async result => {
      if (result.isConfirmed) {
      }
    })
  }

  const handleDeleteUniFixture = fixture => {
    Swal.fire({ title: `Delete Fixture <br><br> ${fixture.home} Vs ${fixture.away}? <br>`, showDenyButton: true, showConfirmButton: true, confirmButtonText: 'Yes, delete it!', denyButtonText: 'Cancel', icon: 'warning', confirmButtonColor: '#87cb16', denyButtonColor: '#ff4a55' }).then(async result => {
      if (result.isConfirmed) {
        let result = await delData(`/api/unis/fixtures/${fixture.id}`, { Authorization: getAuthorizationHeader() })
        if (result != null) {
          Swal.fire('Deleted!', `Fixture <br><br> ${fixture.home} Vs ${fixture.away} <br><br> has been deleted.`, 'success')
          fetchData(controller)
        } else {
          Swal.fire('Error!', `Something went wrong.`, 'error')
        }
      }
    })
  }

  function afterFilter(newResult, newFilters) {
    setFilteredcounter(newResult.length)
  }

  const searchForBestMatch = event => {
    const minSimilarity = checkSimilarity
    const eventToSearch = createString(event) //`${event.region}::${event.competition}::${event.home}::${event.away}`
    console.log('eventToSearch: ', eventToSearch)
    const eventIds = []
    let matches = []

    for (let index = 0; index < tableData.length; index++) {
      //console.log(index)
      const fixture = tableData[index]
      const uniFixtureString = createString(fixture) //`${fixture.region}::${fixture.competition}::${fixture.home}::${fixture.away}`
      let similarity = stringSimilarity.compareTwoStrings(eventToSearch, uniFixtureString)
      //console.log('uniFixtureString: ', uniFixtureString, ', similarity: ', similarity)
      if (similarity > minSimilarity) {
        eventIds.push(fixture.eventId)
        matches.push(uniFixtureString)
        //console.log('eventFound: ', uniFixtureString, ', similarity: ', similarity)
      }
    }

    if (matches.length > 0) {
      let bestMatch = stringSimilarity.findBestMatch(eventToSearch, matches)
      let index = matches.indexOf(bestMatch.bestMatch.target)
      let ev = tableData.filter(item => item.eventId == eventIds[index])
      setBestMatch([eventIds[index]])
      //console.log('BEST MATCH IS: ', bestMatch)
      setEventSimilarity(bestMatch.ratings[0].rating)
      selectOptions.handler(ev[0])
      notificationAlertRef.current.notificationAlert(notificationOptions(`BEST MATCH IS: ${ev[0].id}`, 'success'))
      setSelectedID(ev[0].id.toString())
      setRowSelected([ev[0].id])
    } else {
      setBestMatch([])
      setEventSimilarity(0)
      selectOptions.handler(null)
      notificationAlertRef.current.notificationAlert(notificationOptions('None Uni-Fixture found for the selected Provider Fixture based on the Similarity Sectors', 'danger'))
      setSelectedID(null)
      setSelectedEventForMatching(null)
      setRowSelected([])
    }
  }

  const createString = event => {
    let stringToCheck = ''

    if (regionSector) stringToCheck = event.region
    if (competitionSector) stringToCheck = stringToCheck == '' ? event.competition : `${stringToCheck}::${event.competition}`
    if (participantSector) stringToCheck = stringToCheck == '' ? `${event.home}::${event.away}` : `${stringToCheck}::${event.home}::${event.away}`

    return stringToCheck
  }

  const setMatchingSectors = sectors => {
    //console.log('sectors Changed')
    setRegionSector(sectors.rSector)
    setCompetitionSector(sectors.cSector)
    setParticipantSector(sectors.pSector)
  }

  const clearFilters = () => {
    setSearchId(null)
    setSearchRegion(null)
    setSearchSport(null)
    setSearchCompetition(null)
    setSearchHome(null)
    setSearchAway(null)
    if (idFilter) idFilter('')
    if (sportFilter) sportFilter('')
    if (regionFilter) regionFilter('')
    if (competitionFilter) competitionFilter('')
    if (homeFilter) homeFilter('')
    if (awayFilter) awayFilter('')
    selectOptions.clearFilter()
  }

  const clearMatching = e => {
    e.preventDefault()
    setSelectedID(null)
    setSelectedEventForMatching(null)
    setBestMatch([])
    setEventSimilarity(0)
    selectOptions.handler(null)
    setRowSelected([])
  }

  const showSelectedUni = e => {
    console.log(selectedEventForMatching)
    setSearchId(selectedEventForMatching.id)
    idFilter(selectedEventForMatching.id + '')
  }

  useEffect(() => {
    if (sportFilter && selectOptions.sportSelected != '-1') {
      sportFilter(selectOptions.sportSelected ? selectOptions.sportSelected : '')
      setSearchSport(selectOptions.sportSelected)
    }
    if (regionFilter && selectOptions.regionSelected != '-1') {
      regionFilter(selectOptions.regionSelected ? selectOptions.regionSelected : '')
      setSearchRegion(selectOptions.regionSelected)
    }
    if (competitionFilter && selectOptions.competitionSelected != '-1') {
      competitionFilter(selectOptions.competitionSelected ? selectOptions.competitionSelected : '')
      setSearchCompetition(selectOptions.competitionSelected)
    }
    if (homeFilter && selectOptions.homeSelected != '-1') {
      homeFilter(selectOptions.homeSelected ? selectOptions.homeSelected : '')
      setSearchHome(selectOptions.homeSelected)
    }
    if (awayFilter && selectOptions.awaySelected != '-1') {
      awayFilter(selectOptions.awaySelected ? selectOptions.awaySelected : '')
      setSearchAway(selectOptions.awaySelected)
    }
  }, [selectOptions])

  useEffect(() => {
    fetchData(controller)
  }, [startDate, endDate, sportId, dateOptions, forceUpdate])

  useEffect(() => {
    if (selectOptions.autoSimilaritySuggest) {
      if (selectedEvent) {
        setSelectedEventForMatching(selectedEvent)
        searchForBestMatch(selectedEvent)
      } else {
        clearMatching(new Event('clearMatching'))
      }
    } else {
    }
  }, [selectedEvent])

  useEffect(() => {
    //if (!searchSport) return
    if (!searchSport) {
      if (sportFilter) sportFilter('')
      selectOptions.setFilters('sport', '')
    } else {
      if (sportFilter) sportFilter(searchSport)
      selectOptions.setFilters('sport', searchSport)
    }
    // if (sportFilter) sportFilter(searchSport)
    // selectOptions.setFilters('sport', searchSport)
  }, [searchSport])

  useEffect(() => {
    if (!searchRegion) {
      if (regionFilter) regionFilter('')
      selectOptions.setFilters('region', '')
    } else {
      if (regionFilter) regionFilter(searchRegion)
      selectOptions.setFilters('region', searchRegion)
    }
  }, [searchRegion])

  useEffect(() => {
    if (!searchCompetition) {
      if (competitionFilter) competitionFilter('')
      selectOptions.setFilters('competition', '')
    } else {
      if (competitionFilter) competitionFilter(searchCompetition)
      selectOptions.setFilters('competition', searchCompetition)
    }
  }, [searchCompetition])

  useEffect(() => {
    if (!searchHome) {
      if (homeFilter) homeFilter('')
      selectOptions.setFilters('home', '')
    } else {
      if (homeFilter) homeFilter(searchHome)
      selectOptions.setFilters('home', searchHome)
    }
  }, [searchHome])

  useEffect(() => {
    if (!searchAway) {
      if (awayFilter) awayFilter('')
      selectOptions.setFilters('away', '')
    } else {
      if (awayFilter) awayFilter(searchAway)
      selectOptions.setFilters('away', searchAway)
    }
  }, [searchAway])

  useEffect(() => {
    if (defaultSimilarity) setDefaultSimilarity(defaultSimilarity)
  }, [defaultSimilarity])

  useEffect(() => {
    if (defaultMatchingSectors) setMatchingSectors(defaultMatchingSectors)
  }, [defaultMatchingSectors])

  useEffect(() => {
    return () => {
      controller.abort()
    }
  }, [])

  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div id="backdrop" style={{ zIndex: backdrop ? 10000 : -10000, display: backdrop ? '' : 'none' }}></div>
      <div style={{ width: '100%', display: 'flex' }}>
        <div style={{ width: '100%', fontSize: '13px' }}>
          <TableCounters all={allcounter} filtered={filteredcounter}></TableCounters>
        </div>
        <Button
          className="btn-simple btn-link p-1 btn btn-danger"
          onClick={e => {
            clearFilters(e)
          }}
        >
          <i className="fas fa-times" aria-hidden="true" />
        </Button>
      </div>
      <div style={{ display: selectedEventForMatching ? '' : 'none' }}>
        <Row>
          <Col md="3" style={{ padding: '5px 20px 0px 15px' }}>
            <div>
              matched rating: <span style={{ color: eventSimilarity > 0.8 ? 'green' : 'orange' }}>{(eventSimilarity * 100).toFixed(0)}%</span>
            </div>
          </Col>
          <Col md="9" style={{ textAlign: 'right' }}>
            <Button
              style={{ marginRight: '20px' }}
              className="btn-simple btn-link p-1 btn btn-info"
              onClick={e => {
                showSelectedUni(e)
              }}
            >
              <i className="fas fa-eye" aria-hidden="true" />
              show matching
            </Button>
            <Button
              className="btn-simple btn-link p-1 btn btn-danger"
              onClick={e => {
                clearMatching(e)
              }}
            >
              <i className="fas fa-times" aria-hidden="true" />
              clear matching
            </Button>
          </Col>
        </Row>
      </div>
      <div style={{ display: backdrop ? 'none' : '' }}>
        <BootstrapTable id="uni-fixtures-table" loading={true} bootstrap4 keyField="eventId" data={tableData} columns={sportId == 1000 ? columnsAll : columns} pagination={paginationFactory({ sizePerPage: 10 })} filter={filterFactory({ afterFilter })} selectRow={selectRow} />
      </div>
    </>
  )
}
export default UniFixturesExtra
