import React, { useState, useEffect } from 'react'
import { Card, Container, Row, Col, Button } from 'react-bootstrap'
import RegionTranslations from 'components/Tables/RegionTranslations'
import CompetitionTranslations from 'components/Tables/CompetitionTranslations'
import ParticipantTranslations from 'components/Tables/ParticipantTranslations'
import LanguageSelect from 'components/Inputs/LanguageSelect'
import { useLocation, useHistory, NavLink } from 'react-router-dom'

import { getData } from 'utils/server'

import { getAuthorizationHeader, getUserRole } from 'utils/helpers'

function Translations() {
  let history = useHistory()
  const location = useLocation()
  const userLayout = location.pathname.split('/')[1]
  const userRole = getUserRole()
  let userRoleLayout = 'operator'

  switch (userRole) {
    case 1:
      userRoleLayout = 'admin'
      break
    case 2:
      userRoleLayout = 'admin'
      break
    default:
      break
  }
  if (userLayout !== userRoleLayout) history.push(`/${userRoleLayout}/translations`)

  const [langId, setLangId] = useState(null)
  const [regions, setRegions] = useState([])
  const [competitions, setCompetitions] = useState([])
  const [participants, setParticipants] = useState([])
  const [showTable, setShowTable] = useState('')
  const [translationCoverage, setTranslationsCoverage] = useState({ regions: 0, competitions: 0, participants: 0, '': 0 })

  const updateSelectedLanguage = pId => {
    setLangId(pId)
  }

  const updateTablesVisibility = function (table) {
    if (langId == null) return
    document.getElementById(`regions-btn`).classList.remove('btn-fill')
    document.getElementById(`competitions-btn`).classList.remove('btn-fill')
    document.getElementById(`participants-btn`).classList.remove('btn-fill')
    document.getElementById(`${table}-btn`).classList.add('btn-fill')
    setShowTable(table)
  }
  const fetchRegionTranslations = async () => {
    let response = await getData(`/api/providers/translations/regions/language/${langId}`, { Authorization: getAuthorizationHeader() })
    if (response != null) {
      setRegions(response.data.translations)
      let prevCoverage = translationCoverage
      prevCoverage['regions'] = response.data.coverage.toFixed(2)
      setTranslationsCoverage[prevCoverage]
    } else {
      setRegions([])
    }
  }

  const fetchCompetitionTranslations = async () => {
    let response = await getData(`/api/providers/translations/competitions/language/${langId}`, { Authorization: getAuthorizationHeader() })
    if (response != null) {
      setCompetitions(response.data.translations)
      let prevCoverage = translationCoverage
      prevCoverage['competitions'] = response.data.coverage.toFixed(2)
      setTranslationsCoverage[prevCoverage]
    } else {
      setCompetitions([])
    }
  }

  const fetchParticipantTranslations = async () => {
    let response = await getData(`/api/providers/translations/participants/language/${langId}`, { Authorization: getAuthorizationHeader() })
    if (response != null) {
      setParticipants(response.data.translations)
      let prevCoverage = translationCoverage
      prevCoverage['participants'] = response.data.coverage.toFixed(2)
      setTranslationsCoverage[prevCoverage]
    } else {
      setParticipants([])
    }
  }

  useEffect(() => {
    if (langId == null) return
    fetchRegionTranslations()
    fetchCompetitionTranslations()
    fetchParticipantTranslations()
  }, [langId])

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Body>
                <Row>
                  <Col md="3">
                    <LanguageSelect setParentLanguageId={updateSelectedLanguage} />
                  </Col>
                  <Col md="3">
                    <Button className="match-fixtures-btn" id="regions-btn" variant="info" onClick={() => updateTablesVisibility('regions')}>
                      Regions
                    </Button>
                  </Col>
                  <Col md="3">
                    <Button className="match-fixtures-btn" id="competitions-btn" variant="warning" onClick={() => updateTablesVisibility('competitions')}>
                      Competitions
                    </Button>
                  </Col>
                  <Col md="3">
                    <Button className="match-fixtures-btn" id="participants-btn" variant="danger" onClick={() => updateTablesVisibility('participants')}>
                      Participants
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col md="8">
                    <Card.Title as="h4">{showTable != '' ? showTable[0].toUpperCase() + showTable.substr(1, showTable.length - 1) : showTable}</Card.Title>
                  </Col>
                  <Col md="4">
                    <div>Translation Coverage</div>
                    <div className="progress" style={{ height: '20px' }}>
                      <div className="progress-bar" role="progressbar" style={{ width: translationCoverage[showTable] + '%' }} aria-valuenow={translationCoverage[showTable] + ''} aria-valuemin="0" aria-valuemax="100">
                        {translationCoverage[showTable] + '%'}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Col md="12">
                  {showTable == 'regions' ? <RegionTranslations data={regions} langId={langId} refreshTable={fetchRegionTranslations} /> : null}
                  {showTable == 'competitions' ? <CompetitionTranslations data={competitions} langId={langId} refreshTable={fetchCompetitionTranslations} /> : null}
                  {showTable == 'participants' ? <ParticipantTranslations data={participants} langId={langId} refreshTable={fetchParticipantTranslations} /> : null}
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Translations
