import React from 'react'
import { useLocation, NavLink } from 'react-router-dom'

import { Nav } from 'react-bootstrap'
import { isUserOperator } from 'utils/auth'

function Sidebar({ color, image, routes }) {
  const location = useLocation()
  const activeRoute = routeName => {
    return location.pathname.indexOf(routeName) > -1 ? 'active' : ''
  }
  return (
    <div className="sidebar" data-image={image} data-color={color}>
      <div
        className="sidebar-background"
        style={{
          backgroundImage: 'url(' + image + ')'
        }}
      />
      <div className="sidebar-wrapper">
        <div className="logo d-flex align-items-center justify-content-start">
          <a href="https://www.creative-tim.com?ref=lbd-sidebar" className="simple-text logo-mini mx-1">
            <div className="logo-img">
              <img src={require('assets/img/favicon.ico')} alt="..." />
            </div>
          </a>
          <a className="simple-text" href={location.pathname}>
            INTERNAL PANEL
          </a>
        </div>
        <Nav>
          {routes.map((prop, key) => {
            if (prop.allowOperators == false && isUserOperator()) return null
            if (!prop.redirect)
              return (
                <li className={prop.upgrade ? 'active active-pro' : activeRoute(prop.layout + prop.path)} key={key}>
                  <NavLink to={prop.layout + prop.path} className="nav-link" activeClassName="active">
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </NavLink>
                </li>
              )
            return null
          })}
        </Nav>
      </div>
    </div>
  )
}

export default Sidebar
