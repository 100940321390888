import React from 'react'
import Select from 'react-select'

export const CustomFilter = (onFilter, column, dataType, data) => {
  let ref = React.createRef()
  let options = Object.keys(data).map(key => {
    return { label: key, value: key }
  })
  // console.log(options)
  return (
    <div style={{ textTransform: 'lowercase', fontSize: '13px' }}>
      <Select ref={ref} placeholder={`Select ${dataType}...`} isClearable isMulti options={options} className="providerFilter" onChange={event => onFilter(event.map(entry => entry.value))} />
    </div>
  )
}
