import React, { useEffect, useState } from 'react'

import Select from 'react-select'

import { getConstantData } from 'utils/server'
import { getAuthorizationHeader, getUserData, getUserRole } from 'utils/helpers'

function LanguageSelect({ setParentLanguageId, defaultValue, mustBeDisabled }) {
  const controller = new AbortController()

  const [languages, setData] = useState([])
  const [selected, setSelected] = useState(null)
  const [disabled, setDisabled] = useState(false)

  const fetchData = async controller => {
    let response = await getConstantData('/api/languages', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    let languages = []
    let allowedLanguages = null
    let userData = null
    const userRole = getUserRole()

    switch (userRole) {
      case 1:
        break
      case 2:
        break
      case 3:
        break
      case 4:
        userData = getUserData()
        if (userData.languages != null) allowedLanguages = userData.languages.split(',')
        break
      case 5:
        userData = getUserData()
        if (userData.languages != null) allowedLanguages = userData.languages.split(',')
        break
      default:
        userData = getUserData()
        if (userData.languages != null) allowedLanguages = userData.languages.split(',')
        break
    }

    for (let lang of response.data) {
      if (allowedLanguages != null) {
        if (allowedLanguages.indexOf(lang.id.toString()) == -1) continue
      }
      languages.push({
        value: lang.id,
        label: lang.name,
        name: lang.name,
        id: lang.id
      })
    }
    setData(languages)
    if (defaultValue != null) {
      if (typeof mustBeDisabled == 'undefined') setDisabled(true)
      let initSelection = languages.find(el => el.id == defaultValue)
      if (!initSelection) {
        initSelection = languages[0]
        setParentLanguageId(initSelection.id)
      }
      setSelected(initSelection)
    }
  }

  const handleChange = selectedItem => {
    setSelected(selectedItem)
    setParentLanguageId(selectedItem.id)
  }
  useEffect(() => {
    fetchData(controller)

    return () => {
      controller.abort()
    }
  }, [])
  return <Select className="languagesSelect" name="languagesSelect" options={languages} placeholder="Select Language.." onChange={handleChange} isDisabled={disabled} value={selected} />
}

export default LanguageSelect
