const _ = require('lodash')
const moment = require('moment')

import React, { useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory, { textFilter, customFilter, FILTER_TYPES } from 'react-bootstrap-table2-filter'
import NotificationAlert from 'react-notification-alert'
import { Card, Row, Col, Button, Container } from 'react-bootstrap'
import SportSelect from 'components/Inputs/SportSelect'
import DateSelect from 'components/Inputs/DateSelect'
import Swal from 'sweetalert2'

import { postData, getData } from 'utils/server'
import { notificationOptions, getAuthorizationHeader } from 'utils/helpers'
import TableCounters from 'components/UniObject/TableCounters'
import SelectedCounter from 'components/UniObject/SelectedCounter'
import MatchedFixture from 'views/MatchedFixture'
import { fixtureConflictsValidation } from 'utils/validations'
import ParticipantFixtures from 'components/Modals/ParticipantFixtures'

import { CustomFilter } from 'components/Filters/customFilter'

export default function UnresolvedConflicts(providers) {
  const controller = new AbortController()
  var pos1 = 0,
    pos2 = 0,
    pos3 = 0,
    pos4 = 0
  const notificationAlertRef = React.useRef(null)
  const [disableSearch, setDisableSearch] = useState(true)
  const [disableMatch, setDisableMatch] = useState(true)
  const [icon, setIcon] = useState(<i className="fas fa-search text-primary"></i>)
  const [text, setText] = useState(<span className="text-primary">Search fixtures</span>)
  const [matchIcon, setMatchIcon] = useState(<i className="fas fa-wrench text-success"></i>)
  const [matchText, setMatchText] = useState(<span className="text-success">Resolve selected</span>)
  const [tableData, setData] = useState([])
  const [allcounter, setAllCounter] = useState(0)
  const [filteredcounter, setFilteredcounter] = useState(0)
  const [selectedcounter, setSelectedCounter] = useState(0)
  const [allTableData, setAllData] = useState([])
  const [sports, setSports] = useState([])
  const [selectedSport, setSelectedSport] = useState(null)
  const [sorted, setSorted] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [fixture, setFixture] = useState(null)
  const [checked, setChecked] = useState([])
  const [refreshTable, setRefreshTable] = useState(false)
  const [tableKey, setTableKey] = useState(Math.random())
  const [currentPage, setCurrentPage] = useState(1)
  const [matchedFixtures, setMatchedFixtures] = useState([])
  const [matchedFixturesModal, setMatchedFixturesModal] = useState(false)
  const [selectedProvider, setSelectedProvider] = useState([])
  const [selectedId, setSelectedId] = useState([])
  const [providerFilters, setProviderFilters] = useState({})
  const [providerId, setSelectedProviderId] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [selectedSportId, setSelectedSportId] = useState(0)
  const [sortFieldTo, setSortFieldTo] = useState('')
  const [sortFieldFrom, setSortFieldFrom] = useState('')

  const handleMultiSelectedEvents = (events, isSelected) => {
    for (let index = 0; index < events.length; index++) {
      const event = events[index]
      fetchSelected(event, isSelected)
    }
  }

  const handlePageChange = (page, sizePerPage) => {
    setCurrentPage(page)
  }

  const fetchSelected = (row, isSelect) => {
    if (isSelect == true) {
      checked.push(allTableData.find(o => o.id == row.id))
      setSelectedCounter(checked.length)
    } else if (isSelect == false) {
      let idToRemove = row.id
      let index = checked
        .map(o => {
          return o.id
        })
        .indexOf(idToRemove)
      checked.splice(index, 1)
      setSelectedCounter(checked.length)
    }
  }

  const selectRow = {
    clickToSelect: false,
    selectColumnPosition: 'right',
    style: { backgroundColor: '#24e1cf1a' },
    mode: 'checkbox',
    onSelect: (ev, selected) => {
      if (ev) fetchSelected(ev, selected)
    },
    hideSelectAll: false,
    onSelectAll: (selected, events) => {
      handleMultiSelectedEvents(events, selected)
    }
  }

  const paginationOptions = {
    disablePageTitle: true,
    showTotal: false,

    sizePerPage: 10,
    page: currentPage,
    onPageChange: handlePageChange
  }

  const sport = async () => {
    return selectedSport
  }

  const date1 = async () => {
    return startDate
  }

  const date2 = async () => {
    return endDate
  }

  const checkedList = async () => {
    return checked
  }

  const createFilters = () => {
    let providersArray = providers.providers
    if (providersArray && providersArray.length > 0) {
      let myProviderFilters = {}
      for (let provider of providersArray) myProviderFilters[provider.name] = provider.name
      setProviderFilters(myProviderFilters)
      // console.log(providerFilters)
    }
  }

  const fetchFilters = async controller => {
    let sports_response = await getData('/api/sports', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (sports_response != null) {
      setSports(sports_response.data)
    }
  }

  const updateSortField = event => {
    if (event.target.id == 'sortFieldTo') setSortFieldTo(event.target.value)
    if (event.target.id == 'sortFieldFrom') setSortFieldFrom(event.target.value)
    //console.log('sortFieldTo', event.target.value)
  }

  const handleInfoClick = async (cellContent, row) => {
    let apiUrl = `/api/fixture/participants/${cellContent}/matchedFixtures/500`
    let response = await getData(apiUrl, { Authorization: getAuthorizationHeader() })

    if (response.data != []) {
      setMatchedFixtures(response.data)
      setMatchedFixturesModal(true)
    } else {
      setMatchedFixtures([])
      setMatchedFixturesModal(true)
    }
  }

  const handleInfoProviderClick = async (cellContent, row) => {
    setSelectedProvider(row.provider)
    setSelectedId(parseInt(cellContent))
    setSelectedProviderId(row.provider_id)
    setSelectedSportId(selectedSport.id)
    setShowModal(true)
  }

  const handleMatchedFixturesModal = event => {
    event.preventDefault()
    setMatchedFixturesModal(false)
  }

  const handleCloseFixtureModal = () => {
    setShowModal(false)
  }

  const columns = [
    {
      dataField: 'u_id',
      text: 'Event ID',
      headerStyle: { width: '4vw' },
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'u_region',
      text: 'Region',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'u_provider',
      text: 'Created From',
      headerStyle: { width: '6vw' },
      filterRenderer: (onFilter, column) => CustomFilter(onFilter, column, 'Providers', providerFilters),
      filter: customFilter({
        type: FILTER_TYPES.MULTISELECT
      }),
      classes: (cellContent, row) => {
        return `${cellContent}Cell`
      }
    },
    {
      dataField: 'u_competition',
      text: 'Competition',
      headerStyle: { width: '6vw' },
      filter: textFilter(),
      sort: true,
      headerClasses: 'custom-header-home',
      classes: 'custom-cell-home',
      formatter: (cellContent, row) => <div className="text-primary">{cellContent}</div>
    },
    {
      dataField: 'u_home_id',
      text: 'Home ID',
      headerStyle: { width: '3vw' },
      filter: textFilter(),
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {cellContent}
            {cellContent && (
              <a href="#" onClick={() => handleInfoClick(cellContent, row)} style={{ backgroundColor: '#4CAF50', border: 'none', color: 'white', padding: '2px 5px', textAlign: 'center', textDecoration: 'none', display: 'inline-block', fontSize: '12px', margin: '4px 2px', cursor: 'pointer' }}>
                <i className="fas fa-info fa-xs" style={{ fontSize: '10px' }}></i>
              </a>
            )}
          </div>
        )
      }
    },
    {
      dataField: 'u_home',
      text: 'Home',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'u_away_id',
      text: 'Away ID',
      headerStyle: { width: '3vw' },
      filter: textFilter(),
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {cellContent}
            {cellContent && (
              <a href="#" onClick={() => handleInfoClick(cellContent, row)} style={{ backgroundColor: '#4CAF50', border: 'none', color: 'white', padding: '2px 5px', textAlign: 'center', textDecoration: 'none', display: 'inline-block', fontSize: '12px', margin: '4px 2px', cursor: 'pointer' }}>
                <i className="fas fa-info fa-xs" style={{ fontSize: '10px' }}></i>
              </a>
            )}
          </div>
        )
      }
    },
    {
      dataField: 'u_away',
      text: 'Away',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'u_start_date',
      text: 'Start Date',
      sort: true
    },
    {
      dataField: 'separator',
      text: '',
      headerStyle: { width: '1.7vw', backgroundColor: 'rgba(143, 211, 254, 0.00)', border: '1px solid #fff !important' },
      style: { backgroundColor: 'rgba(143, 211, 254, 0.00)', borderStyle: 'hidden' },
      headerClasses: 'conflicts-header-separator',
      classes: 'conflicts-cell-separator',
      formatter: (cellContent, row) => (
        <div className="text-center" style={{ margin: '15px 0px 15px' }}>
          <span className="nc-icon nc-stre-left" style={{ fontSize: '0.60rem' }}></span>
          <span className="nc-icon nc-stre-right" style={{ fontSize: '0.60rem' }}></span>
        </div>
      )
    },
    {
      dataField: 'id',
      text: 'Event ID',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'region',
      text: 'Region',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'provider',
      text: 'Provider',
      filter: textFilter(),
      sort: true,
      classes: (cellContent, row) => {
        return `${cellContent}Cell`
      }
    },
    {
      dataField: 'competition',
      text: 'Competition',
      headerStyle: { width: '6vw' },
      filter: textFilter(),
      sort: true,
      headerClasses: 'custom-header-home',
      classes: 'custom-cell-home',
      formatter: (cellContent, row) => <div className="text-primary">{cellContent}</div>
    },
    {
      dataField: 'home_id',
      text: 'Home ID',
      headerStyle: { width: '4vw' },
      filter: textFilter(),
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {cellContent}
            {cellContent && (
              <a href="#" onClick={() => handleInfoProviderClick(cellContent, row)} style={{ backgroundColor: '#4CAF50', border: 'none', color: 'white', padding: '2px 5px', textAlign: 'center', textDecoration: 'none', display: 'inline-block', fontSize: '12px', margin: '4px 2px', cursor: 'pointer' }}>
                <i className="fas fa-info fa-xs" style={{ fontSize: '10px' }}></i>
              </a>
            )}
          </div>
        )
      }
    },
    {
      dataField: 'home',
      text: 'Home',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'away_id',
      text: 'Away ID',
      headerStyle: { width: '4vw' },
      filter: textFilter(),
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {cellContent}
            {cellContent && (
              <a href="#" onClick={() => handleInfoProviderClick(cellContent, row)} style={{ backgroundColor: '#4CAF50', border: 'none', color: 'white', padding: '2px 5px', textAlign: 'center', textDecoration: 'none', display: 'inline-block', fontSize: '12px', margin: '4px 2px', cursor: 'pointer' }}>
                <i className="fas fa-info fa-xs" style={{ fontSize: '10px' }}></i>
              </a>
            )}
          </div>
        )
      }
    },
    {
      dataField: 'away',
      text: 'Away',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'start_date',
      text: 'Start Date',
      sort: true
    }
  ]

  const fetchData = async (event, controller) => {
    setChecked([])
    setDisableSearch(true)
    setIcon(<i className="fas fa-spinner fa-spin text-primary"></i>)
    setText(<span className="text-primary">Please wait...</span>)
    let currentSport = await sport()
    let startDate = await date1()
    let endDate = await date2()

    let filters = {
      sport_id: currentSport.value,
      from_date: new Date(startDate).getTime() / 1000,
      to_date: new Date(endDate).getTime() / 1000,
      sorted: sorted,
      uc_sort_from: sortFieldFrom,
      uc_sort_to: sortFieldTo
    }

    let validation = fixtureConflictsValidation(filters)

    if (validation != null) {
      notificationAlertRef.current.notificationAlert(notificationOptions(validation.message, 'danger'))
      setDisableSearch(true)
      setIcon(<i className="fas fa-search text-primary"></i>)
      setText(<span className="text-primary">Search fixtures</span>)
      return
    }

    let uri = '/api/fixture/fixtureConflicts'
    let response = await postData(uri, filters, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (response == null) {
      notificationAlertRef.current.notificationAlert(notificationOptions('Error! Something went wrong', 'danger'))
      setDisableSearch(false)
      setIcon(<i className="fas fa-search text-primary"></i>)
      setText(<span className="text-primary">Search fixtures</span>)
      return
    }
    if (_.isEmpty(response.data)) {
      notificationAlertRef.current.notificationAlert(notificationOptions('There are no data available for these options', 'danger'))
      setDisableSearch(false)
      setIcon(<i className="fas fa-search text-primary"></i>)
      setText(<span className="text-primary">Search fixtures</span>)
    }

    let tableData = []
    for (let fixture in response.data) {
      let jsDate1 = new Date(parseInt(response.data[fixture].start_date))
      let jsDate2 = new Date(parseInt(response.data[fixture].u_start_date))
      let f_key = new Date() + '-' + currentSport.label + '-' + fixture
      response.data[fixture].f_key = f_key
      response.data[fixture].start_date = moment(jsDate1).format('MM/DD/YYYY, HH:mm')
      response.data[fixture].u_start_date = moment(jsDate2).format('MM/DD/YYYY, HH:mm')
      tableData.push(response.data[fixture])
    }

    tableData.sort((a, b) => (a.u_start_date > b.u_start_date ? 1 : -1))

    setAllCounter(tableData.length)
    setAllData(tableData)
    setData(tableData)
    setDisableSearch(false)
    setIcon(<i className="fas fa-search text-primary"></i>)
    setText(<span className="text-primary">Search fixtures</span>)
  }

  const resolveConflicts = async () => {
    let currentChecked = await checkedList()
    let htmlData = Object.keys(currentChecked).length > 1 ? `${Object.keys(currentChecked).length} Conflicts ready to resolve!` : `${Object.keys(currentChecked).length} Conflict ready to resolve!`

    if (currentChecked.length == 0) {
      notificationAlertRef.current.notificationAlert(notificationOptions('Error. Select some fixtures first', 'danger'))
      return
    }

    setMatchIcon(<i className="fas fa-spinner fa-spin text-success"></i>)
    setMatchText(<span className="text-success">Please wait...</span>)

    Swal.fire({ title: checked.length > 1 ? `Resolve conflicts?` : `Resolve conflict?`, html: htmlData, showDenyButton: true, showConfirmButton: true, confirmButtonText: 'Resolve', denyButtonText: 'Cancel', icon: 'warning', confirmButtonColor: '#87cb16', denyButtonColor: '#ff4a55' }).then(async result => {
      if (result.isConfirmed) {
        let request = []
        for (let i = 0; i < checked.length; i++) {
          request.push({
            event_id: parseInt(currentChecked[i].id),
            conflict_uni_id: currentChecked[i].u_id
          })
        }
        let result = await postData('/api/fixture/resolveConflict', request, { Authorization: getAuthorizationHeader() })
        if (result == null) {
          setMatchIcon(<i className="fas fa-wrench text-success"></i>)
          setMatchText(<span className="text-success">Resolve selected</span>)
          return
        }
        setChecked([])
        await fetchData(new Event('FaceEvent'), controller)
        setMatchIcon(<i className="fas fa-wrench text-success"></i>)
        setMatchText(<span className="text-success">Resolve selected</span>)
        if (result != null) {
          Swal.fire({ title: `Conflicts resolved successfully!`, html: '', showDenyButton: false, showConfirmButton: true, confirmButtonText: 'OK', denyButtonText: 'Cancel', icon: 'success', confirmButtonColor: '#87cb16', denyButtonColor: '#ff4a55' }).then(async result => {
            refreshTable == true ? setRefreshTable(false) : setRefreshTable(true)
          })
        } else {
          Swal.fire('Error!', `- Conflicts ${result ? 'resolved' : 'cannot be resolved!'} <br> -Message for Resolving didnt send!`, 'error')
        }
      }
      setMatchIcon(<i className="fas fa-wrench text-success"></i>)
      setMatchText(<span className="text-success">Resolve selected</span>)
    })
  }

  const deselectAllRows = () => {
    setChecked([])
    setSelectedCounter(0)
    setTableKey(Math.random())
  }
  const updateStartDate = startDate => {
    setStartDate(startDate)
  }

  const updateEndDate = endDate => {
    setEndDate(endDate)
  }

  const updateSelectedSport = selSport => {
    setSelectedSport(selSport)
  }

  const updateSorted = e => {
    setSorted(e.target.checked)
    if (!e.target.checked) {
      setSortFieldTo('')
      setSortFieldFrom('')
    }
  }

  function afterFilter(newResult, newFilters) {
    setFilteredcounter(newResult.length)
  }
  function dragMouseDown(e) {
    e = e || window.event
    e.preventDefault()
    // get the mouse cursor position at startup:
    pos3 = e.clientX
    pos4 = e.clientY
    document.onmouseup = closeDragElement
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag
  }

  function elementDrag(e) {
    e = e || window.event
    e.preventDefault()
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX
    pos2 = pos4 - e.clientY
    pos3 = e.clientX
    pos4 = e.clientY

    let element = document.getElementById('matched-fixtures-holder')
    if (element) {
      // set the element's new position:
      element.style.top = element.offsetTop - pos2 + 'px'
      element.style.left = element.offsetLeft - pos1 + 'px'
    } else return
  }

  function closeDragElement() {
    // stop moving when mouse button is released:
    document.onmouseup = null
    document.onmousemove = null
  }

  useEffect(() => {
    if (fixture == null) return
    setDisableMatchFixtures(false)
  }, [fixture])

  useEffect(() => {
    if (selectedSport == null || startDate == null || endDate == null) return
    if (startDate == 'Invalid date' || endDate == 'Invalid date') {
      setDisableSearch(true)
      return
    }
    setDisableSearch(false)
  }, [selectedSport, startDate, endDate])

  useEffect(() => {
    fetchFilters(controller)
    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    setDisableMatch(true)
    if (checked.length > 0) setDisableMatch(false)
    setSelectedCounter(checked.length)
  }, [checked.length])

  useEffect(() => {
    createFilters()
  }, [providers])

  return (
    <>
      <div style={{ position: 'absolute', top: '50px', left: '810px' }}>
        <ParticipantFixtures showModal={showModal} sportId={selectedSportId} pid={selectedId} providerId={providerId} providerName={selectedProvider} signalCloseModal={handleCloseFixtureModal}></ParticipantFixtures>
      </div>
      <Container fluid>
        <Row>
          <Col md="6">
            <Card id="matched-fixtures-holder" style={{ display: matchedFixturesModal ? '' : 'none' }}>
              <Card.Header>
                <Row>
                  <Col md="11">
                    <Card.Title as="h6" onMouseDown={dragMouseDown} name="provider-fixtures-holder" style={{ cursor: 'move' }}>
                      Matched Fixtures
                    </Card.Title>
                  </Col>
                  <Col md="1" style={{ marginTop: '-14px', textAlign: 'right' }}>
                    <Button
                      className="btn-simple btn-link p-1"
                      type="button"
                      variant="danger"
                      onClick={event => {
                        handleMatchedFixturesModal(event)
                      }}
                    >
                      <i className="fas fa-times"></i>
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Col md="12">
                  <div style={{ fontSize: '12px' }}>
                    <div id="main-info-holder"></div>

                    <div style={{ overflowX: 'auto', height: '205px' }}>
                      {matchedFixtures.map((item, index) => {
                        item.date = new Date(item.start_date * 1)
                        item.dateString = item.date.getDate() + '-' + (item.date.getMonth() + 1) + '-' + item.date.getFullYear()
                        let hours = item.date.getHours() < 10 ? '0' + item.date.getHours() : item.date.getHours()
                        let minutes = item.date.getMinutes() < 10 ? '0' + item.date.getMinutes() : item.date.getMinutes()
                        item.hourString = hours + ':' + minutes
                        item.home = item.home
                        item.away = item.away
                        return <MatchedFixture id={'matchedFixture-' + item.event_id} key={'matchedFixture-' + item.event_id + index} fix={item}></MatchedFixture>
                      })}
                    </div>
                  </div>
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Card className="strpied-tabled-with-hover">
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <Card.Body>
          <Row>
            <Col md="2">
              <label>From:</label>
              <DateSelect setParentData={updateStartDate} showTime={true} />
            </Col>
            <Col md="2">
              <label>To:</label>
              <DateSelect setParentData={updateEndDate} showTime={true} minDate={startDate ? startDate : null} />
            </Col>
            <Col md="2">
              <label>Sport:</label>
              <SportSelect sports={sports} updateSport={updateSelectedSport} selectedItem={JSON.stringify(selectedSport)} />
            </Col>
            <Col md="2" lg="2" xl="2" style={{ marginBottom: 'auto', marginTop: 'auto', width: '200px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input type="checkbox" id="sorted" checked={sorted} onChange={updateSorted} />
                <label style={{ paddingLeft: '3px' }} htmlFor="region">
                  Sorted Competitions
                </label>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <input type="text" id="sortFieldFrom" onChange={updateSortField} disabled={!sorted} style={{ marginTop: '5px' }} />{' '}
                <label style={{ paddingLeft: '3px', marginTop: '5px' }} htmlFor="sortFieldFrom">
                  Sort from
                </label>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <input type="text" id="sortFieldTo" onChange={updateSortField} disabled={!sorted} style={{ marginTop: '5px' }} />{' '}
                <label style={{ paddingLeft: '3px', marginTop: '5px' }} htmlFor="sortFieldTo">
                  Sort to
                </label>
              </div>
            </Col>
            <Col md="2 mt-4 text-right">
              <Button variant="primary" onClick={e => fetchData(e, controller)} disabled={disableSearch}>
                {icon} {text}
              </Button>
            </Col>
            <Col md="2 mt-4 text-center">
              <Button variant="success" onClick={resolveConflicts} disabled={disableMatch}>
                {matchIcon} {matchText}
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="strpied-tabled-with-hover">
        <Card.Body>
          <Row>
            <Col md="6">
              <h4>Uni Fixtures:</h4>
            </Col>
            <Col md="6">
              <h4>Provider Fixtures:</h4>
            </Col>
          </Row>
          <Row className="justify-content-md-end">
            <Col md="auto">
              <SelectedCounter all={allcounter} selected={selectedcounter}></SelectedCounter>
            </Col>
            <Col md="auto">
              <TableCounters all={allcounter} filtered={filteredcounter}></TableCounters>
            </Col>
            <Col md="auto">
              <Button variant="secondary" onClick={deselectAllRows}>
                Deselect All
              </Button>
            </Col>
          </Row>
          <BootstrapTable key={tableKey} bootstrap4 keyField="f_key" data={tableData} columns={columns} pagination={paginationFactory(paginationOptions)} filter={filterFactory({ afterFilter })} selectRow={selectRow} hover />
        </Card.Body>
      </Card>
    </>
  )
}
