const _ = require('lodash')
import React, { useState, useEffect } from 'react'
import { Button, Modal, Form, Col } from 'react-bootstrap'
import NotificationAlert from 'react-notification-alert'

import { postData } from 'utils/server'
import { getAuthorizationHeader, notificationOptions } from 'utils/helpers'
import SportSelect from 'components/Inputs/SportSelect'

function CreateUniComps({ showModal, signalCloseModal, sports }) {
  const notificationAlertRef = React.useRef(null)

  const [show, setShow] = useState(false)
  const [selectedSport, setSelectedSport] = useState({})

  const handleClose = () => {
    setShow(false)
    signalCloseModal(false)
  }

  const handleSubmit = async () => {
    let participantName = document.getElementById('participant-name').value
    if (_.isEmpty(selectedSport) || _.isEmpty(participantName)) {
      notificationAlertRef.current.notificationAlert(notificationOptions('Error! Cannot add participant.', 'danger'))
      return
    }
    const participant = {
      name: participantName,
      sport: selectedSport.name,
      sport_id: selectedSport.id
    }
    let response = await postData(`/api/unis/participants`, participant, { Authorization: getAuthorizationHeader() })
    if (response == null) {
      notificationAlertRef.current.notificationAlert(notificationOptions('Error! Cannot add participant.', 'danger'))
      return
    }
    notificationAlertRef.current.notificationAlert(notificationOptions('Success! Participant Added.', 'success'))
    setShow(false)
    signalCloseModal(true)
  }

  const updateSelectedSport = selSport => {
    setSelectedSport(selSport)
  }

  useEffect(() => {
    setShow(showModal)
  }, [showModal])
  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Col md="12">
              <Form.Group>
                <label>Select Sport</label>
                <SportSelect sports={sports} updateSport={updateSelectedSport} />
              </Form.Group>
            </Col>
            <br />
            <Col md="12">
              <Form.Group>
                <label>Participant name</label>
                <Form.Control id="participant-name" name="participant-name" type="text" placeholder="Type participant name"></Form.Control>
              </Form.Group>
            </Col>
            <div className="clearfix"></div>
          </Form>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Create Participant
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CreateUniComps
