const _ = require('lodash')
import React, { useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import NotificationAlert from 'react-notification-alert'
import { Card, Container, Row, Col, Button, ButtonGroup } from 'react-bootstrap'

import { postData } from 'utils/server'
import { getAuthorizationHeader } from 'utils/helpers'
import TableCounters from 'components/UniObject/TableCounters'
import { data } from 'jquery'
import moment from 'moment'

let idFilter
let sportFilter = () => {};
let regionFilter
let competitionFilter
let homeFilter
let awayFilter

function ProviderFixtures({ providerId, dateOptions, startDate, endDate, sportId, allreadyMatched, selectOptions, forceUpdate }) {
  const controller = new AbortController()

  const notificationAlertRef = React.useRef(null)
  const [allTableData, setAllData] = useState([])
  const [tableData, setData] = useState([])
  const [allcounter, setAllCounter] = useState(0)
  const [filteredcounter, setFilteredcounter] = useState(0)

  const [searchSport, setSearchSport] = useState(null)
  const [searchRegion, setSearchRegion] = useState(null)
  const [searchCompetition, setSearchCompetition] = useState(null)
  const [searchHome, setSearchHome] = useState(null)
  const [searchAway, setSearchAway] = useState(null)

  const [matched, setMatched] = useState(false)
  const [filterAll, setFilterAll] = useState(true)
  const [regionUni, setRegionUni] = useState(false)
  const [competitionUni, setCompetitionUni] = useState(false)
  const [homeUni, setHomeUni] = useState(false)
  const [awayUni, setAwayUni] = useState(false)
  const [readyToMatch, setReadyToMatch] = useState(null)

  const [backdrop, setBackdrop] = useState(false)

  const [rowSelected, setRowSelected] = useState([])

  const paginationOptions = {
    disablePageTitle: true,
    showTotal: false,
    sizePerPageList: [
      {
        text: '25',
        value: 20
      },
      {
        text: '50',
        value: 50
      }
    ]
  }

  const Info = props => {
    return props.item.uni_id ? (
      <span title={props.item.uni_id} style={{ cursor: 'help', color: 'blue' }}>
        {props.item.eventId}
      </span>
    ) : (
      <span title={props.item.uni_id}>{props.item.eventId}</span>
    )
  }

  const Sport = props => {
    return (
      <Row className="cell-row">
        <span title={props.item.sportName}>{props.item.sportName}</span>
        <Button
          title="Filter Provider Sports"
          className="btn-simple btn-link p-1 btn btn-warning cell-item"
          onClick={e => {
            setSearchSport(props.item.sportName)
            e.stopPropagation()
          }}
        >
          <i className="fas fa-filter" aria-hidden="true" />
        </Button>
        <Button
          title="Search in Uni Sports"
          className="btn-simple btn-link p-1 btn btn-info cell-item"
          onClick={e => {
            selectOptions.searchUniHandler({ col: 'sport', value: props.item.sportName })
            e.stopPropagation()
          }}
        >
          <i className="fa fa-search" aria-hidden="true" />
        </Button>
      </Row>
    )
  }

  const Region = props => {
    return props.item.pr_uni_id ? (
      <Row className="cell-row">
        <span title={props.item.pr_uni_id + ':' + props.item.ur_name} style={{ cursor: 'help', color: 'green' }}>
          {props.item.region}
        </span>
        <Button
          title="Filter Provider Regions by Name"
          className="btn-simple btn-link p-1 btn btn-warning cell-item"
          onClick={e => {
            setSearchRegion(props.item.region)
            e.stopPropagation()
          }}
        >
          <i className="fas fa-filter" aria-hidden="true" />
        </Button>
      </Row>
    ) : (
      <Row className="cell-row">
        <span title={props.item.region_id} style={{ cursor: 'help', color: 'red' }}>
          {props.item.region}
        </span>
        <Button
          title="Filter Provider Regions by Name"
          className="btn-simple btn-link p-1 btn btn-warning cell-item"
          onClick={e => {
            setSearchRegion(props.item.region)
            e.stopPropagation()
          }}
        >
          <i className="fas fa-filter" aria-hidden="true" />
        </Button>
      </Row>
    )
  }

  const Competition = props => {
    return props.item.pc_uni_id ? (
      <Row className="cell-row">
        <span title={props.item.pc_uni_id + ':' + props.item.uc_name} style={{ cursor: 'help', color: 'green' }}>
          {props.item.competition}
        </span>
        <Button
          title="Filter Provider Competitions by Name"
          className="btn-simple btn-link p-1 btn btn-warning cell-item"
          onClick={e => {
            setSearchCompetition(props.item.competition)
            e.stopPropagation()
          }}
        >
          <i className="fas fa-filter" aria-hidden="true" />
        </Button>
      </Row>
    ) : (
      <Row className="cell-row">
        <span title={props.item.competition_id} style={{ cursor: 'help', color: 'red' }}>
          {props.item.competition}
        </span>
        <Button
          title="Filter Provider Competitions by Name"
          className="btn-simple btn-link p-1 btn btn-warning cell-item"
          onClick={e => {
            setSearchCompetition(props.item.competition)
            e.stopPropagation()
          }}
        >
          <i className="fas fa-filter" aria-hidden="true" />
        </Button>
      </Row>
    )
  }

  const HomeParticipant = props => {
    return props.item.pp1_uni_id ? (
      <Row className="cell-row">
        <span title={props.item.pp1_uni_id + ':' + props.item.up1_name} style={{ cursor: 'help', color: 'green' }}>
          {props.item.home}
        </span>
        <Button
          title="Filter Provider Participant by Name"
          className="btn-simple btn-link p-1 btn btn-warning cell-item"
          onClick={e => {
            setSearchHome(props.item.home)
            e.stopPropagation()
          }}
        >
          <i className="fas fa-filter" aria-hidden="true" />
        </Button>
      </Row>
    ) : (
      <Row className="cell-row">
        <span title={props.item.home_id} style={{ cursor: 'help', color: 'red' }}>
          {props.item.home}
        </span>
        <Button
          title="Filter Provider Participant by Name"
          className="btn-simple btn-link p-1 btn btn-warning cell-item"
          onClick={e => {
            setSearchHome(props.item.home)
            e.stopPropagation()
          }}
        >
          <i className="fas fa-filter" aria-hidden="true" />
        </Button>
      </Row>
    )
  }

  const AwayParticipant = props => {
    return props.item.pp2_uni_id ? (
      <Row className="cell-row">
        <span title={props.item.pp2_uni_id + ':' + props.item.up2_name} style={{ cursor: 'help', color: 'green' }}>
          {props.item.away}
        </span>
        <Button
          title="Filter Provider Participant by Name"
          className="btn-simple btn-link p-1 btn btn-warning cell-item"
          onClick={e => {
            setSearchAway(props.item.away)
            e.stopPropagation()
          }}
        >
          <i className="fas fa-filter" aria-hidden="true" />
        </Button>
      </Row>
    ) : (
      <Row className="cell-row">
        <span title={props.item.away_id} style={{ cursor: 'help', color: 'red' }}>
          {props.item.away}
        </span>
        <Button
          title="Filter Provider Participant by Name"
          className="btn-simple btn-link p-1 btn btn-warning cell-item"
          onClick={e => {
            setSearchAway(props.item.away)
            e.stopPropagation()
          }}
        >
          <i className="fas fa-filter" aria-hidden="true" />
        </Button>
      </Row>
    )
  }

  const handleOnSelect = (row, isSelect) => {
    if (!row) return
    if (isSelect) {
      setRowSelected([row.tsm])
      selectOptions.handler(row)
    } else {
      setRowSelected([])
      selectOptions.handler(null)
    }
  }

  const selectRow = {
    // clickToSelect: true,
    // mode: 'radio',
    // style: { backgroundColor: selectOptions.color },
    mode: 'radio',
    clickToSelect: true,
    hideSelectColumn: true,
    bgColor: selectOptions.color,
    onSelect: handleOnSelect,
    selected: rowSelected
  }

  const columns = [
    {
      dataField: 'eventId',
      text: 'Event ID',
      filter: textFilter({
        getFilter: filter => {
          idFilter = filter
        }
      }),
      sort: true,
      formatter: (cellContent, row) => <Info item={row} />
    },
    {
      dataField: 'region',
      text: 'Region',
      filter: textFilter({
        delay: 600, // default is 500ms
        style: {
          // backgroundColor: 'yellow'
        },
        className: 'test-classname',
        // placeholder: 'Custom PlaceHolder',
        defaultValue: searchRegion ? searchRegion : '',
        getFilter: filter => {
          regionFilter = filter
        }
      }),
      sort: true,
      formatter: (cellContent, row) => <Region item={(cellContent, row)} />
    },
    {
      dataField: 'competition',
      text: 'Competition',
      filter: textFilter({
        delay: 600, // default is 500ms
        style: {
          // backgroundColor: 'yellow'
        },
        className: 'test-classname',
        // placeholder: 'Custom PlaceHolder',
        defaultValue: searchCompetition ? searchCompetition : '',
        getFilter: filter => {
          competitionFilter = filter
        }
      }),
      sort: true,
      formatter: (cellContent, row) => <Competition item={(cellContent, row)} />
    },
    {
      dataField: 'home',
      text: 'Home',
      filter: textFilter({
        delay: 600, // default is 500ms
        style: {
          // backgroundColor: 'yellow'
        },
        className: 'test-classname',
        // placeholder: 'Custom PlaceHolder',
        defaultValue: searchHome ? searchHome : '',
        getFilter: filter => {
          homeFilter = filter
        }
      }),
      sort: true,
      formatter: (cellContent, row) => <HomeParticipant item={(cellContent, row)} />
    },
    {
      dataField: 'away',
      text: 'Away',
      filter: textFilter({
        delay: 600, // default is 500ms
        style: {
          // backgroundColor: 'yellow'
        },
        className: 'test-classname',
        // placeholder: 'Custom PlaceHolder',
        defaultValue: searchAway ? searchAway : '',
        getFilter: filter => {
          awayFilter = filter
        }
      }),
      sort: true,
      formatter: (cellContent, row) => <AwayParticipant item={(cellContent, row)} />
    },
    {
      dataField: 'timestamp',
      text: 'Start Date',
      sort: true
    },
    {
      dataField: 'action',
      isDummyField: true,
      text: '',
      editable: false,
      headerClasses: 'match-column',
      formatter: (cellContent, row) => (
        <>
          <MatchButton item={row} />
          <CopyButton item={row} />
        </>
      )
    }
  ]
  const columnsAll = [
    {
      dataField: 'sportName',
      text: 'sport',
      filter: textFilter({
        delay: 600, // default is 500ms
        style: {
          // backgroundColor: 'yellow'
        },
        className: 'test-classname',
        // placeholder: 'Custom PlaceHolder',
        defaultValue: searchSport ? searchSport : '',
        getFilter: filter => {
          sportFilter = filter
        }
      }),
      sort: true,
      formatter: (cellContent, row) => <Sport item={row} />
    },
    {
      dataField: 'eventId',
      text: 'Event ID',
      filter: textFilter({
        getFilter: filter => {
          idFilter = filter
        }
      }),
      sort: true,
      formatter: (cellContent, row) => <Info item={row} />
    },
    {
      dataField: 'region',
      text: 'Region',
      filter: textFilter({
        delay: 600, // default is 500ms
        style: {
          // backgroundColor: 'yellow'
        },
        className: 'test-classname',
        // placeholder: 'Custom PlaceHolder',
        defaultValue: searchRegion ? searchRegion : '',
        getFilter: filter => {
          regionFilter = filter
        }
      }),
      sort: true,
      formatter: (cellContent, row) => <Region item={(cellContent, row)} />
    },
    {
      dataField: 'competition',
      text: 'Competition',
      filter: textFilter({
        delay: 600, // default is 500ms
        style: {
          // backgroundColor: 'yellow'
        },
        className: 'test-classname',
        // placeholder: 'Custom PlaceHolder',
        defaultValue: searchCompetition ? searchCompetition : '',
        getFilter: filter => {
          competitionFilter = filter
        }
      }),
      sort: true,
      formatter: (cellContent, row) => <Competition item={(cellContent, row)} />
    },
    {
      dataField: 'home',
      text: 'Home',
      filter: textFilter({
        delay: 600, // default is 500ms
        style: {
          // backgroundColor: 'yellow'
        },
        className: 'test-classname',
        // placeholder: 'Custom PlaceHolder',
        defaultValue: searchHome ? searchHome : '',
        getFilter: filter => {
          homeFilter = filter
        }
      }),
      sort: true,
      formatter: (cellContent, row) => <HomeParticipant item={(cellContent, row)} />
    },
    {
      dataField: 'away',
      text: 'Away',
      filter: textFilter({
        delay: 600, // default is 500ms
        style: {
          // backgroundColor: 'yellow'
        },
        className: 'test-classname',
        // placeholder: 'Custom PlaceHolder',
        defaultValue: searchAway ? searchAway : '',
        getFilter: filter => {
          awayFilter = filter
        }
      }),
      sort: true,
      formatter: (cellContent, row) => <AwayParticipant item={(cellContent, row)} />
    },
    {
      dataField: 'timestamp',
      text: 'Start Date',
      sort: true
    },
    {
      dataField: 'action',
      isDummyField: true,
      text: '',
      editable: false,
      headerClasses: 'match-column',
      formatter: (cellContent, row) => (
        <>
          <MatchButton item={row} />
          <CopyButton item={row}></CopyButton>
        </>
      )
    }
  ]

  const clickMatchHandler = e => {
    e.preventDefault()
    selectOptions.matchHandler()
    e.stopPropagation()
  }

  const clickCloneHandler = (e, fixture) => {
    e.preventDefault()
    selectOptions.cloneHandler(fixture)
    e.stopPropagation()
  }

  const MatchButton = props => {
    return (
      <Button className="btn-simple btn-link p-1" type="button" variant={props.item.eventId != selectOptions.enableMatch ? 'dark' : 'success'} disabled={props.item.eventId == selectOptions.enableMatch ? false : true} title={'Match Event'} onClick={e => clickMatchHandler(e)}>
        <i className="fa fa-paper-plane"></i>
      </Button>
    )
  }

  const CopyButton = props => {
    return (
      <Button className="btn-simple btn-link p-1" type="button" variant={'info'} title={'Clone Event'} onClick={e => clickCloneHandler(e, props.item)}>
        <i className="fa fa-clone"></i>
      </Button>
    )
  }

  const sleep = ms =>
    new Promise(resolve => {
      setTimeout(resolve, ms)
    })

  const fetchData = async controller => {
    if (providerId == null || startDate == null || sportId == null) return
    setBackdrop(true)
    clearMatching(new Event('Clear Matching'))
    let filters = {
      provider_id: providerId,
      sport_id: sportId,
      date_option: dateOptions,
      start_date: startDate,
      end_date: endDate,
      matched: allreadyMatched
    }
    let uri = '/api/providers/fixtures/matched'
    let response = await postData(uri, filters, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (response == null) return
    let tableData = []
    for (let fixture of response.data) {
      fixture.tsm = fixture.eventId + ':' + new Date()
      let jsDate = new Date(fixture.startDate)
      fixture.timestamp = moment(jsDate).format('DD/MM/YYYY HH:MM') //.split(',')[1]
      fixture.home = fixture.participants[0].name
      fixture.away = fixture.participants[1].name
      tableData.push(fixture)
    }
    setAllCounter(response.data.length)
    setAllData(tableData)
    setData(tableData)

    let allfitlerPrev = filterAll
    let matchedPrev = matched

    sleep(5000)
    await preFilterData(allfitlerPrev, matchedPrev, tableData)
    await filterFixture(tableData)
    setBackdrop(false)
  }

  const preFilterData = async (allfilter, matchedfilter, data) => {
    sleep(2000)
    // if (data) {
    //   setAllData([])
    // }

    let dataToFilter = data ? data : allTableData

    let filteredData = []
    for (const key in dataToFilter) {
      if (Object.hasOwnProperty.call(dataToFilter, key)) {
        const line = dataToFilter[key]
        if (allfilter == false) {
          if (matchedfilter == true) {
            if (line.uni_id && line.uni_id != '' && line.uni_id != ' ') filteredData.push(line)
          } else {
            if (!line.uni_id || line.uni_id == '' || line.uni_id == ' ') filteredData.push(line)
          }
        } else {
          filteredData.push(line)
        }
      }
    }
    setData(filteredData)
    return true
  }

  function afterFilter(newResult, newFilters) {
    setFilteredcounter(newResult.length)
  }

  const clearToggleButtons = async () => {
    setMatched(false)
    setFilterAll(false)
  }

  const handleMatchedFilter = async () => {
    clearToggleButtons()
    setMatched(true)
    await preFilterData(false, true)
  }

  const handleUnmatchedFilter = async () => {
    clearToggleButtons()
    setMatched(false)
    await preFilterData(false, false)
  }

  const handleFilterAll = async () => {
    clearToggleButtons()
    setFilterAll(true)
    await preFilterData(true, false)
  }

  const handleAll = async () => {
    let allCur = filterAll

    if (allCur == false) {
      setRegionUni(allCur)
      setCompetitionUni(allCur)
      setHomeUni(allCur)
      setAwayUni(allCur)
    }
    setFilterAll(!allCur)
    //await filterFixture()
  }

  const handleRegionUni = async () => {
    let regionUniCur = regionUni
    setRegionUni(!regionUniCur)
    //await filterFixture()
  }

  const handleCompetitionUni = async () => {
    let competitionUniCur = competitionUni
    setCompetitionUni(!competitionUniCur)
    //await filterFixture()
  }

  const handleHomeUni = async () => {
    let homeUniCur = homeUni
    setHomeUni(!homeUniCur)
    //await filterFixture()
  }

  const handleAwayUni = async () => {
    let awayUniCur = awayUni
    setAwayUni(!awayUniCur)
    //await filterFixture()
  }

  const filterFixture = async data => {
    sleep(2000)
    // if (data) {
    //   setAllData([])
    // }

    let dataToFilter = data ? data : allTableData

    let filteredData = []
    for (const key in dataToFilter) {
      if (Object.hasOwnProperty.call(dataToFilter, key)) {
        const line = dataToFilter[key]
        let toPush = true
        if (!filterAll) {
          if (regionUni && !line.pr_uni_id) toPush = false
          if (competitionUni && !line.pc_uni_id) toPush = false
          if (homeUni && !line.pp1_uni_id) toPush = false
          if (awayUni && !line.pp2_uni_id) toPush = false

          if (!regionUni && line.pr_uni_id) toPush = false
          if (!competitionUni && line.pc_uni_id) toPush = false
          if (!homeUni && line.pp1_uni_id) toPush = false
          if (!awayUni && line.pp2_uni_id) toPush = false
        }

        if (toPush) filteredData.push(line)
      }
    }
    setData(filteredData)
    return true
  }

  const clearFilters = () => {
    setSearchRegion(null)
    setSearchCompetition(null)
    setSearchHome(null)
    setSearchAway(null)
    setSearchSport(null)
    idFilter('')
    sportFilter('')
    regionFilter('')
    competitionFilter('')
    homeFilter('')
    awayFilter('')
  }

  const clearMatching = e => {
    e.preventDefault()
    setRowSelected([])
    selectOptions.handler(null)
  }

  useEffect(() => {
    let matchedF = null
    let tmpdata = tableData.map(f => {
      f.tsm = f.eventId + ':' + new Date()
      return f
    })
    if (selectOptions.enableMatch) {
      console.log('enableMatch: ', selectOptions.enableMatch)
      matchedF = tableData.find(f => f.eventId == selectOptions.enableMatch)
      handleOnSelect(matchedF, true)
    } else {
      if (rowSelected.length > 0) {
        let prevSelect = tableData.find(f => rowSelected[0].split(':')[0] == f.eventId)
        handleOnSelect(prevSelect, true)
      }
      console.log('matching Aborded ')
    }

    filterFixture(tmpdata)
  }, [selectOptions.enableMatch])

  useEffect(() => {
    filterFixture()
    console.log('regionUni: ', regionUni, 'competitionUni: ', competitionUni, 'homeUni: ', homeUni, 'awayUni: ', awayUni)
  }, [filterAll, regionUni, competitionUni, homeUni, awayUni])

  useEffect(() => {
    fetchData(controller)
  }, [providerId, startDate, sportId, forceUpdate])

  useEffect(() => {
    return () => {
      controller.abort()
    }
  }, [])

  return (
    <>
      <div id="backdrop" style={{ zIndex: backdrop ? 10000 : -10000, display: backdrop ? '' : 'none' }}></div>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div style={{ display: backdrop ? 'none' : 'flex' }}>
        <div style={{ width: '100%' }}>
          <div style={{ width: '100%', height: '50%' }}></div>
          <div style={{ width: '100%', display: 'flex' }}>
            <div style={{ width: 'fit-content', minWidth: '120px' }}>Entities Filters: </div>
            <Button
              className={filterAll ? 'btn btn-success' : 'btn btn-danger'}
              size="sm"
              type="button"
              onClick={e => {
                handleAll(e)
              }}
            >
              All{' '}
            </Button>
            <Button
              className={filterAll ? 'btn btn-default copy-button-disabled' : regionUni ? 'btn btn-success' : 'btn btn-danger'}
              size="sm"
              type="button"
              disabled={filterAll}
              onClick={e => {
                handleRegionUni(e)
              }}
            >
              RegionUni{' '}
            </Button>
            <Button
              className={filterAll ? 'btn btn-default copy-button-disabled' : competitionUni ? 'btn btn-success' : 'btn btn-danger'}
              size="sm"
              type="button"
              disabled={filterAll}
              onClick={e => {
                handleCompetitionUni(e)
              }}
            >
              CompetitionUni{' '}
            </Button>
            <Button
              className={filterAll ? 'btn btn-default copy-button-disabled' : homeUni ? 'btn btn-success' : 'btn btn-danger'}
              size="sm"
              type="button"
              disabled={filterAll}
              onClick={e => {
                handleHomeUni(e)
              }}
            >
              HomeUni{' '}
            </Button>
            <Button
              className={filterAll ? 'btn btn-default copy-button-disabled' : awayUni ? 'btn btn-success' : 'btn btn-danger'}
              size="sm"
              type="button"
              disabled={filterAll}
              onClick={e => {
                handleAwayUni(e)
              }}
            >
              AwayUni{' '}
            </Button>
          </div>
        </div>

        <div style={{ width: '100%' }}>
          <div style={{ textAlign: 'right', minHeight: '30px' }}>
            <Button
              style={{ display: rowSelected.length > 0 ? '' : 'none' }}
              className="btn-simple btn-link p-1 btn btn-danger"
              onClick={e => {
                clearMatching(e)
              }}
            >
              <i className="fas fa-times" aria-hidden="true" />
              clearMatching
            </Button>
          </div>
          <div style={{ width: '100%', display: 'flex' }}>
            <div style={{ width: '100%', fontSize: '13px' }}>
              <TableCounters all={allcounter} filtered={filteredcounter}></TableCounters>
            </div>
            <Button
              className="btn-simple btn-link p-1 btn btn-danger"
              onClick={e => {
                clearFilters(e)
              }}
            >
              <i className="fas fa-times" aria-hidden="true" />
            </Button>
          </div>
        </div>
      </div>
      <div style={{ display: backdrop ? 'none' : '' }}>
        <BootstrapTable id="prov-fixtures-table" bootstrap4 keyField="tsm" data={tableData} columns={sportId == 1000 ? columnsAll : columns} pagination={paginationFactory({ sizePerPage: 10 })} filter={filterFactory({ afterFilter })} selectRow={selectRow} />
      </div>
    </>
  )
}
export default ProviderFixtures
