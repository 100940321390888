const _ = require('lodash')
const moment = require('moment')

import React, { useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import NotificationAlert from 'react-notification-alert'
import { Card, Container, Row, Col, Button, ButtonGroup } from 'react-bootstrap'
import DataSelect from 'components/Inputs/generalDataSelect'
import SportSelect from 'components/Inputs/SportSelect'
import DateSelect from 'components/Inputs/DateSelect'
import Swal from 'sweetalert2'

import { postData, getData, delData } from 'utils/server'
import { notificationOptions, getAuthorizationHeader } from 'utils/helpers'
import TableCounters from 'components/UniObject/TableCounters'
import SelectedCounter from 'components/UniObject/SelectedCounter'
import useMousetrap from 'react-hook-mousetrap'

import { unmatchedFixturesValidation } from 'utils/validations'

function UnmatchedFixtures() {
  const controller = new AbortController()

  const notificationAlertRef = React.useRef(null)
  const [disableSearch, setDisableSearch] = useState(true)
  const [disableMatch, setDisableMatch] = useState(true)
  const [icon, setIcon] = useState(<i className="fas fa-search text-primary"></i>)
  const [text, setText] = useState(<span className="text-primary">Search fixtures</span>)
  const [matchIcon, setMatchIcon] = useState(<i className="fas fa-equals text-danger"></i>)
  const [matchText, setMatchText] = useState(<span className="text-danger">Match selected</span>)
  const [tableData, setData] = useState([])
  const [allcounter, setAllCounter] = useState(0)
  const [filteredcounter, setFilteredcounter] = useState(0)
  const [selectedcounter, setSelectedCounter] = useState(0)
  const [allTableData, setAllData] = useState([])
  const [providers, setProviders] = useState([])
  const [sports, setSports] = useState([])
  const [selectedSport, setSelectedSport] = useState(null)
  const [selectedProvider, setSelectedProvider] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [percentage, setPercentage] = useState('')
  const [fixture, setFixture] = useState(null)
  const [checked, setChecked] = useState([])
  const [refreshTable, setRefreshTable] = useState(false)

  const handleMultiSelectedEvents = (events, isSelected) => {
    for (let index = 0; index < events.length; index++) {
      const event = events[index]
      fetchSelected(event, isSelected)
    }
  }

  const fetchSelected = (row, isSelect) => {
    if (isSelect == true) {
      checked.push(allTableData.find(o => o.id == row.id))
      setSelectedCounter(checked.length)
      console.log(checked)
    } else if (isSelect == false) {
      let idToRemove = row.id
      let index = checked
        .map(o => {
          return o.id
        })
        .indexOf(idToRemove)
      checked.splice(index, 1)
      setSelectedCounter(checked.length)
      console.log(checked)
    }
  }

  const selectRow = {
    clickToSelect: true,
    selectColumnPosition: 'right',
    style: { backgroundColor: '#24e1cf1a' },
    mode: 'checkbox',
    onSelect: (ev, selected) => {
      if (ev) fetchSelected(ev, selected)
    },
    hideSelectAll: false,
    onSelectAll: (selected, events) => {
      handleMultiSelectedEvents(events, selected)
    }
  }

  const paginationOptions = {
    disablePageTitle: true,
    showTotal: false,
    sizePerPageList: [
      {
        text: '25',
        value: 20
      },
      {
        text: '50',
        value: 50
      }
    ]
  }

  const provider = async () => {
    return selectedProvider
  }

  const sport = async () => {
    return selectedSport
  }

  const date1 = async () => {
    return startDate
  }

  const date2 = async () => {
    return endDate
  }

  const rating = async () => {
    return percentage
  }

  const checkedList = async () => {
    return checked
  }

  const fetchFilters = async controller => {
    let providers_response = await getData('/api/providers', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (providers_response != null) {
      setProviders(providers_response.data)
    }

    let sports_response = await getData('/api/sports', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (sports_response != null) {
      setSports(sports_response.data)
    }
  }

  const columns = [
    {
      dataField: 'u_id',
      text: 'Event ID',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'u_region',
      text: 'Region',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'u_competition',
      text: 'Competition',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'u_home',
      text: 'Home',
      filter: textFilter(),
      sort: true,
      headerClasses: 'custom-header-home',
      classes: 'custom-cell-home',
      formatter: (cellContent, row) => <div className="text-primary">{cellContent}</div>
    },
    {
      dataField: 'u_away',
      text: 'Away',
      filter: textFilter(),
      sort: true,
      headerClasses: 'custom-header-away',
      classes: 'custom-cell-away',
      formatter: (cellContent, row) => <div className="text-danger">{cellContent}</div>
    },
    {
      dataField: 'u_start_date',
      text: 'Start Date',
      sort: true
    },
    {
      dataField: 'separator',
      text: '',
      headerStyle: { width: '3vw', backgroundColor: 'rgba(143, 211, 254, 0.00)', border: '1px solid #fff !important' },
      style: { backgroundColor: 'rgba(143, 211, 254, 0.00)', borderStyle: 'hidden' },
      headerClasses: 'custom-header-seperator',
      classes: 'custom-cell-seperator',
      formatter: (cellContent, row) => (
        <div className="text-center" style={{ margin: '24px 0px 24px' }}>
          <span className="nc-icon nc-stre-left"></span>
          <span className="nc-icon nc-stre-right"></span>
        </div>
      )
    },
    {
      dataField: 'id',
      text: 'Event ID',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'region',
      text: 'Region',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'competition',
      text: 'Competition',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'home',
      text: 'Home',
      filter: textFilter(),
      sort: true,
      headerClasses: 'custom-header-home',
      classes: 'custom-cell-home',
      formatter: (cellContent, row) => <div className="text-primary">{cellContent}</div>
    },
    {
      dataField: 'away',
      text: 'Away',
      filter: textFilter(),
      sort: true,
      headerClasses: 'custom-header-away',
      classes: 'custom-cell-away',
      formatter: (cellContent, row) => <div className="text-danger">{cellContent}</div>
    },
    {
      dataField: 'start_date',
      text: 'Start Date',
      sort: true
    }
  ]

  const fetchData = async (event, controller) => {
    setChecked([])
    setDisableSearch(true)
    setIcon(<i className="fas fa-spinner fa-spin text-primary"></i>)
    setText(<span className="text-primary">Please wait...</span>)
    let currentProvider = await provider()
    let currentSport = await sport()
    let startDate = await date1()
    let endDate = await date2()
    let percentage = await rating()

    console.log(currentProvider)
    console.log(currentSport)
    console.log(startDate)
    console.log(endDate)
    console.log(percentage)

    let filters = {
      provider_id: currentProvider.value,
      sport_id: currentSport.value,
      from_date: new Date(startDate).getTime(),
      to_date: new Date(endDate).getTime(),
      percentage: parseFloat(percentage)
    }

    let validation = unmatchedFixturesValidation(filters)
    if (validation != null) {
      notificationAlertRef.current.notificationAlert(notificationOptions(validation.message, 'danger'))
      setDisableSearch(true)
      setIcon(<i className="fas fa-search text-primary"></i>)
      setText(<span className="text-primary">Search fixtures</span>)
      return
    }

    let uri = '/api/fixture/unmatchedFixtures'
    let response = await postData(uri, filters, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (response == null) {
      notificationAlertRef.current.notificationAlert(notificationOptions('Error! Something went wrong', 'danger'))
      setDisableSearch(false)
      setIcon(<i className="fas fa-search text-primary"></i>)
      setText(<span className="text-primary">Search fixtures</span>)
      return
    }
    if (_.isEmpty(response.data)) {
      notificationAlertRef.current.notificationAlert(notificationOptions('There are no data available for these options', 'danger'))
      setDisableSearch(false)
      setIcon(<i className="fas fa-search text-primary"></i>)
      setText(<span className="text-primary">Search fixtures</span>)
    }

    let tableData = []
    for (let fixture in response.data) {
      let jsDate1 = new Date(parseInt(response.data[fixture].start_date))
      let jsDate2 = new Date(parseInt(response.data[fixture].u_start_date))
      let f_key = new Date() + '-' + currentProvider.label + '-' + currentSport.label + '-' + fixture
      response.data[fixture].f_key = f_key
      response.data[fixture].start_date = moment(jsDate1).format('MM/DD/YYYY, HH:mm')
      response.data[fixture].u_start_date = moment(jsDate2).format('MM/DD/YYYY, HH:mm')
      tableData.push(response.data[fixture])
    }

    tableData.sort((a, b) => (a.u_start_date > b.u_start_date ? 1 : -1))

    setAllCounter(tableData.length)
    setAllData(tableData)
    setData(tableData)
    setDisableSearch(false)
    setIcon(<i className="fas fa-search text-primary"></i>)
    setText(<span className="text-primary">Search fixtures</span>)
  }

  const matchParticipants = async () => {
    let currentProvider = await provider()
    let currentSport = await sport()
    let currentChecked = await checkedList()
    let htmlData = `${Object.keys(currentChecked).length * 2} Participants ready to match!`

    if (currentChecked.length == 0) {
      notificationAlertRef.current.notificationAlert(notificationOptions('Error. Select some fixtures first', 'danger'))
      return
    }

    setMatchIcon(<i className="fas fa-spinner fa-spin text-danger"></i>)
    setMatchText(<span className="text-danger">Prease wait...</span>)

    Swal.fire({ title: `Match Participants?`, html: htmlData, showDenyButton: true, showConfirmButton: true, confirmButtonText: 'Yes, match them!', denyButtonText: 'Cancel', icon: 'warning', confirmButtonColor: '#87cb16', denyButtonColor: '#ff4a55' }).then(async result => {
      if (result.isConfirmed) {
        let request = []
        for (let i = 0; i < checked.length; i++) {
          request.push({
            provider_id: currentProvider.value,
            sport_id: currentSport.value,
            home_pid: currentChecked[i].home_id,
            away_pid: currentChecked[i].away_id,
            u_home_id: currentChecked[i].u_home_id,
            u_away_id: currentChecked[i].u_away_id
          })
        }
        let result = await postData('/api/matching/participants', request, { Authorization: getAuthorizationHeader() })
        if (result == null) {
          setMatchIcon(<i className="fas fa-equals text-danger"></i>)
          setMatchText(<span className="text-danger">Match selected</span>)
          return
        }
        setChecked([])
        await fetchData(new Event('FaceEvent'), controller)
        console.log(result)
        setMatchIcon(<i className="fas fa-equals text-danger"></i>)
        setMatchText(<span className="text-danger">Match selected</span>)
        if (result != null) {
          Swal.fire({ title: `Participants matched successfully!`, html: '', showDenyButton: false, showConfirmButton: true, confirmButtonText: 'OK', denyButtonText: 'Cancel', icon: 'success', confirmButtonColor: '#87cb16', denyButtonColor: '#ff4a55' }).then(async result => {
            refreshTable == true ? setRefreshTable(false) : setRefreshTable(true)
          })
        } else {
          Swal.fire('Error!', `- Partitipants Entities ${result ? 'matched' : 'cannot be matched!'} <br> -Message for Matching didnt send!`, 'error')
        }
      }
      setMatchIcon(<i className="fas fa-equals text-danger"></i>)
      setMatchText(<span className="text-danger">Match selected</span>)
    })
  }

  const updateSelectedProvider = selProv => {
    setSelectedProvider(selProv)
  }

  const updateStartDate = startDate => {
    setStartDate(startDate)
  }

  const updateEndDate = endDate => {
    setEndDate(endDate)
  }

  const updateSelectedSport = selSport => {
    setSelectedSport(selSport)
  }

  const changePercentage = e => {
    e.preventDefault()
    setPercentage(e.target.value)
  }

  function afterFilter(newResult, newFilters) {
    setFilteredcounter(newResult.length)
  }

  useEffect(() => {
    if (fixture == null) return
    setDisableMatchFixtures(false)
  }, [fixture])

  useEffect(() => {
    if (selectedSport == null || selectedProvider == null || startDate == null || endDate == null) return
    if (percentage.length == 0 || isNaN(parseFloat(percentage)) || startDate == 'Invalid date' || endDate == 'Invalid date') {
      setDisableSearch(true)
      return
    }
    setDisableSearch(false)
  }, [selectedProvider, selectedSport, startDate, endDate, percentage])

  useEffect(() => {
    fetchFilters(controller)
    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    setDisableMatch(true)
    if (checked.length > 0) setDisableMatch(false)
    setSelectedCounter(checked.length)
  }, [checked.length])

  return (
    <>
      <Card className="strpied-tabled-with-hover">
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <Card.Body>
          <Row>
            <Col md="2">
              <label>From:</label>
              <DateSelect setParentData={updateStartDate} showTime={true} />
            </Col>
            <Col md="2">
              <label>To:</label>
              <DateSelect setParentData={updateEndDate} showTime={true} minDate={startDate ? startDate : null} />
            </Col>
            <Col md="2">
              <label>Provider:</label>
              <DataSelect data={providers} updateItem={updateSelectedProvider} selectedItem={selectedProvider} />
            </Col>
            <Col md="2">
              <label>Sport:</label>
              <SportSelect sports={sports} updateSport={updateSelectedSport} selectedItem={JSON.stringify(selectedSport)} />
            </Col>
            <Col md="1" style={{ textAlign: 'right' }}>
              <label>Match Rating:</label>
              <div>
                <input style={{ width: '80px' }} defaultValue={percentage} onChange={changePercentage} placeholder="0.0"></input>
              </div>
            </Col>
            <Col md="3" style={{ marginTop: '2vh' }}>
              <Row>
                <Col md="6">
                  <Button variant="primary" onClick={e => fetchData(e, controller)} disabled={disableSearch}>
                    {icon} {text}
                  </Button>
                </Col>
                <Col md="6">
                  <Button variant="danger" onClick={matchParticipants} disabled={disableMatch}>
                    {matchIcon} {matchText}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="strpied-tabled-with-hover">
        <Card.Body>
          <Row>
            <Col md="6">
              <h4>Uni Fixtures:</h4>
            </Col>
            <Col md="6">
              <h4>Provider Fixtures:</h4>
            </Col>
          </Row>
          <Row className="justify-content-md-end">
            <Col md="auto">
              <SelectedCounter all={allcounter} selected={selectedcounter}></SelectedCounter>
            </Col>
            <Col md="auto">
              <TableCounters all={allcounter} filtered={filteredcounter}></TableCounters>
            </Col>
          </Row>
          <BootstrapTable bootstrap4 keyField="f_key" data={tableData} columns={columns} pagination={paginationFactory({ sizePerPage: 10 })} filter={filterFactory({ afterFilter })} selectRow={selectRow} hover />
        </Card.Body>
      </Card>
    </>
  )
}

export default UnmatchedFixtures
