import React, { useEffect, useState } from 'react'
import Select from 'react-select'

function SettlementOriginSelect({ settlementOrigins, selectedItem, updateSettlementOrigin }) {
  const [initItem, setInitItem] = useState(selectedItem)
  const handleChange = selectedItem => {
    updateSettlementOrigin(selectedItem)
    setInitItem(selectedItem)
  }
  settlementOrigins.map(el => {
    ;(el.value = el.id), (el.label = el.description)
  })

  useEffect(() => {
    if (selectedItem) {
      let item = null
      if (typeof selectedItem == 'string') item = JSON.parse(selectedItem)
      if (typeof selectedItem == 'object') item = selectedItem
      if (item) {
        //console.log('UniSelect->useEffect->', item)
        setInitItem(item)
      }
    }
  }, [selectedItem])

  return <Select className="settlement-select" name="settlement-select" value={initItem} options={settlementOrigins} placeholder="Select Settlement Origin.." onChange={handleChange} />
}

export default SettlementOriginSelect
