const _ = require('lodash')
import React, { useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import NotificationAlert from 'react-notification-alert'
import { Button, ButtonGroup } from 'react-bootstrap'

import { postData } from 'utils/server'
import { getAuthorizationHeader } from 'utils/helpers'
import TableCounters from 'components/UniObject/TableCounters'

function ProviderFixtures({ providerId, startDate, sportId, selectOptions, forceUpdate }) {
  const controller = new AbortController()

  const notificationAlertRef = React.useRef(null)
  const [allTableData, setAllData] = useState([])
  const [tableData, setData] = useState([])
  const [allcounter, setAllCounter] = useState(0)
  const [filteredcounter, setFilteredcounter] = useState(0)

  const [matched, setMatched] = useState(false)
  const [filterAll, setFilterAll] = useState(true)

  const paginationOptions = {
    disablePageTitle: true,
    showTotal: false,
    sizePerPageList: [
      {
        text: '25',
        value: 20
      },
      {
        text: '50',
        value: 50
      }
    ]
  }

  const Info = props => {
    return props.item.uni_id ? (
      <span title={props.item.uni_id} style={{ cursor: 'help', color: 'blue' }}>
        {props.item.eventId}
      </span>
    ) : (
      <span title={props.item.uni_id}>{props.item.eventId}</span>
    )
  }

  const selectRow = {
    clickToSelect: true,
    mode: 'radio',
    style: { backgroundColor: selectOptions.color },
    onSelect: selectOptions.handler
  }

  const columns = [
    {
      dataField: 'eventId',
      text: 'Event ID',
      filter: textFilter(),
      sort: true,
      formatter: (cellContent, row) => <Info item={row} />
    },
    {
      dataField: 'region',
      text: 'Region',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'competition',
      text: 'Competition',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'home',
      text: 'Home',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'away',
      text: 'Away',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'timestamp',
      text: 'Start Date',
      sort: true
    }
  ]

  const sleep = ms =>
    new Promise(resolve => {
      setTimeout(resolve, ms)
    })

  const fetchData = async controller => {
    if (providerId == null || startDate == null || sportId == null) return
    let filters = {
      provider_id: providerId,
      sport_id: sportId,
      start_date: startDate
    }
    let uri = '/api/providers/fixtures/matching-extra'
    let response = await postData(uri, filters, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (response == null) return
    let tableData = []
    for (let fixture of response.data) {
      let jsDate = new Date(fixture.startDate)
      fixture.timestamp = jsDate.toLocaleString('en-US').split(',')[1]
      fixture.home = fixture.participants[0].name
      fixture.away = fixture.participants[1].name
      tableData.push(fixture)
    }
    setAllCounter(response.data.length)
    setAllData(tableData)
    setData(tableData)

    let allfitlerPrev = filterAll
    let matchedPrev = matched

    sleep(5000)
    await preFilterData(allfitlerPrev, matchedPrev, tableData)
  }

  const preFilterData = async (allfilter, matchedfilter, data) => {
    sleep(2000)
    // if (data) {
    //   setAllData([])
    // }

    let dataToFilter = data ? data : allTableData

    let filteredData = []
    for (const key in dataToFilter) {
      if (Object.hasOwnProperty.call(dataToFilter, key)) {
        const line = dataToFilter[key]
        if (allfilter == false) {
          if (matchedfilter == true) {
            if (line.uni_id && line.uni_id != '' && line.uni_id != ' ') filteredData.push(line)
          } else {
            if (!line.uni_id || line.uni_id == '' || line.uni_id == ' ') filteredData.push(line)
          }
        } else {
          filteredData.push(line)
        }
      }
    }
    setData(filteredData)
    return true
  }

  function afterFilter(newResult, newFilters) {
    setFilteredcounter(newResult.length)
  }

  const clearToggleButtons = async () => {
    setMatched(false)
    setFilterAll(false)
  }

  const handleMatchedFilter = async () => {
    clearToggleButtons()
    setMatched(true)
    await preFilterData(false, true)
  }

  const handleUnmatchedFilter = async () => {
    clearToggleButtons()
    setMatched(false)
    await preFilterData(false, false)
  }

  const handleFilterAll = async () => {
    clearToggleButtons()
    setFilterAll(true)
    await preFilterData(true, false)
  }

  useEffect(() => {
    fetchData(controller)
  }, [providerId, startDate, sportId, forceUpdate])

  useEffect(() => {
    return () => {
      controller.abort()
    }
  }, [])

  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '100%' }}>
          <TableCounters all={allcounter} filtered={filteredcounter}></TableCounters>
        </div>
        <div style={{ width: '300px', textAlign: 'right' }}>
          <ButtonGroup size="sm" aria-label="Basic example">
            <Button variant={filterAll ? 'success' : 'danger'} onClick={handleFilterAll}>
              All
            </Button>
            <Button variant={matched ? 'success' : 'danger'} onClick={handleMatchedFilter}>
              Matched
            </Button>
            <Button variant={filterAll ? 'danger' : !matched ? 'success' : 'danger'} onClick={handleUnmatchedFilter}>
              Unmatched
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <BootstrapTable bootstrap4 keyField="eventId" data={tableData} columns={columns} pagination={paginationFactory({ sizePerPage: 10 })} filter={filterFactory({ afterFilter })} selectRow={selectRow} />
    </>
  )
}
export default ProviderFixtures
