export function notificationOptions(message, type) {
  return {
    place: 'tr',
    message: (
      <div>
        <div>{message}</div>
      </div>
    ),
    type: type,
    icon: 'nc-icon nc-bell-55',
    autoDismiss: 7
  }
}

export function validCookieExists() {
  let cookie = localStorage.getItem('s90Cookie')
  if (!cookie) return false
  return true
}

export function getAuthorizationHeader() {
  let cookie = localStorage.getItem('s90Cookie')
  return `Bearer ${cookie}`
}

export function getUserData() {
  let userInfoStorage = localStorage.getItem('userInfo')
  if (!userInfoStorage) return null
  return JSON.parse(userInfoStorage)
}

export function getUserRole() {
  let userInfoStorage = localStorage.getItem('userInfo')
  if (!userInfoStorage) return null
  let role = JSON.parse(userInfoStorage).role_id
  return role
}

export function copyToClipboard(e, textToCopy, notificationAlertRef = null) {
  e.preventDefault()

  if (notificationAlertRef != null) notificationAlertRef.current.notificationAlert(notificationOptions(`UNI ID (${textToCopy}) has been copied to the clipboard!`, 'success'))

  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) return navigator.clipboard.writeText(textToCopy)

  // text area method
  let textArea = document.createElement('textarea')
  textArea.value = textToCopy
  // make the textarea out of viewport
  textArea.style.position = 'fixed'
  textArea.style.left = '-999999px'
  textArea.style.top = '-999999px'
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()
  return new Promise((res, rej) => {
    // here the magic happens
    document.execCommand('copy') ? res() : rej()
    textArea.remove()
  })
}

export function generateRandomColors(amount) {
  let colors = []

  for (let num = 0; num < amount; num++) {
    const letters = '0123456789ABCDEF'
    let color = '#'
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)]
    }
    colors.push(color)
  }

  return colors
}
