const _ = require('lodash')
import React, { useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory, { textFilter, selectFilter, Comparator, numberFilter } from 'react-bootstrap-table2-filter'
import cellEditFactory from 'react-bootstrap-table2-editor'
import NotificationAlert from 'react-notification-alert'
import { Button, ButtonGroup, Card, Container, Row, Col } from 'react-bootstrap'

import { getData, putData } from 'utils/server'
import { getAuthorizationHeader, notificationOptions } from 'utils/helpers'
import TableCounters from 'components/UniObject/TableCounters'
import DateSelect from 'components/Inputs/DateSelect'
import CustomSelect from 'components/Inputs/CustomSelect'
import ParticipantFixtures from 'components/Modals/ParticipantFixtures'
import Swal from 'sweetalert2'
let customFilterFunc

function ProviderParticipants({ providerId, sports, informParent, forceUpdate }) {
  const controller = new AbortController()

  const notificationAlertRef = React.useRef(null)
  const [sportFilters, setSportFilters] = useState({})
  const [allTableData, setAllData] = useState([])
  const [tableData, setData] = useState([])
  const [allcounter, setAllCounter] = useState(0)
  const [filteredcounter, setFilteredcounter] = useState(0)

  const [matched, setMatched] = useState(false)
  const [filterAll, setFilterAll] = useState(true)

  const [operators, setOperators] = useState({ like: Comparator.LIKE, equal: Comparator.EQ, greater: Comparator.GT, less: Comparator.LT })
  const [selectedOperator, setSelectedOperator] = useState('>')
  const [selectedDate, setSelectedDate] = useState(1)
  const [pid, setpid] = useState([])
  const [providerid, setProviderid] = useState([])
  const [sportId, setsportId] = useState([])
  const [showModal, setshowModal] = useState(false)
  const [fixturesData, setFixturesData] = useState([])

  const customFilter = numberFilter({
    placeholder: 'My Custom PlaceHolder', // custom the input placeholder
    className: 'my-custom-text-filter', // custom classname on input
    defaultValue: { number: 1, comparator: selectedOperator }, // default filtering value
    // comparator: operators[selectedOperator], // default is Comparator.LIKE
    comparators: [Comparator.EQ, Comparator.GT, Comparator.LT],
    caseSensitive: false, // default is false, and true will only work when comparator is LIKE
    style: { display: 'inline-grid' }, // custom the style on number filter
    className: 'custom-numberfilter-class', // custom the class on number filter
    comparatorStyle: { backgroundColor: 'antiquewhite', visibility: 'hidden', display: 'none' }, // custom the style on comparator select
    comparatorClassName: 'custom-comparator-class', // custom the class on comparator select
    numberStyle: { backgroundColor: 'cadetblue', margin: '0px', visibility: 'hidden', display: 'none' }, // custom the style on number input/select
    numberClassName: 'custom-number-class', // custom the class on ber input/select
    delay: 500, // how long will trigger filtering after user typing, default is 500 ms
    getFilter: filter => (customFilterFunc = filter), // accept callback function and you can call it for filter programmtically
    id: 'uni_id' // assign a unique value for htmlFor attribute, it's useful when you have same dataField across multiple table in one page
  })

  function customExternalFilter(func, data) {
    if (func) func(data)
  }

  const paginationOptions = {
    disablePageTitle: true,
    showTotal: false,
    sizePerPageList: [
      {
        text: '25',
        value: 20
      },
      {
        text: '50',
        value: 50
      }
    ]
  }

  const columns = [
    {
      dataField: 'pid',
      text: 'Provider ID',
      editable: false,
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'sport_id',
      text: 'Sport',
      editable: false,
      formatter: cell => sportFilters[cell],
      filter: selectFilter({
        options: sportFilters
      }),
      sort: true
    },
    {
      dataField: 'name',
      text: 'name',
      filter: textFilter(),
      editable: false,
      sort: true
    },
    {
      dataField: 'created_tmstmp',
      text: 'Created',
      filter: customFilter,
      editable: false,
      sort: true,
      headerStyle: { width: '5vw' },
      formatter: (cell, row) => {
        return (
          <div className="text-center" style={{ margin: '24px 0px 24px', fontSize: '0.85em' }}>
            {row.created.split('.')[0].split('T')[0] + ', ' + row.created.split('.')[0].split('T')[1]}
          </div>
        )
      }
    },
    {
      dataField: 'created',
      text: 'Created',
      filter: textFilter(),
      editable: false,
      sort: true,
      headerStyle: { width: '5vw' },
      hidden: true,
      formatter: (cell, row) => (
        <div className="text-center" style={{ margin: '24px 0px 24px', fontSize: '0.85em' }}>
          {cell.split('.')[0].split('T')[0] + ', ' + cell.split('.')[0].split('T')[1]}
        </div>
      )
    },
    {
      dataField: 'uni_id',
      text: 'Uni Id',
      validator: newValue => {
        if (newValue != '' && !_.isInteger(parseInt(newValue))) {
          return {
            valid: false,
            message: 'Invalid Uni Id'
          }
        }
      },
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'dm1',
      isDummyField: true,
      editable: false,
      headerStyle: { width: '4.5vw' },
      style: { padding: '0px' },
      text: 'Actions',
      formatter: (cellContent, row) =>
        !row.uni_id ? (
          <div className="actions">
            <Button
              title="Show Fixtures"
              className="btn-simple btn-link p-1"
              type="button"
              variant="success"
              onClick={() => {
                handleFixturesClick(row)
              }}
            >
              <i className="fas fa-tasks"></i>
            </Button>
            <Button
              title="Clone Participant"
              className="btn-simple btn-link p-1"
              type="button"
              variant="info"
              onClick={() => {
                handleCloneClick(row)
              }}
            >
              <i className="fas fa-clone"></i>
            </Button>
          </div>
        ) : (
          <div className="actions">
            <Button
              title="Show Fixtures"
              className="btn-simple btn-link p-1"
              type="button"
              variant="success"
              onClick={() => {
                handleFixturesClick(row)
              }}
            >
              <i className="fas fa-tasks"></i>
            </Button>
          </div>
        )
    }
  ]
  const handleFixturesClick = row => {
    let pid = parseInt(row.pid)
    let sport_id = parseInt(row.sport_id)
    setsportId(sport_id)
    setpid(pid)
    setProviderid(row.provider_id)
    setshowModal(true)
  }

  const handleCloseModal = () => {
    setshowModal(false)
  }

  const errorHandler = err => {
    let errorToShow = err.name + ': ' + err.message
    if (err.response && err.response.data) errorToShow = errorToShow + ' (' + err.response.data + ')'

    notificationAlertRef.current.notificationAlert(notificationOptions('Error info: ' + errorToShow, 'danger'))
  }

  const handleCloneClick = async row => {
    console.log(row)
    Swal.fire({ title: `You are going to Copy Participant,  \n"${row.name}". \nAre you sure?`, showDenyButton: true, showConfirmButton: true, confirmButtonText: 'Yes, Copy it!', denyButtonText: 'Cancel', icon: 'warning', confirmButtonColor: '#87cb16', denyButtonColor: '#ff4a55' }).then(async result => {
      if (result.isConfirmed) {
        if (!row.provider_id || !row.pid) {
          notificationAlertRef.current.notificationAlert(notificationOptions('Error! Cannot clone record.', 'danger'))
          return
        }
        await putData('/api/providers/participants/transfer', { participantId: row.pid, providerId: row.provider_id, sportId: row.sport_id, sport: row.sport }, { Authorization: getAuthorizationHeader() }, errorHandler).then(async response => {
          if (response == null) {
            notificationAlertRef.current.notificationAlert(notificationOptions('Error! Cannot clone record.', 'danger'))
          } else {
            notificationAlertRef.current.notificationAlert(notificationOptions('Record cloned successfully.', 'success'))
            fetchData(controller)
            informParent({ refresh: true })
          }
        })
        return { async: true }
      }
    })
  }
  const sleep = ms =>
    new Promise(resolve => {
      setTimeout(resolve, ms)
    })

  const fetchData = async controller => {
    if (providerId == null) return
    if (providerId != 0) {
      let response = await getData(`/api/providers/${providerId}/participants`, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
      let tableData = []
      if (response != null) tableData = response.data

      tableData.map(item => {
        item.created_tmstmp = new Date(item.created).getTime()
      })

      tableData.sort((a, b) => (a.created < b.created ? 1 : -1))

      setAllCounter(response.data.length)
      setAllData(tableData)
      setData(tableData)

      let allfitlerPrev = filterAll
      let matchedPrev = matched

      sleep(5000)
      await preFilterData(allfitlerPrev, matchedPrev, tableData)
    }
  }

  const changeCellValue = (oldValue, newValue, row, column, done) => {
    row.uni_id = newValue == '' ? null : parseInt(newValue)

    let rowData = { ...row }
    delete rowData.created_tmstmp

    putData(`/api/providers/participants/${row.id}`, rowData, { Authorization: getAuthorizationHeader() }).then(async response => {
      if (response == null) {
        notificationAlertRef.current.notificationAlert(notificationOptions('Error! Cannot edit record.', 'danger'))
        done(false)
      } else {
        done(true)

        let allfitlerPrev = filterAll
        let matchedPrev = matched

        sleep(5000)
        await preFilterData(allfitlerPrev, matchedPrev)
      }
    })
    return { async: true }
  }

  const preFilterData = async (allfilter, matchedfilter, data) => {
    sleep(2000)
    // if (data) {
    //   setAllData([])
    // }

    let dataToFilter = data ? data : allTableData

    let filteredData = []
    for (const key in dataToFilter) {
      if (Object.hasOwnProperty.call(dataToFilter, key)) {
        const line = dataToFilter[key]
        if (allfilter == false) {
          if (matchedfilter == true) {
            if (line.uni_id && line.uni_id != '' && line.uni_id != ' ') filteredData.push(line)
          } else {
            if (!line.uni_id || line.uni_id == '' || line.uni_id == ' ') filteredData.push(line)
          }
        } else {
          filteredData.push(line)
        }
      }
    }
    setData(filteredData)
    return true
  }

  const creteFilters = () => {
    let sportFilters = {}
    for (let sport of sports) sportFilters[sport.id] = sport.name
    setSportFilters(sportFilters)
  }

  function afterFilter(newResult, newFilters) {
    setFilteredcounter(newResult.length)
  }

  const clearToggleButtons = async () => {
    setMatched(false)
    setFilterAll(false)
  }

  const handleMatchedFilter = async () => {
    clearToggleButtons()
    setMatched(true)
    await preFilterData(false, true)
  }

  const handleUnmatchedFilter = async () => {
    clearToggleButtons()
    setMatched(false)
    await preFilterData(false, false)
  }

  const handleFilterAll = async () => {
    clearToggleButtons()
    setFilterAll(true)
    await preFilterData(true, false)
  }

  const updateDate = endDate => {
    setSelectedDate(endDate ? new Date(endDate).getTime() : 0)
    //if ((endDate, customFilterFunc)) customExternalFilter(customFilterFunc, endDate ? endDate : '')
    if ((endDate, customFilterFunc)) customExternalFilter(customFilterFunc, { number: endDate ? new Date(endDate).getTime() : 0, comparator: selectedOperator })
  }

  const updateOperator = operator => {
    let operatorSymbol = ''
    if (operator.label == 'After') operatorSymbol = '>'
    if (operator.label == 'Before') operatorSymbol = '<'
    setSelectedOperator(operatorSymbol)
    if ((selectedDate, customFilterFunc)) customExternalFilter(customFilterFunc, { number: selectedDate ? new Date(selectedDate).getTime() : 0, comparator: operatorSymbol })
  }

  useEffect(() => {
    fetchData(controller)
  }, [forceUpdate])

  useEffect(() => {
    fetchData(controller)
  }, [providerId])

  useEffect(() => {
    if (sports) creteFilters()
  }, [sports])

  useEffect(() => {
    return () => {
      controller.abort()
    }
  }, [])

  return (
    <>
      <ParticipantFixtures showModal={showModal} sportId={sportId} pid={pid} providerId={providerid} signalCloseModal={handleCloseModal} />
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div style={{ display: 'flex', marginBottom: '20px' }}>
        <div md="2">
          <label>Created:</label>
          <CustomSelect
            options={[
              { id: '1', name: 'After' },
              { id: '2', name: 'Before' }
            ]}
            selectedItem={'{ "id": "1", "name": "After", "value": "1", "label": "After" }'}
            setName="operators"
            updateSelection={updateOperator}
          ></CustomSelect>
          <DateSelect setParentData={updateDate} showTime={false} />
        </div>
        <div style={{ display: 'flex', width: '100%' }}>
          <div style={{ width: '100%', marginTop: '30px', marginRight: '5px' }}>
            <TableCounters all={allcounter} filtered={filteredcounter}></TableCounters>
          </div>
          <div style={{ width: '300px', textAlign: 'right' }}>
            <ButtonGroup size="sm" aria-label="Basic example">
              <Button variant={filterAll ? 'success' : 'danger'} onClick={handleFilterAll}>
                All
              </Button>
              <Button variant={matched ? 'success' : 'danger'} onClick={handleMatchedFilter}>
                Matched
              </Button>
              <Button variant={filterAll ? 'danger' : !matched ? 'success' : 'danger'} onClick={handleUnmatchedFilter}>
                Unmatched
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </div>
      <BootstrapTable bootstrap4 keyField="id" data={tableData} columns={columns} pagination={paginationFactory({ sizePerPage: 10 })} filter={filterFactory({ afterFilter })} cellEdit={cellEditFactory({ mode: 'click', beforeSaveCell: changeCellValue })} />
    </>
  )
}
export default ProviderParticipants
