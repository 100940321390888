import React, { useState, useEffect } from 'react'

import { Chart } from 'chart.js/auto'

import { getData } from 'utils/server'
import { getAuthorizationHeader } from 'utils/helpers'

import SportSelect from 'components/Inputs/SportSelect'
import ProviderSelect from 'components/Inputs/ProviderSelect'

// react-bootstrap components
import { Badge, Button, Card, Navbar, Nav, Table, Container, Row, Col, Form, OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap'
import { CardColumns } from 'reactstrap'

let myChart = null

function MarketsSettlementStats({ autoload, collapsed }) {
  const controller = new AbortController()

  const [stats, setStats] = useState([])
  const [uniSports, setUniSports] = useState([])
  const [selectedSport, setSelectedSport] = useState({ value: 1, label: 'Soccer' })
  const [visible, setVisible] = useState(collapsed)
  const [availableProviders, setAvailableProviders] = useState([])
  const [message, setMessage] = useState('')

  const excludedProviders = ['bet365', 'mozzartbet', 'mozzart', 'betradar']

  const statsHandler = () => {
    myChart.data.datasets.forEach(dataset => {
      let label = dataset.label.indexOf('Not ') > -1 ? 'unsettled' : 'settled'
      let stack = dataset.stack.toLowerCase()
      let newData = []
      stats.forEach(stat => {
        newData.push(stat.sport_stats[selectedSport.label][stack][label])
      })

      dataset.data = newData
    })
    myChart.update()
  }

  const fetchStats = async controller => {
    setMessage(
      <div className="text-center">
        <i className="fas fa-spinner fa-spin text-center loadingSpinnerColor"></i>
        <span className="loadingSpinnerColor"> Loading Data ...</span>
      </div>
    )

    let sports_response = await getData('/api/sports', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (sports_response != null) {
      setUniSports(sports_response.data)
      setSelectedSport({ value: 1, label: 'Soccer' })
    }

    let response = await getData('/api/reports/markets/settlements', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (response != null) {
      let filteredData = []
      response.data.forEach(stat => {
        if (excludedProviders.indexOf(stat.provider) == -1) {
          filteredData.push(stat)
        }
      })
      setStats(filteredData)

      let statsChart = document.getElementById('settlementStatsChart')
      let providers = []
      let dataSet = [
        {
          label: 'Settled (Online)',
          data: [],
          stack: 'Online'
        },
        {
          label: 'Not Settled (Online)',
          data: [],
          stack: 'Online'
        },
        {
          label: 'Settled (Offline)',
          data: [],
          stack: 'Offline'
        },
        {
          label: 'Not Settled (Offline)',
          data: [],
          stack: 'Offline'
        }
      ]

      dataSet.forEach(dt => {
        let label = dt.label.indexOf('Not ') > -1 ? 'unsettled' : 'settled'
        let stack = dt.stack.toLowerCase()
        let newData = []
        filteredData.forEach(stat => {
          newData.push(stat.sport_stats[selectedSport.label][stack][label])
          if (providers.indexOf(stat.provider) == -1) providers.push(stat.provider)
        })

        dt.data = newData
      })

      setAvailableProviders(providers)

      myChart = new Chart(statsChart, {
        type: 'bar',
        data: {
          labels: providers,
          datasets: dataSet
        },
        options: {
          plugins: {
            title: {
              display: false,
              text: 'Chart.js Bar Chart - Stacked'
            },
            legend: {
              display: true,
              position: 'top'
            }
          },
          responsive: true,
          scales: {
            x: {
              stacked: true
            },
            y: {
              stacked: true
            }
          },
          maintainAspectRatio: false
        }
      })
      //console.log(myChart)
    }
    setMessage('')
  }

  const updateStatsSelectedSport = selSport => {
    setSelectedSport(selSport)
  }

  const minimizeChart = () => {
    if (visible) setVisible(false)
    if (!visible) setVisible(true)

    if (!myChart) fetchStats(controller)
  }

  // const makeLegend = (data, items) => {
  //   if (!data) return

  //   return (
  //     <Col md={12 / items}>
  //       <span style={{ fontSize: '0.8rem' }}>{data.provider}</span>
  //       <Row>
  //         <Col md="12">
  //           <Col md="12" style={{ textAlign: 'right', marginRight: '0px', paddingRight: '0px', display: 'flex' }}>
  //             <div style={{ textAlign: 'center', backgroundColor: '#9ad0f5', display: 'block', height: '15px', width: '15px', marginRight: '5px' }}> </div>
  //             <label style={{ textAlign: 'left', display: 'block', width: '50px' }}>{data.sport_stats[selectedSport.label].online.settled_abs}</label>
  //           </Col>
  //           <Col md="12" style={{ textAlign: 'right', marginRight: '0px', paddingRight: '0px', display: 'flex' }}>
  //             <div style={{ textAlign: 'center', backgroundColor: '#ffb1c1', display: 'block', height: '15px', width: '15px', marginRight: '5px' }}> </div>
  //             <label style={{ textAlign: 'left', display: 'block', width: '50px' }}>{data.sport_stats[selectedSport.label].online.unsettled_abs}</label>
  //           </Col>
  //         </Col>
  //         <Col md="12">
  //           <Col md="12" style={{ textAlign: 'right', marginRight: '0px', paddingRight: '0px', display: 'flex' }}>
  //             <div style={{ textAlign: 'center', backgroundColor: '#ffcf9f', display: 'block', height: '15px', width: '15px', marginRight: '5px' }}> </div>
  //             <label style={{ textAlign: 'left', display: 'block', width: '50px' }}>{data.sport_stats[selectedSport.label].offline.settled_abs}</label>
  //           </Col>
  //           <Col md="12" style={{ textAlign: 'right', marginRight: '0px', paddingRight: '0px', display: 'flex' }}>
  //             <div style={{ textAlign: 'center', backgroundColor: '#ffe6aa', display: 'block', height: '15px', width: '15px', marginRight: '5px' }}> </div>
  //             <label style={{ textAlign: 'left', display: 'block', width: '50px' }}>{data.sport_stats[selectedSport.label].offline.unsettled_abs}</label>
  //           </Col>
  //         </Col>
  //       </Row>
  //     </Col>
  //   )
  // }

  const makeLegend = data => {
    if (!data) return
    let domElements = []
    for (let object of data) {
      domElements.push(
        <Col key={object.provider} md={12 / object.length}>
          <span style={{ fontSize: '0.8rem' }}>{object.provider}</span>

          <Row>
            <Col md="12">
              <Col md="12" style={{ textAlign: 'right', marginRight: '0px', paddingRight: '0px', display: 'flex' }}>
                <div style={{ textAlign: 'center', backgroundColor: '#9ad0f5', display: 'block', height: '15px', width: '15px', marginRight: '5px' }}> </div>
                <label style={{ textAlign: 'left', display: 'block', width: '50px' }}>{object.sport_stats[selectedSport.label].online.settled_abs}</label>
              </Col>
              <Col md="12" style={{ textAlign: 'right', marginRight: '0px', paddingRight: '0px', display: 'flex' }}>
                <div style={{ textAlign: 'center', backgroundColor: '#ffb1c1', display: 'block', height: '15px', width: '15px', marginRight: '5px' }}> </div>
                <label style={{ textAlign: 'left', display: 'block', width: '50px' }}>{object.sport_stats[selectedSport.label].online.unsettled_abs}</label>
              </Col>
            </Col>
            <Col md="12">
              <Col md="12" style={{ textAlign: 'right', marginRight: '0px', paddingRight: '0px', display: 'flex' }}>
                <div style={{ textAlign: 'center', backgroundColor: '#ffcf9f', display: 'block', height: '15px', width: '15px', marginRight: '5px' }}> </div>
                <label style={{ textAlign: 'left', display: 'block', width: '50px' }}>{object.sport_stats[selectedSport.label].offline.settled_abs}</label>
              </Col>
              <Col md="12" style={{ textAlign: 'right', marginRight: '0px', paddingRight: '0px', display: 'flex' }}>
                <div style={{ textAlign: 'center', backgroundColor: '#ffe6aa', display: 'block', height: '15px', width: '15px', marginRight: '5px' }}> </div>
                <label style={{ textAlign: 'left', display: 'block', width: '50px' }}>{object.sport_stats[selectedSport.label].offline.unsettled_abs}</label>
              </Col>
            </Col>
          </Row>
        </Col>
      )
    }
    return domElements
  }

  useEffect(() => {
    if (autoload) fetchStats(controller)

    return () => {
      controller.abort()
      if (myChart) {
        myChart.destroy()
        myChart = null
      }
    }
  }, [])

  useEffect(() => {
    if (myChart && selectedSport) statsHandler()
  }, [selectedSport])

  return (
    <Card>
      <Card.Header>
        <Card.Title as="h4">
          Markets Settlements Stats
          <div style={{ position: 'absolute', top: '5px', right: '10px' }} onClick={minimizeChart}>
            <i style={{ display: visible ? '' : 'none' }} className="fas fa-angle-down custom-button-like"></i>
            <i style={{ display: visible ? 'none' : '' }} className="fas fa-angle-up custom-button-like"></i>
          </div>
        </Card.Title>
        <Row style={{ display: visible ? 'none' : '' }}>
          <Col md="12">
            <Row>
              <Col md="6">
                <Form.Group>
                  <label>Sport:</label>
                  <SportSelect sports={uniSports} updateSport={updateStatsSelectedSport} selectedItem={JSON.stringify(selectedSport)} />
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body style={{ display: visible ? 'none' : '' }}>
        <div className="stats-chart" id="settlementStatsChartHolder" style={{ display: message === '' ? '' : 'none' }}>
          <div style={{ height: '30rem' }}>
            <canvas id="settlementStatsChart" height="450px auto" />
          </div>
          <Row style={{ margin: '10px 0px 20px 35px', display: stats.length == 0 ? 'none' : '' }}>
            <Col md="12">
              <Row>
                {makeLegend(stats)}
                {/* {makeLegend(stats[0], stats.length)}
                {makeLegend(stats[1], stats.length)}
                {makeLegend(stats[2], stats.length)}
                {makeLegend(stats[3], stats.length)}
                {makeLegend(stats[4], stats.length)}
                {makeLegend(stats[5], stats.length)}
                {makeLegend(stats[6], stats.length)}
                {makeLegend(stats[7], stats.length)}
                {makeLegend(stats[8], stats.length)} */}
              </Row>
            </Col>
          </Row>
        </div>
        <div>{message}</div>
      </Card.Body>
      <Card.Footer>
        <hr></hr>
        <div className="stats">
          <i className="fas fa-history"></i>
          Updated 3 minutes ago
        </div>
      </Card.Footer>
    </Card>
  )
}

export default MarketsSettlementStats
