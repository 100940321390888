import React, { useState, useEffect } from 'react'
import { Card, Container, Row, Col, Button } from 'react-bootstrap'
import ProviderFixtures from 'components/Tables/ProviderFixtures'
import UniFixtures from 'components/Tables/UniFixtures'
import ProviderSelect from 'components/Inputs/ProviderSelect'
import SportSelect from 'components/Inputs/SportSelect'
import DateSelect from 'components/Inputs/DateSelect'
import Swal from 'sweetalert2'
import CreateUniFixture from 'components/Modals/CreateUniFixture'

import { getData, postData } from 'utils/server'
import { getAuthorizationHeader, getUserData } from 'utils/helpers'

import useMousetrap from 'react-hook-mousetrap'

function Fixtures() {
  const controller = new AbortController()

  const [disableMatchFixtures, setDisableMatchFixtures] = useState(true)
  const [refreshRightTable, setRefreshRightTable] = useState(false)
  const [refreshLeftTable, setRefreshLeftTable] = useState(false)
  const [downloadcsv, setDownloadcsv] = useState(false)
  const [leftFixture, setLeftFixture] = useState(null)
  const [rightFixture, setRightFixture] = useState(null)
  const [sportId, setSportId] = useState(null)
  const [sports, setSports] = useState([])
  const [rightProviderId, setRightProviderId] = useState(null)
  const [rightSportId, setRigthSportId] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [showCreateUniFixtureModal, setShowCreateUniFixtureModal] = useState(false)
  const [validMatch, setValidMatch] = useState(0.8)
  const [competitionSector, setCompetitionSector] = useState(false)
  const [regionSector, setRegionSector] = useState(false)
  const [participantSector, setParticipantSector] = useState(true)
  const [matchingPattern, setMatchingPattern] = useState('')
  const [disabled, setDisabled] = useState(true)
  useMousetrap('alt+n', () => {
    if (!disableMatchFixtures) clickMatchFixtures()
  })

  const updateRightSelectedProvider = pId => {
    setRightProviderId(pId)
  }

  const updateStartDate = startDate => {
    setStartDate(startDate)
  }

  const updateSportId = sport => {
    setSportId(sport.id)
  }

  const updateRightSportId = sport => {
    setRigthSportId(sport.id)
  }

  const leftHandler = event => {
    setLeftFixture(event)
  }

  const rightHandler = event => {
    setRightFixture(event)
  }

  const leftSelector = {
    color: '#00BFFF',
    handler: leftHandler
  }
  const rightSelectColor = {
    color: '#DC143C',
    handler: rightHandler
  }

  const clickMatchFixtures = () => {
    let html = `
    <p><b>${leftFixture.home}</b> = ${rightFixture.home}</p>
    <p><b>${leftFixture.away}</b> = ${rightFixture.away}</p>
    `

    const matchParticipants = []

    if (leftFixture.home_id) {
      matchParticipants.push({
        master_id: leftFixture.home_id,
        master_provider_id: 0,
        child_id: rightFixture.participants[0].id,
        child_provider_id: rightFixture.providerId
      })
    }

    if (leftFixture.away_id) {
      matchParticipants.push({
        master_id: leftFixture.away_id,
        master_provider_id: 0,
        child_id: rightFixture.participants[1].id,
        child_provider_id: rightFixture.providerId
      })
    }

    if (matchParticipants.length == 0) {
      Swal.fire('Error!', `Fixtures cannot be matched! Data missing!`, 'error')
      return
    }

    const matchingMessage = {
      uni_id: leftFixture.eventId,
      provider_id: rightFixture.providerId,
      event_id: rightFixture.eventId,
      user_id: getUserData().id
    }

    Swal.fire({ title: `Match Entities?`, html: html, showDenyButton: true, showConfirmButton: true, confirmButtonText: 'Yes, match them!', denyButtonText: 'Cancel', icon: 'info', confirmButtonColor: '#87cb16', denyButtonColor: '#ff4a55' }).then(async result => {
      if (result.isConfirmed) {
        let participantsResults = await postData(`/api/providers/participants/match`, matchParticipants, { Authorization: getAuthorizationHeader() })

        if (participantsResults != null) {
          Swal.fire({ title: `Entities Matched. Send Matching Message!`, html: html, showDenyButton: false, showConfirmButton: true, confirmButtonText: 'Ok', denyButtonText: 'Cancel', icon: 'info', confirmButtonColor: '#87cb16', denyButtonColor: '#ff4a55' }).then(async result => {
            refreshRightTable == true ? setRefreshRightTable(false) : setRefreshRightTable(true)

            let informAboutMatching = await postData(`/api/fixture/matched`, matchingMessage, { Authorization: getAuthorizationHeader() })

            if (informAboutMatching != null) {
              Swal.fire('Process Completed!', `Matched Fixtures Sent.`, 'success')
            } else {
              Swal.fire('Error!', `Something goes wrong while saving fixture relation.`, 'error')
            }
          })

          // Swal.fire('Matched!', `Entities Matched.`, 'success')
          // refreshRightTable == true ? setRefreshRightTable(false) : setRefreshRightTable(true)

          // let informAboutMatching = await postData(`/api/fixture/matched`, matchingMessage, { Authorization: getAuthorizationHeader() })

          // if (informAboutMatching != null) {
          //   console.log('Matching sent to RMQ.')
          // }
        } else {
          Swal.fire('Error!', `- Partitipants Entities ${participantsResults ? 'matched' : 'cannot be matched!'} <br> -Message for Matching didnt send!`, 'error')
        }
      }
    })
  }

  const clickCreateUniFixture = () => {
    setShowCreateUniFixtureModal(true)
  }

  const clickDownloadUniFixture = () => {
    setDownloadcsv(true)
    setDisabled(true)
  }

  const handleUniFixtures = data => {
    if (data.downloadcsv && data.downloadcsv == true) {
      setDisabled(false)
      setDownloadcsv(false)
    }
  }

  const handleCloseUniFixtureModal = update => {
    setShowCreateUniFixtureModal(false)
    if (update) {
      refreshLeftTable ? setRefreshLeftTable(false) : setRefreshLeftTable(true)
    } else {
      setRefreshLeftTable(false)
    }
  }

  const fetchSports = async controller => {
    let response = await getData('/api/sports', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (response != null) {
      setSports(response.data)
    }
  }

  const changeValidMatchRate = ev => {
    ev.preventDefault()
    setValidMatch(ev.target.value)
  }

  const changeCompetitionSector = ev => {
    setCompetitionSector(ev.target.checked)
  }

  const changeRegionSector = ev => {
    setRegionSector(ev.target.checked)
  }

  const changeParticipantSector = ev => {
    setParticipantSector(ev.target.checked)
  }

  const constructMatchingStringPattern = () => {
    let stringToCheck = ''

    if (regionSector) stringToCheck = 'Region'
    if (competitionSector) stringToCheck = stringToCheck == '' ? 'Competition' : `${stringToCheck}::Competition`
    if (participantSector) stringToCheck = stringToCheck == '' ? `Home::Away` : `${stringToCheck}::Home::Away`

    setMatchingPattern(stringToCheck)
  }

  useEffect(() => {
    fetchSports(controller)
    constructMatchingStringPattern()

    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    if (leftFixture == null || rightFixture == null) return
    setDisableMatchFixtures(false)
  }, [leftFixture, rightFixture])

  useEffect(() => {
    constructMatchingStringPattern()
  }, [regionSector, competitionSector, participantSector])

  return (
    <>
      <Container fluid>
        <CreateUniFixture showModal={showCreateUniFixtureModal} signalCloseModal={handleCloseUniFixtureModal} copiedFixture={rightFixture} />
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Body>
                <Row>
                  <Col md="3">
                    <DateSelect setParentData={updateStartDate} />
                  </Col>
                  <Col md="9">
                    <Row>
                      <Col md="8">
                        <Row>
                          <Col md="6" style={{ textAlign: 'right' }}>
                            <div>Matching sectors:</div>
                          </Col>
                          <Col md="2">
                            <div style={{ cursor: 'pointer !important' }}>
                              <input type="checkbox" id="region" checked={regionSector} value="" onChange={changeRegionSector} />
                              <label style={{ paddingLeft: '3px' }} htmlFor="region">
                                Region
                              </label>
                            </div>
                          </Col>
                          <Col md="2">
                            <div style={{ cursor: 'pointer !important' }}>
                              <input type="checkbox" id="competition" checked={competitionSector} value="" onChange={changeCompetitionSector} />
                              <label style={{ paddingLeft: '3px' }} htmlFor="competition">
                                Competition
                              </label>
                            </div>
                          </Col>
                          <Col md="2">
                            <div style={{ cursor: 'pointer !important' }}>
                              <input type="checkbox" id="participant" checked={participantSector} value="" onChange={changeParticipantSector} />
                              <label style={{ paddingLeft: '3px' }} htmlFor="participant">
                                Participants
                              </label>
                            </div>
                          </Col>
                          <Col md="12" style={{ textAlign: 'right', fontSize: '12px' }}>
                            <span style={{ color: 'rgb(132, 132, 132)' }}>string matching pattern:</span> <span style={{ color: '#cecece' }}>({matchingPattern})</span>
                          </Col>
                        </Row>
                      </Col>

                      <Col md="2" style={{ textAlign: 'right' }}>
                        Match Rating: <input style={{ width: '60px' }} value={validMatch} onChange={changeValidMatchRate}></input>
                      </Col>
                      <Col md="2">
                        <Button className="btn-fill match-fixtures-btn" variant="info" onClick={clickMatchFixtures} disabled={disableMatchFixtures}>
                          Match Fixtures
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col md="6">
                    <SportSelect sports={sports} updateSport={updateSportId} />
                  </Col>
                  <Col md="6">
                    <Row>
                      <Col md="6">
                        <Button className="btn-fill match-fixtures-btn" variant="info" onClick={clickCreateUniFixture}>
                          Create Uni-Fixture
                        </Button>
                      </Col>
                      <Col md="6">
                        <Button className="btn-fill match-fixtures-btn" disabled={disabled} variant="info" onClick={clickDownloadUniFixture}>
                          Export Data
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Col md="12">
                  <UniFixtures
                    informParent={handleUniFixtures}
                    providerId={-1}
                    startDate={startDate}
                    sportId={sportId}
                    defaultSimilarity={validMatch}
                    defaultMatchingSectors={{ rSector: regionSector, cSector: competitionSector, pSector: participantSector }}
                    selectedEvent={rightFixture}
                    selectOptions={leftSelector}
                    forceUpdate={refreshLeftTable}
                    downloadcsv={downloadcsv}
                  />
                </Col>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col md="6">
                    <SportSelect sports={sports} updateSport={updateRightSportId} />
                  </Col>
                  <Col md="6">
                    <ProviderSelect setParentProviderId={updateRightSelectedProvider} defaultValue={null} />
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Col md="12">
                  <ProviderFixtures providerId={rightProviderId} startDate={startDate} sportId={rightSportId} selectOptions={rightSelectColor} forceUpdate={refreshRightTable} />
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Fixtures
