import React, { useState, useEffect } from 'react'
import { Card, Container, Row, Col, Button } from 'react-bootstrap'
import UsersTable from 'components/Tables/Users'
import CreateUser from 'components/Modals/CreateUser'
import { validateViewCredentials } from 'utils/auth'

function Users(props) {
  validateViewCredentials(props.routeInfo)
  const [showCreateUserModal, setCreateUserModal] = useState(false)
  const [updateUsers, setUpdateUsers] = useState(false)

  const handleCreateUser = () => {
    setCreateUserModal(true)
  }

  const handleCloseModal = uUsers => {
    setCreateUserModal(false)
    if (uUsers) {
      updateUsers == true ? setUpdateUsers(false) : setUpdateUsers(true)
    }
  }

  return (
    <>
      <Container fluid>
        <CreateUser showModal={showCreateUserModal} signalCloseModal={handleCloseModal} />
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col md="8">
                    <Card.Title as="h4">Users</Card.Title>
                  </Col>
                  <Col md="4">
                    <Button className="create-user-btn" variant="success" onClick={handleCreateUser}>
                      Create User
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Col md="12">
                  <UsersTable forceUpdate={updateUsers} />
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Users
