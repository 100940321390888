const _ = require('lodash')
import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Badge } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter'
import cellEditFactory from 'react-bootstrap-table2-editor'
import Swal from 'sweetalert2'
import NotificationAlert from 'react-notification-alert'

import { getData, delData, putData } from 'utils/server'
import { notificationOptions, getAuthorizationHeader } from 'utils/helpers'
import UniTranslation from 'components/Modals/UniTranslation'
import UniMarketUpdate from 'components/Modals/UpdateUniMarket'
import MarketOdds from 'components/Modals/MarketOdds'
import TableCounters from 'components/UniObject/TableCounters'
import SportSelect from 'components/Inputs/SportSelect'
import CustomSelect from 'components/Inputs/CustomSelect'

let filters = []

function OrderingMarkets({ forceUpdate, sports, groups }) {
  const controller = new AbortController()

  const notificationAlertRef = React.useRef(null)
  const [allTableData, setAllData] = useState([])
  const [tableData, setData] = useState([])
  const [allcounter, setAllCounter] = useState(0)
  const [filteredcounter, setFilteredcounter] = useState(0)
  const [sportFilters, setSportFilters] = useState({})
  const [groupsFilters, setGroupsFilters] = useState({})
  const [typeFilters] = useState({ inPlay: 'inPlay', preGame: 'preGame' })
  const [enabledFilters] = useState({ 1: 'Enabled', 0: 'Disabled' })
  const [showModal, setShowModal] = useState(false)
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const [showOddsModal, setShowOddsModal] = useState(false)
  const [selectedMarketId, setSelectedMarketId] = useState(null)
  const [selectedMarket, setSelectedMarket] = useState({})
  const [selectedMarketName, setSelectedMarketName] = useState(null)
  const [matched, setMatched] = useState(false)
  const [filterAll, setFilterAll] = useState(true)
  const [selectedEventType, setSelectedEventType] = useState(null)
  const [sportId, setSportId] = useState(null)
  const [saveEnabled, setSaveEnabled] = useState(false)
  const [initializing, setInitializing] = useState(false)
  const [tableColumnSorting, setColumnSorting] = useState('sort')
  const [tableSorting, setTableSorting] = useState('asc')

  let allowFetchData = false

  const paginationOptions = {
    disablePageTitle: true,
    showTotal: false,
    sizePerPageList: [
      {
        text: '25',
        value: 20
      },
      {
        text: '50',
        value: 50
      }
    ]
  }

  const columns = [
    {
      dataField: 'id_multi',
      text: 'UNI ID',
      align: 'center',
      editable: false,
      hidden: true
    },
    {
      dataField: 'market_numbers_id',
      text: 'exception id',
      align: 'center',
      editable: false,
      hidden: true
    },
    {
      dataField: 'id',
      text: 'UNI ID',
      align: 'center',
      editable: false,
      formatter: (cellContent, row) => (
        <>
          <div>
            {cellContent}
            <Badge bg="info" className="market-matches" style={{ display: row.matched != 0 ? '' : 'none' }}>
              {row.matched}
            </Badge>
          </div>
        </>
      ),
      filter: textFilter(),
      sort: true,
      headerClasses: 'id-custom-cell'
    },
    {
      dataField: 'groups',
      text: 'Groups',
      editable: false,
      headerClasses: 'group-custom-cell',
      formatter: cell => {
        let groupNamesArray = []
        for (const key in cell) {
          if (Object.hasOwnProperty.call(cell, key)) {
            const groupId = cell[key]
            groupNamesArray.push(groupsFilters[groupId])
          }
        }
        return groupNamesArray.toString()
      },
      filter: textFilter(),
      filterValue: (cell, row) => {
        let groupNamesArray = []
        for (const key in cell) {
          if (Object.hasOwnProperty.call(cell, key)) {
            const groupId = cell[key]
            groupNamesArray.push(groupsFilters[groupId])
          }
        }
        return groupNamesArray.toString()
      }
    },
    {
      dataField: 'match_name',
      text: 'UniName',
      align: 'center',
      editable: false,
      filter: textFilter(),
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <div style={{ backgroundColor: row.affected ? '#ffd6d6' : '' }}>{cellContent}</div>
        </>
      )
    },
    {
      dataField: 'market_numbers',
      text: 'Numbers',
      align: 'center',
      editable: (cellContent, row) => (row.multiInstanceEdit ? true : false),
      filter: textFilter(),
      sort: true,
      headerClasses: 'id-custom-cell',
      formatter: (cellContent, row) => (
        <>
          <div style={{ backgroundColor: row.numbersValidationError ? '#ffd6d6' : '' }}>{cellContent}</div>
        </>
      )
    },
    {
      dataField: 'sort',
      text: 'Order',
      editable: false,
      sort: true,
      headerClasses: 'order-custom-cell',
      formatter: (cellContent, row) => (
        <>
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex' }}>
              <Button className="btn-simple btn-link p-1" type="button" variant="warning" onClick={e => changeline(e, row.id_multi, '-1')}>
                <i className="fas fa-angle-up"></i>
              </Button>
              <Button className="btn-simple btn-link p-1" type="button" variant="info" onClick={e => changeline(e, row.id_multi, '+1')}>
                <i className="fas fa-angle-down"></i>
              </Button>
            </div>
            <div style={{ display: 'flex' }}>
              <input id={'positioning-' + row.id_multi} defaultValue={cellContent.toString()} style={{ width: '100px' }} onKeyDown={e => keyDownCatcher(e, row.id_multi, null)}></input>
              <Button className="btn-simple btn-link p-1" type="button" variant="success" onClick={e => changeline(e, row.id_multi, null)}>
                <i className="fas fa-arrows-alt-v"></i>
              </Button>
            </div>
            <div style={{ display: row.multiInstance ? 'flex' : 'none' }}>
              <Button className="btn-simple btn-link p-1" type="button" variant="danger" onClick={e => addline(e, row.id_multi, null)}>
                <i className="fas fa-plus"></i>
              </Button>
            </div>
            <div style={{ display: row.multiInstanceEdit ? 'flex' : 'none' }}>
              <Button className="btn-simple btn-link p-1" type="button" variant="danger" onClick={e => deleteline(e, row.market_numbers_id, row)}>
                <i className="fas fa-minus"></i>
              </Button>
            </div>
          </div>
        </>
      )
    }
  ]

  const sleep = ms =>
    new Promise(resolve => {
      setTimeout(resolve, ms)
    })

  const initTable = () => {
    setMatched(false)
    setFilterAll(true)
    setAllData([])
    setData([])
    setAllCounter(0)
    setFilteredcounter(0)
    afterFilter([], null)

    // preFilterData(false, true, [])
    return true
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleUpdatedMarketCloseModal = (update, data) => {
    setShowUpdateModal(false)
    if (update) {
      fetchData(controller)
    }
  }

  const handleOddsCloseModal = () => {
    setShowOddsModal(false)
  }

  const handleShowOddsClick = row => {
    setSelectedMarket(row)
    setShowOddsModal(true)
  }

  const handleTranslationClick = row => {
    setSelectedMarketId(row.id_multi)
    setSelectedMarketName(row.match_name)
    setShowModal(true)
  }

  const handleEditClick = row => {
    setSelectedMarket(row)
    setShowUpdateModal(true)
  }

  const handleLoadData = row => {
    if (!saveEnabled) {
      fetchData(controller)
      return
    }

    Swal.fire({ title: `You have usaved Changes, that will be lost if you continue. Are you sure you want to procceed?`, showDenyButton: true, showConfirmButton: true, confirmButtonText: 'Yes, proceed!', denyButtonText: 'Cancel', icon: 'warning', confirmButtonColor: '#87cb16', denyButtonColor: '#ff4a55' }).then(async result => {
      if (result.isConfirmed) {
        fetchData(controller)
      }
    })
  }

  function keyDownCatcher(e, id, value) {
    if (e.key != 'Enter') return
    changeline(e, id, value)
    console.log(e.currentTarget.value)
  }

  const fetchData = async controller => {
    if (allowFetchData) return
    setSaveEnabled(false)
    setInitializing(true)
    initTable()
    if (sports.length == 0 || !sportId || !selectedEventType) return
    let response = await getData(`/api/unis/markets?sport=${sportId}&type=${selectedEventType.name}`, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    let tableData = []
    if (response != null) {
      for (let index = 0; index < response.data.length; index++) {
        const item = response.data[index]

        let numbersId = ''
        let market_numbers = item.market_numbers ? item.market_numbers.split(',') : ['0', '0', '0']

        item.multiInstance = false
        item.multiInstanceEdit = false

        if (item.match_name.indexOf('_X_') > -1) item.multiInstance = true
        if (item.match_name.indexOf('X_') > -1) item.multiInstance = true
        if (item.match_name.indexOf('_X') > -1) item.multiInstance = true

        for (let index = 0; index < 3; index++) {
          let number = market_numbers[index]
          if (typeof number == 'undefined') {
            number = '0'
          }
          numbersId = numbersId + '' + number
        }

        if (item.market_numbers) {
          if (item.match_name.indexOf('_X_') > -1) item.multiInstanceEdit = true
          if (item.match_name.indexOf('X_') > -1) item.multiInstanceEdit = true
          if (item.match_name.indexOf('_X') > -1) item.multiInstanceEdit = true
        }

        if (item.multiInstanceEdit) item.multiInstance = false

        if (!item.sort) item.sort = 0
        item.id_multi = item.id + '' + (numbersId ? numbersId : 0)
        item.sort = item.sort * 1
        if (item.sort.toString().indexOf('.') > -1) item.sort = parseFloat(item.sort.toFixed(3))
        item.affected = false
        tableData.push(item)
      }
      tableData.sort((a, b) => a.sort * 1 - b.sort * 1)
    }
    setAllCounter(tableData.length)
    setAllData(tableData)
    setData(tableData)

    setInitializing(false)
  }

  const createFilters = caller => {
    if (sports && sports.length > 0) {
      let mySportFilters = {}
      for (let sport of sports) mySportFilters[sport.id] = sport.name
      setSportFilters(mySportFilters)
    }
    if (groups && groups.length > 0) {
      let myGroupsFilters = {}
      for (let group of groups) myGroupsFilters[group.id] = group.name
      setGroupsFilters(myGroupsFilters)
    }
  }

  const afterFilter = async (newResult, newFilters) => {
    let filterstoPush = []
    if (newFilters) {
      for (const key in newFilters) {
        if (Object.hasOwnProperty.call(newFilters, key)) {
          filterstoPush.push(key)
        }
      }
    }
    filters = filterstoPush
    setFilteredcounter(newResult.length)
  }

  const updateSportId = sport => {
    setSportId(sport.id)
  }

  const getFilters = () => {
    let filtersString = filters.join(',')
    return filtersString
  }

  function changeline(e, id, value) {
    e.preventDefault()
    let barrier = 0
    let itemAffected = allTableData.find(item => item.id_multi == id)
    let oldOrder = itemAffected.sort
    let newOrder = null
    if (value == null) {
      newOrder = document.getElementById('positioning-' + id).value * 1
      barrier = document.getElementById('positioning-' + id).value * 1 - parseFloat(itemAffected.sort)
    } else if (value == '++') {
      newOrder = allTableData.length
    } else if (value == '--') {
      newOrder = 0
    } else if (parseFloat(value) != NaN) {
      barrier = parseFloat(value)
    }
    console.log('barrier:', barrier)
    newOrder = newOrder ? newOrder : document.getElementById('positioning-' + id).value * 1 + barrier
    newOrder = newOrder <= 0 ? 1 : newOrder

    let itemSideAffected = allTableData.find(item => item.sort == newOrder)

    let tmpData = []
    setData(tmpData)

    if (allTableData != null) {
      let newOrderTocheck = newOrder
      for (let index = 0; index < allTableData.length; index++) {
        const item = allTableData[index]
        if (item.id_multi == id) {
          item.sort = parseFloat(newOrder.toFixed(3).toString())
          item.affected = true
          setSaveEnabled(true)
        } else {
          if (Math.abs(barrier) == 1) {
            if (item.sort == newOrder) {
              console.log('must change order in other item ', Math.abs(barrier))
              item.sort = parseFloat(oldOrder.toFixed(3).toString())
              item.affected = true
              setSaveEnabled(true)
            }
          } else {
            if (itemSideAffected) {
              console.log('check itemSideAffected:', itemSideAffected)
              if (barrier < 0) {
                if (newOrderTocheck <= item.sort && item.sort < oldOrder) {
                  newOrderTocheck = item.sort + 1
                  itemSideAffected = allTableData.find(item => item.sort == newOrderTocheck)
                  item.sort = parseFloat(newOrderTocheck.toFixed(3).toString())
                  item.affected = true
                  setSaveEnabled(true)
                }
              } else {
                if (newOrderTocheck <= item.sort && item.sort < newOrderTocheck + Math.abs(barrier)) {
                  newOrderTocheck = item.sort + 1
                  itemSideAffected = allTableData.find(item => item.sort == newOrderTocheck)
                  item.sort = parseFloat(newOrderTocheck.toFixed(3).toString())
                  item.affected = true
                  setSaveEnabled(true)
                  console.log('itemSideAffected:', itemSideAffected)
                }
              }
            }
          }
        }
        tmpData.push(item)
      }
    }
    allTableData.sort((a, b) => a.sort * 1 - b.sort * 1)
    tmpData.sort((a, b) => a.sort * 1 - b.sort * 1)

    setData(tmpData)

    console.log('change Index: ', id, oldOrder, newOrder, value)
  }

  function addline(e, id, value) {
    e.preventDefault()
    let itemToCopy = allTableData.find(item => item.id_multi == id)

    let tmpData = []
    setData(tmpData)

    if (itemToCopy) {
      const itemToAdd = JSON.parse(JSON.stringify(itemToCopy))
      const marketNumbersLength = (itemToAdd.match_name.match(/X/g) || []).length

      const marketInstances = allTableData.filter(item => item.id == itemToAdd.id && item.market_numbers)
      marketInstances.sort((a, b) => a.sort * 1 - b.sort * 1)
      const lastMarketInstance = marketInstances.length > 0 ? marketInstances[marketInstances.length - 1] : itemToAdd

      let newNumbers = []
      const numbersPrototype = ['0', '0', '0']

      if (lastMarketInstance.market_numbers) {
        for (let index = 0; index < lastMarketInstance.market_numbers.split(',').length; index++) {
          const element = lastMarketInstance.market_numbers.split(',')[index]
          numbersPrototype[index] = element
        }
      }

      const lastMarketNumbers = numbersPrototype
      const firstNumber = lastMarketNumbers.slice(0, 1)[0]
      const restNumbers = lastMarketNumbers.slice(1, 3)
      newNumbers = [parseInt(firstNumber) + 1].concat(restNumbers)

      itemToAdd.multiInstance = false
      itemToAdd.multiInstanceEdit = true
      itemToAdd.id_multi = itemToAdd.id + '' + newNumbers.join('')
      itemToAdd.sort = lastMarketInstance.sort + 1
      itemToAdd.affected = true
      itemToAdd.market_numbers = newNumbers.slice(0, marketNumbersLength).join(',')
      allTableData.push(itemToAdd)
      allTableData.sort((a, b) => a.sort * 1 - b.sort * 1)
    }
    setAllCounter(allTableData.length)
    setAllData(allTableData)
    setData(allTableData)
    setSaveEnabled(true)
  }

  async function deleteline(e, id, row) {
    e.preventDefault()
    console.log(id, row)

    if (id) {
      Swal.fire({ title: `You are going to Delete an exception for Marker ordering from DATABASE. Are you sure you want to procceed?`, showDenyButton: true, showConfirmButton: true, confirmButtonText: 'Yes, proceed!', denyButtonText: 'Cancel', icon: 'warning', confirmButtonColor: '#87cb16', denyButtonColor: '#ff4a55' }).then(async result => {
        if (result.isConfirmed) {
          let response = await delData(`/api/unis/marketnumbersexception/${id}`, { Authorization: getAuthorizationHeader() })
          if (response == null) {
            notificationAlertRef.current.notificationAlert(notificationOptions('Error! Something went wrong.', 'danger'))
          } else {
            notificationAlertRef.current.notificationAlert(notificationOptions('Success! Market Deleted.', 'success'))
            fetchData(controller)
          }
        }
      })
    } else {
      Swal.fire({ title: `You are going to Delete an exception for Marker ordering from THIS list. Are you sure you want to procceed?`, showDenyButton: true, showConfirmButton: true, confirmButtonText: 'Yes, proceed!', denyButtonText: 'Cancel', icon: 'warning', confirmButtonColor: '#87cb16', denyButtonColor: '#ff4a55' }).then(async result => {
        if (result.isConfirmed) {
          let itemToDelete = tableData.find(item => item.id_multi == row.id_multi)
          let itemToDeleteFromAll = allTableData.find(item => item.id_multi == row.id_multi)

          let tmpData = []
          setData(tmpData)
          if (itemToDelete) {
            tmpData = [...tableData]

            const itemIndexToDelete = tableData.findIndex(item => item.id_multi == row.id_multi)
            const itemIndexToDeleteFromAll = allTableData.findIndex(item => item.id_multi == row.id_multi)

            tmpData.splice(itemIndexToDelete, 1)
            allTableData.splice(itemIndexToDeleteFromAll, 1)
            setData(tmpData)
            setAllCounter(allTableData.length)
            setAllData(allTableData)
            setSaveEnabled(true)
          }
        }
      })
    }
  }

  const changeCellValue = (oldValue, newValue, row, column, done) => {
    if (oldValue != newValue) {
      setTableSorting('desc')
      row.affected = true
    } else {
      return
    }
    let currentSort = row.sort

    row.sort = 0
    const acceptedMarketNumbersLength = (row.match_name.match(/X/g) || []).length
    const marketNumbers = newValue.split(',')
    let numbersValidationError = false

    if (marketNumbers.length > acceptedMarketNumbersLength) {
      numbersValidationError = true
    }

    for (let index = 0; index < marketNumbers.length; index++) {
      const number = marketNumbers[index]
      if (isNaN(number) && !numbersValidationError) {
        numbersValidationError = true
      }
    }

    const marketInstances = allTableData.filter(item => item.id == row.id && item.market_numbers == newValue)

    if (marketInstances.length) {
      numbersValidationError = true
    }

    row.numbersValidationError = numbersValidationError
    row.market_numbers = newValue
    let tmpData = []
    setData(tmpData)

    for (let index = 0; index < allTableData.length; index++) {
      let item = allTableData[index]
      if (item.id_multi === row.id_multi) {
        item = row
      }
      tmpData.push(item)
    }

    setSaveEnabled(!numbersValidationError)
    setAllCounter(allTableData.length)
    setAllData(allTableData)
    setData(tmpData)
    row.sort = currentSort
    setColumnSorting('id')
    setTableSorting('asc')
    console.log(row.affected, oldValue, newValue)
    setColumnSorting('sort')
  }

  async function saveData() {
    setSaveEnabled(true)
    console.log('Saving Data')
    let marketsAffected = []
    let marketsExceptions = []
    for (let index = 0; index < tableData.length; index++) {
      const market = tableData[index]
      if (market.affected && !market.market_numbers) marketsAffected.push({ id: market.id, sort: market.sort.toString() })
      if (market.affected && market.market_numbers) {
        marketsExceptions.push({ id: market.id, exceptionId: market.market_numbers_id, numbers: market.market_numbers, sort: market.sort })
      }
    }

    let responseError = false
    if (marketsAffected.length > 0) {
      let response = await putData(`/api/unis/marketsordering`, marketsAffected, { Authorization: getAuthorizationHeader() })
      if (response == null) {
        notificationAlertRef.current.notificationAlert(notificationOptions('Error! Something went wrong.', 'danger'))
        responseError = true
      }
    }

    if (marketsExceptions.length > 0 && !responseError) {
      let response = await putData(`/api/unis/marketnumbersexception`, marketsExceptions, { Authorization: getAuthorizationHeader() })
      if (response == null) {
        notificationAlertRef.current.notificationAlert(notificationOptions('Error! Something went wrong.', 'danger'))
        responseError = true
      }
    }

    if (!responseError) {
      notificationAlertRef.current.notificationAlert(notificationOptions('Success! Market Updated.', 'success'))
      fetchData(controller)
    }
  }

  const updateSelectedType = selection => {
    setSelectedEventType(selection)
  }

  Array.prototype.move = function (from, to) {
    this.splice(to, 0, this.splice(from, 1)[0])
  }

  useEffect(() => {
    createFilters('sports')
  }, [sports])

  useEffect(() => {
    createFilters('groups')
  }, [groups])

  useEffect(() => {
    console.log('initializing...', initializing)
    allowFetchData = initializing
  }, [initializing])

  useEffect(() => {
    return () => {
      controller.abort()
    }
  }, [])

  return (
    <>
      <UniTranslation showModal={showModal} entityId={selectedMarketId} entityName={selectedMarketName} entityType={'markets'} entityKey={'market_id'} signalCloseModal={handleCloseModal} />
      <UniMarketUpdate showModal={showUpdateModal} market={selectedMarket} groups={groups} signalCloseModal={handleUpdatedMarketCloseModal} />
      <MarketOdds showModal={showOddsModal} market={selectedMarket} signalCloseModal={handleOddsCloseModal} />
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '50%' }}>
          <SportSelect sports={sports} updateSport={updateSportId} />
        </div>
        <div style={{ width: '50%' }}>
          <CustomSelect
            options={[
              { id: 1, name: 'inPlay' },
              { id: 2, name: 'preGame' }
            ]}
            setName="event-type"
            updateSelection={updateSelectedType}
            selectedItem={null}
            disabled={false}
          />
        </div>
        <div style={{ width: '200px' }}>
          <Button size="sm" variant={'info'} onClick={handleLoadData} disabled={!sportId || !selectedEventType || initializing}>
            Load Data
          </Button>
        </div>
        <div style={{ width: '50%' }}>
          <TableCounters all={allcounter} filtered={filteredcounter}></TableCounters>
        </div>
        <div style={{ width: '300px', textAlign: 'right' }}>
          <ButtonGroup size="sm" aria-label="Basic example">
            <Button variant={'success'} onClick={saveData} disabled={!saveEnabled}>
              Save
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <div style={{ display: getFilters().length > 0 ? 'flex' : 'none', fontSize: '0.75rem', color: '#ff00837d' }}>You have apply filters: ({getFilters()})</div>
      <BootstrapTable bootstrap4 keyField="id_multi" data={tableData} columns={columns} sort={{ dataField: tableColumnSorting, order: tableSorting }} filter={filterFactory({ afterFilter })} cellEdit={cellEditFactory({ mode: 'click', beforeSaveCell: changeCellValue })} />
    </>
  )
}
export default OrderingMarkets
