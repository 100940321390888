import React, { useEffect, useState } from 'react'

import Select from 'react-select'

function CustomSelect({ options, setName, updateSelection, disabled, selectedItem }) {
  const [initItem, setInitItem] = useState(selectedItem)
  const handleChange = selectedItem => {
    updateSelection(selectedItem)
    setInitItem(selectedItem)
  }

  //console.log('Run CustomSelect-> ', 'options: ', options, 'setName: ', setName, 'updateSelection: ', updateSelection, 'disabled: ', disabled, 'selectedItem: ', selectedItem)

  options.map(el => {
    el.value = el.id
    el.label = el.name
  })

  useEffect(() => {
    if (selectedItem) {
      let item = JSON.parse(selectedItem)
      if (item) {
        //console.log(`CustomSelect->${setName}useEffect->`, item)
        setInitItem(item)
        updateSelection(item)
      } else {
        updateSelection(null)
        setInitItem(null)
      }
    } else {
      updateSelection(null)
      setInitItem(null)
    }
  }, [selectedItem])

  return <Select isDisabled={disabled} className={setName + '-select'} name={setName + '-select'} id={setName + '-select'} value={initItem} options={options} onChange={handleChange} />
}

export default CustomSelect
