const _ = require('lodash')
import React, { useEffect, useState } from 'react'
import { Button, Container, Col, Row, Card } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter'
import cellEditFactory from 'react-bootstrap-table2-editor'
import Swal from 'sweetalert2'
import NotificationAlert from 'react-notification-alert'
import { getData, delData, putData, postData } from 'utils/server'
import { getAuthorizationHeader, notificationOptions, copyToClipboard } from 'utils/helpers'
import UniTranslation from 'components/Modals/UniTranslation'
import TableCounters from 'components/UniObject/TableCounters'
import ParticipantFixtures from 'components/Modals/ParticipantFixtures'
import MatchedFixture from 'views/MatchedFixture'

function UniParticipants({ forceUpdate, sports, informParent, downloadcsv, informer }) {
  const controller = new AbortController()
  var pos1 = 0,
    pos2 = 0,
    pos3 = 0,
    pos4 = 0
  const notificationAlertRef = React.useRef(null)
  const [tableData, setData] = useState([])
  const [allcounter, setAllCounter] = useState(0)
  const [filteredcounter, setFilteredcounter] = useState(0)
  const [sportFilters, setSportFilters] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [selectedParticipantId, setSelectedParticipantId] = useState(null)
  const [selectedParticipantName, setSelectedParticipantName] = useState(null)
  const [selectedparticipantuni, setSelectedParticipantUni] = useState(null)
  const [showFixturesModal, setShowFixturesModal] = useState(false)
  const [pid, setpid] = useState([])
  const [providerid, setProviderid] = useState([])
  const [sportId, setSportId] = useState([])
  const [uni_id, setuni_id] = useState([])
  const [matchedFixturesModal, setMatchedFixturesModal] = useState(false)
  const [matchedFixtures, setMatchedFixtures] = useState([])
  const [ProviderName, setProviderName] = useState([])

  const paginationOptions = {
    disablePageTitle: true,
    showTotal: false,
    sizePerPageList: [
      {
        text: '25',
        value: 20
      },
      {
        text: '50',
        value: 50
      }
    ]
  }

  function dragMouseDown(e) {
    e = e || window.event
    e.preventDefault()
    // get the mouse cursor position at startup:
    pos3 = e.clientX
    pos4 = e.clientY
    document.onmouseup = closeDragElement
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag
  }

  function elementDrag(e) {
    e = e || window.event
    e.preventDefault()
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX
    pos2 = pos4 - e.clientY
    pos3 = e.clientX
    pos4 = e.clientY

    let element = document.getElementById('matched-fixtures-holder')
    if (element) {
      // set the element's new position:
      element.style.top = element.offsetTop - pos2 + 'px'
      element.style.left = element.offsetLeft - pos1 + 'px'
    } else return
  }

  function closeDragElement() {
    // stop moving when mouse button is released:
    document.onmouseup = null
    document.onmousemove = null
  }

  window.checkedProviders = []

  window.handleCheckboxChange = function (event, pid, provider_id) {
    provider_id = parseInt(provider_id)
    pid = parseInt(pid)
    if (event.target.checked) {
      window.checkedProviders.push({ selectedparticipantuni, pid, provider_id })
    } else {
      const index = window.checkedProviders.findIndex(provider => provider.pid === pid && provider.provider_id === provider_id)
      if (index > -1) {
        window.checkedProviders.splice(index, 1)
      }
    }
  }

  window.handleInfoClick = function (event, pid, provider_id, sport_id, provider_name) {
    event.preventDefault()
    setpid(parseInt(pid))
    console.log(provider_name)
    setProviderName(provider_name)
    setProviderid(parseInt(provider_id))
    setSportId(parseInt(sport_id))
    setShowFixturesModal(true)
  }
  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleCloseFixtureModal = () => {
    setShowFixturesModal(false)
  }

  const handleMatchingInfoUniParticipant = async (participants, controller) => {
    setSelectedParticipantUni(participants.id)
    let result = await getData(`/api/providers/participants/bestmatches/${participants.id}`, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    // console.log(result.data)
    if (result == null) {
      Swal.fire('Error!', `Something went wrong.`, 'error')
      return
    }
    if (result.data.length === 0) {
      Swal.fire('No matches found!', ``, 'error')
      return
    }
    //setFixtures(result.data)
    let htmlText = '<div style="min-width:150px;text-align: left;"><ul>'
    let target_width = 100
    let provider_width = 100
    for (const element of result.data) {
      target_width = Math.max(target_width, element.name * 10)
      provider_width = Math.max(provider_width, element.provider_name.length * 10)
    }

    // Construct the HTML text for each element
    for (const element of result.data) {
      const uniIdClass = element.uni_id ? 'uniIdPresent' : ''
      htmlText += `<li class="${uniIdClass}" style="border-bottom: 1px solid;width: max-content; display: flex;">
      <input type="checkbox" id="${element.pid}" name="${element.pid}" style="margin: 8px;" onchange="window.handleCheckboxChange(event, '${element.pid}','${element.provider_id}')">
        <div class="rowCell" style="text-align: left;padding: 8px;min-width: max-content;">${element.pid}</div>
        <div class="${element.provider_name}Provider" style="text-align: center; padding: 8px;width: ${provider_width}px;">${element.provider_name}</div>
        <div class="rowCell" style="text-align: center;padding: 8px;width: ${target_width}px;">${element.name}</div>
        <div class="rowCell" style="text-align: center;padding: 8px;width: ${target_width}px;">${element.similarity.toFixed(2)}</div>
        <a  id="info-${element.pid}" href="#" style="background-color: #4CAF50; border: none; color: white; padding: 5px 10px; text-align: center; text-decoration: none; display: inline-block; font-size: 12px; margin: 4px 2px; cursor: pointer;" onclick="window.handleInfoClick(event, '${element.pid}', '${element.provider_id}','${participants.sport_id}')">
          <i class="fas fa-info"></i>
        </a>
      </li>`
    }
    htmlText += '</ul></div>'
    Swal.fire({
      title: `Best Matched:`,
      width: '80%',
      customClass: 'swal-wide',
      html: htmlText,
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: 'OK',
      denyButtonText: 'Cancel',
      icon: 'info',
      confirmButtonColor: '#87cb16',
      denyButtonColor: '#ff4a55'
    }).then(async result => {
      if (result.isConfirmed) {
        // Handle confirmation
        if (window.checkedProviders.length == 0) return Swal.fire('Error!', `No participants selected.`, 'error')
        postData('/api/providers/participants/bestmatch', window.checkedProviders, { Authorization: getAuthorizationHeader() }).then(response => {
          if (response == null) {
            notificationAlertRef.current.notificationAlert(notificationOptions('Error! Something went wrong.', 'danger'))
          } else {
            notificationAlertRef.current.notificationAlert(notificationOptions('Success! Participants have been matched.', 'success'))
          }
        })
      }
    })
  }
  const handleMatchingParticipants = async (participants, controller) => {
    let result = await getData(`/api/unis/participants/${participants.id}/relations`, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })

    console.log(result.data)

    let htmlText = '<div style="min-width:150px;text-align: left;"><ul>'

    let name_width = 100
    let provider_width = 115

    for (const element of result.data) {
      name_width = Math.max(name_width, element.name.length * 10)
      provider_width = Math.max(provider_width, element.provider.length * 10)
    }

    // Construct the HTML text for each element
    for (const element of result.data) {
      htmlText += `<li style="border-bottom: 1px solid;width: max-content; display: flex;">
      <div class="rowCell" style="text-align: left;padding: 8px;min-width: max-content;">${element.pid}</div>
          <div style="text-align: left;padding: 8px;min-width: 100px;">${element.sport}</div>
          <div class="${element.provider}Provider" style="text-align: center; padding: 8px;width: ${provider_width}px;">${element.provider}</div>
          <div class="rowCell" style="text-align: center; padding: 8px; width: ${name_width}px; white-space: nowrap;">${element.name}</div>          <a  id="info-${element.pid}" href="#" style="background-color: #4CAF50; border: none; color: white; padding: 5px 10px; text-align: center; text-decoration: none; display: inline-block; font-size: 12px; margin: 4px 2px; cursor: pointer;" onclick="window.handleInfoClick(event, '${element.pid}',  '${element.provider_id}','${participants.sport_id}','${element.provider}')">
          <i class="fas fa-info"></i>
        </a>
        </li>`
    }

    htmlText += '</ul></div>'

    Swal.fire({
      title: `Matched Participants:`,
      customClass: 'swal-wide',
      html: htmlText,
      showDenyButton: false,
      showConfirmButton: true,
      confirmButtonText: 'OK',
      denyButtonText: 'Cancel',
      icon: 'info',
      confirmButtonColor: '#87cb16',
      denyButtonColor: '#ff4a55'
    }).then(async result => {
      if (result.isConfirmed) {
        // Handle confirmation
      }
    })
  }

  const handleTranslationClick = row => {
    setSelectedParticipantId(row.id)
    setSelectedParticipantName(row.name)
    setShowModal(true)
  }

  const handleMatchedFixturesModal = event => {
    event.preventDefault()
    setMatchedFixturesModal(false)
  }

  const handleDeleteClick = row => {
    Swal.fire({ title: `Delete Participant ${row.name}?`, showDenyButton: true, showConfirmButton: true, confirmButtonText: 'Yes, delete it!', denyButtonText: 'Cancel', icon: 'warning', confirmButtonColor: '#87cb16', denyButtonColor: '#ff4a55' }).then(async result => {
      if (result.isConfirmed) {
        let result = await delData(`/api/unis/participants/${row.id}`, { Authorization: getAuthorizationHeader() })
        if (result != null) {
          Swal.fire('Deleted!', `Participant ${row.name} has been deleted.`, 'success')
          fetchData(controller)
          informer({ refresh: true })
        } else {
          Swal.fire('Error!', `Something went wrong.`, 'error')
        }
      }
    })
  }

  const fetchData = async controller => {
    if (sports.length == 0) return
    let response = await getData('/api/unis/participants', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    let tableData = []
    if (response != null) tableData = response.data
    setAllCounter(response.data.length)
    setData(tableData)
    informParent({ downloadcsv: true })
  }

  const handleFixturesClick = async row => {
    let apiUrl = `/api/fixture/participants/${row.id}/matchedFixtures/500`
    let response = await getData(apiUrl, { Authorization: getAuthorizationHeader() })

    if (response.data != []) {
      setMatchedFixtures(response.data)
      setMatchedFixturesModal(true)
    } else {
      setMatchedFixtures([])
      setMatchedFixturesModal(true)
    }
  }

  const downloadCsv = async () => {
    let csvData = _.sortBy(tableData, ['sport , name'])
    let csv = 'data:text/csv;charset=utf-8,'
    csv += 'Sport;Name\n'
    csvData.forEach(row => {
      csv += `${row.sport};${row.name}\n`
    })
    var encodedUri = encodeURI(csv)
    var link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', 'participants.csv')
    document.body.appendChild(link)
    link.click()
    informParent({ downloadcsv: true })
  }

  const changeCellValue = (oldValue, newValue, row, column, done) => {
    if (newValue == '') done(false)
    row.name = newValue
    putData(`/api/unis/participants/${row.id}`, row, { Authorization: getAuthorizationHeader() }).then(response => {
      if (response == null) {
        notificationAlertRef.current.notificationAlert(notificationOptions('Error! Cannot edit record.', 'danger'))
        done(false)
      } else {
        notificationAlertRef.current.notificationAlert(notificationOptions(`Participant ${row.id} has been Updated.`, 'success'))
        done(true)
      }
    })
    return { async: true }
  }

  const createFilters = () => {
    if (sports.length == 0) return
    let mySportFilters = {}
    for (let sport of sports) mySportFilters[sport.id] = sport.name
    setSportFilters(mySportFilters)
    fetchData(controller)
  }

  function afterFilter(newResult, newFilters) {
    setFilteredcounter(newResult.length)
  }

  useEffect(() => {
    if (downloadcsv) downloadCsv(controller)
  }, [downloadcsv])

  useEffect(() => {
    createFilters()
  }, [sports])

  useEffect(() => {
    if (Object.keys(sportFilters).length == 0) return
    fetchData(controller)
  }, [forceUpdate])

  useEffect(() => {
    return () => {
      controller.abort()
    }
  }, [])

  const columns = [
    {
      dataField: 'id',
      text: 'UNI ID',
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <div onClick={e => copyToClipboard(e, cellContent, notificationAlertRef)} style={{ cursor: 'copy' }}>
            <p>{cellContent}</p>
          </div>
        </>
      ),
      filter: textFilter()
    },
    {
      dataField: 'sport_id',
      text: 'Sport',
      formatter: cell => sportFilters[cell],
      filter: selectFilter({
        options: sportFilters
      }),
      editable: false
    },
    {
      dataField: 'name',
      text: 'Name',
      editable: true,
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'dm1',
      isDummyField: true,
      text: 'Actions',
      editable: false,
      formatter: (cellContent, row) => (
        <>
          <Button
            className="btn-simple btn-link p-1"
            type="button"
            //variant={row.matched.length > 0 ? 'dark' : 'secondary'}
            //disabled={row.matched.length > 0 ? false : true}
            onClick={() => {
              handleMatchingParticipants(row, controller)
            }}
            //title={'Matched Markets: ' + row.matched.length}
          >
            <i className="fas fa fa-info"></i>
          </Button>

          <Button
            title="Show Fixtures"
            className="btn-simple btn-link p-1"
            type="button"
            variant="success"
            onClick={() => {
              handleFixturesClick(row)
            }}
          >
            <i className="fas fa-tasks"></i>
          </Button>
          <Button
            className="btn-simple btn-link p-1"
            type="button"
            variant={row.length > 0 ? 'dark' : 'secondary'}
            onClick={() => {
              handleMatchingInfoUniParticipant(row, controller)
            }}
            //title={'Matched Markets: ' + row.matched.length}
          >
            <i className="fas fa-equals"></i>
          </Button>
          <Button
            title="Edit Translations"
            className="btn-simple btn-link p-1"
            type="button"
            variant="warning"
            onClick={() => {
              handleTranslationClick(row)
            }}
          >
            <i className="fas fa-comments"></i>
          </Button>
          <span>|</span>
          <Button
            title="Delete Participant"
            className="btn-simple btn-link p-1"
            type="button"
            variant="danger"
            onClick={() => {
              handleDeleteClick(row)
            }}
          >
            <i className="fas fa-times"></i>
          </Button>
        </>
      )
    }
  ]

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="6">
            <Card id="matched-fixtures-holder" style={{ display: matchedFixturesModal ? '' : 'none' }}>
              <Card.Header>
                <Row>
                  <Col md="11">
                    <Card.Title as="h6" onMouseDown={dragMouseDown} name="provider-fixtures-holder" style={{ cursor: 'move' }}>
                      Matched Fixtures
                    </Card.Title>
                  </Col>
                  <Col md="1" style={{ marginTop: '-14px', textAlign: 'right' }}>
                    <Button
                      className="btn-simple btn-link p-1"
                      type="button"
                      variant="danger"
                      onClick={event => {
                        handleMatchedFixturesModal(event)
                      }}
                    >
                      <i className="fas fa-times"></i>
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Col md="12">
                  <div style={{ fontSize: '12px' }}>
                    <div id="main-info-holder"></div>

                    <div style={{ overflowX: 'auto', height: '205px' }}>
                      {matchedFixtures.map((item, index) => {
                        item.date = new Date(item.start_date * 1)
                        item.dateString = item.date.getDate() + '-' + (item.date.getMonth() + 1) + '-' + item.date.getFullYear()
                        let hours = item.date.getHours() < 10 ? '0' + item.date.getHours() : item.date.getHours()
                        let minutes = item.date.getMinutes() < 10 ? '0' + item.date.getMinutes() : item.date.getMinutes()
                        item.hourString = hours + ':' + minutes
                        item.home = item.home
                        item.away = item.away
                        return <MatchedFixture id={'matchedFixture-' + item.event_id} key={'matchedFixture-' + item.event_id + index} fix={item}></MatchedFixture>
                      })}
                    </div>
                  </div>
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <div style={{ width: '100%', height: '100vh', position: 'absolute', right: '-100%', overflowX: 'auto' }}>
        <ParticipantFixtures showModal={showFixturesModal} pid={pid} providerId={providerid} providerName={ProviderName} sportId={sportId} signalCloseModal={handleCloseFixtureModal}></ParticipantFixtures>
      </div>
      <UniTranslation showModal={showModal} entityId={selectedParticipantId} entityName={selectedParticipantName} entityType={'participants'} entityKey={'participant_id'} signalCloseModal={handleCloseModal} />
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <TableCounters all={allcounter} filtered={filteredcounter}></TableCounters>
      <BootstrapTable bootstrap4 keyField="id" data={tableData} columns={columns} pagination={paginationFactory({ sizePerPage: 10 })} filter={filterFactory({ afterFilter })} cellEdit={cellEditFactory({ mode: 'click', beforeSaveCell: changeCellValue })} />
    </>
  )
}
export default UniParticipants
