import React, { useState, useEffect } from 'react'
import { Card, Container, Row, Col, Button } from 'react-bootstrap'
import MarketGroupsTable from 'components/Tables/MarketGroups'
import CreateMarketGroup from 'components/Modals/CreateMarketGroup'

import { getData, delData, putData } from 'utils/server'
import { getAuthorizationHeader } from 'utils/helpers'
import { validateViewCredentials } from 'utils/auth'

function MarketGroups(props) {
  validateViewCredentials(props.routeInfo)
  const controller = new AbortController()

  const [showCreateMarketGroupModal, setCreateMarketGroupModal] = useState(false)
  const [updateMarketGroup, setUpdateMarketGroup] = useState(false)
  const [uniSports, setUniSports] = useState([])

  const handleCreateMarketGroup = () => {
    setCreateMarketGroupModal(true)
  }

  const handleCloseModal = uMarketGroup => {
    setCreateMarketGroupModal(false)
    if (uMarketGroup) {
      updateMarketGroup == true ? setUpdateMarketGroup(false) : setUpdateMarketGroup(true)
    }
  }

  const fetchSports = async controller => {
    let response = await getData('/api/sports', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (response != null) {
      setUniSports(response.data)
    }
  }

  useEffect(() => {
    fetchSports(controller)

    return () => {
      controller.abort()
    }
  }, [])

  return (
    <>
      <Container fluid>
        <CreateMarketGroup showModal={showCreateMarketGroupModal} signalCloseModal={handleCloseModal} sports={uniSports} />
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col md="8">
                    <Card.Title as="h4">Market Group</Card.Title>
                  </Col>
                  <Col md="4">
                    <Button className="create-group-btn" variant="success" onClick={handleCreateMarketGroup}>
                      Create Market Group
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Col md="12">
                  <MarketGroupsTable forceUpdate={updateMarketGroup} sports={uniSports} />
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default MarketGroups
