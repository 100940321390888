import { useEffect } from 'react'

export function isUserOperator() {
  let userInfoStorage = localStorage.getItem('userInfo')
  if (!userInfoStorage) return false
  let userInfo = JSON.parse(userInfoStorage)
  if (userInfo.role_id == 2) return true
  return false
}

export function validateViewCredentials(view) {
  useEffect(() => {
    if (view.allowOperators == false && isUserOperator()) window.location.replace('/logout')
  }, [])
}
