function SelectedCounter({ selected, all }) {
    return (
      <>
        <div style={{ marginBottom: '10px', textAlign: 'right' }}>
          <span>
            Selected: {selected} / {all}
            <span style={{ fontSize: '12px' }}>(Total)</span>
          </span>
        </div>
      </>
    )
  }
  
  export default SelectedCounter